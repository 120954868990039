import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { FormFeedback, FormGroup, Input, Label } from 'reactstrap';
import confirm from 'reactstrap-confirm';
import { urlSlugify } from 'utils/helper';

const SlugInput = (props) => {
  const {
    formik,
    ID,
    nameValue,
    formGroupClassName,
    required,
    formFeedbackClassName,
    isDisabled,
    showResetRegenerate,
    currentSlug,
  } = props;
  const [showHidePassword, setShowHidePassword] = useState(false);

  useEffect(() => {
    if (isEmpty(ID)) {
      if (!isEmpty(nameValue)) {
        const slugValue = urlSlugify(nameValue);
        formik.setFieldValue('slug', slugValue);
      }
    }
  }, [nameValue]);

  const handleEditSlug = async () => {
    const result = await confirm({
      title: 'Slug Edit',
      message: 'Are you sure, you want to edit this slug ?',
      confirmText: 'Confirm',
      confirmColor: 'primary',
      cancelColor: 'link text-danger',
    });

    if (result) {
      setShowHidePassword(!showHidePassword);
    } else {
      setShowHidePassword(false);
    }
  };

  return (
    <FormGroup className={`${formGroupClassName ? formGroupClassName : 'mt-3'} form-group`}>
      <div className="mt-4 mb-2 d-flex align-items-center justify-content-between">
        <Label for="slug" className="form-label mb-0">
          <span className="pe-2">Slug</span>
          {required ? <span className="text-danger">*</span> : ''}
        </Label>

        {showResetRegenerate && showHidePassword && (
          <div className="text-end">
            <a
              className={` `}
              onClick={() => {
                formik.setFieldValue('slug', currentSlug);
              }}>
              Revert to Original
            </a>
            <a
              className={` ms-3`}
              onClick={() => {
                formik.setFieldValue('slug', urlSlugify(nameValue));
              }}>
              Regenerate using title
            </a>
          </div>
        )}
      </div>

      {isEmpty(ID) ? (
        <Input
          id="slug"
          type="text"
          className={`form-control`}
          placeholder="Slug..."
          label=" slug"
          invalid={!!(formik.touched.slug && formik.errors.slug)}
          {...formik.getFieldProps('slug')}
          error={!isEmpty(formik.errors.slug)}
        />
      ) : (
        <>
          <Input
            id="slug"
            type="text"
            disabled={isDisabled ? isDisabled : !showHidePassword ? true : false}
            className={`form-control position-relative ${!showHidePassword && 'bg-light'}`}
            placeholder="Slug..."
            label=" slug"
            invalid={!!(formik.touched.slug && formik.errors.slug)}
            {...formik.getFieldProps('slug')}
            error={!isEmpty(formik.errors.slug)}
          />
          {!isDisabled && (
            <i
              style={{
                position: 'absolute',
                cursor: 'pointer',
                right: '30px',
                top: '56.5%',
              }}
              className={`${showHidePassword ? 'mdi mdi-lock-open' : 'mdi mdi-lock'}`}
              onClick={handleEditSlug}
            />
          )}
        </>
      )}

      <FormFeedback className={formFeedbackClassName ? formFeedbackClassName : ''}>{formik.errors.slug}</FormFeedback>
    </FormGroup>
  );
};

export default SlugInput;
