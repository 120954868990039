import { useGoogleLogin } from '@react-oauth/google';
import React from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { doSocialLogin } from './actions';

const GoogleLoginComponent = ({ userType }) => {
  const dispatch = useDispatch();

  const login = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      try {
        const res = await fetch('https://www.googleapis.com/oauth2/v3/userinfo', {
          headers: {
            Authorization: `Bearer ${tokenResponse.access_token}`,
          },
        });
        const googleUserData = await res.json();
        const userResponse = {
          name: googleUserData.given_name + ' ' + googleUserData.family_name || '',
          email: googleUserData.email || '',
          googleId: googleUserData.sub || '',
          imageUrl: googleUserData.picture || '',
          device: 0,
        };

        dispatch(doSocialLogin.trigger({ userObj: userResponse, userType }));
      } catch (error) {
        toast.error('Failed to fetch user data');
        console.error(error);
      }
    },
    onError: (err) => toast.error('Login failed: ' + err.message),
  });

  return (
    <div className="mt-3">
      <button
        type="button"
        className="btn btn-light w-100"
        onClick={() => {
          login();
        }}>
        Sign in with Google 🚀
      </button>
    </div>
  );
};

export default GoogleLoginComponent;
