import Loader from 'components/Loader';
import SlugInput from 'components/SlugInput';
import SmkAsyncSelect from 'components/SmkAsyncSelect';
import { useFormik } from 'formik';
import isEmpty from 'lodash/isEmpty';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toastify';
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap';
import { API_BASE_URL, LANGUAGE_ARRAY_WITH_STRING, MODULE_TYPE } from 'utils/constants';
import * as Yup from 'yup';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import useRequest from '../../../hooks/useRequest';
import { SMKLinks } from '../../../utils/links';
import AssetImageUploader from '../BlogList/components/AssetImageUploader';
import SeoMetaData from '../BlogList/components/SeoMetaData';
import { getTranslationLabel } from 'utils/helper';

const AddUpdateCategories = () => {
  const history = useHistory();
  const { id: categoryId } = useParams();

  toast.configure();

  const [categoryList, setCategoryList] = useState();
  const [categorydetails, setCategorydetails] = useState();

  const rootCategory = categoryList?.filter((e) => e?.parentCategoryId === null);
  const rootCategorySelect = rootCategory?.map((e) => ({
    value: e?.id,
    label: e?.name,
  }));
  const parentCategorySelect = categoryList?.map((e) => ({
    value: e?.id,
    label: e?.name,
  }));

  const [categoryListRequest, { loading: categoryListLoading }] = useRequest({
    method: 'POST',
    url: `/article/admin/categories/search`,
    onSuccess: (data) => {
      setCategoryList(data?.results);
    },
  });

  const [getCategoryRequest, { loading: getCategoryLoading }] = useRequest({
    url: `/article/admin/categories/${categoryId}`,
    method: 'GET',
    onSuccess: (data) => {
      setCategorydetails(data);
    },
    onError: (err) => {
      toast.error(err?.message);
      console.log(err);
    },
  });

  const [addCategoryRequest, { loading: addCategoryLoading }] = useRequest({
    url: `/article/admin/categories`,
    method: 'POST',
    onSuccess: (data) => {
      const url = SMKLinks.BLOG_CATEGORIES_LIST;
      history.replace(url);
      toast.success(`Success! Category ( ${data?.id} ) has been added.`);
    },
    onError: (err) => {
      toast.error(err?.message);
      console.log(err);
    },
  });

  const [updateCategoryRequest, { loading: updateCategoryLoading }] = useRequest({
    url: `/article/admin/categories/${categoryId}`,
    method: 'PUT',
    onSuccess: (data) => {
      toast.success(`Success! Category ( ${data?.id} ) has been updated.`);

      if (!isEmpty(categorydetails)) {
        window.location.reload();
      } else {
        const url = SMKLinks.BLOG_CATEGORIES_LIST;
        history.replace(url);
      }
    },
    onError: (err) => {
      toast.error(err?.message);
      console.log(err);
    },
  });

  const editInitialValues = () => {
    const parentCategoryObj = parentCategorySelect?.find((e) => e?.value === categorydetails?.parentCategoryId);
    const rootCategoryObj = rootCategorySelect?.find((e) => e?.value === categorydetails?.rootCategoryId);

    return {
      id: categorydetails?.id,
      active: categorydetails?.active,
      deleted: categorydetails?.deleted,
      name: categorydetails?.name,
      slug: categorydetails?.slug,
      description: categorydetails?.description,
      parentCategory: !isEmpty(parentCategoryObj) ? parentCategoryObj : null,
      rootCategory: !isEmpty(rootCategoryObj) ? rootCategoryObj : null,

      language: categorydetails?.language,
      translations: !isEmpty(categorydetails?.translations) ? categorydetails?.translations[0] : {},
      seoTitle: categorydetails?.seo?.title || '',
      seoDescription: categorydetails?.seo?.description || '',
      seoKeywords: !isEmpty(categorydetails?.seo?.keywords)
        ? categorydetails?.seo?.keywords?.split(', ')?.map((e) => ({
            label: e,
            value: e,
          }))
        : '',
      ogImage: categorydetails?.seo?.ogImage || {},
      image: categorydetails?.image || {},
    };
  };

  const getInitialValues = () => ({
    active: true,
    deleted: false,
    name: '',
    slug: '',
    description: '',
    language: LANGUAGE_ARRAY_WITH_STRING[0]?.value,
    parentCategory: '',
    rootCategory: '',

    seoTitle: '',
    seoDescription: '',
    seoKeywords: '',
    ogImage: {},

    image: {},
    translations: '',
  });

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: false,
    initialValues: getInitialValues(),
    onSubmit: (values) => {
      const data = {
        active: values?.active,
        deleted: values?.deleted,
        name: values?.name,
        slug: values?.slug,
        description: values?.description,
        ...(!isEmpty(values?.parentCategory)
          ? {
              parentCategoryId: values?.parentCategory?.value,
              parentCategory: undefined,
            }
          : { parentCategoryId: null }),
        ...(!isEmpty(values?.rootCategory)
          ? {
              rootCategoryId: values?.rootCategory?.value,
              rootCategory: undefined,
            }
          : { rootCategoryId: null }),

        language: values?.language,
        translations: !isEmpty(values?.translations) ? [values?.translations?.id] : [],
        seo: {
          title: !isEmpty(values?.seoTitle) ? values?.seoTitle : '',
          description: !isEmpty(values?.seoDescription) ? values?.seoDescription : '',
          keywords: !isEmpty(values?.seoKeywords) ? values?.seoKeywords?.map((e) => e?.value).join(', ') : '',
          ...(!isEmpty(values?.ogImage)
            ? {
                ogImageId: values?.ogImage?.id,
                ogImage: undefined,
              }
            : { ogImageId: null }),
        },
        ...(!isEmpty(values?.image)
          ? {
              imageId: values?.image?.id,
              image: undefined,
            }
          : { imageId: null, image: undefined }),

        seoTitle: undefined,
        seoDescription: undefined,
        seoKeywords: undefined,
        ogImage: undefined,
      };

      isEmpty(categoryId)
        ? addCategoryRequest({
            body: {
              ...data,
            },
          })
        : updateCategoryRequest({
            body: {
              ...data,
            },
          });
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required('Please provide name'),
    }),
  });

  useEffect(() => {
    if (!isEmpty(categoryId)) {
      getCategoryRequest();
    }
  }, [categoryId]);

  useEffect(() => {
    if (!isEmpty(categorydetails) && !isEmpty(categoryList)) {
      formik.setValues(editInitialValues());
    }
  }, [categorydetails, categoryList]);

  useEffect(() => {
    if (!isEmpty(formik.values.language)) {
      categoryListRequest({
        body: {
          page: 1,
          size: 500,
          sortBy: 'id',
          sortOrder: 0,
          language: formik.values.language,
        },
      });
    }
  }, [formik.values.language]);

  return (
    <div className="page-content">
      <Loader isActive={addCategoryLoading || getCategoryLoading || updateCategoryLoading || categoryListLoading} />
      <Container fluid>
        <Breadcrumbs title="Location" breadcrumbItem={`${isEmpty(categoryId) ? 'Add' : 'Edit'}  Blog Category`} />

        <Form onSubmit={formik.handleSubmit}>
          <Row>
            <Col md={9}>
              <Card>
                <CardBody>
                  <Row className="align-items-center">
                    <Col md={6}>
                      <FormGroup className="mb-4">
                        <Label for="rootCategory" className="form-label  ">
                          Root Category
                        </Label>
                        <Select
                          isClearable
                          id="rootCategory"
                          options={rootCategorySelect}
                          value={formik?.values?.rootCategory}
                          onChange={(rootCategory) => formik.setFieldValue('rootCategory', rootCategory)}
                        />
                        <FormFeedback>{formik.errors.rootCategory}</FormFeedback>
                      </FormGroup>
                    </Col>

                    <Col md={6}>
                      <FormGroup className="mb-4">
                        <Label for="parentCategory" className="form-label  ">
                          Parent Category
                        </Label>
                        <Select
                          isClearable
                          id="parentCategory"
                          options={parentCategorySelect}
                          value={formik?.values?.parentCategory}
                          onChange={(parentCategory) => formik.setFieldValue('parentCategory', parentCategory)}
                        />
                        <FormFeedback>{formik.errors.parentCategory}</FormFeedback>
                      </FormGroup>
                    </Col>

                    <Col md={12}>
                      <FormGroup className="mb-4">
                        <Label for="name" className="form-label  ">
                          Name
                        </Label>
                        <Input
                          id="name"
                          name="name"
                          type="text"
                          className="form-control"
                          placeholder="Name.."
                          invalid={!!(formik.touched.name && formik.errors.name)}
                          {...formik.getFieldProps('name')}
                        />
                        <FormFeedback>{formik.errors.name}</FormFeedback>
                      </FormGroup>
                    </Col>

                    <Col md={12} className="mb-4">
                      <SlugInput
                        formik={formik}
                        ID={categoryId}
                        nameValue={formik.values.name}
                        formGroupClassName={'mt-0'}
                        showResetRegenerate={true}
                        currentSlug={categorydetails?.slug}
                      />
                    </Col>

                    <Col md={12}>
                      <FormGroup className="mb-4">
                        <Label for="description" className="form-label  ">
                          Description
                        </Label>
                        <Input
                          id="description"
                          name="description"
                          style={{ height: '100px' }}
                          type="textarea"
                          className="form-control"
                          placeholder="Description.."
                          invalid={!!(formik.touched.description && formik.errors.description)}
                          {...formik.getFieldProps('description')}
                        />
                        <FormFeedback>{formik.errors.description}</FormFeedback>
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
              </Card>

              <SeoMetaData formik={formik} />
            </Col>
            <Col md={3}>
              <Card>
                <CardBody>
                  <Button type="submit" color="primary" className="mb-3 w-100">
                    {categoryId ? 'Save' : 'Add'}
                  </Button>

                  <Button
                    className="w-100"
                    color="light"
                    onClick={() => {
                      history.replace(SMKLinks.BLOG_CATEGORIES_LIST);
                    }}>
                    Cancel
                  </Button>
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  <div>
                    <CardTitle className="mb-3">Active </CardTitle>
                    {[true, false].map((option, i) => (
                      <div key={`active_${i}`} className="form-check mb-3 d-inline-block me-4">
                        <input
                          type="radio"
                          id={`active_${option}`}
                          name="active"
                          className="form-check-input"
                          checked={formik.values.active === option}
                          onChange={() => formik.setFieldValue('active', option)}
                        />
                        <label className="form-check-label" htmlFor={`active_${option}`}>
                          {option ? 'Yes' : 'No'}
                        </label>
                      </div>
                    ))}
                  </div>

                  <div>
                    <CardTitle className="mb-3">Delete </CardTitle>
                    {[true, false].map((option, i) => (
                      <div key={`deleted_${i}`} className="form-check mb-3 d-inline-block me-4">
                        <input
                          type="radio"
                          id={`deleted_${option}`}
                          name="deleted"
                          className="form-check-input"
                          checked={formik.values.deleted === option}
                          onChange={() => formik.setFieldValue('deleted', option)}
                        />
                        <label className="form-check-label" htmlFor={`deleted_${option}`}>
                          {option ? 'Yes' : 'No'}
                        </label>
                      </div>
                    ))}
                  </div>
                </CardBody>
              </Card>

              <Card className="border">
                <CardBody>
                  <Row>
                    <Col md={12}>
                      <CardTitle className="mb-3">Language </CardTitle>
                      {LANGUAGE_ARRAY_WITH_STRING?.map((option, i) => (
                        <div key={`language_${i}`} className="form-check mb-3 d-inline-block me-4">
                          <input
                            type="radio"
                            id={`language_${option?.value}`}
                            name="language"
                            className="form-check-input"
                            checked={formik.values.language === option?.value}
                            onChange={() => formik.setFieldValue('language', option?.value)}
                          />
                          <label className="form-check-label" htmlFor={`language_${option?.value}`}>
                            {option?.label}
                          </label>
                        </div>
                      ))}
                    </Col>
                    <Col md={12}>
                      <CardTitle className="mb-3">Translation </CardTitle>
                      <SmkAsyncSelect
                        isDisabled={formik.values?.language === 'hi'}
                        acceptedKey={'name'}
                        acceptedValue={'id'}
                        placeholder="Type article name.."
                        onChange={(res) => {
                          formik.setFieldValue('translations', res);
                        }}
                        isValueSlugify={true}
                        fetchUrl={`${API_BASE_URL}/article/admin/categories/search`}
                        filters={{
                          autoComplete: true,
                          active: true,
                          page: 1,
                          size: 500,
                          sortBy: 'id',
                          sortOrder: 0,
                          language: formik.values.language === 'en' ? 'hi' : 'en',
                        }}
                        searchKeyName="name"
                        value={
                          !isEmpty(formik?.values?.translations)
                            ? {
                                label: getTranslationLabel(formik?.values?.translations),
                                value: formik?.values?.translations?.id,
                              }
                            : ''
                        }
                      />

                      {!isEmpty(formik?.values?.translations) && (
                        <div className="mt-3">
                          <div className="card bg-light">
                            <div className="card-body">
                              <a target="_blank" href={`/blog/category/edit/${formik?.values?.translations?.id}`}>
                                <p className="mb-1">
                                  <span className="text-dark">Language:</span> {formik?.values?.translations?.language}
                                </p>
                                <p className="mb-1">
                                  <span className="text-dark">ID:</span> {formik?.values?.translations?.id}
                                </p>
                                <p className="mb-1">
                                  <span className="text-dark">Title:</span>{' '}
                                  <span
                                    dangerouslySetInnerHTML={{
                                      __html: getTranslationLabel(formik?.values?.translations),
                                    }}></span>
                                </p>
                              </a>
                            </div>
                          </div>
                        </div>
                      )}
                    </Col>
                  </Row>
                </CardBody>
              </Card>

              <AssetImageUploader
                forType={MODULE_TYPE.ARTICLE}
                formik={formik}
                fieldName={'image'}
                label={'Select Image'}
              />
            </Col>
          </Row>
        </Form>
      </Container>
    </div>
  );
};

export default AddUpdateCategories;
