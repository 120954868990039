import React, { useEffect, useState } from 'react';
import { Card, CardBody, CardTitle } from 'reactstrap';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import useRequest from '../../../hooks/useRequest';
import { printDateTime } from '../../../utils/helper';

const ActivityTypeByLastUsage = () => {
  const [activities, setActivities] = useState([]);
  const [getLastUsedActivitiesRequest, { loading: getLastUsedActivitiesLoading }] = useRequest({
    url: `/patient-tracking/admin-tools/app-last-used-activity`,
    method: 'POST',
    onSuccess: (data) => {
      setActivities(data?.results);
    },
    onError: (err) => {
      toast.error(err?.message?.message || err?.message);
    },
  });

  useEffect(() => {
    getLastUsedActivitiesRequest();
  }, []);

  return (
    <>
      <Card>
        <CardBody>
          <CardTitle className="mb-4">Activity Type By Last Usage Done</CardTitle>
          <div className="table-responsive" style={{ minHeight: 300, maxHeight: 300 }}>
            <table className="table table-centered table-nowrap mb-0">
              <thead className="thead-light">
                <tr>
                  {/* <th>ID</th> */}
                  <th>Activity Type</th>
                  <th>Last Used</th>
                </tr>
              </thead>
              <tbody>
                {activities.map((transaction, key) => (
                  <tr key={`_tr_${key}`}>
                    {/* <td>
                      <Link to="#" className="text-body font-weight-bold">
                        {transaction.activityId}
                      </Link>
                    </td> */}
                    <td>{transaction.activity}</td>
                    <td>{printDateTime(transaction.last_used)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </CardBody>
      </Card>
    </>
  );
};

export default ActivityTypeByLastUsage;
