import { useState } from 'react';
import config from '../config/config';
import { api } from '../utils/axios';
import AuthService from 'common/auth.service';

const useRequest = ({ method, url, headers, body: data, onSuccess, onError, source }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const doRequest = async (overrideOptions) => {
    try {
      setLoading(true);

      const authService = new AuthService();
      const appSource = authService.getAppSourceCookie();
      const API_KEY =
        appSource === 'theparentz'
          ? process.env.REACT_APP_TPZ_API_KEY
          : appSource === 'mediwatcher'
          ? process.env.REACT_APP_MEDIWATCHER_API_KEY
          : process.env.REACT_APP_API_KEY;

      const axiosConfig = {
        method,
        data,
        ...overrideOptions,
        url: `${config.apiEndpoint}${overrideOptions?.url || url}`,
      };

      axiosConfig.headers = {
        ...overrideOptions?.headers,
        ...headers,
        'x-api-key': API_KEY,
      };

      if (appSource === 'theparentz') {
        delete axiosConfig['headers']['x-api-key'];
        axiosConfig.headers = {
          'x-api-key': process.env.REACT_APP_TPZ_API_KEY,
        };
      }

      if (overrideOptions?.body) {
        axiosConfig.data = overrideOptions.body;
      }

      const response = await api(axiosConfig, axiosConfig?.headers);

      // if (url.indexOf('/v1/') < 0) {
      //   response = response?.data;
      // }

      // console.log({ response });

      if (response.success === false) {
        console.log('Error found!');
        if (onError) {
          onError(response);
        }
      } else if (onSuccess) {
        onSuccess(response);
      }

      return response;
    } catch (err) {
      console.log({ err });
      const { response, message } = err;

      if (response) {
        setError(response);
        if (onError) {
          onError(response);
        }
      } else {
        setError({ message });
        if (onError) {
          onError({ message });
        }
      }
    } finally {
      setLoading(false);
    }
  };

  return [doRequest, { loading, error }];
};

export default useRequest;
