import { Divider } from '@material-ui/core';
import Breadcrumbs from 'components/Common/Breadcrumb';
import TableError from 'components/TableError';
import { createTreeStructure, showImageError } from 'containers/blog/helper';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toastify';
import {
  Badge,
  Card,
  CardBody,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Table,
  UncontrolledDropdown,
} from 'reactstrap';
import { buildURL, checkIfValueExist, showImage } from 'utils/helper';
import { SMKLinks } from 'utils/links';
import Loader from '../../../components/Loader';
import Paginate from '../../../components/Paginate';
import useRequest from '../../../hooks/useRequest';
import { useRouter } from '../../../hooks/useRouter';
import { ACTIVE_AND_DELETED_ARRAY, LANGUAGE_ARRAY, LANGUAGE_VALUES, sortBySearchData } from '../../../utils/constants';

const SmkTaxonomyListing = () => {
  const router = useRouter();
  const history = useHistory();
  toast.configure();

  const [taxonomyList, setTaxonomyList] = useState([]);
  const [taxonomyPagination, setTaxonomyPagination] = useState({});

  const defaultFilters = {
    active: { value: null, label: 'All' },
    deleted: { value: null, label: 'All' },
    sortBy: { value: 'createdDate', label: 'Created Date' },
    language: LANGUAGE_ARRAY[0],
    name: '',
  };

  const [filters, setFilters] = useState(defaultFilters);

  const [gettaxonomyListRequest, { loading: gettaxonomyListLoading }] = useRequest({
    source: 'smk',
    url: `/common/admin/taxonomy/search`,
    method: 'POST',
    onSuccess: (data) => {
      const categoryTree = createTreeStructure(data?.results, 'parentId');
      setTaxonomyList(categoryTree);
      setTaxonomyPagination(data?.pagination || data?.paginator);
    },
  });

  const [updateTaxonomyRequest, { loading: updateTaxonomyLoading }] = useRequest({
    source: 'smk',
    onSuccess: (data) => {
      handleChangePage(1);
      toast.success(`Success! Taxonomy has been updated.`);
    },
  });

  const setPageInUrl = (page) => {
    history.push({
      pathname: router.pathname,
      search: `?${new URLSearchParams({ page }).toString()}`,
    });
  };

  const handleChangePage = (page) => {
    setPageInUrl(page);

    gettaxonomyListRequest({
      body: {
        page,
        size: 50,
        sortOrder: 1,
        language: filters?.language?.value,
        sortBy: checkIfValueExist(filters.sortBy?.value),
        active: checkIfValueExist(filters.active?.value),
        deleted: checkIfValueExist(filters.deleted?.value),
        name: checkIfValueExist(filters.name),
      },
    });
  };

  const applyFilters = () => {
    handleChangePage(1);
  };

  const clearFilters = () => {
    setPageInUrl(1);

    setFilters({ ...defaultFilters });
    gettaxonomyListRequest({
      body: {
        page: 1,
        size: 50,
        sortOrder: 1,
        language: defaultFilters?.language?.value,
        active: checkIfValueExist(defaultFilters.active?.value),
        deleted: checkIfValueExist(defaultFilters.deleted?.value),
        sortBy: checkIfValueExist(defaultFilters.sortBy?.value),
        name: checkIfValueExist(defaultFilters.name),
      },
    });
  };

  useEffect(() => {
    handleChangePage(parseInt(router?.query?.page, 10) || 1);
  }, []);

  const handleUpdateTaxonomy = (data, key) => {
    if (data[key]) {
      updateTaxonomyRequest({
        method: 'PUT',
        url: `/common/admin/taxonomy/${data?.id}`,
        body: {
          [key]: false,
        },
      });
    } else {
      updateTaxonomyRequest({
        method: 'PUT',
        url: `/common/admin/taxonomy/${data?.id}`,
        body: {
          [key]: true,
          ...(key === 'deleted' ? { active: false } : {}),
        },
      });
    }
  };

  const renderCategoryRow = (category, level = 0) => {
    return (
      <React.Fragment key={category?.id}>
        <tr className={category?.active ? '' : 'bg-danger text-emphasis-warning'}>
          <td>
            {!isEmpty(category?.image) && (
              <a href={showImage(category?.image?.original)} target="_blank">
                <img
                  src={showImage(category?.image?.original)}
                  width={40}
                  height={40}
                  onError={(e) => {
                    showImageError(e);
                  }}
                />
              </a>
            )}
          </td>
          <td>
            <Link
              to={buildURL(SMKLinks.EDIT_SMK_TAXONOMY, {
                id: category?.id,
              })}>
              {category?.name}
            </Link>
            <br />
            {category?.slug}
          </td>
          <td>
            {!isEmpty(category.parent) && (
              <Link
                to={buildURL(SMKLinks.EDIT_SMK_TAXONOMY, {
                  id: category?.parent?.id,
                })}>
                {category?.parent?.name}
              </Link>
            )}
          </td>
          <td>
            {!isEmpty(category.translations) && (
              <Link
                to={buildURL(SMKLinks.EDIT_SMK_TAXONOMY, {
                  id: category?.translations[0]?.id,
                })}>
                Hindi Translation
              </Link>
            )}
          </td>
          <td>
            <Badge className={`${category?.language == 0 ? 'bg-info' : 'bg-warning'} p-1`} style={{ fontSize: '10px' }}>
              {LANGUAGE_VALUES[category?.language]}
            </Badge>
          </td>
          <td className="text-center">
            <i
              className={`fas  ${
                category?.approved ? 'fa-check-circle text-success' : 'fa-times-circle text-danger'
              }   m-1 pe-3 fs-4`}
            />
          </td>
          <td>
            <Badge className={category?.isPopular ? 'bg-success' : 'bg-danger'}>
              {category?.isPopular ? 'Yes' : 'No'}
            </Badge>
          </td>

          <td>
            <Badge className={category?.active ? 'bg-success' : 'bg-danger'}>{category?.active ? 'Yes' : 'No'}</Badge>
          </td>
          <td>
            <Badge className={category?.deleted ? 'bg-success' : 'bg-danger'}>{category?.deleted ? 'Yes' : 'No'}</Badge>
          </td>
          <td>
            <UncontrolledDropdown>
              <DropdownToggle href="#" className="card-drop " tag="i">
                <i role="button" className="mdi mdi-dots-vertical font-size-18 " />
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-end">
                <DropdownItem onClick={() => handleUpdateTaxonomy(category, 'approved')}>
                  <i
                    className={`fas  ${
                      category?.approved ? 'fa-thumbs-down text-danger' : 'fa-thumbs-up text-primary'
                    }   m-1 pe-3`}
                  />
                  {category?.approved ? 'UnApprove' : 'Approve'}
                </DropdownItem>
                <DropdownItem onClick={() => handleUpdateTaxonomy(category, 'active')}>
                  <i
                    className={`fas  ${
                      category?.active ? 'fa-ban text-danger' : 'fa-power-off text-success'
                    }   m-1 pe-3`}
                  />
                  {category?.active ? 'Disable' : 'Enable (Active)'}
                </DropdownItem>
                <DropdownItem onClick={() => handleUpdateTaxonomy(category, 'deleted')}>
                  <i className="fas fa-trash-alt text-danger m-1 pe-3" />
                  {category?.deleted ? 'Undelete' : 'Delete'}
                </DropdownItem>
                <Divider className="my-2" />

                <Link
                  to={buildURL(SMKLinks.EDIT_SMK_TAXONOMY, {
                    id: category?.id,
                  })}>
                  <DropdownItem>
                    <i className="fas fa-pencil-alt text-success m-1 pe-3 " />
                    Edit
                  </DropdownItem>
                </Link>
              </DropdownMenu>
            </UncontrolledDropdown>
          </td>
        </tr>

        {category?.subcategories && category?.subcategories.length > 0 && (
          <tr>
            <td
              colSpan="10"
              style={{
                background: 'transparent',
                borderLeft: '1px solid #121212',
                paddingLeft: `${level === 0 ? 40 : level * 40}px`,
              }}>
              <div className="table-responsive">
                <Table className="project-list-table mt-0 table-centered table-borderless">
                  <thead>
                    <tr className="table-warning">
                      <th scope="col"></th>
                      <th scope="col">Name/Slug</th>
                      <th scope="col">Parent Category</th>
                      <th scope="col">Translation</th>
                      <th scope="col">Language</th>
                      <th scope="col">Approve Status</th>
                      <th scope="col">isPopular</th>
                      <th scope="col">Active</th>
                      <th scope="col">Deleted</th>
                      <th scope="col" />
                    </tr>
                  </thead>
                  <tbody>{category?.subcategories.map((sub) => renderCategoryRow(sub, level + 1))}</tbody>
                </Table>
              </div>
            </td>
          </tr>
        )}
      </React.Fragment>
    );
  };

  return (
    <>
      <div className="page-content">
        <Loader isActive={gettaxonomyListLoading || updateTaxonomyLoading} />
        <Container fluid>
          <Breadcrumbs title="Taxonomy" breadcrumbItem={`SMK Taxonomy List`} />

          <Card>
            <CardBody>
              <div className="mt-4">
                <div className="row">
                  <div className="col-lg-2 mb-4">
                    <label className="form-label">Active</label>
                    <Select
                      options={ACTIVE_AND_DELETED_ARRAY}
                      value={filters.active}
                      onChange={(value) => {
                        setFilters({ ...filters, active: value });
                      }}
                    />
                  </div>
                  <div className="col-lg-2 mb-4">
                    <label className="form-label">Deleted</label>
                    <Select
                      options={ACTIVE_AND_DELETED_ARRAY}
                      value={filters.deleted}
                      onChange={(value) => setFilters({ ...filters, deleted: value })}
                    />
                  </div>
                  <div className="col-lg-2 mb-4">
                    <label className="form-label">Sort By</label>
                    <Select
                      options={sortBySearchData}
                      placeholder="Select sortBy..."
                      value={filters.sortBy}
                      onChange={(value) => setFilters({ ...filters, sortBy: value })}
                    />
                  </div>

                  <div className="col-lg-3 mb-4">
                    <label className="form-label">Language</label>
                    <Select
                      options={LANGUAGE_ARRAY}
                      value={filters.language}
                      onChange={(value) => setFilters({ ...filters, language: value })}
                    />
                  </div>

                  <div className="col-lg-3 mb-4">
                    <label className="form-label">Name</label>
                    <input
                      className="form-control"
                      placeholder="Enter name..."
                      value={filters.name}
                      onChange={(e) => setFilters({ ...filters, name: e.target.value })}
                    />
                  </div>
                </div>
              </div>

              <div className="mt-4">
                <a className="btn btn-primary me-4" onClick={applyFilters}>
                  Apply Filters
                </a>
                <a className="" onClick={clearFilters}>
                  Clear
                </a>
              </div>
            </CardBody>
          </Card>

          <Row className="mb-2 align-items-center">
            <Col sm="4">
              <div className="search-box me-2 mb-2 d-inline-block">
                <div className="position-relative font-bold">{taxonomyPagination?.totalCount} Taxonomy Found</div>
              </div>
            </Col>
            <Col sm="8">
              <div className="text-sm-end">
                <Link
                  replace
                  className="btn btn-success btn-rounded waves-effect waves-light mb-2 me-2"
                  to={SMKLinks.ADD_SMK_TAXONOMY}>
                  <i className="mdi mdi-plus me-1" />
                  Add Taxonomy
                </Link>
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg="12">
              <div className="">
                <div className="table-responsive" style={{ minHeight: 300 }}>
                  <Table className="project-list-table  table-borderless ">
                    <thead>
                      <tr className="table-warning">
                        <th scope="col"></th>
                        <th scope="col">Name/Slug</th>
                        <th scope="col">Parent Category</th>
                        <th scope="col">Translation</th>
                        <th scope="col">Language</th>
                        <th scope="col">Approve Status</th>
                        <th scope="col">isPopular</th>
                        <th scope="col">Active</th>
                        <th scope="col">Deleted</th>
                        <th scope="col" />
                      </tr>
                    </thead>
                    <tbody>
                      {!isEmpty(taxonomyList) ? (
                        taxonomyList?.map((category) => renderCategoryRow(category))
                      ) : (
                        <TableError />
                      )}
                    </tbody>
                  </Table>
                </div>
              </div>
            </Col>
          </Row>
          <Paginate pageInfo={taxonomyPagination} onChangePage={handleChangePage} />
        </Container>
      </div>
    </>
  );
};

export default SmkTaxonomyListing;
