import useRequest from 'hooks/useRequest';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Badge, Card, CardBody, CardTitle, Col, Container, Media, Row, Table } from 'reactstrap';
import { BabyNameGenderValue, GENDER_VALUES, GET_GENDER, LANGUAGE_VALUES } from 'utils/constants';
import { buildURL, printDateTime, showImage } from 'utils/helper';
import profileBanner from 'assets/images/profile-img.png';
import Loader from 'components/Loader';
import Breadcrumbs from 'components/Common/Breadcrumb';
import map from 'lodash/map';
import MiniCards from 'components/MiniCard/mini-card';
import { isEmpty } from 'lodash';
import dummyUserIcon from 'assets/images/dummy-user.svg';

const HealthCareFacilityDetails = () => {
  const { id: healthCareFacilityId } = useParams();

  const [healthCarefacilityDetail, sethealthCarefacilityDetail] = useState([]);
  const [doctorsDetails, setDoctorsDetails] = useState([]);

  const [getBabyNameDetails, { loading: getBabyNameLoading }] = useRequest({
    url: `/doctor/admin/healthcareFacility/${healthCareFacilityId}`,
    method: 'GET',
    onSuccess: (data) => {
      sethealthCarefacilityDetail(data);
    },
    onError: (err) => {
      console.warn(err);
    },
  });

  const [getDoctorsDetails, { loading: getDoctorLoading }] = useRequest({
    url: `/doctor/admin/healthcareFacility/${healthCareFacilityId}/searchDoctors`,
    method: 'POST',
    onSuccess: (data) => {
      setDoctorsDetails(data?.results);
    },
    onError: (err) => {
      console.warn(err);
    },
  });

  useEffect(() => {
    getBabyNameDetails();
    getDoctorsDetails({
      body: {
        page: 1,
        size: 500,
        sortBy: 'id',
        sortOrder: 0,
      },
    });
  }, []);

  const miniCards = [
    {
      title: 'Approved',
      iconClass: 'bx-power-off',
      text: `${!isEmpty(healthCarefacilityDetail?.approvedById) ? 'Yes' : 'No'}`,
      textColor: `${!isEmpty(healthCarefacilityDetail?.approvedById) ? 'text-success' : 'text-danger'}`,
    },
    {
      title: 'Active',
      iconClass: 'bx-power-off',
      text: `${healthCarefacilityDetail?.active ? 'Yes' : 'No'}`,
      textColor: `${healthCarefacilityDetail?.active ? 'text-success' : 'text-danger'}`,
    },
    {
      title: 'Deleted',
      iconClass: 'bx-trash',
      text: `${healthCarefacilityDetail?.deleted ? 'Yes' : 'No'}`,
      textColor: `${healthCarefacilityDetail?.deleted ? 'text-success' : 'text-danger'}`,
    },
  ];

  return (
    <>
      <div className="page-content">
        <Loader isActive={getBabyNameLoading || getDoctorLoading} />
        <Container fluid>
          <Breadcrumbs title="Health care facility " breadcrumbItem="Health care facility Detail" />

          <Row>
            <Col xl="4">
              <Card className="overflow-hidden">
                <div className="bg-soft-primary">
                  <Row>
                    <Col xs="7">
                      <div className="text-primary p-3">
                        <h5 className="text-primary">Welcome {healthCarefacilityDetail?.name} !</h5>
                        <p>It will seem like simplified</p>
                      </div>
                    </Col>
                    <Col xs="5" className="align-self-end">
                      <img src={profileBanner} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="p-3">
                  <Row className="align-items-center">
                    <Col sm="3">
                      <div className="avatar-md profile-doctorDetails-wid m-auto">
                        <img
                          src={
                            !isEmpty(healthCarefacilityDetail?.images)
                              ? showImage(healthCarefacilityDetail?.images[0]?.filename, 'tpz')
                              : dummyUserIcon
                          }
                          className="avatar-md rounded-circle img-thumbnail"
                        />
                      </div>
                    </Col>
                    <Col sm="9">
                      <h5 className="font-size-15 text-truncate">{healthCarefacilityDetail?.name}</h5>
                      <p className="text-muted mb-0 text-truncate">{healthCarefacilityDetail?.email}</p>
                    </Col>
                  </Row>
                </CardBody>
              </Card>

              <Card>
                <CardBody>
                  <CardTitle className="py-3 card-title text-center bg-light mb-0">Basic Information</CardTitle>

                  <div className="table-responsive" style={{ minHeight: 200 }}>
                    <Table className="table-nowrap mb-0 table-striped table-white">
                      <tbody>
                        <tr>
                          <th>Name :</th>
                          <td>{healthCarefacilityDetail?.name}</td>
                        </tr>
                        <tr>
                          <th>Email :</th>
                          <td>{healthCarefacilityDetail?.email}</td>
                        </tr>
                        <tr>
                          <th>Phone number :</th>
                          <td>{healthCarefacilityDetail?.phoneNumber}</td>
                        </tr>
                        <tr>
                          <th>Landmark :</th>
                          <td>{healthCarefacilityDetail?.address?.landmark}</td>
                        </tr>
                        <tr>
                          <th>PostalCode :</th>
                          <td>{healthCarefacilityDetail?.address?.postalCode}</td>
                        </tr>
                        <tr>
                          <th>notes :</th>
                          <td>{healthCarefacilityDetail?.notes}</td>
                        </tr>

                        <tr>
                          <th>Active :</th>
                          <td>
                            <Badge className={healthCarefacilityDetail?.active ? 'bg-success' : 'bg-danger'}>
                              {healthCarefacilityDetail?.active ? 'Yes' : 'No'}
                            </Badge>
                          </td>
                        </tr>
                        <tr>
                          <th>Deleted :</th>
                          <td>
                            <Badge className={healthCarefacilityDetail?.deleted ? 'bg-success' : 'bg-danger'}>
                              {healthCarefacilityDetail?.deleted ? 'Yes' : 'No'}
                            </Badge>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>

            <Col xl="8">
              <Row>
                {map(miniCards, (card, key) => (
                  <MiniCards
                    title={card?.title}
                    text={card?.text}
                    textColor={card?.textColor}
                    iconClass={card?.iconClass}
                    key={`_card_${key}`}
                  />
                ))}
              </Row>
              <Card>
                <CardBody>
                  <CardTitle className="py-3 card-title text-center bg-light mb-4">Doctors Information </CardTitle>
                  <Row>
                    <Col xl="12">
                      <div className="table-responsive" style={{ minHeight: 200, maxHeight: 430 }}>
                        <Table className="table-nowrap mb-0 table-striped table-white">
                          <thead>
                            <tr>
                              <th>Doctor full name</th>
                              <th>Gender</th>
                              <th>Email</th>
                              <th>Phone number</th>
                              <th>Area of expertise</th>
                              <th>Active</th>
                              <th>Delete</th>
                            </tr>
                          </thead>
                          <tbody>
                            {doctorsDetails?.map((doctor) => (
                              <tr>
                                <td>{`${doctor?.firstName} ${doctor?.lastName}`}</td>
                                <td>{GET_GENDER[doctor?.gender]}</td>

                                <td>{doctor?.email}</td>
                                <td>{doctor?.phoneNumber}</td>
                                <td>{doctor?.areaOfExpertise}</td>
                                <td>
                                  <Badge className={doctor?.active ? 'bg-success' : 'bg-danger'}>
                                    {doctor?.active ? 'Yes' : 'No'}
                                  </Badge>
                                </td>
                                <td>
                                  <Badge className={doctor?.deleted ? 'bg-success' : 'bg-danger'}>
                                    {doctor?.deleted ? 'Yes' : 'No'}
                                  </Badge>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

HealthCareFacilityDetails.propTypes = {
  currentPost: PropTypes.object,
};

export default HealthCareFacilityDetails;
