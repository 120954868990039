import NestedImageUploader from 'components/NestedImageUploader';
import TextEditor from 'components/TextEditor';
import isEmpty from 'lodash/isEmpty';
import React from 'react';
import { Button, Card, CardBody, Col, Input, Label, Row } from 'reactstrap';
import { MODULE_TYPE } from 'utils/constants';

const StaticContentSections = ({ sections, setSections }) => {
  const defaultPayload = {
    order: '',
    title: '',
    content: '',
    image: {},
    items: [],
  };

  const handleAddSection = () => {
    if (sections?.length === 0) {
      setSections([defaultPayload]);
      return;
    }

    const lastSection = sections[sections?.length - 1];

    if (!lastSection?.title || !lastSection?.content) {
      alert('Please fill required fields.');
      return;
    }

    setSections([...sections, defaultPayload]);
  };

  const handleRemoveSection = (index) => {
    setSections(sections?.filter((_, i) => i !== index));
  };

  const handleUpdateSection = (index, field, value) => {
    setSections((prev) => prev?.map((section, i) => (i === index ? { ...section, [field]: value } : section)));
  };

  const handleAddItem = (sectionIndex) => {
    const currentSec = sections[sectionIndex];
    const lastItem = currentSec?.items[currentSec?.items?.length - 1];

    if (lastItem && !lastItem?.title) {
      alert('Please add title in items...');
      return;
    }

    const newItem = {
      title: '',
      description: '',
      image: {},
    };
    const updatedSections = sections?.map((section, i) =>
      i === sectionIndex ? { ...section, items: [...section.items, newItem] } : section
    );
    setSections(updatedSections);
  };

  const handleRemoveItem = (sectionIndex, itemIndex) => {
    const updatedSections = sections?.map((section, i) =>
      i === sectionIndex
        ? {
            ...section,
            items: section.items.filter((_, j) => j !== itemIndex),
          }
        : section
    );
    setSections(updatedSections);
  };

  const handleUpdateItems = (sectionIndex, itemIndex, field, val) => {
    const updatedSections = sections?.map((section, i) =>
      i === sectionIndex
        ? {
            ...section,
            items: section?.items?.map((item, j) => (j === itemIndex ? { ...item, [field]: val } : item)),
          }
        : section
    );
    setSections(updatedSections);
  };

  return (
    <Card>
      <CardBody>
        <h5>Sections</h5>
        {!isEmpty(sections) &&
          sections?.map((section, sectionIndex) => (
            <div key={sectionIndex} className="mb-4 border border-dark p-4 rounded-2">
              <div className="bg-light p-3 text-center rounded-2 mb-4">
                <h4 className="mb-0">Section - {sectionIndex + 1}</h4>
              </div>

              <div className="row mb-4 d-flex justify-content-end">
                <div className="col-md-3 text-end">
                  <Button outline color="danger" onClick={() => handleRemoveSection(sectionIndex)}>
                    Remove Section
                  </Button>
                </div>
              </div>
              <div className="mb-4 d-flex align-items-start row">
                <div className=" col-md-2">
                  <Label for={`order-${sectionIndex}`} className="form-label mb-0">
                    Order
                  </Label>
                </div>
                <div className="col-md-4">
                  <Input
                    id={`order-${sectionIndex}`}
                    name={`order-${sectionIndex}`}
                    type="number"
                    className="form-control"
                    placeholder="order..."
                    value={section.order}
                    onChange={(e) => handleUpdateSection(sectionIndex, 'order', e.target.value)}
                  />
                </div>
              </div>
              <div className="mb-4 d-flex align-items-start row">
                <div className=" col-md-2">
                  <Label for={`title-${sectionIndex}`} className="form-label mb-0">
                    Title
                  </Label>
                </div>
                <div className="col-md-10">
                  <Input
                    id={`title-${sectionIndex}`}
                    name={`title-${sectionIndex}`}
                    type="text"
                    className="form-control"
                    placeholder="title..."
                    value={section.title}
                    onChange={(e) => handleUpdateSection(sectionIndex, 'title', e.target.value)}
                  />
                </div>
              </div>

              <div className="mb-4 d-flex align-items-start row">
                <div className=" col-md-2">
                  <Label for={`content-${sectionIndex}`} className="form-label mb-0">
                    Content
                  </Label>
                </div>
                <div className="col-md-10">
                  <TextEditor
                    simple
                    height={200}
                    initialValue={section.content}
                    onChange={(val) => handleUpdateSection(sectionIndex, 'content', val)}
                  />
                </div>
              </div>
              <div className="mb-4 d-flex align-items-start row">
                <div className=" col-md-2">
                  <Label for={`image-${sectionIndex}`} className="form-label mb-0">
                    Image
                  </Label>
                </div>
                <div className="col-md-10">
                  <NestedImageUploader
                    forType={MODULE_TYPE.METADATA}
                    label={'Select Image'}
                    value={section.image}
                    onChange={(val) => handleUpdateSection(sectionIndex, 'image', val)}
                  />
                </div>
              </div>
              <div className="mb-4 d-flex align-items-start row">
                <div className="col-md-2">
                  <Label for={`items-${sectionIndex}`} className="form-label mb-0">
                    Items
                  </Label>
                </div>
                <div className="col-md-10">
                  {!isEmpty(section?.items) &&
                    section?.items?.map((item, itemIndex) => {
                      return (
                        <div key={itemIndex} className="mb-3">
                          <div className="bg-light p-3 text-center rounded-2 mb-4">
                            <h4 className="mb-0">
                              Section - {sectionIndex + 1} , <span className="ms-3">Item - {itemIndex + 1}</span>
                            </h4>
                          </div>

                          <Row className="align-items-start mb-3 border border-dark mb-0 rounded-2 p-3 py-4">
                            <Col md={12} className="mb-3">
                              <Label for={`title-${sectionIndex}`} className="form-label">
                                Title
                              </Label>
                              <Input
                                id={`title-${itemIndex}`}
                                name={`title-${itemIndex}`}
                                type="text"
                                className="form-control"
                                placeholder="Title..."
                                value={item.title}
                                onChange={(e) => handleUpdateItems(sectionIndex, itemIndex, 'title', e.target.value)}
                              />
                            </Col>
                            <Col md={12} className="mb-3">
                              <Label for={`description-${sectionIndex}`} className="form-label">
                                Description
                              </Label>
                              <Input
                                id={`description-${itemIndex}`}
                                name={`description-${itemIndex}`}
                                type="textarea"
                                rows={3}
                                className="form-control"
                                placeholder="Description..."
                                value={item.description}
                                onChange={(e) =>
                                  handleUpdateItems(sectionIndex, itemIndex, 'description', e.target.value)
                                }
                              />
                            </Col>
                            <Col md={12} className="mb-2">
                              <NestedImageUploader
                                forType={MODULE_TYPE.METADATA}
                                label={'Select Image'}
                                value={item.image}
                                onChange={(val) => handleUpdateItems(sectionIndex, itemIndex, 'image', val)}
                              />
                            </Col>

                            <Col md={12} className="mb-3 text-end">
                              <Button
                                outline
                                className="w-100"
                                color="danger"
                                onClick={() => handleRemoveItem(sectionIndex, itemIndex)}>
                                Delete <i class="bx bx-trash text-white" />
                              </Button>
                            </Col>
                          </Row>
                        </div>
                      );
                    })}

                  <Button color="success" onClick={() => handleAddItem(sectionIndex)}>
                    Add Item
                  </Button>
                </div>
              </div>
            </div>
          ))}

        <Button className="ps-0 mt-2" color="link" onClick={handleAddSection}>
          <i className="bx bx-plus me-3" />
          Add Section
        </Button>
      </CardBody>
    </Card>
  );
};

export default React.memo(StaticContentSections);
