import AuthService from 'common/auth.service';
import SmkAsyncSelect from 'components/SmkAsyncSelect';
import TextEditor from 'components/TextEditor';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Button, Card, CardBody, Col, Input, Label, Row } from 'reactstrap';
import { API_BASE_URL } from 'utils/constants';
import { getSchoolAddress } from '../../helper';
import { showImage } from 'utils/helper';

const BlogListData = ({ setCustomData, blogdetails }) => {
  const auth = new AuthService();
  const appSource = auth.getAppSourceCookie();
  const isSMK = appSource === 'schoolmykids';
  const isTPZ = appSource === 'theparentz';

  const [city, setCity] = useState('');
  const [header, setHeader] = useState('');
  const [footer, setFooter] = useState('');

  const defaultPayload = {
    title: '',
    description: '',
    items: [],
  };

  const [sections, setSections] = useState([]);

  const handleAddSection = () => {
    if (sections?.length === 0) {
      setSections([defaultPayload]);
      return;
    }

    const lastSection = sections[sections?.length - 1];

    if (!lastSection?.title || !lastSection?.description) {
      alert('Please fill required fields.');
      return;
    }

    setSections([...sections, defaultPayload]);
  };

  const handleRemoveSection = (index) => {
    setSections(sections?.filter((_, i) => i !== index));
  };

  const handleSectionChange = (index, field, value) => {
    const updatedSections = sections?.map((section, i) => (i === index ? { ...section, [field]: value } : section));
    setSections(updatedSections);
  };

  const handleAddItem = (sectionIndex) => {
    const currentSec = sections[sectionIndex];
    const lastItem = currentSec?.items[currentSec?.items?.length - 1];

    if (isSMK) {
      if (lastItem && !lastItem?.school) {
        alert('Please add school in items...');
        return;
      }
    }
    if (isTPZ) {
      if (lastItem && !lastItem?.doctor) {
        alert('Please add doctor in items...');
        return;
      }
    }

    const newItem = {
      order: sections[sectionIndex].items.length + 1,
      [isSMK ? 'school' : 'doctor']: {
        name: '',
        id: '',
      },
    };
    const updatedSections = sections?.map((section, i) =>
      i === sectionIndex ? { ...section, items: [...section.items, newItem] } : section
    );
    setSections(updatedSections);
  };

  const handleRemoveItem = (sectionIndex, itemIndex) => {
    const updatedSections = sections?.map((section, i) =>
      i === sectionIndex
        ? {
            ...section,
            items: section.items.filter((_, j) => j !== itemIndex),
          }
        : section
    );
    setSections(updatedSections);
  };

  const handleSetSchoolObj = (school) => {
    return {
      name: school?.displayName ? school?.displayName : '',
      address: getSchoolAddress(school),
      logo: school?.logoUrl
        ? school?.logoUrl
        : !isEmpty(school?.images)
        ? showImage(school?.images?.filter((e) => e?.type === 'logo')[0]?.url, source)
        : '',
      id: school?.id,
    };
  };

  const handleUpdateItems = (sectionIndex, itemIndex, field, value) => {
    const updatedSections = sections?.map((section, i) =>
      i === sectionIndex
        ? {
            ...section,
            items: section?.items?.map((item, j) =>
              j === itemIndex ? { ...item, [field]: field === 'school' ? handleSetSchoolObj(value) : value } : item
            ),
          }
        : section
    );
    setSections(updatedSections);
  };

  // update formik listData value
  useEffect(() => {
    const values = {
      dataType: isSMK ? 'school' : 'doctor',
      header,
      footer,
      city: {
        id: '',
        name: city,
      },
      sections: sections?.map((section) => ({
        title: section?.title,
        description: section?.description,
        items: section?.items?.map((item) => {
          return {
            order: item?.order,
            ...(!isEmpty(item?.title) ? { title: item?.title } : {}),
            ...(!isEmpty(item?.description) ? { description: item?.description } : {}),
            id: isSMK ? item?.school?.id : item?.doctor?.id,
          };
        }),
      })),
    };

    setCustomData((prev) => ({
      ...prev,
      ...values,
    }));
  }, [city, header, footer, sections]);

  useEffect(() => {
    if (!isEmpty(blogdetails?.customData)) {
      let currentSectionDetails = [];

      if (!isEmpty(blogdetails?.customData?.sections)) {
        if (isSMK) {
          currentSectionDetails = blogdetails?.customData?.sections?.map((elem) => ({
            ...elem,
            items: elem?.items?.map((e) => {
              const current_school = blogdetails?.customData?.schools?.find((s) => +s?.id === +e?.id);
              return {
                order: e?.order,
                ...(!isEmpty(e?.title) ? { title: e?.title } : {}),
                ...(!isEmpty(e?.description) ? { description: e?.description } : {}),
                school: handleSetSchoolObj(current_school),
              };
            }),
          }));
        } else {
          currentSectionDetails = blogdetails?.customData?.sections?.map((elem) => ({
            ...elem,
            items: elem?.items?.map((e) => {
              const current_doctor = blogdetails?.customData?.doctors?.find((s) => s?.id === e?.id);
              return {
                order: e?.order,
                doctor: {
                  name: current_doctor?.firstName + ' ' + current_doctor?.lastName,
                  id: current_doctor?.id,
                },
              };
            }),
          }));
        }
      }

      setCity(blogdetails?.customData?.city?.name);
      setFooter(blogdetails?.customData?.footer);
      setHeader(blogdetails?.customData?.header);
      setSections(currentSectionDetails);
    }
  }, [blogdetails]);

  return (
    <div className="mb-5">
      <Card className="mt-5">
        <CardBody>
          <div className="border-bottom pb-3 d-flex align-items-center justify-content-between mb-4">
            <h5 className="mb-0">{isSMK ? 'School' : 'Doctor'} Lists</h5>
          </div>

          <div className="d-flex align-items-center row my-4">
            <Label className="form-label col-md-2">City</Label>
            <div className="col-md-5">
              <Input
                id={`city`}
                name={`city`}
                type="text"
                className="form-control"
                placeholder="Enter city name..."
                value={city}
                onChange={(e) => setCity(e.target.value)}
              />
            </div>
          </div>

          <h5 className="mb-2">Sections</h5>
          {!isEmpty(sections) &&
            sections?.map((section, sectionIndex) => (
              <div key={sectionIndex} className="mb-4 border border-dark p-4 rounded-2">
                <div className="row mb-4 d-flex justify-content-end">
                  <div className="col-md-3 text-end">
                    <Button outline color="danger" onClick={() => handleRemoveSection(sectionIndex)}>
                      Remove Section
                    </Button>
                  </div>
                </div>
                <div className="mb-4 d-flex align-items-start row">
                  <div className=" col-md-2">
                    <Label for={`title-${sectionIndex}`} className="form-label mb-0">
                      title
                    </Label>
                  </div>
                  <div className="col-md-10">
                    <Input
                      id={`title-${sectionIndex}`}
                      name={`title-${sectionIndex}`}
                      type="text"
                      className="form-control"
                      placeholder="title..."
                      value={section.title}
                      onChange={(e) => handleSectionChange(sectionIndex, 'title', e.target.value)}
                    />
                  </div>
                </div>

                <div className="mb-4 d-flex align-items-start row">
                  <div className=" col-md-2">
                    <Label for={`description-${sectionIndex}`} className="form-label mb-0">
                      Description
                    </Label>
                  </div>
                  <div className="col-md-10">
                    <TextEditor
                      height="100"
                      simple
                      initialValue={section.description}
                      onChange={(val) => handleSectionChange(sectionIndex, 'description', val)}
                    />
                  </div>
                </div>
                <div className="mb-4 d-flex align-items-start row">
                  <div className="col-md-2">
                    <Label for={`items-${sectionIndex}`} className="form-label mb-0">
                      Items
                    </Label>
                  </div>
                  <div className="col-md-10">
                    {!isEmpty(section?.items) &&
                      section?.items?.map((item, itemIndex) => {
                        return (
                          <div key={itemIndex} className="mb-3">
                            <Row className="align-items-start mb-3 border border-dark mb-0 rounded-2 p-3 py-4">
                              <Col md={2} className="mb-3">
                                <Input
                                  id={`order-${itemIndex}`}
                                  name={`order-${itemIndex}`}
                                  type="number"
                                  className="form-control"
                                  placeholder="Order..."
                                  value={item.order}
                                  onChange={(e) => handleUpdateItems(sectionIndex, itemIndex, 'order', e.target.value)}
                                />
                              </Col>
                              <Col md={8} className="mb-0">
                                {isSMK ? (
                                  <>
                                    {!isEmpty(item?.title) ? (
                                      <div>
                                        <label className="form-label mb-1">Title</label>
                                        <Input
                                          id={`title-${itemIndex}`}
                                          name={`title-${itemIndex}`}
                                          type="text"
                                          className="form-control"
                                          placeholder="Title..."
                                          value={item.title}
                                          onChange={(e) =>
                                            handleUpdateItems(sectionIndex, itemIndex, 'title', e.target.value)
                                          }
                                        />
                                      </div>
                                    ) : (
                                      <div>
                                        <SmkAsyncSelect
                                          isClearable={false}
                                          combineLabelKey={'id'}
                                          acceptedKey={'name'}
                                          acceptedValue={'id'}
                                          placeholder={`Search for schools...`}
                                          onChange={(res) => {
                                            handleUpdateItems(sectionIndex, itemIndex, 'school', res);
                                          }}
                                          apiMethod={'POST'}
                                          fetchUrl={`${API_BASE_URL}/school/admin/search`}
                                          filters={{
                                            page: 1,
                                            size: 20,
                                            sortBy: 'name',
                                            sortOrder: 0,
                                            active: true,
                                          }}
                                          searchKeyName="name"
                                          isQueryParam={true}
                                          value={
                                            !isEmpty(item.school?.name)
                                              ? {
                                                  label: `${item.school?.id} - ${item.school?.name}`,
                                                  value: item.school?.id,
                                                }
                                              : null
                                          }
                                        />

                                        {!isEmpty(item?.school?.name) && (
                                          <div className="mt-3">
                                            <Card className="border bg-light mb-0">
                                              <CardBody>
                                                <Row>
                                                  {item?.school?.logo && (
                                                    <Col xl={2}>
                                                      <div className="border bg-white rounded-2 d-flex align-items-center justify-content-center me-2 mb-3">
                                                        <img src={item?.school?.logo} style={{ maxWidth: 30 }} />
                                                      </div>
                                                    </Col>
                                                  )}

                                                  <Col xl={item?.school?.logo ? 10 : 12}>
                                                    <h6 className="mb-1">{item?.school?.name}</h6>
                                                    <p className="mb-0">{item?.school?.address}</p>
                                                  </Col>
                                                </Row>
                                              </CardBody>
                                            </Card>
                                          </div>
                                        )}
                                      </div>
                                    )}

                                    {!isEmpty(item?.description) && (
                                      <div className="mt-3">
                                        <label className="form-label mb-1">Description</label>
                                        <Input
                                          id={`description-${itemIndex}`}
                                          name={`description-${itemIndex}`}
                                          type="textarea"
                                          rows={3}
                                          className="form-control"
                                          placeholder="Description..."
                                          value={item.description}
                                          onChange={(e) =>
                                            handleUpdateItems(sectionIndex, itemIndex, 'description', e.target.value)
                                          }
                                        />
                                      </div>
                                    )}
                                  </>
                                ) : (
                                  <SmkAsyncSelect
                                    isClearable={false}
                                    acceptedKey={'firstName'}
                                    acceptedValue={'id'}
                                    placeholder={`Search for doctors...`}
                                    onChange={(res) =>
                                      handleUpdateItems(
                                        sectionIndex,
                                        itemIndex,
                                        'doctor',
                                        res
                                          ? {
                                              ...res,
                                              name: res?.firstName + ' ' + res.lastName,
                                            }
                                          : null
                                      )
                                    }
                                    apiMethod={'POST'}
                                    fetchUrl={`${API_BASE_URL}/doctor/admin/search`}
                                    filters={{
                                      page: 1,
                                      size: 20,
                                      sortBy: 'id',
                                      sortOrder: 0,
                                      active: true,
                                    }}
                                    searchKeyName="name"
                                    isQueryParam={true}
                                    value={
                                      !isEmpty(item.doctor?.name)
                                        ? {
                                            label: `${item.doctor?.name}`,
                                            value: item.doctor?.id,
                                          }
                                        : null
                                    }
                                  />
                                )}
                              </Col>
                              <Col md={2} className="mb-3 text-end">
                                <Button color="danger" onClick={() => handleRemoveItem(sectionIndex, itemIndex)}>
                                  <i class="bx bx-trash text-white" />
                                </Button>
                              </Col>
                            </Row>
                          </div>
                        );
                      })}

                    <Button color="success" onClick={() => handleAddItem(sectionIndex)}>
                      Add Item
                    </Button>
                  </div>
                </div>
              </div>
            ))}

          <Button className="ps-0 mt-2" color="link" onClick={handleAddSection}>
            <i className="bx bx-plus me-3" />
            Add Section
          </Button>

          <div className="my-4">
            <Label className="mb-2 form-label">Header</Label>
            <TextEditor height="100" simple initialValue={header} onChange={(val) => setHeader(val)} />
          </div>
          <div className="my-4">
            <Label className="mb-2 form-label">Footer</Label>
            <TextEditor height="100" simple initialValue={footer} onChange={(val) => setFooter(val)} />
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default React.memo(BlogListData);
