import AssetImageUploader from 'containers/blog/BlogList/components/AssetImageUploader';
import { useFormik } from 'formik';
import { invert, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { Button, Card, CardBody, CardTitle, Col, Form, FormFeedback, FormGroup, Input, Label, Row } from 'reactstrap';
import { createStructuredSelector } from 'reselect';
import { SMKLinks } from 'utils/links';
import * as Yup from 'yup';
import InputSlug from '../../../components/EditSlug/InputSlug';
import SmkAsyncSelect from '../../../components/SmkAsyncSelect';
import TextEditor from '../../../components/TextEditor';
import {
  AGE_GROUP,
  API_BASE_URL,
  DIFFICULTY_LEVEL,
  GRADES,
  MODULE_TYPE,
  PUBLIC_URL,
  WORKSHEET_STATUS,
  WORKSHEET_TYPE,
} from '../../../utils/constants';
import { titleCaseIfExists, urlSlugify } from '../../../utils/helper';
import { createWorksheet, getWorksheetSubjectTopics } from '../actions';
import { clearCDNCacheApiCall } from '../helper';
import { workSheetsSubjectsSelector, workSheetsTopicsSelector } from '../selectors';
import { WorksheetGeneratorsConstant } from './WorksheetGeneratorsConstants';

const stateSelector = createStructuredSelector({
  subjectList: workSheetsSubjectsSelector,
  topicList: workSheetsTopicsSelector,
});

const SingleWorksheetForm = (props) => {
  const { worksheet } = props;
  toast.configure();

  const { subjectList, topicList } = useSelector(stateSelector);

  const dispatch = useDispatch();
  const history = useHistory();

  const isEditMode = !isEmpty(worksheet);

  useEffect(() => {
    dispatch(getWorksheetSubjectTopics.trigger());
  }, []);

  const isJSON = (val) => {
    if (isEmpty(val)) {
      return false;
    }
    try {
      const parseObj = JSON.parse(val);
      return typeof parseObj === 'object' || Array.isArray(parseObj) ? true : false;
    } catch (err) {
      toast.error(err);
      return false;
    }
  };

  const convertJSON = (val) => {
    if (isEmpty(val)) {
      return false;
    }
    try {
      const parseObj = JSON.parse(val);
      return parseObj;
    } catch (err) {
      toast.error(err);
    }
  };

  const getInitialValues = () => {
    const values = {
      type: isEditMode
        ? {
            value: WORKSHEET_TYPE.PRINTABLE_WORKSHEETS,
            label: titleCaseIfExists(WORKSHEET_TYPE.PRINTABLE_WORKSHEETS),
          }
        : {},
      active: isEditMode ? worksheet.active : true,
      deleted: isEditMode ? worksheet.deleted : false,
      autoGenerate: isEditMode ? worksheet.autoGenerate : false,
      generatorId: isEditMode
        ? !isEmpty(worksheet.generatorId)
          ? {
              label: worksheet.generatorId,
              value: worksheet.generatorId,
            }
          : {}
        : {},
      generatorPayload: isEditMode
        ? !isEmpty(worksheet.generatorPayload)
          ? JSON.stringify(worksheet.generatorPayload, null, 2)
          : null
        : {},

      title: isEditMode ? worksheet.title : '',
      slug: isEditMode ? worksheet.slug : '',
      ageGroup: {},
      level: isEditMode ? worksheet.level : '',
      description: isEditMode ? worksheet.description : '',
      worksheetImage: isEditMode ? worksheet.worksheetImage : {},
      answerImage: isEditMode ? worksheet.answerImage : {},
      subjects: [],
      primarySubject: isEditMode ? worksheet.primarySubject : null,
      primaryTopic: isEditMode ? worksheet.primaryTopic : null,
      topics: {},
      tags: [],
      grades: [],
      sequence: isEditMode ? worksheet.sequence : 0,
      isAnswerVisible: isEditMode ? worksheet.isAnswerVisible : false,
      isFree: isEditMode ? worksheet.isFree : false,
      isInteractive: isEditMode ? worksheet.isInteractive : false,
      status: isEditMode ? worksheet.status : WORKSHEET_STATUS.PENDING_FOR_APPROVAL,
      isDuplicate: false,
    };

    const worksheetType = Object.keys(WORKSHEET_TYPE)
      ?.filter((e) => worksheet?.type == WORKSHEET_TYPE[e])
      ?.map((elem) => ({
        label: titleCaseIfExists(elem),
        value: WORKSHEET_TYPE[elem],
      }));
    if (isEditMode) {
      values.type = worksheetType[0];

      values.ageGroup = {
        value: worksheet.ageGroup,
        label: invert(AGE_GROUP)[worksheet.ageGroup],
      };
      values.level = {
        value: worksheet.level,
        label: invert(DIFFICULTY_LEVEL)[worksheet.level],
      };

      values.subjects = worksheet.subjects.map((sub) => ({
        ...sub,
        label: sub.name,
        value: sub.slug,
      }));

      // categorize topic by subject
      worksheet.topics.forEach((top) => {
        const topics = values.topics[top.subject.slug] || [];
        values.topics[top.subject.slug] = [...topics, { ...top, label: top.name, value: top.slug }];
      });

      values.tags = worksheet.tags.map((tag) => ({
        label: tag.name,
        value: tag.name,
      }));

      values.grades = worksheet.grades.map((g) => ({
        value: g,
        label: invert(GRADES)[g],
      }));
    }
    return values;
  };

  const formik = useFormik({
    validateOnChange: true,
    validateOnBlur: false,
    initialValues: getInitialValues(),
    onSubmit: async (values) => {
      if (isEditMode) {
        clearCDNCacheApiCall(values?.slug);
      }
      const { isDuplicate, primarySubject, primaryTopic, ageGroup, level, topics, grades, tags } = values;
      const topicList = [];
      Object.keys(topics).forEach((key) => {
        topicList.push(...topics[key]);
      });

      const worksheetObj = {
        ...worksheet,
        ...values,
        type: values.type.value,
        autoGenerate: values?.autoGenerate,
        generatorId: !isEmpty(values.generatorId) ? String(values.generatorId.value) : '',
        generatorPayload: !isEmpty(values.generatorPayload) ? convertJSON(values.generatorPayload) : [],
        active: values.active,
        deleted: values.deleted,
        ageGroup: ageGroup.value,
        primarySubject: {
          id: primarySubject.id || primarySubject.value,
        },
        primaryTopic: {
          id: primaryTopic.id || primaryTopic.value,
        },
        level: level.value,
        topics: topicList,
        grades: grades.map((g) => g.value),
        tags: [...new Set(tags.map((t) => t.value))], // remove duplicates
        isFree: values?.isFree,
        isInteractive: values?.isInteractive,
        ...(values.worksheetImage?.id
          ? {
              worksheetImageId: values.worksheetImage?.id,
            }
          : {
              worksheetImageId: null,
              worksheetImage: null,
            }),
        ...(values.worksheetImage?.id
          ? {
              answerImageId: values.answerImage?.id,
            }
          : {
              answerImageId: null,
              answerImage: null,
            }),
      };

      // checking JSON and worksheet type
      if (values?.type?.value === 'worksheet_generator' && !isEmpty(values?.generatorPayload)) {
        if (isJSON(values.generatorPayload)) {
          dispatch(createWorksheet.trigger({ worksheetObj, isDuplicate, isEditMode }));
          toast.success(
            `Success! ${worksheet ? worksheet?.id : ''} Worksheet has been ${worksheet ? 'Updated' : 'Added'}.`
          );
        } else {
          toast.error('Please enter valid Generator payload JSON...');
        }
      } else {
        dispatch(createWorksheet.trigger({ worksheetObj, isDuplicate, isEditMode }));
        toast.success(
          `Success! ${worksheet ? worksheet?.id : ''} Worksheet has been ${worksheet ? 'Updated' : 'Added'}.`
        );
      }
    },
    validationSchema: Yup.object().shape({
      title: Yup.string()
        .required('Please Provide a title')
        .min(3, 'Title should be at least 3 characters long')
        .max(150, 'Title should be at most 15 characters long'),
      description: Yup.string(),
      ageGroup: Yup.object().test('agegroup test', 'Please Select a Age Group', (val) => !isEmpty(val)),
      level: Yup.object().test('level test', 'Please select a difficulty level', (val) => !isEmpty(val)),
      type: Yup.object()
        .required('Please select a type')
        .nullable(true),
      worksheetImage: isEditMode && Yup.object().nullable(true),
      subjects: Yup.array().min(1, 'Please select atleast one subject'),
      sequence: Yup.number(),
      grades: Yup.array().min(1, 'Please select atleast one grade'),
      tags: Yup.array(),
      primarySubject: Yup.object()
        .test('primary-subject-test', 'Please select a primary subject', (val) => !isEmpty(val))
        .nullable(),
      primaryTopic: Yup.object()
        .test('primary-topic-test', 'Please select a primary topic', (val) => !isEmpty(val))
        .nullable(),
    }),
  });

  useEffect(() => {
    if (!isEmpty(subjectList)) {
      if (isEditMode) {
        const primarySubjectObj = subjectList?.find((e) => e?.id === worksheet?.primarySubjectId);
        formik.setFieldValue('primarySubject', primarySubjectObj);

        const primaryTopicObj = topicList?.find((e) => e?.id === worksheet?.primaryTopicId);
        formik.setFieldValue('primaryTopic', primaryTopicObj);
      }
    }
  }, [subjectList]);

  const handleToggleSubject = (checked, subject) => {
    let array = formik.values.subjects.slice();
    if (checked) {
      array = [...array, subject];
    } else {
      array = array?.filter((sub) => sub.id !== subject.id);
    }
    formik.setFieldValue('subjects', array);
  };

  const handleUpdateTopics = (subject, topics) => {
    formik.setFieldValue('topics', {
      ...formik.values.topics,
      [subject.slug]: topics,
    });
  };

  const handleSaveWorksheet = async (isDuplicate) => {
    await formik.setFieldValue('isDuplicate', isDuplicate);
    formik.submitForm();
  };

  return (
    <>
      <Form onSubmit={(e) => e.preventDefault()}>
        <Row>
          <Col md={9}>
            <Card>
              <CardBody>
                <Row>
                  <Col md={8}>
                    <FormGroup className="mb-4">
                      <Label htmlFor="title" className="form-label  ">
                        Title
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        placeholder="Enter Title..."
                        id="title"
                        invalid={!!(formik.touched.title && formik.errors.title)}
                        {...formik.getFieldProps('title')}
                      />
                      <FormFeedback className="d-block">{formik.errors.title}</FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col md={4} className="d-flex align-items-center">
                    <div className="custom-control custom-checkbox ">
                      <Input
                        type="checkbox"
                        className="form-check-input input-mini me-1"
                        id="isFree"
                        checked={formik?.values.isFree}
                        {...formik.getFieldProps('isFree')}
                      />
                      <Label className="form-check-label " for="isFree">
                        isFree
                      </Label>
                    </div>
                    <div className="custom-control custom-checkbox ms-md-4">
                      <Input
                        type="checkbox"
                        className="form-check-input input-mini me-1"
                        id="isInteractive"
                        checked={formik?.values.isInteractive}
                        {...formik.getFieldProps('isInteractive')}
                      />
                      <Label className="form-check-label " for="isInteractive">
                        isInteractive
                      </Label>
                    </div>
                  </Col>

                  <Col md={12} className="mb-4">
                    <FormGroup className="mb-4">
                      <Label htmlFor="slug" className="form-label  ">
                        Slug
                      </Label>
                      <InputSlug formik={formik} />
                      <FormFeedback className="d-block">{formik.errors.slug}</FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col md={12}>
                    <FormGroup className="mb-4">
                      <Label htmlFor="jsonContent" className="form-label  ">
                        Description
                      </Label>

                      <TextEditor
                        height="100"
                        simple
                        initialValue={formik.values.description}
                        onChange={(val) => formik.setFieldValue('description', val)}
                      />
                      {formik.errors.description && (
                        <div className="invalid-feedback d-block">{formik.errors.description}</div>
                      )}
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col lg="3" sm="6">
                    <FormGroup>
                      <Label htmlFor="ageGroup">Select Age group</Label>
                      <Select
                        className="select2-dropdown"
                        value={formik.values.ageGroup}
                        onChange={(val) => formik.setFieldValue('ageGroup', val)}
                        options={Object.keys(AGE_GROUP).map((e) => ({
                          value: AGE_GROUP[e],
                          label: e,
                        }))}
                      />
                      {formik.errors.ageGroup && (
                        <div className="invalid-feedback d-block">{formik.errors.ageGroup}</div>
                      )}
                    </FormGroup>
                  </Col>
                  <Col lg="3" sm="6">
                    <FormGroup>
                      <Label htmlFor="level">Select Difficulty Level</Label>
                      <Select
                        className="select2-dropdown"
                        value={formik.values.level}
                        onChange={(val) => formik.setFieldValue('level', val)}
                        options={Object.keys(DIFFICULTY_LEVEL).map((e) => ({
                          value: DIFFICULTY_LEVEL[e],
                          label: e,
                        }))}
                      />
                      {formik.errors.level && <div className="invalid-feedback d-block">{formik.errors.level}</div>}
                    </FormGroup>
                  </Col>
                  <Col lg="3" sm="6">
                    <FormGroup>
                      <Label htmlFor="level">Select Class Levels</Label>
                      <Select
                        isMulti
                        className="select2-dropdown"
                        value={formik.values.grades}
                        onChange={(val) => formik.setFieldValue('grades', val)}
                        options={Object.keys(GRADES).map((e) => ({
                          value: GRADES[e],
                          label: e,
                        }))}
                      />
                      {formik.errors.grades && <div className="invalid-feedback d-block">{formik.errors.grades}</div>}
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  {!isEmpty(subjectList) &&
                    subjectList.map((subject, key) => (
                      <Col key={`key_${key}`} md={6}>
                        <Row className="my-2">
                          <Col>
                            {/* <div className="bg-soft-info custom-control custom-checkbox mt-1 mb-2 ms-1 me-1"> */}
                            <div className="custom-control custom-checkbox mt-1 mb-2 ms-1 me-1" style={{ zIndex: 0 }}>
                              <input
                                type="checkbox"
                                className="form-check-input input-mini"
                                id={`${key}_first`}
                                name={`${key}_first`}
                                checked={formik.values.subjects.some((sub) => sub?.id === subject?.id)}
                                onChange={(e) => handleToggleSubject(e.target.checked, subject)}
                              />
                              <Label className="form-check-label" for={`${key}_first`}>
                                {subject.name}
                              </Label>
                            </div>
                          </Col>
                          <Col>
                            <Select
                              className="select2-dropdown"
                              isMulti
                              options={topicList?.filter((topic) => topic?.subject?.id === subject?.id)}
                              value={formik.values.topics[subject.slug]}
                              onChange={(val) => handleUpdateTopics(subject, val)}
                            />
                          </Col>
                        </Row>
                      </Col>
                    ))}
                  {formik.errors.subjects && <div className="invalid-feedback d-block">{formik.errors.subjects}</div>}
                </Row>

                <Row className="mt-4">
                  <Col md="6">
                    <FormGroup>
                      <Label htmlFor="primarySubject">Select Primary Subject</Label>
                      <Select
                        className="select2-dropdown"
                        value={formik.values.primarySubject}
                        onChange={(val) => {
                          formik.setFieldValue('primarySubject', val);
                          formik.setFieldValue('generatorId', '');
                        }}
                        options={subjectList?.map((sub) => ({
                          value: sub?.id,
                          label: sub?.name,
                        }))}
                      />
                      {formik.errors.primarySubject && (
                        <div className="invalid-feedback d-block">{formik.errors.primarySubject}</div>
                      )}
                    </FormGroup>
                  </Col>

                  <Col md="6">
                    <FormGroup>
                      <Label htmlFor="primaryTopic">Select Primary Topic</Label>
                      <Select
                        className="select2-dropdown"
                        value={formik.values.primaryTopic}
                        isDisabled={!formik.values.primarySubject}
                        onChange={(val) => {
                          formik.setFieldValue('primaryTopic', val);
                          formik.setFieldValue('generatorId', '');
                        }}
                        options={topicList
                          .filter(
                            (topic) =>
                              topic?.subject?.id === formik.values.primarySubject?.id ||
                              topic?.subject?.id === formik.values.primarySubject?.value
                          )
                          ?.map((sub) => ({
                            value: sub?.id,
                            label: sub?.name,
                            ...sub,
                          }))}
                      />
                      {formik.errors.primaryTopic && (
                        <div className="invalid-feedback d-block">{formik.errors.primaryTopic}</div>
                      )}
                      {isEditMode && (
                        <div className="mt-3 bg-light rounded-2 px-3 py-2">
                          {formik.values.primaryTopic?.rootTopicId && (
                            <span className="me-3 font-weight-bold">
                              rootTopic :<span className="text-primary">{formik.values.primaryTopic?.rootTopicId}</span>
                            </span>
                          )}
                          {formik.values.primaryTopic?.parentTopicId && (
                            <span className="me-3 font-weight-bold">
                              parentTopic :
                              <span className="text-primary">{formik.values.primaryTopic?.parentTopicId}</span>
                            </span>
                          )}
                          {formik.values.primaryTopic?.id && (
                            <span className="me-3 font-weight-bold">
                              Primary Topic :<span className="text-primary">{formik.values.primaryTopic?.id}</span>
                            </span>
                          )}
                        </div>
                      )}
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup className="mt-4">
                      <Label htmlFor="generatorId">Select Worksheet/Live/Generator Id</Label>
                      <Select
                        className="select2-dropdown"
                        value={!isEmpty(formik.values.generatorId) ? formik.values.generatorId : null}
                        isDisabled={!formik.values.primaryTopic && !formik.values.primarySubject}
                        onChange={(val) => formik.setFieldValue('generatorId', val)}
                        options={WorksheetGeneratorsConstant.find(
                          (elem) =>
                            elem.subject == formik.values.primarySubject?.label?.toLowerCase() &&
                            elem.topic == urlSlugify(formik.values.primaryTopic?.label)
                        )?.generators?.map((sub) => ({
                          value: sub?.slug,
                          label: sub?.slug,
                        }))}
                      />
                      {formik.errors.generatorId && (
                        <div className="invalid-feedback d-block">{formik.errors.generatorId}</div>
                      )}
                    </FormGroup>
                  </Col>
                  <Col md="12">
                    <FormGroup className="my-4">
                      <Label htmlFor="metaData" className="form-label  ">
                        Generator payload
                      </Label>
                      <Input
                        id="generatorPayload"
                        name="generatorPayload"
                        style={{ height: '100px' }}
                        type="textarea"
                        cols={100}
                        className="form-control"
                        placeholder="Enter Generator Payload JSON..."
                        invalid={!!(formik.touched.generatorPayload && formik.errors.generatorPayload)}
                        {...formik.getFieldProps('generatorPayload')}
                      />
                      {formik.errors.generatorPayload && (
                        <div className="invalid-feedback d-block">{formik.errors.generatorPayload}</div>
                      )}
                    </FormGroup>
                  </Col>
                </Row>

                <FormGroup className="mb-4">
                  <Label className="col-form-label ">Add Tags</Label>
                  <SmkAsyncSelect
                    id="tags"
                    small
                    multi
                    creatable
                    placeholder="Tags.."
                    onChange={(val) => formik.setFieldValue('tags', val)}
                    fetchUrl={`${API_BASE_URL}/common/admin/tag/search`}
                    filters={{ forType: MODULE_TYPE.WORKSHEET, page: 1, size: 20 }}
                    searchKeyName="searchText"
                    value={formik.values.tags}
                  />
                  {formik.errors.tags && <div className="invalid-feedback d-block">{formik.errors.tags}</div>}
                </FormGroup>
              </CardBody>
            </Card>
          </Col>
          <Col md={3}>
            <Card>
              <CardBody>
                <div>
                  <Button
                    type="submit"
                    color="primary"
                    className="w-100"
                    onClick={() => {
                      handleSaveWorksheet(false);
                    }}>
                    Save
                  </Button>
                </div>

                <Button
                  className="w-100 mt-3"
                  color="light"
                  onClick={() => {
                    history.replace(SMKLinks.WORKSHEET_LISTING);
                  }}>
                  Cancel
                </Button>
              </CardBody>
            </Card>

            <Card>
              <CardBody>
                <FormGroup className="mb-4">
                  <Label htmlFor="type">Select Type</Label>
                  <Select
                    className="select2-dropdown"
                    value={!isEmpty(formik.values.type) ? formik.values.type : null}
                    onChange={(val) => formik.setFieldValue('type', val)}
                    options={Object.keys(WORKSHEET_TYPE)
                      ?.filter((e) => e !== 'LIVE_WORKSHEETS')
                      ?.map((e) => ({
                        value: WORKSHEET_TYPE[e],
                        label: titleCaseIfExists(e),
                      }))}
                  />
                  {formik.errors.type && <div className="invalid-feedback d-block">{formik.errors.type}</div>}
                </FormGroup>

                <div>
                  <CardTitle className="mb-3">Active</CardTitle>
                  {[true, false].map((option, i) => (
                    <div key={`active_${i}`} className="form-check mb-3 d-inline-block me-4">
                      <input
                        type="radio"
                        id={`active_${option}`}
                        name="active"
                        className="form-check-input"
                        checked={formik.values.active === option}
                        onChange={() => formik.setFieldValue('active', option)}
                      />
                      <label className="form-check-label" htmlFor={`active_${option}`}>
                        {option ? 'Yes' : 'No'}
                      </label>
                    </div>
                  ))}
                </div>
                <div>
                  <CardTitle className="mb-3">Delete </CardTitle>
                  {[true, false].map((option, i) => (
                    <div key={`deleted_${i}`} className="form-check mb-3 d-inline-block me-4">
                      <input
                        type="radio"
                        id={`deleted_${option}`}
                        name="deleted"
                        className="form-check-input"
                        checked={formik.values.deleted === option}
                        onChange={() => formik.setFieldValue('deleted', option)}
                      />
                      <label className="form-check-label" htmlFor={`deleted_${option}`}>
                        {option ? 'Yes' : 'No'}
                      </label>
                    </div>
                  ))}
                </div>
                <div>
                  <CardTitle className="mb-3">AutoGenerate </CardTitle>
                  {[true, false].map((option, i) => (
                    <div key={`autoGenerate_${i}`} className="form-check mb-3 d-inline-block me-4">
                      <input
                        type="radio"
                        id={`autoGenerate_${option}`}
                        name="autoGenerate"
                        className="form-check-input"
                        checked={formik.values.autoGenerate === option}
                        onChange={() => formik.setFieldValue('autoGenerate', option)}
                      />
                      <label className="form-check-label" htmlFor={`autoGenerate_${option}`}>
                        {option ? 'Yes' : 'No'}
                      </label>
                    </div>
                  ))}
                </div>
              </CardBody>
            </Card>

            <AssetImageUploader
              forType={MODULE_TYPE.WORKSHEET}
              formik={formik}
              fieldName={'worksheetImage'}
              label={'Upload Worksheet'}
            />

            <AssetImageUploader
              forType={MODULE_TYPE.WORKSHEET}
              formik={formik}
              fieldName={'answerImage'}
              label={'Upload Worksheet Answers'}
            />
          </Col>
        </Row>
      </Form>
    </>
  );
};

SingleWorksheetForm.propTypes = {
  worksheet: PropTypes.object,
};

export default SingleWorksheetForm;
