import Breadcrumbs from 'components/Common/Breadcrumb';
import Loader from 'components/Loader';
import SlugInput from 'components/SlugInput';
import SmkAsyncSelect from 'components/SmkAsyncSelect';
import TextEditor from 'components/TextEditor';
import AssetImageUploader from 'containers/blog/BlogList/components/AssetImageUploader';
import SeoMetaData from 'containers/blog/BlogList/components/SeoMetaData';
import { useFormik } from 'formik';
import useRequest from 'hooks/useRequest';
import isEmpty from 'lodash/isEmpty';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toastify';
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap';
import { API_BASE_URL, LANGUAGE_ARRAY, MODULE_TYPE, taxonomyType } from 'utils/constants';
import { getTranslationLabel } from 'utils/helper';
import { SMKLinks } from 'utils/links';
import * as Yup from 'yup';

const AddUpdateCalculators = () => {
  const history = useHistory();
  const { id: calculatorID } = useParams();

  toast.configure();

  const [calculatorCategories, setCalculatorCategories] = useState([]);
  const [calculatorDetails, setCalculatorDetails] = useState();

  const [getCalculatorTaxonomyList, { loading: getCalculatorTaxonomyListLoading }] = useRequest({
    url: `/common/admin/taxonomy/search`,
    method: 'POST',
    onSuccess: (data) => {
      setCalculatorCategories(data?.results);
    },
    onError: (err) => {
      toast.error(err?.message?.message || err?.message);
    },
  });

  const [getCalculatorRequest, { loading: getCalculatorLoading }] = useRequest({
    url: `/calculator/admin/${calculatorID}`,
    method: 'GET',
    onSuccess: (data) => {
      setCalculatorDetails(data);
    },
    onError: (err) => {
      toast.error(err?.message?.message || err?.message);
    },
  });

  const [addCalculatorRequest, { loading: addCalculatorLoading }] = useRequest({
    url: `/calculator/admin`,
    method: 'POST',
    onSuccess: (data) => {
      const url = SMKLinks.CALCULATOR_LISTING;
      history.replace(url);
      toast.success('Success! Calculator has been added.');
    },
    onError: (err) => {
      toast.error(err?.message?.message || err?.message);
    },
  });

  const [UpdateCalculatorRequest, { loading: UpdateCalculatorLoading }] = useRequest({
    url: `/calculator/admin/${calculatorID}`,
    method: 'PUT',
    onSuccess: (data) => {
      const url = SMKLinks.CALCULATOR_LISTING;
      toast.success('Success! Calculator has been updated.');

      if (calculatorDetails) {
        window.location.reload();
      } else {
        history.replace(url);
      }
    },
    onError: (err) => {
      toast.error(err?.message?.message || err?.message);
    },
  });

  const editInitialValues = () => {
    return {
      active: calculatorDetails?.active,
      deleted: calculatorDetails?.deleted,
      isPopular: calculatorDetails?.isPopular,
      name: calculatorDetails?.name,

      title: calculatorDetails?.title,
      slug: calculatorDetails?.slug,
      content: calculatorDetails?.content,
      description: calculatorDetails?.description,
      primaryCategory: {
        label: calculatorDetails?.primaryCategory?.name,
        value: calculatorDetails?.primaryCategory?.id,
      },
      categories: calculatorDetails?.categories?.map((e) => ({
        label: e?.name,
        value: e?.id,
      })),

      language: LANGUAGE_ARRAY?.find((e) => e?.value === calculatorDetails?.language),
      translations: !isEmpty(calculatorDetails?.translations) ? calculatorDetails?.translations[0] : {},
      seoTitle: calculatorDetails?.seo?.title || '',
      seoDescription: calculatorDetails?.seo?.description || '',
      seoKeywords: !isEmpty(calculatorDetails?.seo?.keywords)
        ? calculatorDetails?.seo?.keywords?.split(', ')?.map((e) => ({
            label: e,
            value: e,
          }))
        : '',
      ogImage: calculatorDetails?.seo?.ogImage || {},
      image: calculatorDetails?.image || {},
    };
  };

  const getInitialValues = () => ({
    active: true,
    deleted: false,
    isPopular: false,
    name: '',

    title: '',
    slug: '',
    content: '',
    description: '',
    primaryCategory: null,
    categories: [],

    language: LANGUAGE_ARRAY[0],
    seoTitle: '',
    seoDescription: '',
    seoKeywords: '',
    ogImage: {},

    image: {},
    translations: '',
  });

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: false,
    initialValues: isEmpty(calculatorID) ? { ...getInitialValues() } : { ...editInitialValues() },
    onSubmit: async (values) => {
      const data = {
        active: values?.active,
        deleted: values?.deleted,
        isPopular: values?.isPopular,
        title: values?.title,
        name: values?.name,
        slug: values?.slug,
        content: values?.content,
        description: values?.description,
        primaryCategory: {
          id: values?.primaryCategory?.value,
        },
        categories: values?.categories?.map((e) => ({
          id: e?.value,
        })),

        language: values?.language?.value,
        translations: !isEmpty(values?.translations) ? [values?.translations?.id] : [],
        seo: {
          title: !isEmpty(values?.seoTitle) ? values?.seoTitle : '',
          description: !isEmpty(values?.seoDescription) ? values?.seoDescription : '',
          keywords: !isEmpty(values?.seoKeywords) ? values?.seoKeywords?.map((e) => e?.value).join(', ') : '',
          ...(!isEmpty(values?.ogImage)
            ? {
                ogImageId: values?.ogImage?.id,
                ogImage: undefined,
              }
            : { ogImageId: null }),
        },
        ...(!isEmpty(values?.image)
          ? {
              imageId: values?.image?.id,
              image: undefined,
            }
          : { imageId: null }),

        seoTitle: undefined,
        seoDescription: undefined,
        seoKeywords: undefined,
        ogImage: undefined,
      };

      if (isEmpty(calculatorID)) {
        addCalculatorRequest({
          body: {
            ...data,
          },
        });
      } else {
        UpdateCalculatorRequest({
          body: {
            ...data,
          },
        });
      }
    },
    validationSchema: Yup.object().shape({
      title: Yup.string().required('Please provide title'),
      name: Yup.string().required('Please provide name'),
      primaryCategory: Yup.object()
        .required('Please provide primary category')
        .nullable(true),
      categories: Yup.array()
        .required('Please provide category')
        .min(1, 'Please select category'),
    }),
  });

  useEffect(() => {
    if (!isEmpty(calculatorID)) {
      getCalculatorRequest();
    }
  }, [calculatorID]);

  useEffect(() => {
    if (!isEmpty(formik.values.language)) {
      getCalculatorTaxonomyList({
        body: {
          page: 1,
          size: 500,
          sortBy: 'id',
          sortOrder: 0,
          type: taxonomyType?.CALCULATOR_CATEGORY,
          language: formik.values.language?.value,
        },
      });
    }
  }, [formik.values.language]);

  return (
    <>
      <div className="page-content">
        <Loader
          isActive={
            addCalculatorLoading || getCalculatorLoading || UpdateCalculatorLoading || getCalculatorTaxonomyListLoading
          }
        />
        <Container fluid>
          <Breadcrumbs title="Calculator" breadcrumbItem={`${isEmpty(calculatorID) ? 'Add' : 'Edit'}  Calculator`} />
          <Form onSubmit={formik.handleSubmit}>
            <Row>
              <Col md={9}>
                <Card>
                  <CardBody>
                    <Row className="align-items-center">
                      <Col md={12}>
                        <FormGroup className="mb-4">
                          <Label for="title" className="form-label  ">
                            Title <span className="text-danger">*</span>
                          </Label>
                          <Input
                            id="title"
                            name="title"
                            type="text"
                            className="form-control"
                            placeholder="Enter Title..."
                            invalid={!!(formik.touched.title && formik.errors.title)}
                            {...formik.getFieldProps('title')}
                          />
                          <FormFeedback>{formik.errors.title}</FormFeedback>
                        </FormGroup>
                      </Col>
                      <Col md={12}>
                        <FormGroup className="mb-4">
                          <Label for="name" className="form-label  ">
                            Name <span className="text-danger">*</span>
                          </Label>
                          <Input
                            id="name"
                            name="name"
                            type="text"
                            className="form-control"
                            placeholder="Enter Name..."
                            invalid={!!(formik.touched.name && formik.errors.name)}
                            {...formik.getFieldProps('name')}
                          />
                          <FormFeedback>{formik.errors.name}</FormFeedback>
                        </FormGroup>
                      </Col>
                      <Col md={12} className="mb-4">
                        <SlugInput
                          required={true}
                          formik={formik}
                          ID={calculatorID}
                          nameValue={formik.values.name}
                          formGroupClassName="mb-0"
                        />
                      </Col>

                      <Col md={6}>
                        <FormGroup className="mb-4">
                          <Label for="categories" className="form-label  ">
                            Primary Category <span className="text-danger">*</span>
                          </Label>
                          <Select
                            styles={{
                              menu: (provided) => ({ ...provided, zIndex: 999 }),
                            }}
                            placeholder="Select primary category..."
                            options={calculatorCategories
                              ?.filter((d) => d.level === 0)
                              ?.map((e) => ({
                                label: e?.name,
                                value: e?.id,
                              }))}
                            value={formik.values.primaryCategory}
                            onChange={(val) => formik.setFieldValue('primaryCategory', val)}
                          />
                          {formik.touched.primaryCategory && formik.errors.primaryCategory && (
                            <div className="invalid-feedback d-block">{formik.errors.primaryCategory}</div>
                          )}
                        </FormGroup>
                      </Col>

                      <Col md={6}>
                        <FormGroup className="mb-4">
                          <Label for="categories" className="form-label  ">
                            Categories <span className="text-danger">*</span>
                          </Label>

                          <Select
                            isMulti={true}
                            styles={{
                              menu: (provided) => ({ ...provided, zIndex: 999 }),
                            }}
                            placeholder="Select category..."
                            options={calculatorCategories?.map((e) => ({
                              label: e?.name + ' (' + e?.level + ')',
                              value: e?.id,
                            }))}
                            value={formik.values.categories}
                            onChange={(val) => formik.setFieldValue('categories', val)}
                          />
                          {formik.touched.categories && formik.errors.categories && (
                            <div className="invalid-feedback d-block">{formik.errors.categories}</div>
                          )}
                        </FormGroup>
                      </Col>

                      <Col md={12}>
                        <FormGroup className="mb-4">
                          <Label for="description" className="form-label">
                            Description
                          </Label>
                          <Input
                            id="description"
                            name="description"
                            type="textarea"
                            rows={5}
                            className="form-control"
                            placeholder="Enter description..."
                            invalid={!!(formik.touched.description && formik.errors.description)}
                            {...formik.getFieldProps('description')}
                          />
                          <FormFeedback className="d-block">{formik.errors.description}</FormFeedback>
                        </FormGroup>
                      </Col>
                      <Col md={12}>
                        <FormGroup className="mb-4">
                          <Label for="content" className="form-label">
                            Content
                          </Label>
                          <TextEditor
                            simple
                            height={200}
                            initialValue={formik.values?.content}
                            onChange={(val) => formik.setFieldValue('content', val)}
                          />
                          <FormFeedback className="d-block">{formik.errors.content}</FormFeedback>
                        </FormGroup>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>

                <SeoMetaData formik={formik} />
              </Col>
              <Col md={3}>
                <Card>
                  <CardBody>
                    <div>
                      <Button type="submit" color="primary" className="mb-3 w-100">
                        {calculatorID ? 'Save Calculator' : 'Add Calculator'}
                      </Button>

                      <Button
                        className="w-100"
                        color="light"
                        onClick={() => {
                          history.replace(SMKLinks.CALCULATOR_LISTING);
                        }}>
                        Cancel
                      </Button>
                    </div>
                  </CardBody>
                </Card>
                <Card>
                  <CardBody>
                    <Row className="align-items-center">
                      <Col md={12} className="mb-3">
                        <CardTitle className="mb-3">Active </CardTitle>
                        {[true, false].map((option, i) => (
                          <div key={`active_${i}`} className="form-check d-inline-block me-4">
                            <input
                              type="radio"
                              id={`active_${option}`}
                              name="active"
                              className="form-check-input"
                              checked={formik.values.active === option}
                              onChange={() => formik.setFieldValue('active', option)}
                            />
                            <label className="form-check-label" htmlFor={`active_${option}`}>
                              {option ? 'Yes' : 'No'}
                            </label>
                          </div>
                        ))}
                      </Col>

                      <Col md={12} className="mb-3">
                        <CardTitle className="mb-3">Delete </CardTitle>
                        {[true, false].map((option, i) => (
                          <div key={`deleted_${i}`} className="form-check d-inline-block me-4">
                            <input
                              type="radio"
                              id={`deleted_${option}`}
                              name="deleted"
                              className="form-check-input"
                              checked={formik.values.deleted === option}
                              onChange={() => formik.setFieldValue('deleted', option)}
                            />
                            <label className="form-check-label" htmlFor={`deleted_${option}`}>
                              {option ? 'Yes' : 'No'}
                            </label>
                          </div>
                        ))}
                      </Col>

                      <Col md={12} className="mb-3">
                        <CardTitle className="mb-3">isPopular </CardTitle>
                        {[true, false].map((option, i) => (
                          <div key={`isPopular_${i}`} className="form-check d-inline-block me-4">
                            <input
                              type="radio"
                              id={`isPopular_${option}`}
                              name="isPopular"
                              className="form-check-input"
                              checked={formik.values.isPopular === option}
                              onChange={() => formik.setFieldValue('isPopular', option)}
                            />
                            <label className="form-check-label" htmlFor={`isPopular_${option}`}>
                              {option ? 'Yes' : 'No'}
                            </label>
                          </div>
                        ))}
                      </Col>
                    </Row>
                  </CardBody>
                </Card>

                <Card className="border">
                  <CardBody>
                    <Row>
                      <Col md={12}>
                        <CardTitle className="mb-3">Language </CardTitle>
                        {LANGUAGE_ARRAY?.map((option, i) => (
                          <div key={`language_${i}`} className="form-check mb-3 d-inline-block me-4">
                            <input
                              type="radio"
                              id={`language_${option?.value}`}
                              name="language"
                              className="form-check-input"
                              checked={formik.values.language?.value === option?.value}
                              onChange={() => {
                                formik.setFieldValue('language', option);
                                formik.setFieldValue('primaryCategory', null);
                                formik.setFieldValue('categories', []);
                              }}
                            />
                            <label className="form-check-label" htmlFor={`language_${option?.value}`}>
                              {option?.label}
                            </label>
                          </div>
                        ))}
                      </Col>
                      <Col md={12}>
                        <CardTitle className="mb-3">Translation </CardTitle>
                        <SmkAsyncSelect
                          isDisabled={formik.values.language?.value === 1}
                          acceptedKey={'name'}
                          acceptedValue={'id'}
                          placeholder="Type article name.."
                          onChange={(res) => {
                            formik.setFieldValue('translations', res);
                          }}
                          isValueSlugify={true}
                          fetchUrl={`${API_BASE_URL}/calculator/admin/search`}
                          filters={{
                            autoComplete: true,
                            active: true,
                            page: 1,
                            size: 500,
                            sortBy: 'id',
                            sortOrder: 0,
                            language: formik.values.language?.value === 0 ? 1 : 0,
                          }}
                          searchKeyName="name"
                          value={
                            !isEmpty(formik?.values?.translations)
                              ? {
                                  label: getTranslationLabel(formik?.values?.translations),
                                  value: formik?.values?.translations?.id,
                                }
                              : ''
                          }
                        />

                        {!isEmpty(formik?.values?.translations) && (
                          <div className="mt-3">
                            <div className="card">
                              <div className="card-body bg-light">
                                <a target="_blank" href={`/calculator/edit/${formik?.values?.translations?.id}`}>
                                  <p className="mb-1">
                                    <span className="text-dark">Language:</span>{' '}
                                    {formik?.values?.translations?.language}
                                  </p>
                                  <p className="mb-1">
                                    <span className="text-dark">ID:</span> {formik?.values?.translations?.id}
                                  </p>
                                  <p className="mb-1">
                                    <span className="text-dark">Title:</span>{' '}
                                    <span
                                      dangerouslySetInnerHTML={{
                                        __html: getTranslationLabel(formik?.values?.translations),
                                      }}></span>
                                  </p>
                                </a>
                              </div>
                            </div>
                          </div>
                        )}
                      </Col>
                    </Row>
                  </CardBody>
                </Card>

                <AssetImageUploader
                  forType={MODULE_TYPE.METADATA}
                  formik={formik}
                  fieldName={'image'}
                  label={'Select Image'}
                />
              </Col>
            </Row>
          </Form>
        </Container>
      </div>
    </>
  );
};

export default AddUpdateCalculators;
