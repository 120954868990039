import SmkAsyncSelect from 'components/SmkAsyncSelect';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  FormFeedback,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';
import { API_BASE_URL } from 'utils/constants';
import { showImage } from 'utils/helper';
import { showImageError } from '../../helper';
import { ImageUploadModal } from './editor/editorImageUploader';
import confirm from 'reactstrap-confirm';
import { toast } from 'react-toastify';
import { api } from 'utils/axios';

const AssetImageUploader = ({ formik, fieldName, label, forType }) => {
  const [imageUploadedModal, setImageUploadedModal] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [uploadedImage, setUploadedImage] = useState({});
  toast.configure();

  const fieldObj = formik?.values[fieldName];
  const IMAGE_TYPE = 'thumbnailMedium';

  const toggleModal = () => setModalOpen(!modalOpen);
  const appSource = formik?.values?.appSource === 'schoolmykids' ? 'smk' : 'tpz';

  const imageURL = fieldObj?.[IMAGE_TYPE]
    ? showImage(fieldObj?.[IMAGE_TYPE], appSource)
    : fieldObj?.original
    ? showImage(fieldObj?.original, appSource)
    : fieldObj?.url;

  const handleSubmit = () => {
    formik.setFieldValue(fieldName, uploadedImage);
    toggleModal();
  };

  useEffect(() => {
    if (!isEmpty(fieldObj)) {
      setUploadedImage({
        ...fieldObj,
        label: fieldObj?.name,
        value: fieldObj?.id,
      });
    }
  }, [fieldObj]);

  const toggleImageUploadedModal = () => {
    setModalOpen(false);
    setImageUploadedModal(!imageUploadedModal);
  };

  // const handleDeleteAsset = async () => {
  //   if (!isEmpty(uploadedImage)) {
  //     return await api({
  //       method: 'DELETE',
  //       url: `${API_BASE_URL}/asset/admin/${uploadedImage?.id}`,
  //     })
  //       ?.then((data) => {
  //         if (!isEmpty(data?.message)) {
  //           toast.error(data?.message || 'Something went wrong');
  //         } else {
  //           setUploadedImage({});
  //           formik.setFieldValue(fieldName, '');
  //         }
  //       })
  //       ?.catch((err) => {
  //         console.warn('err', err);
  //         toast.error(err?.message || 'Something went wrong');
  //       });
  //   }
  // };

  const handleRemove = async () => {
    const result = await confirm({
      title: 'Remove Image',
      message: 'Are you sure, you want to remove this image?',
      confirmText: 'Confirm',
      confirmColor: 'primary',
      cancelColor: 'link text-danger',
    });
    if (result) {
      // handleDeleteAsset();
      setUploadedImage({});
      formik.setFieldValue(fieldName, '');
    }
  };

  return (
    <Card className="border">
      <CardBody>
        <div>
          <CardTitle>{label}</CardTitle>

          {isEmpty(imageURL) && (
            <Button className="w-100 py-3 mt-2" outline color="dark" onClick={toggleModal}>
              Add Image
            </Button>
          )}

          <Modal isOpen={modalOpen} toggle={toggleModal}>
            <ModalHeader toggle={toggleModal}>Select and Upload Image</ModalHeader>
            <ModalBody>
              <Label className="form-label">Select Image</Label>

              <SmkAsyncSelect
                acceptedKey={'name'}
                acceptedValue={'name'}
                placeholder="Type media name.."
                onChange={(res) => {
                  if (res !== null) {
                    const resURL = res?.[IMAGE_TYPE] ? res?.[IMAGE_TYPE] : res['original'];
                    const url = res ? showImage(resURL) : '';
                    setUploadedImage({
                      ...res,
                      url,
                    });
                  } else {
                    setUploadedImage({});
                  }
                }}
                fetchUrl={`${API_BASE_URL}/asset/admin/search`}
                filters={{
                  autoComplete: true,
                  page: 1,
                  size: 1000,
                  sortBy: 'id',
                  sortOrder: 0,
                  forType,
                }}
                searchKeyName="name"
                value={
                  !isEmpty(uploadedImage)
                    ? {
                        label: uploadedImage?.name,
                        value: uploadedImage?.id,
                      }
                    : ''
                }
              />

              <div className="py-3 h5 mb-0 text-center">OR</div>
              <Button className="w-100 py-3" outline color="info" onClick={toggleImageUploadedModal}>
                Upload Image
              </Button>

              {!isEmpty(uploadedImage) && (
                <div className="mt-4">
                  <img
                    className="mt-4"
                    alt={uploadedImage?.name}
                    src={uploadedImage.url || showImage(uploadedImage?.original)}
                    style={{
                      maxWidth: '100%',
                      objectFit: 'contain',
                    }}
                    onError={(e) => {
                      showImageError(e);
                    }}
                  />
                </div>
              )}
            </ModalBody>
            <ModalFooter>
              <Button color="primary" className="me-3" onClick={handleSubmit}>
                Add
              </Button>
              <Button
                color="light"
                onClick={() => {
                  if (isEmpty(fieldObj)) {
                    setUploadedImage({});
                  }
                  toggleModal();
                }}>
                Cancel
              </Button>
            </ModalFooter>
          </Modal>

          {!isEmpty(fieldObj) && (
            <div className="mt-4 row">
              <div className={`col-md-${fieldName === 'ogImage' ? '6' : '12'}`}>
                <a
                  onClick={() => {
                    toggleModal();
                  }}>
                  <img
                    style={{
                      width: '-webkit-fill-available',
                    }}
                    className="mb-3 p-2"
                    src={imageURL}
                    onError={(e) => {
                      showImageError(e);
                    }}
                  />
                  <p className="mb-3">{fieldObj?.name}</p>
                </a>
                <Button color="danger" className="w-100" onClick={handleRemove}>
                  Remove
                </Button>
              </div>
            </div>
          )}

          <ImageUploadModal
            isOpen={imageUploadedModal}
            toggle={toggleImageUploadedModal}
            forType={forType}
            onUpload={(file) => {
              toggleImageUploadedModal();
              setUploadedImage(file);
              formik.setFieldValue(fieldName, file);
            }}
          />

          <FormFeedback className="d-block">{formik.errors[fieldName]}</FormFeedback>
        </div>
      </CardBody>
    </Card>
  );
};

export default AssetImageUploader;
