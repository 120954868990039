import Breadcrumbs from 'components/Common/Breadcrumb';
import Loader from 'components/Loader';
import TextEditor from 'components/TextEditor';
import AssetImageUploader from 'containers/blog/BlogList/components/AssetImageUploader';
import { useFormik } from 'formik';
import isEmpty from 'lodash/isEmpty';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Card, CardBody, Col, Container, Form, FormFeedback, FormGroup, Input, Label, Row } from 'reactstrap';
import { MODULE_TYPE } from 'utils/constants';
import { titleCaseIfExists } from 'utils/helper';
import * as Yup from 'yup';
import useRequest from '../../../hooks/useRequest';
import { SMKLinks } from '../../../utils/links';
import StaticPageSections from './StaticContentSections';
import SlugInput from 'components/SlugInput';
import CreatableSelect from 'react-select/creatable';

const AddUpdateStaticContent = () => {
  const history = useHistory();
  const { id: pageID } = useParams();

  toast.configure();

  const [sections, setSections] = useState([]);

  const [staticContentDetails, setStaticContentDetails] = useState();
  const [contentModuleList, setContentModuleList] = useState([]);
  const [contentSubModuleList, setContentSubModuleList] = useState([]);

  const [getContentModule, { loading: getContentModuleLoading }] = useRequest({
    url: `/cms/admin/static-content/modules`,
    method: 'GET',
    onSuccess: (data) => {
      setContentModuleList(
        data?.map((e) => ({
          label: e.module,
          value: e.module,
        }))
      );
    },
    onError: (err) => {
      toast.error(err?.message?.message || err?.message);
    },
  });
  const [getContentSubModule, { loading: getContentSubModuleLoading }] = useRequest({
    method: 'GET',
    onSuccess: (data) => {
      setContentSubModuleList(
        data?.map((e) => ({
          label: e.subModule,
          value: e.subModule,
        }))
      );
    },
    onError: (err) => {
      toast.error(err?.message?.message || err?.message);
    },
  });

  const [getStaticContentRequest, { loading: getStaticContentLoading }] = useRequest({
    url: `/cms/admin/static-content/${pageID}`,
    method: 'GET',
    onSuccess: (data) => {
      setStaticContentDetails(data);
      setSections(data?.sections);
    },
    onError: (err) => {
      toast.error(err?.message?.message || err?.message);
    },
  });

  const [addStaticContentRequest, { loading: addStaticContentLoading }] = useRequest({
    url: `/cms/admin/static-content`,
    method: 'POST',
    onSuccess: () => {
      const url = SMKLinks.STATIC_PAGES_LIST;
      history.replace(url);
      toast.success('Success! Staic Content has been added.');
    },
    onError: (err) => {
      toast.error(err?.message?.message || err?.message);
    },
  });
  const [updateContentRequest, { loading: updateContentLoading }] = useRequest({
    url: `/cms/admin/static-content/${pageID}`,
    method: 'PUT',
    onSuccess: () => {
      toast.success('Success! Staic Content has been updated.');
      window.location.reload();
    },
    onError: (err) => {
      toast.error(err?.message?.message || err?.message);
    },
  });

  const isJSON = (val) => {
    try {
      return typeof val === 'string' && JSON.parse(val);
    } catch (err) {
      toast.error(err);
      return false;
    }
  };

  const convertJSON = (val) => {
    try {
      const parseObj = JSON.parse(val);
      return parseObj;
    } catch (err) {
      toast.error(err);
    }
  };

  const getFormikValues = () => {
    return {
      active: staticContentDetails?.active || true,
      deleted: staticContentDetails?.deleted || false,
      module: !isEmpty(staticContentDetails?.module)
        ? {
            label: staticContentDetails?.module,
            value: staticContentDetails?.module,
          }
        : null,
      subModule: !isEmpty(staticContentDetails?.subModule)
        ? {
            label: staticContentDetails?.subModule,
            value: staticContentDetails?.subModule,
          }
        : null,
      title: staticContentDetails?.title || '',
      slug: staticContentDetails?.slug || '',
      content: staticContentDetails?.content || '',
      sections: staticContentDetails?.sections || [],
      image: staticContentDetails?.image || {},
      metadata: !isEmpty(staticContentDetails?.metadata)
        ? JSON.stringify(staticContentDetails?.metadata, null, 2)
        : '{}',
    };
  };

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: false,
    initialValues: getFormikValues(),
    onSubmit: async (values) => {
      if (!isJSON(values?.metadata)) {
        toast.error('Please enter a valid metadata JSON');
        return;
      }

      const data = {
        active: values?.active,
        deleted: values?.deleted,
        imageId: !isEmpty(values?.image) ? values?.image?.id : null,
        title: values?.title,
        slug: values?.slug,
        content: values?.content,
        module: values?.module?.value,
        subModule: values?.subModule?.value,
        sections: !isEmpty(sections) ? sections : [],
        metadata: convertJSON(values?.metadata),
      };

      if (isEmpty(pageID)) {
        addStaticContentRequest({
          body: {
            ...data,
          },
        });
      } else {
        updateContentRequest({
          body: {
            ...data,
          },
        });
      }
    },
    validationSchema: Yup.object().shape({
      title: Yup.string().required('Please provide title'),
      slug: Yup.string().required('Please provide slug'),
      module: Yup.object()
        .required('Please provide module ')
        .nullable(true),
      subModule: Yup.object()
        .required('Please provide subModule ')
        .nullable(true),
      content: Yup.string().required('Please provide content'),
    }),
  });

  useEffect(() => {
    if (!isEmpty(pageID)) {
      getStaticContentRequest();
    }
  }, [pageID]);

  useEffect(() => {
    getContentModule();
  }, []);

  useEffect(() => {
    if (!isEmpty(formik.values.module?.value) && !formik.values.module?.__isNew__) {
      getContentSubModule({
        url: `/cms/admin/faq/${formik?.values?.module?.value}/sub-modules`,
      });
    }
  }, [formik.values.module?.value]);

  return (
    <>
      <div className="page-content">
        <Loader
          isActive={
            getStaticContentLoading ||
            addStaticContentLoading ||
            updateContentLoading ||
            getContentModuleLoading ||
            getContentSubModuleLoading
          }
        />
        <Container fluid>
          <Breadcrumbs title="Static Page" breadcrumbItem={`${isEmpty(pageID) ? 'Add' : 'Edit'}  Static Page`} />

          <Form onSubmit={formik.handleSubmit}>
            <Row>
              <Col md={9}>
                <Card>
                  <CardBody>
                    <Row className="align-items-center">
                      <Col md={12}>
                        <FormGroup className="mb-4">
                          <Label for="title" className="form-label  ">
                            Title <span className="text-danger">*</span>
                          </Label>
                          <Input
                            id="title"
                            name="title"
                            type="text"
                            className="form-control"
                            placeholder="Enter title..."
                            invalid={!!(formik.touched.title && formik.errors.title)}
                            {...formik.getFieldProps('title')}
                          />
                          {formik.errors.title && formik.touched.title && (
                            <FormFeedback className="d-block">{formik.errors.title}</FormFeedback>
                          )}
                        </FormGroup>
                      </Col>
                      <Col md={12} className="mb-4">
                        <SlugInput
                          formik={formik}
                          ID={pageID}
                          nameValue={formik.values.title}
                          formGroupClassName={'mt-0'}
                        />
                      </Col>
                      <Col md={6}>
                        <FormGroup className="mb-4">
                          <Label for="module" className="form-label  ">
                            Module <span className="text-danger">*</span>
                          </Label>
                          <CreatableSelect
                            id="module"
                            options={contentModuleList}
                            value={formik.values.module}
                            onChange={(e) => formik.setFieldValue('module', e)}
                          />
                          {formik.errors.module && formik.touched.module && (
                            <FormFeedback className="d-block">{formik.errors.module}</FormFeedback>
                          )}
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup className="mb-4">
                          <Label for="subModule" className="form-label  ">
                            Sub module <span className="text-danger">*</span>
                          </Label>
                          <CreatableSelect
                            id="subModule"
                            options={contentSubModuleList}
                            value={formik.values.subModule}
                            onChange={(e) => formik.setFieldValue('subModule', e)}
                          />
                          {formik.errors.subModule && formik.touched.subModule && (
                            <FormFeedback className="d-block">{formik.errors.subModule}</FormFeedback>
                          )}
                        </FormGroup>
                      </Col>

                      <Col md={12}>
                        <FormGroup className="mb-4">
                          <Label for="content" className="form-label  ">
                            Content <span className="text-danger">*</span>
                          </Label>
                          <TextEditor
                            simple
                            height={200}
                            initialValue={formik.values?.content}
                            onChange={(val) => formik.setFieldValue('content', val)}
                          />
                          {formik.errors.content && formik.touched.content && (
                            <FormFeedback className="d-block">{formik.errors.content}</FormFeedback>
                          )}
                        </FormGroup>
                      </Col>
                      <Col md={12}>
                        <FormGroup className="mb-4">
                          <Label for="metadata" className="form-label  ">
                            Metadata
                          </Label>
                          <Input
                            rows={6}
                            id="metadata"
                            name="metadata"
                            type="textarea"
                            className="form-control"
                            placeholder="Meta Data..."
                            invalid={!!(formik.touched.metadata && formik.errors.metadata)}
                            {...formik.getFieldProps('metadata')}
                          />
                          {formik.errors.metadata && formik.touched.metadata && (
                            <FormFeedback className="d-block">{formik.errors.metadata}</FormFeedback>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>

                <StaticPageSections sections={sections} setSections={setSections} />
              </Col>
              <Col md={3}>
                <Card>
                  <CardBody>
                    <div>
                      <Button type="submit" color="primary" className="mb-3 w-100">
                        {pageID ? 'Save' : 'Add'}
                      </Button>

                      <Button
                        className="w-100"
                        color="light"
                        onClick={() => {
                          history.replace(SMKLinks.STATIC_PAGES_LIST);
                        }}>
                        Cancel
                      </Button>
                    </div>
                  </CardBody>
                </Card>

                <Card>
                  <CardBody>
                    {['active', 'deleted']?.map((elem, index) => (
                      <FormGroup className={`mb-4`} key={index}>
                        <Label className="mb-3">{titleCaseIfExists(elem)} </Label>
                        <div className="d-flex align-items-center">
                          {[true, false].map((option, i) => (
                            <div key={`${elem}_${i}`} className="form-check d-inline-block me-4">
                              <input
                                type="radio"
                                id={`${elem}_${option}`}
                                name={elem}
                                className="form-check-input"
                                checked={formik.values[elem] === option}
                                onChange={() => formik.setFieldValue(`${elem}`, option)}
                              />
                              <label className="form-check-label" htmlFor={`${elem}_${option}`}>
                                {option ? 'Yes' : 'No'}
                              </label>
                            </div>
                          ))}
                        </div>
                      </FormGroup>
                    ))}
                  </CardBody>
                </Card>

                <AssetImageUploader
                  forType={MODULE_TYPE.METADATA}
                  formik={formik}
                  fieldName={'image'}
                  label={'Select Image'}
                />
              </Col>
            </Row>
          </Form>
        </Container>
      </div>
    </>
  );
};

export default AddUpdateStaticContent;
