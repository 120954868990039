import { replace } from 'connected-react-router';
import isEmpty from 'lodash/isEmpty';
import { call, put, takeLatest } from 'redux-saga/effects';
import AuthService from '../../common/auth.service';
import config from '../../config/config';
import { api } from '../../utils/axios';
import { USER_ROLE } from '../../utils/constants';
import { SMKLinks } from '../../utils/links';
import { doLogin, doLogout, doSocialLogin, getUser } from './actions';

export const LOGIN_API_URL = `${config.apiEndpoint}/user/login`;
export const LOGOUT_API_URL = `${config.apiEndpoint}/user/logout`;
export const GET_USER_API_URL = `${config.apiEndpoint}/user/me`;
export const SOCIAL_LOGIN_API_URL = `${config.apiEndpoint}/user/social-login`;

export const getUserAPIKey = (source) => {
  return {
    'x-api-key':
      source === 'TPZ'
        ? process.env.REACT_APP_TPZ_API_KEY
        : source === 'Mediwatcher'
        ? process.env.REACT_APP_MEDIWATCHER_API_KEY
        : process.env.REACT_APP_API_KEY,
  };
};
const loginCall = (email, password, userType) =>
  api(
    {
      method: 'POST',
      url: LOGIN_API_URL,
      data: {
        email,
        password,
        device: 0,
      },
    },
    {
      ...getUserAPIKey(userType),
    }
  );

const logoutCall = () =>
  api({
    method: 'POST',
    url: LOGOUT_API_URL,
  });

const getUserCall = (userType) =>
  api(
    {
      method: 'GET',
      url: GET_USER_API_URL,
    },
    {
      ...getUserAPIKey(userType),
    }
  );

const socialLoginCall = (payload, userType) =>
  api(
    {
      method: 'POST',
      url: SOCIAL_LOGIN_API_URL,
      data: {
        ...payload,
      },
    },
    {
      ...getUserAPIKey(userType),
    }
  );

function* doLoginRequest(action) {
  try {
    const auth = new AuthService();

    const removeAllCookies = () => {
      auth.unsetSessionCookie();
      auth.unsetRefreshSessionCookie();
      auth.unsetAppSourceCookie();
    };

    yield put(doLogin.request({ isLoading: true }));

    const { email, password, userType } = action.payload;

    const response = yield call(loginCall, email, password, userType);

    if (response?.success !== false) {
      const { accessToken, refreshToken } = response;
      if (accessToken && refreshToken) {
        // set token
        auth.setSessionCookie(accessToken);
        auth.setRefreshSessionCookie(refreshToken);

        const user = yield call(getUserCall, userType);

        if (!isEmpty(user)) {
          auth.setAppSourceCookie(user?.appSource);

          const allowUsers = [USER_ROLE.ADMIN, USER_ROLE.STAFF];
          if (allowUsers.includes(user.role)) {
            yield put(doLogin.success({ token: accessToken }));
            yield put(getUser.success({ user, appSource: user?.appSource }));

            yield put(replace(SMKLinks.DASHBOARD));
          } else {
            alert('Not Authorised');
            removeAllCookies();
          }
        } else {
          removeAllCookies();
        }
      }
    } else {
      yield put(doLogin.failure('Something went wrong'));
    }
  } catch (error) {
    yield put(doLogin.failure(error));
  } finally {
    yield put(doLogin.fulfill({ isLoading: false }));
  }
}

function* getUserRequest() {
  try {
    yield put(getUser.request({ isLoading: true }));

    const auth = new AuthService();
    const appSourceSession = auth.getAppSourceCookie();
    const appSource =
      appSourceSession === 'theparentz' ? 'TPZ' : appSourceSession === 'mediwatcher' ? 'Mediwatcher' : 'SMK';

    const response = yield call(getUserCall, appSource);
    if (response?.success !== false) {
      const user = response;
      const allowUsers = [USER_ROLE.ADMIN, USER_ROLE.STAFF];
      if (allowUsers.includes(user.role)) {
        yield put(getUser.success({ user }));
      } else {
        yield put(replace(SMKLinks.LOGIN_PAGE));
      }
    }
  } catch (error) {
    yield put(getUser.failure(error));
  } finally {
    yield put(getUser.fulfill({ isLoading: false }));
  }
}

export function* doLogoutRequest() {
  try {
    const auth = new AuthService();

    yield put(doLogout.request({ isLoading: true }));

    const response = yield call(logoutCall);

    auth.unsetSessionCookie();
    auth.unsetRefreshSessionCookie();
    auth.unsetAppSourceCookie();

    yield put(doLogout.success());
    yield put(replace(SMKLinks.LOGIN_PAGE));
  } catch (error) {
    yield put(doLogout.failure(error));
  } finally {
    yield put(doLogout.fulfill({ isLoading: false }));
  }
}

function* doSocialLoginRequest(action) {
  try {
    const auth = new AuthService();

    yield put(doSocialLogin.request({ isLoading: true }));

    const removeAllCookies = () => {
      auth.unsetSessionCookie();
      auth.unsetRefreshSessionCookie();
      auth.unsetAppSourceCookie();
    };

    const { userObj, userType } = action.payload;

    const response = yield call(socialLoginCall, userObj, userType);

    if (response?.success === false) {
      const { message } = response;
      yield put(doSocialLogin.failure(message?.message));
    } else {
      const { accessToken, refreshToken } = response;
      if (accessToken && refreshToken) {
        auth.setSessionCookie(accessToken);
        auth.setRefreshSessionCookie(refreshToken);

        const user = yield call(getUserCall, userType);

        if (!isEmpty(user)) {
          auth.setAppSourceCookie(user?.appSource);

          const allowUsers = [USER_ROLE.ADMIN, USER_ROLE.STAFF];
          if (allowUsers.includes(user.role)) {
            yield put(doLogin.success({ token: accessToken }));
            yield put(getUser.success({ user, appSource: user?.appSource }));

            yield put(replace(SMKLinks.DASHBOARD));
          } else {
            alert('Not Authorised');
            removeAllCookies();
          }
        } else {
          removeAllCookies();
        }
      }
    }
  } catch (error) {
    yield put(doSocialLogin.failure(error));
  } finally {
    yield put(doSocialLogin.fulfill({ isLoading: false }));
  }
}

export default function* authSaga() {
  yield takeLatest(doLogin.TRIGGER, doLoginRequest);
  yield takeLatest(doSocialLogin.TRIGGER, doSocialLoginRequest);
  yield takeLatest(getUser.TRIGGER, getUserRequest);
  yield takeLatest(doLogout.TRIGGER, doLogoutRequest);
}
