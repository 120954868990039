import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import {
  Card,
  CardBody,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Table,
  UncontrolledDropdown,
} from 'reactstrap';
import { toast } from 'react-toastify';
import confirm from 'reactstrap-confirm';

import { isEmpty } from 'lodash';
import TableError from 'components/TableError';
import useRequest from '../../../hooks/useRequest';
import Loader from '../../../components/Loader';
import Breadcrumbs from '../../../components/Common/Breadcrumb';

const CDNCacheList = () => {
  toast.configure();

  const [cdnURL, setCDNURL] = useState('');
  const [fetchURLData, setFetchURLData] = useState(false);
  const [headersData, setHeadersData] = useState({});
  const [clearCacheResponse, setClearCacheResponse] = useState({});

  const defaultFilters = {
    url: '',
    source: { value: 'schoolmykids', label: 'SchoolMyKids' },
  };
  const [filters, setFilters] = useState(defaultFilters);

  const [getURLsHeaders, { loading: getURLsHeadersLoading }] = useRequest({
    url: `/admin-tools/checkHeaders`,
    method: 'POST',
    onSuccess: (data) => {
      setHeadersData(data);
    },
  });
  const [clearCacheRequest, { loading: clearCacheLoading }] = useRequest({
    url: `/admin-tools/clearCDNCache`,
    method: 'POST',
    onSuccess: (data) => {
      toast.success('Success! Cache cleared');
      setClearCacheResponse(data);
    },
    onError: (err) => {
      toast.error(err?.response?.message || err?.message);
    },
  });

  const fetchData = () => {
    if (isEmpty(cdnURL) && isEmpty(filters.url)) {
      toast.error('Enter CDN URL or URL....');
    } else {
      if (cdnURL == '') {
        if (!isEmpty(filters.url)) {
          const urlArray = filters.url?.split('\n');
          const filteredURL = urlArray?.map((e) => e?.trim())?.filter((elem) => elem);
          const removeSourceInUrl = filteredURL
            ?.map((e) => e?.replaceAll('https://www.schoolmykids.com', ''))
            ?.map((e) => e?.replaceAll('https://www.theparentz.com', ''));

          clearCacheRequest({
            body: {
              urls: removeSourceInUrl,
              source: filters.source?.value,
            },
          });
        } else {
          toast.error('Please enter URL....');
        }
      } else {
        setFetchURLData(true);
        getURLsHeaders({
          body: {
            urls: [cdnURL],
            source: filters.source?.value,
          },
        });
      }
    }
  };

  return (
    <>
      <div className="page-content">
        <Loader isActive={clearCacheLoading || getURLsHeadersLoading} />

        <Container fluid>
          <Breadcrumbs title="Others" breadcrumbItem="Clear CDN Cache" />

          <Card>
            <CardBody>
              <div className="mt-4">
                <Row className="mt-4">
                  <Col md={3}>
                    <Label htmlFor="source" className="form-label">
                      Source
                    </Label>
                    <FormGroup className="mb-4">
                      <Select
                        id="source"
                        options={[
                          {
                            label: 'SchoolMyKids',
                            value: 'schoolmykids',
                          },
                          {
                            label: 'TheParentz',
                            value: 'theparentz',
                          },
                        ]}
                        value={filters.source}
                        onChange={(e) =>
                          setFilters({
                            ...filters,
                            source: e?.value,
                          })
                        }
                      />
                    </FormGroup>
                  </Col>

                  <Col md={12}>
                    <FormGroup className="mb-4">
                      <Label className="form-label">URL</Label>
                      <Input
                        type="textarea"
                        rows={'10'}
                        value={filters.url}
                        onChange={(e) => {
                          setFilters({
                            ...filters,
                            url: e.target.value,
                          });
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </div>
              <div className="mt-4 pt-2">
                <a className="btn btn-primary me-4 px-4" onClick={fetchData}>
                  Clear CDN Cache
                </a>
                <a
                  className="btn btn-light me-4 px-4"
                  onClick={() => {
                    setFilters(defaultFilters);
                  }}>
                  Clear
                </a>
              </div>
            </CardBody>
          </Card>

          <Card>
            <CardBody>
              <h4>Check Headers</h4>
              <div className="mt-4">
                <Row className="mt-4">
                  <Col md={12}>
                    <FormGroup className="mb-4">
                      <Label className="form-label">URL</Label>
                      <Input
                        type="text"
                        value={cdnURL}
                        onChange={(e) => {
                          setCDNURL(e.target.value);
                          setFetchURLData(false);
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </div>
              <div className="mt-4 pt-2">
                <a className="btn btn-primary me-4 px-4" onClick={fetchData}>
                  Check Headers
                </a>
                <a
                  className="btn btn-light me-4 px-4"
                  onClick={() => {
                    setFetchURLData(false);
                    setCDNURL('');
                  }}>
                  Clear
                </a>
              </div>
            </CardBody>
          </Card>

          {!isEmpty(cdnURL) && fetchURLData && (
            <Row>
              <Col md={12}>
                <Card>
                  <CardBody>
                    {/* <div className="text-end w-100">
                      <a className="btn btn-light me-4 " onClick={clearCache}>
                        Clear Cache
                      </a>
                    </div> */}
                    <div className="table-responsive">
                      <Table className="project-list-table  table-centered table-striped">
                        <thead>
                          <tr className="table-warning">
                            <th>Keys</th>
                            <th>Values</th>
                          </tr>
                        </thead>

                        <tbody>
                          {!isEmpty(clearCacheResponse) &&
                            Object.keys(clearCacheResponse)?.map((key) => (
                              <tr>
                                <td>{key}</td>
                                <td>{clearCacheResponse[key]}</td>
                              </tr>
                            ))}
                          {isEmpty(clearCacheResponse) &&
                            Object.keys(headersData)?.map((key) => (
                              <tr>
                                <td>{key}</td>
                                <td>{headersData[key]}</td>
                              </tr>
                            ))}
                        </tbody>
                      </Table>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </Container>
      </div>
    </>
  );
};

export default CDNCacheList;
