import { userSelector } from 'common/globalComponents/selectors';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import { connect, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { changeSidebarType, showRightSidebarAction, toggleLeftmenu } from '../../store/actions';
import ProfileMenu from '../CommonForBoth/TopbarDropdown/ProfileMenu';
import { RenderLogo } from './Sidebar';

const stateSelector = createStructuredSelector({
  user: userSelector,
});
const Header = (props) => {
  const [search, setsearch] = useState(false);

  const { user } = useSelector(stateSelector);
  const appSource = user?.appSource || 'schoolmykids';

  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  function tToggle() {
    props.toggleLeftmenu(!props.leftMenu);
    if (props.leftSideBarType === 'default') {
      props.changeSidebarType('condensed', isMobile);
    } else if (props.leftSideBarType === 'condensed') {
      props.changeSidebarType('default', isMobile);
    }
  }

  return (
    <>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            <div className="navbar-brand-box d-lg-none bg-white">
              <Link to="/" className="logo logo-dark">
                <span className="logo-sm">
                  <RenderLogo leftMenu={true} appSource={appSource} />
                </span>
                <span className="logo-lg">
                  <RenderLogo leftMenu={false} appSource={appSource} />
                </span>
              </Link>

              <Link to="/" className="logo logo-light">
                <span className="logo-sm">
                  <RenderLogo leftMenu={true} appSource={appSource} />
                </span>
                <span className="logo-lg">
                  <RenderLogo leftMenu={false} appSource={appSource} />
                </span>
              </Link>
            </div>

            <button
              type="button"
              onClick={() => {
                tToggle();
              }}
              className="btn btn-sm px-3 font-size-16 header-item waves-effect"
              id="vertical-menu-btn">
              <i className="fa fa-fw fa-bars" />
            </button>
          </div>
          <div className="d-flex">
            <ProfileMenu />
          </div>
        </div>
      </header>
    </>
  );
};

Header.propTypes = {
  changeSidebarType: PropTypes.func,
  leftMenu: PropTypes.any,
  leftSideBarType: PropTypes.any,
  showRightSidebar: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func,
};

const mapStatetoProps = (state) => {
  const { layoutType, showRightSidebar, leftMenu, leftSideBarType } = state.Layout;
  return { layoutType, showRightSidebar, leftMenu, leftSideBarType };
};

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
})(withTranslation()(Header));
