import Loader from 'components/Loader';
import SlugInput from 'components/SlugInput';
import { useFormik } from 'formik';
import isEmpty from 'lodash/isEmpty';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toastify';
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap';
import { syllable } from 'syllable';
import { BABY_NAMES_GENDER_ARRAY, HINDI_BABY_NAMES_GENDER_ARRAY, LANGUAGE_ARRAY } from 'utils/constants';
import * as Yup from 'yup';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import useRequest from '../../../hooks/useRequest';
import { SMKLinks } from '../../../utils/links';

const AddBabynames = () => {
  const history = useHistory();
  const location = useLocation();

  toast.configure();
  const [suggestedNameObj, setSuggestedNameObj] = useState();
  const [nakshatraList, setNakshatraList] = useState([]);
  const [rashiList, setRashiList] = useState([]);
  const [religionList, setReligionList] = useState([]);
  const [originList, setOriginList] = useState([]);
  const [meaningWordsList, setMeaningWordsList] = useState([]);

  const [addBabyNameRequest, { loading: addBabyNameLoading }] = useRequest({
    url: `/baby-name/admin`,
    method: 'POST',
    onSuccess: (data) => {
      // console.log(data);
      const url = SMKLinks.BABY_NAME_LISTING;
      history.replace(url);
      toast.success('Success! Baby Name has been added.');
    },
    onError: (err) => {
      toast.error(err?.message);
      console.log(err);
    },
  });

  const [getbabyNameMetaRequest, { loading: getbabyNameMetaLoading }] = useRequest({
    url: `/baby-name/admin/metaInfo/search`,
    method: 'POST',
    onSuccess: (data) => {
      const reply = data?.results;
      getInitialValuesOfDropdowns(reply);
    },
  });

  const getInitialValues = () => ({
    name: '',
    slug: '',
    description: '',
    genderValue: '',
    language: { label: 'English', value: 0 },
    published: false,
    active: false,
    meaning: '',
    rashi: '',
    nakshatra: '',
    origins: [],
    religions: [],
    meanings: [],
  });

  const checkValueExist = (val) => {
    return val !== '' && val !== null ? val : undefined;
  };
  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: false,
    initialValues: suggestedNameObj ? { ...suggestedNameObj } : { ...getInitialValues() },
    onSubmit: (values) => {
      addBabyNameRequest({
        body: {
          language: values?.language?.value,
          genderValue: values.genderValue,
          name: checkValueExist(values?.name),
          slug: checkValueExist(values?.slug),
          published: checkValueExist(values.published),
          active: checkValueExist(values.active),
          meaning: checkValueExist(values.meaning),
          meanings: checkValueExist(values.meanings),
          description: checkValueExist(values.description),
          origins: checkValueExist(values.origins),
          religions: checkValueExist(values.religions),
          rashi: checkValueExist(values?.rashi),
          nakshatra: checkValueExist(values?.nakshatra),
        },
      });
    },
    validationSchema: Yup.object().shape({
      name: Yup.string()
        .required('Please provide babyname name')
        .min(2, 'Name should be at least 2 characters long')
        .max(128, 'Name should be at most 128 characters long'),
      slug: Yup.string().required('Please provide slug'),
      description: Yup.string()
        // .required('Please provide baby name description')
        .min(2, 'Description should be at least 2 characters long')
        .max(1024, 'Description should be at most 1024 characters long'),
      genderValue: Yup.string()
        .required('Please provide gender')
        .nullable(),
      // meanings: Yup.array().required().min(1, 'Please provide meanings'),
      origins: Yup.array()
        .required()
        .min(1, 'Please provide origin'),
      // nakshatra: Yup.object().required('Please provide nakshatra').nullable(),
      religions: Yup.array()
        .required()
        .min(1, 'Please provide religion'),
      // meaning: Yup.string()
      //   .required('Please provide meaning')
      //   .min(10, 'Meaning should be at least 10 characters long')
      //   .max(512, 'Meaning should be at most 512 characters long'),
    }),
  });

  const wordCount = (babyName) => {
    const result = babyName
      .split(' ')
      .filter((n) => n.trim().length > 0)
      .join(' ');

    // set word count
    return result.split(' ').length;
  };

  const getSum = (total, num) => {
    return total + Math.round(num);
  };

  const characterCount = (babyName) => {
    try {
      return babyName
        .split(' ')
        .filter((n) => n.trim().length > 0)
        .map((n) => n.length)
        .reduce(getSum, 0);
    } catch (e) {
      return babyName.trim().length;
    }
  };

  const reduce = (n) => {
    return ((n - 1) % 9) + 1;
  };

  const getCharacterNumber = (character) => {
    const lowerAlphaCheck = 'abcdefghijklmnopqrstuvwxyz'.indexOf(character) + 1;
    const upperAlphaCheck = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.indexOf(character) + 1;
    const numberCheck = '123456789'.indexOf(character) + 1;
    return reduce(lowerAlphaCheck + upperAlphaCheck + numberCheck);
  };

  const getNumerologyNumber = (input) => {
    let fullNumber = 0;
    for (const character of input) {
      fullNumber += getCharacterNumber(character);
    }
    return reduce(fullNumber);
  };

  const getVedicNumber = (input) => {
    const alphaNumbers = {
      A: 1,
      B: 2,
      C: 2,
      D: 4,
      E: 5,
      F: 8,
      G: 3,
      H: 8,
      I: 1,
      J: 1,
      K: 2,
      L: 3,
      M: 4,
      N: 5,
      O: 7,
      P: 8,
      Q: 1,
      R: 2,
      S: 3,
      T: 4,
      U: 6,
      V: 6,
      W: 6,
      X: 6,
      Y: 1,
      Z: 7,
    };

    let fullNumber = 0;
    for (const character of input) {
      if (character !== ' ') {
        fullNumber += alphaNumbers[character.toUpperCase()];
      }
    }

    let sum = 0;

    while (fullNumber) {
      sum += fullNumber % 10;
      fullNumber = Math.floor(fullNumber / 10);
    }

    return sum;
  };

  useEffect(() => {
    getbabyNameMetaRequest({
      body: {
        page: 1,
        size: 1000,
        sortBy: 'id',
        sortOrder: -1,
      },
    });
  }, []);

  const setNonEditableFields = (text) => {
    if (text) {
      formik.setFieldValue('characterCount', characterCount(text));
      formik.setFieldValue('wordCount', wordCount(text));
      formik.setFieldValue('syllableCount', syllable(text));
      formik.setFieldValue('pythagoreanNumerology', getNumerologyNumber(text));
      formik.setFieldValue('indianNumerology', getVedicNumber(text));
    }
  };

  useEffect(() => {
    setSuggestedNameObj(location?.state?.data);
  }, [location?.state]);

  useEffect(() => {
    setNonEditableFields(formik.values.name);
  }, [formik.values.name]);

  const getInitialValuesOfDropdowns = (reply) => {
    setNakshatraList(
      reply
        .filter((t) => t.type === 1 && t.language === formik.values.language?.value)
        .map((r) => ({ ...r, label: r.name, value: r.id }))
    );
    setRashiList(
      reply
        .filter((t) => t.type === 2 && t.language === formik.values.language?.value)
        .map((r) => ({ ...r, label: r.name, value: r.id }))
    );
    setReligionList(
      reply
        .filter((t) => t.type === 3 && t.language === formik.values.language?.value)
        .map((r) => ({ ...r, label: r.name, value: r.id }))
    );
    setOriginList(
      reply
        .filter((t) => t.type === 4 && t.language === formik.values.language?.value)
        .map((r) => ({ ...r, label: r.name, value: r.id }))
    );
    setMeaningWordsList(
      reply
        .filter((t) => t.type === 5 && t.language === formik.values.language?.value)
        .map((r) => ({ ...r, label: r.name, value: r.id }))
    );
  };

  useEffect(() => {
    getbabyNameMetaRequest({
      body: {
        page: 1,
        size: 1000,
        sortBy: 'id',
        sortOrder: -1,
      },
    });
  }, [suggestedNameObj]);

  return (
    <>
      <div className="page-content">
        <Loader isActive={addBabyNameLoading || getbabyNameMetaLoading} />
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Babynames" breadcrumbItem="Add Babyname" />

          <Form onSubmit={formik.handleSubmit}>
            <Row>
              <Col md={9}>
                <Card>
                  <CardBody>
                    <Row className="align-items-center">
                      <Col md={6}>
                        <FormGroup className="mb-4">
                          <Label for="language" className="form-label">
                            Language
                          </Label>
                          <Select
                            id="language"
                            options={LANGUAGE_ARRAY}
                            value={formik.values.language}
                            onChange={(language) => formik.setFieldValue('language', language)}
                          />
                          {formik.errors.language && (
                            <div className="invalid-feedback d-block">{formik.errors.language}</div>
                          )}
                        </FormGroup>
                      </Col>

                      <Col md={6}>
                        <FormGroup className="mb-4">
                          <Label for="baby_gender" className="form-label">
                            Gender
                          </Label>
                          <Select
                            id="baby_gender"
                            options={
                              formik.values.language?.value === 1
                                ? HINDI_BABY_NAMES_GENDER_ARRAY
                                : BABY_NAMES_GENDER_ARRAY
                            }
                            value={
                              formik.values.language?.value === 1
                                ? HINDI_BABY_NAMES_GENDER_ARRAY?.find((e) => e?.value === formik.values.genderValue)
                                : BABY_NAMES_GENDER_ARRAY?.find((e) => e?.value === formik.values.genderValue)
                            }
                            onChange={(obj) => formik.setFieldValue('genderValue', obj?.value)}
                          />
                          {formik.errors.genderValue && (
                            <div className="invalid-feedback d-block">{formik.errors.genderValue}</div>
                          )}
                        </FormGroup>
                      </Col>

                      <Col md={12}>
                        <FormGroup className="mb-4">
                          <Label for="babyname" className="form-label  ">
                            Name
                          </Label>
                          <Input
                            id="babyname"
                            type="text"
                            className="form-control"
                            placeholder="Baby name..."
                            invalid={!!(formik.touched.name && formik.errors.name)}
                            {...formik.getFieldProps('name')}
                            error={!isEmpty(formik.errors.name)}
                          />
                          <FormFeedback className="d-block">{formik.errors.name}</FormFeedback>
                        </FormGroup>
                      </Col>
                      <Col md={12}>
                        <FormGroup className="mb-4">
                          <SlugInput formik={formik} ID="" nameValue={formik.values.name} formGroupClassName="mb-0" />
                        </FormGroup>
                      </Col>

                      <Col md={12}>
                        <FormGroup className="mb-4">
                          <Label for="babyname_meaning" className="form-label  ">
                            Meaning
                          </Label>
                          <Input
                            id="babyname_meaning"
                            name="babyname_meaning"
                            type="text"
                            rows="2"
                            className="form-control"
                            placeholder="Baby name meaning..."
                            invalid={!!(formik.touched.meaning && formik.errors.meaning)}
                            {...formik.getFieldProps('meaning')}
                          />
                          <FormFeedback className="d-block">{formik.errors.meaning}</FormFeedback>
                        </FormGroup>
                      </Col>

                      <Col md={6}>
                        <FormGroup className="mb-4">
                          <Label for="baby_meanings" className="form-label  ">
                            Meanings
                          </Label>
                          <Select
                            id="baby_meanings"
                            placeholder="Select..."
                            value={formik.values.meanings}
                            onChange={(meaning) => formik.setFieldValue('meanings', meaning)}
                            options={meaningWordsList}
                            isMulti
                            isClearable
                          />
                          {formik.errors.meanings && (
                            <div className="invalid-feedback d-block">{formik.errors.meanings}</div>
                          )}
                        </FormGroup>
                      </Col>

                      <Col md={6}>
                        <FormGroup className="mb-4">
                          <Label for="baby_origin" className="form-label  ">
                            Origins
                          </Label>
                          <Select
                            id="baby_origin"
                            placeholder="Select..."
                            value={formik.values.origins}
                            onChange={(origin) => formik.setFieldValue('origins', origin)}
                            options={originList}
                            isMulti
                            isClearable
                          />
                          {formik.errors.origins && (
                            <div className="invalid-feedback d-block">{formik.errors.origins}</div>
                          )}
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup className="mb-4">
                          <Label for="baby_religion" className="form-label  ">
                            Religions
                          </Label>
                          <Select
                            id="baby_religion"
                            placeholder="Select..."
                            value={formik.values.religions}
                            onChange={(religion) => formik.setFieldValue('religions', religion)}
                            options={religionList}
                            isMulti
                            isClearable
                          />
                          {formik.errors.religions && (
                            <div className="invalid-feedback d-block">{formik.errors.religions}</div>
                          )}
                        </FormGroup>
                      </Col>

                      <Col md={6}>
                        <FormGroup className="mb-4">
                          <Label for="baby_rashi" className="form-label  ">
                            Rashi
                          </Label>
                          <Select
                            id="baby_rashi"
                            placeholder="Select..."
                            value={formik.values.rashi}
                            onChange={(rashi) => formik.setFieldValue('rashi', rashi)}
                            options={rashiList}
                            isClearable
                          />
                          {formik.errors.rashi && <div className="invalid-feedback d-block">{formik.errors.rashi}</div>}
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup className="mb-4">
                          <Label for="baby_nakshatra" className="form-label  ">
                            Nakshatra
                          </Label>
                          <Select
                            id="baby_nakshatra"
                            placeholder="Select..."
                            value={formik.values.nakshatra}
                            onChange={(nakshatra) => formik.setFieldValue('nakshatra', nakshatra)}
                            options={nakshatraList}
                            isClearable
                          />
                          {formik.errors.nakshatra && (
                            <div className="invalid-feedback d-block">{formik.errors.nakshatra}</div>
                          )}
                        </FormGroup>
                      </Col>

                      <Col md={12}>
                        <FormGroup className="mb-3">
                          <Label for="description" cla ssName="form-label  ">
                            Description
                          </Label>
                          <Input
                            id="description"
                            type="textarea"
                            rows={10}
                            className="form-control"
                            placeholder="Baby name description..."
                            label="description"
                            invalid={!!(formik.touched.description && formik.errors.description)}
                            {...formik.getFieldProps('description')}
                            error={!isEmpty(formik.errors.description)}
                          />
                          <FormFeedback className="d-block">{formik.errors.description}</FormFeedback>
                        </FormGroup>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col md={3}>
                <Card>
                  <CardBody>
                    <Button type="submit" color="primary" className="mb-3 w-100">
                      Save Name
                    </Button>
                    <Button
                      className="w-100"
                      color="light"
                      onClick={() => {
                        history.replace(SMKLinks.BABY_NAME_LISTING);
                      }}>
                      Cancel
                    </Button>
                  </CardBody>
                </Card>
                <Card>
                  <CardBody>
                    <div>
                      <CardTitle className="mb-3">Active</CardTitle>
                      {[true, false].map((option, i) => (
                        <div key={`active_${i}`} className="form-check mb-3 d-inline-block me-4">
                          <input
                            type="radio"
                            id={`active_${option}`}
                            name="babyname_active"
                            className="form-check-input"
                            checked={formik.values.active === option}
                            onChange={() => formik.setFieldValue('active', option)}
                          />
                          <label className="form-check-label" htmlFor={`active_${option}`}>
                            {option ? 'Yes' : 'No'}
                          </label>
                        </div>
                      ))}
                    </div>

                    <div>
                      <CardTitle className="mb-3">Publish</CardTitle>
                      {[true, false].map((option, i) => (
                        <div key={`published_${i}`} className="form-check mb-3 d-inline-block me-4">
                          <input
                            type="radio"
                            id={`published_${option}`}
                            name="babyname_published"
                            className="form-check-input"
                            checked={formik.values.published === option}
                            onChange={() => formik.setFieldValue('published', option)}
                          />
                          <label className="form-check-label" htmlFor={`published_${option}`}>
                            {option ? 'Yes' : 'No'}
                          </label>
                        </div>
                      ))}
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Form>
        </Container>
      </div>
    </>
  );
};

export default AddBabynames;
