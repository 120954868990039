import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import map from 'lodash/map';
import { Badge, Card, CardBody, CardTitle, Col, Container, Media, Row, Table } from 'reactstrap';
import Breadcrumbs from 'components/Common/Breadcrumb';
import profileBanner from 'assets/images/profile-img.png';
import Loader from 'components/Loader';
import { invert } from 'lodash';
import { GET_GENDER, GET_USER_STATUS, USER_ROLE, USER_SOURCE } from 'utils/constants';
import { printDateTime } from 'utils/helper';
import useRequest from 'hooks/useRequest';
import MiniCards from './miniCard';

const UsersProfile = (props) => {
  const { id: userId } = useParams();

  const [user, setuser] = useState({});

  const [getUserDetails, { loading: getUserDetailsLoading }] = useRequest({
    url: `/user/admin/${userId}`,
    method: 'GET',
    onSuccess: (data) => {
      setuser(data);
    },
    onError: (err) => {
      console.warn(err);
    },
  });

  useEffect(() => {
    getUserDetails();
  }, []);
  console.log(user);

  const GET_USER_STATUS_TEXT_COLOR = {
    0: 'text-warning',
    1: 'text-success',
    2: 'text-danger',
  };

  const miniCards = [
    {
      title: 'User ID',
      iconClass: 'bxs-user-circle',
      text: `${user?.id || '-'} `,
    },
    {
      title: 'Status',
      iconClass: 'bxs-message-rounded-dots',
      text: `${GET_USER_STATUS[user?.status]}`,
      textColor: `${GET_USER_STATUS_TEXT_COLOR[user?.status]}`,
    },
    {
      title: 'App Installed',
      iconClass: 'bxl-android',
      text: `${user?.isAppInstalled ? 'Yes' : 'No'}`,
      textColor: `${user?.isAppInstalled ? 'text-success' : 'text-danger'}`,
    },
  ];

  return (
    <>
      <div className="page-content">
        <Loader isActive={getUserDetailsLoading} />
        <Container fluid>
          <Breadcrumbs title="User" breadcrumbItem="Profile" />

          <Row>
            <Col xl="4">
              <Card className="overflow-hidden">
                <div className="bg-soft-primary">
                  <Row>
                    <Col xs="7">
                      <div className="text-primary p-3">
                        <h5 className="text-primary">Welcome {user?.firstName} !</h5>
                        <p>It will seem like simplified</p>
                      </div>
                    </Col>
                    <Col xs="5" className="align-self-end">
                      <img src={profileBanner} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="p-3">
                  <Row className="align-items-center">
                    <Col sm="3">
                      <div className="avatar-md profile-user-wid m-auto">
                        {user?.profileImage ? (
                          <img src={user?.profileImage} alt="" className="img-thumbnail rounded-circle" />
                        ) : (
                          <i className="bx bxs-user-circle" style={{ fontSize: '70px', color: '#3460BB' }} />
                        )}
                      </div>
                    </Col>
                    <Col sm="9">
                      <h5 className="font-size-15 text-truncate">{`${user?.firstName} ${user?.lastName || ''}`}</h5>
                      <p className="text-muted mb-0 text-truncate">{invert(USER_ROLE)[user?.role]}</p>
                    </Col>
                  </Row>
                </CardBody>
              </Card>

              <Card>
                <CardBody>
                  <CardTitle className="py-3 card-title text-center bg-light mb-0">Personal Information</CardTitle>
                  <p className="text-muted mb-4">{user?.bio}</p>
                  <div className="table-responsive" style={{ minHeight: 200 }}>
                    <Table className="table-nowrap mb-0">
                      <tbody>
                        <tr>
                          <th scope="row">First Name :</th>
                          <td>{user?.firstName}</td>
                        </tr>
                        <tr>
                          <th scope="row">Last Name :</th>
                          <td>{user?.lastName}</td>
                        </tr>
                        <tr>
                          <th scope="row">Gender :</th>
                          <td>{GET_GENDER[user?.gender]}</td>
                        </tr>
                        <tr>
                          <th scope="row">Phone Number :</th>
                          <td>{user?.phoneNumber || '-'}</td>
                        </tr>
                        <tr>
                          <th scope="row">Source :</th>
                          <td>{invert(USER_SOURCE)[user?.source]}</td>
                        </tr>
                        <tr>
                          <th scope="row">Active :</th>
                          <td>
                            <Badge className={user?.active ? 'bg-success' : 'bg-danger'}>
                              {user?.active ? 'Yes' : 'No'}
                            </Badge>
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">Deleted :</th>
                          <td>
                            <Badge className={user?.deleted ? 'bg-success' : 'bg-danger'}>
                              {user?.deleted ? 'Yes' : 'No'}
                            </Badge>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>

            <Col xl="8">
              <Row>
                {map(miniCards, (card, key) => (
                  <MiniCards
                    title={card?.title}
                    text={card?.text}
                    textColor={card?.textColor}
                    iconClass={card?.iconClass}
                    key={`_card_${key}`}
                  />
                ))}
              </Row>
              <Card>
                <CardBody>
                  <CardTitle className="py-3 card-title text-center bg-light mb-4">More Information </CardTitle>
                  <Row>
                    <Col xl="12">
                      <div className="table-responsive" style={{ minHeight: 300 }}>
                        <table className="table">
                          <tbody>
                            <tr>
                              <th scope="row">E-mail :</th>
                              <td>{user?.email}</td>
                            </tr>
                            <tr>
                              <th scope="row">Created Date :</th>
                              <td>{printDateTime(user?.createdDate)}</td>
                            </tr>
                            <tr>
                              <th scope="row">Updated Date :</th>
                              <td>{printDateTime(user?.updatedDate)}</td>
                            </tr>

                            <tr>
                              <th scope="row">Roles :</th>
                              <td>
                                {user?.roles?.length > 0
                                  ? user?.roles?.map((r, i) => (
                                      <Badge key={i} className="p-2 my-1 me-3 rounded-pill bg-light">
                                        {r.name}
                                      </Badge>
                                    ))
                                  : '-'}
                              </td>
                            </tr>
                            <tr>
                              <th scope="row">Team Leader :</th>
                              <td>
                                {user?.teamLead?.displayName ? (
                                  <Badge className="p-1 my-1 rounded-pill bg-warning">
                                    {user?.teamLead?.displayName}
                                  </Badge>
                                ) : (
                                  '-'
                                )}
                              </td>
                            </tr>
                            <tr>
                              <th scope="row">Email Verified :</th>
                              <td>
                                <Badge className={user?.emailVerified ? 'bg-success' : 'bg-danger'}>
                                  {user?.emailVerified ? 'Yes' : 'No'}
                                </Badge>
                              </td>
                            </tr>
                            <tr>
                              <th scope="row">Phone Verified :</th>
                              <td>
                                <Badge className={user?.phoneVerified ? 'bg-success' : 'bg-danger'}>
                                  {user?.phoneVerified ? 'Yes' : 'No'}
                                </Badge>
                              </td>
                            </tr>
                            <tr>
                              <th scope="row">unSubscribed :</th>
                              <td>
                                <Badge className={user?.unsubscribed ? 'bg-success' : 'bg-danger'}>
                                  {user?.unsubscribed ? 'Yes' : 'No'}
                                </Badge>
                              </td>
                            </tr>

                            <tr>
                              <th scope="row">Google Id :</th>
                              <td>{user?.googleId ? user?.googleId : '-'} </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default UsersProfile;
