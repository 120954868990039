import Loader from 'components/Loader';
import { getCountries } from 'containers/school/actions';
import { useFormik } from 'formik';
import isEmpty from 'lodash/isEmpty';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toastify';
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap';
import { BABY_NAMES_GENDER_ARRAY, GENDER_ARRAY } from 'utils/constants';
import * as Yup from 'yup';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import useRequest from '../../../hooks/useRequest';
import { SMKLinks } from '../../../utils/links';

const UpdateBabyNameMeaningOrigin = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id: babyNameId } = useParams();

  toast.configure();
  const [originalBabyNameDetails, setOriginalBabyNameDetails] = useState();
  const [babyNameDetails, setBabyNameDetails] = useState();
  const [originList, setOriginList] = useState([]);
  const [countriesList, setCountriesList] = useState([]);

  const [getBabyNameDetailsRequest, { loading: getBabyNameDetailsLoading }] = useRequest({
    method: 'GET',
    onSuccess: (data) => {
      setOriginalBabyNameDetails(data);
    },
    onError: (err) => {
      toast.error(err?.message);
    },
  });
  const [addBabyNameRequest, { loading: addBabyNameLoading }] = useRequest({
    url: `/baby-name/admin/meaningOrigin`,
    method: 'POST',
    onSuccess: (data) => {
      // console.log(data);
      const url = SMKLinks.BABY_NAME_MEANING_ORIGIN;
      history.replace(url);
      toast.success('Success! Baby Name Meaning origin has been added.');
    },
    onError: (err) => {
      toast.error(err?.message);
      console.log(err);
    },
  });
  const [getBabyNameRequest, { loading: getBabyNameLoading }] = useRequest({
    url: `/baby-name/admin/meaningOrigin/${babyNameId}`,
    method: 'GET',
    onSuccess: (data) => {
      setBabyNameDetails(data);
      getBabyNameDetailsRequest({
        url: `/baby-name/admin/${data.babyNameId}`,
      });
    },
    onError: (err) => {
      toast.error(err?.message);
      console.log(err);
    },
  });

  const [updateBabyNameRequest, { loading: updateBabyNameLoading }] = useRequest({
    url: `/baby-name/admin/meaningOrigin/${babyNameId}`,
    method: 'PUT',
    onSuccess: (data) => {
      const url = SMKLinks.BABY_NAME_MEANING_ORIGIN;
      history.replace(url);
      toast.success('Success! Baby Name Meaning origin has been updated.');
    },
    onError: (err) => {
      toast.error(err?.message);
      console.log(err);
    },
  });

  const [getbabyNameMetaRequest, { loading: getbabyNameMetaLoading }] = useRequest({
    url: `/baby-name/admin/metaInfo/search`,
    method: 'POST',
    onSuccess: (data) => {
      const reply = data?.results;
      setOriginList(reply.filter((t) => t.type === 4).map((r) => ({ ...r, label: r.name, value: r.id })));
    },
  });
  const [getCountriesData, { loading: getCountriesLoading }] = useRequest({
    url: `/place/allCountries`,
    method: 'GET',
    onSuccess: (data) => {
      setCountriesList(data);
    },
  });

  const editInitialValues = () => {
    const genderObj = GENDER_ARRAY?.filter((e) => e?.value == babyNameDetails?.gender);
    const originObj = originList?.filter((e) => e?.id == babyNameDetails?.originId);
    const countryObj = countriesList?.filter((e) => e?.id == babyNameDetails?.countryId);
    const filteredGenderObj = genderObj[0];
    const filteredOriginObj = originObj[0];
    const filteredCountryObj = countryObj[0];

    return {
      babyId: babyNameDetails?.babyNameId,
      language: babyNameDetails?.language == 1 ? { label: 'Hindi', value: 1 } : { label: 'English', value: 0 },
      active: babyNameDetails?.active,
      deleted: babyNameDetails?.deleted,
      meaning: babyNameDetails?.meaning,
      gender: {
        label: filteredGenderObj?.label,
        value: filteredGenderObj?.value,
      },
      origin: {
        label: filteredOriginObj?.name,
        value: filteredOriginObj?.id,
      },
      countryId: {
        label: filteredCountryObj?.name,
        value: filteredCountryObj?.id,
      },
      script: babyNameDetails?.script || '',
      pronounced: babyNameDetails?.pronounced || '',
      source: babyNameDetails?.source || '',
      anonymous: babyNameDetails?.anonymous,
    };
  };

  const getInitialValues = () => ({
    language: { label: 'English', value: 0 },
    active: true,
    deleted: false,
    meaning: '',
    gender: '',
    origin: '',
    countryId: '',
    script: '',
    pronounced: '',
    source: '',
    babyId: '',
    anonymous: false,
  });

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: false,
    initialValues: isEmpty(babyNameId) ? { ...getInitialValues() } : { ...editInitialValues() },
    onSubmit: (values) => {
      const data = {
        language: values?.language?.value,
        active: values?.active,
        deleted: values?.deleted,
        babyNameId: values?.babyId,
        meaning: values?.meaning,
        gender: values.gender?.value,
        originId: values?.origin?.value,
        script: values?.script,
        pronounced: values?.pronounced,
        source: values?.source,
        countryId: values?.countryId?.value,
        anonymous: values?.anonymous,
      };
      isEmpty(babyNameId)
        ? addBabyNameRequest({
            body: {
              ...data,
            },
          })
        : updateBabyNameRequest({
            body: {
              ...data,
            },
          });
    },
    validationSchema: Yup.object().shape({
      babyId: Yup.string().required('Please provide baby name ID'),
      meaning: Yup.string().required('Please provide meaning'),
      origin: Yup.object().required('Please provide origin'),
    }),
  });

  useEffect(() => {
    getCountriesData();
    getbabyNameMetaRequest({
      body: {
        page: 1,
        size: 1000,
        sortBy: 'id',
        sortOrder: -1,
      },
    });
    dispatch(getCountries.trigger({ page: 1, size: 500 }));
  }, []);

  useEffect(() => {
    if (!isEmpty(babyNameId)) {
      getBabyNameRequest();
    }
  }, [babyNameId]);

  return (
    <>
      <div className="page-content">
        <Loader
          isActive={
            addBabyNameLoading ||
            getbabyNameMetaLoading ||
            getCountriesLoading ||
            getBabyNameLoading ||
            updateBabyNameLoading ||
            getBabyNameDetailsLoading
          }
        />
        <Container fluid>
          <Breadcrumbs
            title="Babynames"
            breadcrumbItem={`${isEmpty(babyNameId) ? 'Add' : 'Edit'}  Babyname meaning origin`}
          />

          <Form onSubmit={formik.handleSubmit}>
            <Row>
              <Col md={9}>
                <Card>
                  <CardBody>
                    <Row className="align-items-center">
                      <Col md={12}>
                        <FormGroup className="mb-4">
                          <Label for="babyId" className="form-label  ">
                            Baby Name ID
                          </Label>
                          <Input
                            id="babyId"
                            type="number"
                            className="form-control"
                            disabled={!isEmpty(babyNameId)}
                            style={!isEmpty(babyNameId) ? { background: 'whitesmoke' } : {}}
                            placeholder="Baby ID..."
                            invalid={!!(formik.touched.babyId && formik.errors.babyId)}
                            {...formik.getFieldProps('babyId')}
                            error={!isEmpty(formik.errors.babyId)}
                          />
                          <FormFeedback>{formik.errors.babyId}</FormFeedback>
                        </FormGroup>
                      </Col>

                      <Col md={6}>
                        <FormGroup className="mb-4">
                          <Label for="baby_gender" className="form-label">
                            Gender
                          </Label>
                          <Select
                            id="baby_gender"
                            options={BABY_NAMES_GENDER_ARRAY}
                            value={formik.values.gender}
                            onChange={(gender) => formik.setFieldValue('gender', gender)}
                          />
                          {formik.touched.gender && (
                            <div className="invalid-feedback d-block">{formik.errors.gender}</div>
                          )}
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup className="mb-4">
                          <Label for="baby_origin" className="form-label  ">
                            Origin
                          </Label>
                          <Select
                            id="baby_origin"
                            placeholder="Select..."
                            value={formik.values.origin}
                            onChange={(origin) => formik.setFieldValue('origin', origin)}
                            options={originList?.map((e) => ({
                              label: e?.name,
                              value: e?.id,
                            }))}
                            isClearable
                          />
                          {formik.touched.origin && (
                            <div className="invalid-feedback d-block">{formik.errors.origin}</div>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      {!isEmpty(babyNameId) && (
                        <Col md={12}>
                          <FormGroup className="mb-4">
                            <Label for="babyname_original_meaning" className="form-label  ">
                              BabyName original Meaning
                            </Label>
                            <Input
                              type="textarea"
                              style={{ height: '50px', background: 'whitesmoke' }}
                              disabled
                              className="form-control"
                              value={originalBabyNameDetails?.meaning}
                            />
                          </FormGroup>
                        </Col>
                      )}

                      <Col md={6}>
                        <FormGroup className="mb-4">
                          <Label for="script" className="form-label  ">
                            Script
                          </Label>
                          <Input
                            id="script"
                            name="script"
                            type="text"
                            className="form-control"
                            placeholder="Script..."
                            invalid={!!(formik.touched.script && formik.errors.script)}
                            {...formik.getFieldProps('script')}
                          />
                          <FormFeedback>{formik.errors.script}</FormFeedback>
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup className="mb-4">
                          <Label for="pronounced" className="form-label  ">
                            Pronounced
                          </Label>
                          <Input
                            id="pronounced"
                            name="pronounced"
                            type="text"
                            className="form-control"
                            placeholder="Pronounced..."
                            invalid={!!(formik.touched.pronounced && formik.errors.pronounced)}
                            {...formik.getFieldProps('pronounced')}
                          />
                          <FormFeedback>{formik.errors.pronounced}</FormFeedback>
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup className="mb-4">
                          <Label for="source" className="form-label  ">
                            Source
                          </Label>
                          <Input
                            id="source"
                            name="source"
                            type="text"
                            className="form-control"
                            placeholder="Source..."
                            invalid={!!(formik.touched.source && formik.errors.source)}
                            {...formik.getFieldProps('source')}
                          />
                          <FormFeedback>{formik.errors.source}</FormFeedback>
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup className="mb-4">
                          <Label for="country" className="form-label  ">
                            Country
                          </Label>
                          <Select
                            menuPlacement="top"
                            id="country"
                            placeholder="Select..."
                            value={formik.values.countryId}
                            onChange={(country) => formik.setFieldValue('countryId', country)}
                            options={countriesList?.map((e) => ({
                              label: e?.name,
                              value: e?.id,
                            }))}
                            isClearable
                          />
                          {formik.touched.countryId && (
                            <div className="invalid-feedback d-block">{formik.errors.countryId}</div>
                          )}
                        </FormGroup>
                      </Col>

                      <Col md={12}>
                        <FormGroup className="mb-4">
                          <Label for="babyname_meaning" className="form-label  ">
                            BabyName Meaning
                          </Label>
                          <Input
                            id="babyname_meaning"
                            name="babyname_meaning"
                            type="textarea"
                            rows={10}
                            className="form-control"
                            placeholder="Baby name meaning..."
                            invalid={!!(formik.touched.meaning && formik.errors.meaning)}
                            {...formik.getFieldProps('meaning')}
                          />
                          <FormFeedback>{formik.errors.meaning}</FormFeedback>
                        </FormGroup>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col md={3}>
                <Card>
                  <CardBody>
                    <Button type="submit" color="primary" className="mb-3 w-100">
                      Save Name
                    </Button>

                    <Button
                      className="w-100"
                      color="light"
                      onClick={() => {
                        history.replace(SMKLinks.BABY_NAME_MEANING_ORIGIN);
                      }}>
                      Cancel
                    </Button>
                  </CardBody>
                </Card>
                <Card>
                  <CardBody>
                    <div>
                      <CardTitle className="mb-3">Active </CardTitle>
                      {[true, false].map((option, i) => (
                        <div key={`active_${i}`} className="form-check mb-3 d-inline-block me-4">
                          <input
                            type="radio"
                            id={`active_${option}`}
                            name="babyname_active"
                            className="form-check-input"
                            checked={formik.values.active === option}
                            onChange={() => formik.setFieldValue('active', option)}
                          />
                          <label className="form-check-label" htmlFor={`active_${option}`}>
                            {option ? 'Yes' : 'No'}
                          </label>
                        </div>
                      ))}
                    </div>

                    <div>
                      <CardTitle className="mb-3">Delete </CardTitle>
                      {[true, false].map((option, i) => (
                        <div key={`deleted_${i}`} className="form-check mb-3 d-inline-block me-4">
                          <input
                            type="radio"
                            id={`deleted_${option}`}
                            name="babyname_deleted"
                            className="form-check-input"
                            checked={formik.values.deleted === option}
                            onChange={() => formik.setFieldValue('deleted', option)}
                          />
                          <label className="form-check-label" htmlFor={`deleted_${option}`}>
                            {option ? 'Yes' : 'No'}
                          </label>
                        </div>
                      ))}
                    </div>
                    <div>
                      <CardTitle className="mb-3">Anonymous </CardTitle>
                      {[true, false].map((option, i) => (
                        <div key={`anonymous_${i}`} className="form-check mb-3 d-inline-block me-4">
                          <input
                            type="radio"
                            id={`anonymous_${option}`}
                            name="babyname_anonymous"
                            className="form-check-input"
                            checked={formik.values.anonymous === option}
                            onChange={() => formik.setFieldValue('anonymous', option)}
                          />
                          <label className="form-check-label" htmlFor={`anonymous_${option}`}>
                            {option ? 'Yes' : 'No'}
                          </label>
                        </div>
                      ))}
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Form>
        </Container>
      </div>
    </>
  );
};

export default UpdateBabyNameMeaningOrigin;
