import Breadcrumbs from 'components/Common/Breadcrumb';
import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Select from 'react-select';
import {
  Badge,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  Input,
  Label,
  Row,
  Table,
  UncontrolledDropdown,
} from 'reactstrap';
import Paginate from 'components/Paginate';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ACTIVE_AND_DELETED_ARRAY, LANGUAGE_ARRAY, LANGUAGE_VALUES, MODULE_TYPE, SORT_VALUES } from 'utils/constants';
import Loader from '../../../../components/Loader';
import useRequest from '../../../../hooks/useRequest';
import TableError from 'components/TableError';
import { invert, isEmpty } from 'lodash';
import { Divider } from '@material-ui/core';
import { buildURL, checkIfValueExist } from 'utils/helper';
import { SMKLinks } from 'utils/links';
import { useRouter } from 'hooks/useRouter';

const CategoriesList = () => {
  const router = useRouter();
  const history = useHistory();
  toast.configure();

  const [categoryPagination, setcategoryPagination] = useState({});
  const [categoryList, setcategoryList] = useState();
  const [categorySelectList, setcategorySelectList] = useState();
  const [source, setSource] = useState();

  const [categoryListRequest, { loading: categoryListLoading }] = useRequest({
    method: 'POST',
    url: `/common/admin/category/search`,
    onSuccess: (data) => {
      setcategoryList(data?.results);
      setcategoryPagination(data?.paginator);
    },
  });

  const [categoryListSelectRequest, { loading: categoryListSelectLoading }] = useRequest({
    method: 'POST',
    url: `/common/admin/category/search`,
    onSuccess: (data) => {
      setcategorySelectList(data.results);
    },
  });

  const [markActiveInActiveRequest, { loading: markActiveInActiveLoading }] = useRequest({
    method: 'PUT',
    onSuccess: (data) => {
      handleChangePage(parseInt(router?.query?.page, 10) || 1);
      toast.success(`Success! Category has been ${data?.active ? 'active' : 'Inactive'}.`);
    },
  });

  const [deleteUnDeleteRequest, { loading: deleteUnDeleteLoading }] = useRequest({
    method: 'PUT',
    onSuccess: (data) => {
      handleChangePage(parseInt(router?.query?.page, 10) || 1);
      toast.success(`Success! Category has been ${data?.deleted ? 'deleted' : 'Undeleted'}.`);
    },
  });

  const defaultPayload = {
    page: 1,
    size: 20,
    source: '',
    ...SORT_VALUES,
  };

  const setPageInUrl = (page) => {
    history.push({
      pathname: router.pathname,
      search: `?${new URLSearchParams({ page }).toString()}`,
    });
  };

  const handleChangePage = (page) => {
    setPageInUrl(page);

    categoryListRequest({
      body: {
        page: page,
        size: 50,
        ...SORT_VALUES,
      },
    });
  };

  const applyFilters = (obj) => {
    setPageInUrl(1);

    categoryListRequest({
      body: {
        page: 1,
        size: 50,
        ...SORT_VALUES,
        name: checkIfValueExist(obj?.name),
        slug: checkIfValueExist(obj?.slug),
        categories: obj.categories == '' ? undefined : [obj.categories.value],
        source: obj?.source?.value,
        language: checkIfValueExist(obj?.language?.value) || 0,
        active: checkIfValueExist(obj?.active?.value),
        deleted: checkIfValueExist(obj?.deleted?.value),
      },
    });
  };

  const formik = useFormik({
    initialValues: {
      name: '',
      slug: '',
      createdBy: '',
      categories: '',
      source: '',
      language: { value: 0, label: 'English' },
      active: { value: null, label: 'All' },
      deleted: { value: null, label: 'All' },
    },
    onSubmit: (values) => {
      applyFilters(values);
    },
  });

  const clearFilters = () => {
    setPageInUrl(1);
    formik.resetForm();

    categoryListRequest({
      body: {
        ...defaultPayload,
      },
    });
  };

  useEffect(() => {
    handleChangePage(parseInt(router?.query?.page, 10) || 1);
  }, []);

  useEffect(() => {
    categoryListSelectRequest({
      body: {
        page: 1,
        size: 500,
        sortBy: 'id',
        sortOrder: 0,
        source: source,
        forType: MODULE_TYPE?.ARTICLE,
        // parentCategoryOnly: true,
      },
    });
  }, [source]);

  const handleActive = (data) => {
    markActiveInActiveRequest({
      url: `/common/admin/category/${data?.id}`,
      body: {
        active: data?.active ? false : true,
      },
    });
  };
  const handleDelete = (data) => {
    deleteUnDeleteRequest({
      url: `/common/admin/category/${data?.id}`,
      body: {
        deleted: data?.deleted ? false : true,
      },
    });
  };

  return (
    <>
      <div className="page-content">
        <Loader
          isActive={
            categoryListLoading || categoryListSelectLoading || markActiveInActiveLoading || deleteUnDeleteLoading
          }
        />
        <Container fluid>
          <Breadcrumbs title="Others" breadcrumbItem="Categories List" />
          <Card>
            <CardBody>
              <Form onSubmit={formik.handleSubmit}>
                <div className="mt-4">
                  <div className="row" style={{ gap: '20px' }}>
                    <div className="col-lg-2">
                      <Label className="form-label">Active</Label>
                      <Select
                        options={ACTIVE_AND_DELETED_ARRAY}
                        value={formik?.values?.active}
                        onChange={(active) => formik.setFieldValue('active', active)}
                      />
                    </div>

                    <div className="col-lg-2">
                      <Label className="form-label">Deleted</Label>
                      <Select
                        options={ACTIVE_AND_DELETED_ARRAY}
                        value={formik?.values?.deleted}
                        onChange={(deleted) => formik.setFieldValue('deleted', deleted)}
                      />
                    </div>

                    <div className="col-lg-2">
                      <Label className="form-label">Language</Label>
                      <Select
                        options={LANGUAGE_ARRAY}
                        value={formik?.values?.language}
                        onChange={(language) => formik.setFieldValue('language', language)}
                      />
                    </div>

                    <div className="col-lg-2">
                      <Label className="form-label">Source</Label>
                      <Select
                        options={[
                          { value: 'theparentz', label: 'theParentz' },
                          { value: 'schoolmykids', label: 'Schoolmykids' },
                        ]}
                        value={formik?.values?.source}
                        onChange={(source) => {
                          formik.setFieldValue('source', source);
                          setSource(source.value);
                        }}
                      />
                    </div>

                    <div className="col-lg-3">
                      <Label className="form-label">Categories</Label>
                      <Select
                        options={categorySelectList?.map((s) => ({ value: s.slug, label: s.name }))}
                        value={formik?.values?.categories}
                        onChange={(categories) => formik.setFieldValue('categories', categories)}
                      />
                    </div>

                    <div className="col-lg-3">
                      <Label for="name" className="form-label">
                        Name
                      </Label>
                      <Input
                        type="text"
                        id="name"
                        placeholder="Name..."
                        value={formik.values.name}
                        onChange={formik.handleChange}
                      />
                    </div>

                    <div className="col-lg-3">
                      <Label for="slug" className="form-label">
                        Slug
                      </Label>
                      <Input
                        type="text"
                        id="slug"
                        placeholder="Slug..."
                        value={formik.values.slug}
                        onChange={formik.handleChange}
                      />
                    </div>
                  </div>
                </div>

                <div className="mt-4">
                  <button className="btn btn-primary me-4" type="submit">
                    Apply Filters
                  </button>
                  <a className="" type="reset" onClick={clearFilters}>
                    Clear
                  </a>
                </div>
              </Form>
            </CardBody>
          </Card>

          <Row className="mb-2  align-items-center">
            <Col sm="4">
              <div className="search-box me-2 mb-2">
                <div className="position-relative font-bold">{categoryPagination?.totalCount} Categories Found</div>
              </div>
            </Col>
            <Col sm="8">
              <div className="text-sm-end">
                <Link
                  replace
                  className="btn btn-success btn-rounded waves-effect waves-light mb-2 me-2"
                  to={SMKLinks.ADD_CATEGORY}>
                  <i className="mdi mdi-plus me-1" />
                  Add Category
                </Link>
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg="12">
              <div className="">
                <div className="table-responsive" style={{ minHeight: 300 }}>
                  <Table className="project-list-table table-borderless">
                    <thead>
                      <tr className="table-warning">
                        <th scope="col">ID</th>
                        <th scope="col">For Type</th>
                        <th scope="col">Name / Slug</th>
                        <th scope="col">Parent Category</th>
                        <th scope="col">Root Category</th>
                        <th scope="col">Frontend App Name</th>
                        <th scope="col">Language</th>
                        <th scope="col">Active</th>
                        <th scope="col">Deleted</th>
                        <th scope="col" />
                      </tr>
                    </thead>
                    <tbody>
                      {!isEmpty(categoryList) ? (
                        categoryList?.map((data, index) => (
                          <tr key={index}>
                            <td>{data?.id}</td>
                            <td>{invert(MODULE_TYPE)[data?.forType]}</td>
                            <td>
                              <h5 className="text-truncate font-size-14">
                                <Link
                                  to={buildURL(SMKLinks.EDIT_CATEGORY, {
                                    id: data?.id,
                                  })}
                                  className="text-primary">
                                  {data?.name}
                                </Link>
                              </h5>
                              {data?.slug}
                            </td>
                            {/* 
                                                            <td>{data?.name}</td>
                                                            <td>{data?.slug}</td> */}
                            <td>
                              <Badge className="bg-light p-2 rounded-pill">{data?.parentCategory?.name}</Badge>
                            </td>
                            <td>
                              <Badge className="bg-light p-2 rounded-pill">{data?.rootCategory?.name}</Badge>
                            </td>
                            <td>{data?.frontend_app_name}</td>
                            <td>
                              <Badge
                                className={`${data?.language == 0 ? 'bg-info' : 'bg-warning'} p-1`}
                                style={{ fontSize: '10px' }}>
                                {LANGUAGE_VALUES[data?.language]}
                              </Badge>
                            </td>
                            <td>
                              <Badge className={data?.active ? 'bg-success' : 'bg-danger'}>
                                {data?.active ? 'Yes' : 'No'}
                              </Badge>
                            </td>
                            <td>
                              <Badge className={data?.deleted ? 'bg-success' : 'bg-danger'}>
                                {data?.deleted ? 'Yes' : 'No'}
                              </Badge>
                            </td>
                            <td>
                              <UncontrolledDropdown>
                                <DropdownToggle href="#" className="card-drop " tag="i">
                                  <i role="button" className="mdi mdi-dots-vertical font-size-18 " />
                                </DropdownToggle>
                                <DropdownMenu className="dropdown-menu-end">
                                  <DropdownItem onClick={() => handleActive(data)}>
                                    <i
                                      className={`fas  ${
                                        data?.active ? 'fa-ban text-danger' : 'fa-power-off text-success'
                                      }   m-1 pe-3`}
                                    />
                                    {data?.active ? 'Disable' : 'Enable'}
                                  </DropdownItem>
                                  <DropdownItem onClick={() => handleDelete(data)}>
                                    <i className={`fas fa-trash-alt text-danger m-1 pe-3`} />
                                    {data?.deleted ? 'Undelete' : 'Delete'}
                                  </DropdownItem>
                                  <Divider className="my-2" />

                                  <Link
                                    to={buildURL(SMKLinks.EDIT_CATEGORY, {
                                      id: data?.id,
                                    })}>
                                    <DropdownItem>
                                      <i className="fas fa-pencil-alt text-success m-1 pe-3 " />
                                      Edit
                                    </DropdownItem>
                                  </Link>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <TableError />
                      )}
                    </tbody>
                  </Table>
                </div>
              </div>
            </Col>
          </Row>

          <Paginate pageInfo={categoryPagination} onChangePage={handleChangePage} />
        </Container>
      </div>
    </>
  );
};

export default CategoriesList;
