import React, { useEffect } from 'react';
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
  Badge,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Table,
  UncontrolledDropdown,
  Input,
} from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { invert, isArray, isEmpty, map } from 'lodash';
import MiniCards from 'components/MiniCard/mini-card';
import { Link, useHistory, useParams } from 'react-router-dom';
import {
  LANGUAGE_VALUES,
  DIFFICULTY_LEVEL,
  GET_GENDER,
  GET_USER_ROLE,
  GET_USER_SOURCE,
  GET_USER_STATUS,
  USER_ROLE,
} from 'utils/constants';
import { buildURL, formatBytes, printDate, printDateTime, printDateTimeTwoLines } from 'utils/helper';
import Breadcrumbs from 'components/Common/Breadcrumb';
import { toast } from 'react-toastify';
import useRequest from 'hooks/useRequest';
import Loader from 'components/Loader';
import TableError from 'components/TableError';
import { SMKLinks } from 'utils/links';
import { worksheetDetailSelector } from '../selectors';
import { getWorksheetDetail } from '../actions';

const stateSelector = createStructuredSelector({
  worksheetDetail: worksheetDetailSelector,
});

const WorksheetDetail = () => {
  const { worksheetDetail } = useSelector(stateSelector);
  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  toast.configure();

  useEffect(() => {
    dispatch(getWorksheetDetail.trigger({ id }));
  }, []);

  const miniCards = [
    {
      title: 'View Count',
      iconClass: 'bx-receipt',
      text: `${worksheetDetail?.viewCount}`,
    },
    {
      title: 'Download Count',
      iconClass: 'bx-receipt',
      text: `${worksheetDetail?.downloadCount}`,
    },

    {
      title: 'Active',
      iconClass: `${worksheetDetail?.active ? 'bx-check-shield' : 'bxs-shield-x'}`,
      iconBgColor: `${worksheetDetail?.active ? 'bg-success' : 'bg-danger'}`,
      text: `${worksheetDetail?.active ? 'Yes' : 'No'}`,
      textColor: `${worksheetDetail?.active ? 'text-success' : 'text-danger'}`,
    },
    {
      title: 'Deleted',
      iconClass: `${worksheetDetail?.deleted ? 'bxs-trash' : 'bxs-trash'}`,
      iconBgColor: `${worksheetDetail?.deleted ? 'bg-success' : 'bg-danger'}`,
      text: `${worksheetDetail?.deleted ? 'Yes' : 'No'}`,
      textColor: `${worksheetDetail?.deleted ? 'text-success' : 'text-danger'}`,
    },
  ];

  const [approveRejectWorksheetRequest, { loading: approveRejectWorksheetLoading }] = useRequest({
    url: '/worksheet/admin/approve',
    method: 'PUT',
    onSuccess: () => {
      toast.success(`Success! Worksheet has been ${worksheetDetail?.active ? 'Rejected' : 'Approved'}`);
      dispatch(getWorksheetDetail.trigger({ id }));
    },
  });

  const handleApproveAndReject = (data) => {
    approveRejectWorksheetRequest({
      body: {
        active: !data?.active,
        id: data.id,
      },
    });
  };

  const handleEditClick = (data) => {
    const url = buildURL(SMKLinks.WORKSHEET_EDIT, { id: data.id });
    history.replace(url);
  };

  const renderFilePreview = (files) => {
    let array = [];

    if (!isEmpty(files)) {
      if (!isArray(files)) {
        array = [files];
      } else {
        array = files;
      }
    }
    return array.map((file, i) => {
      let className = 'mdi rounded bg-light ';
      if (file.type.includes('image')) {
        className += 'mdi-file-image-outline';
      } else if (file.type.includes('pdf')) {
        className += 'mdi-file-pdf-outline  text-danger';
      } else {
        className += 'mdi-file-document-outline';
      }

      return (
        <Card
          className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
          key={`${i}-file`}>
          <div className="p-2">
            <Row className="align-items-center">
              <Col className="col-auto">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={`${process.env.REACT_APP_SCHOOLMYKIDS_WEB_URL}${
                    !isEmpty(file?.original) ? file?.original : `/upload/${file?.filename}`
                  } `}>
                  <i className={className} style={{ fontSize: '3rem' }} />
                </a>
              </Col>
              <Col>
                <Link to="#" className="text-muted font-weight-bold">
                  {file?.name}
                </Link>
                <p className="mb-0">
                  <strong>{formatBytes(file?.size)}</strong>
                </p>
              </Col>
            </Row>
          </div>
        </Card>
      );
    });
  };

  const GET_USER_STATUS_COLOR = {
    0: 'primary',
    1: 'success',
    2: 'danger',
  };

  return (
    <div className="page-content">
      <Loader isActive={approveRejectWorksheetLoading} />

      <Container fluid>
        <Breadcrumbs title="Worksheet" breadcrumbItem={`Worksheet detail - ${worksheetDetail?.id}  `} />

        {!isEmpty(worksheetDetail) && (
          <>
            <Card>
              <CardBody>
                <span className="text-muted font-weight-medium mb-0 pb-0">{worksheetDetail?.type}</span>
                <div className="d-flex justify-content-between align-items-end pt-3">
                  <h3 className="mb-0 mt-0">
                    {` (${worksheetDetail?.id})  `} {worksheetDetail?.title}
                  </h3>
                  <UncontrolledDropdown style={{ zIndex: '9999' }}>
                    <DropdownToggle href="#" className="card-drop cursor-pointer" tag="i">
                      <i role="button" className="mdi mdi-dots-vertical font-size-18 " />
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-end">
                      <DropdownItem onClick={() => handleApproveAndReject(worksheetDetail)}>
                        <i
                          className={`fas  ${
                            worksheetDetail?.active ? 'fa-ban text-danger' : 'fa-power-off text-success'
                          }   m-1 pe-3`}
                        />
                        {worksheetDetail?.active ? 'Reject' : 'Approve'}
                      </DropdownItem>

                      <DropdownItem href="#" onClick={() => handleEditClick(worksheetDetail)}>
                        <i className="fas fa-pencil-alt text-success m-1 pe-3 " />
                        Edit
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </div>
                <CardTitle
                  className="mt-4 text-muted  font-weight-normal"
                  dangerouslySetInnerHTML={{
                    __html: worksheetDetail?.description,
                  }}
                />
              </CardBody>
            </Card>
          </>
        )}

        <Row>
          <Col xl="12">
            <Row>
              {map(miniCards, (card, key) => (
                <MiniCards
                  title={card?.title}
                  text={card?.text}
                  textColor={card?.textColor}
                  iconBgColor={card?.iconBgColor}
                  iconClass={card?.iconClass}
                  key={`_card_${key}`}
                  mdCol={3}
                />
              ))}
            </Row>
          </Col>

          <Col xl="4">
            <Card>
              <CardBody>
                <div className="table-responsive" style={{ minHeight: 50 }}>
                  <Table className="table-nowrap mb-0">
                    {!isEmpty(worksheetDetail) ? (
                      <>
                        <thead className="bg-light text-center">
                          <tr>
                            <th colSpan={2}>More Details</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th>Type</th>
                            <td>{worksheetDetail?.type}</td>
                          </tr>
                          <tr>
                            <th>Created Date</th>
                            <td>{printDate(worksheetDetail?.createdDate)}</td>
                          </tr>
                          <tr>
                            <th>Updated Date</th>
                            <td>{printDate(worksheetDetail?.updatedDate)}</td>
                          </tr>
                          <tr>
                            <th>Version</th>
                            <td>{worksheetDetail?.version}</td>
                          </tr>

                          <tr>
                            <th>Level</th>
                            <td>{invert(DIFFICULTY_LEVEL)[worksheetDetail?.level]}</td>
                          </tr>
                          <tr>
                            <th>Age Group</th>
                            <td>{worksheetDetail?.ageGroup}</td>
                          </tr>

                          <tr>
                            <th>Grades</th>
                            <td>{worksheetDetail?.grades?.join(', ')}</td>
                          </tr>
                          <tr>
                            <th>Status</th>
                            <td>
                              <Badge className={GET_USER_STATUS_COLOR[worksheetDetail?.status]}>
                                {GET_USER_STATUS[worksheetDetail?.status]}
                              </Badge>
                            </td>
                          </tr>
                          <tr>
                            <th>Tags</th>
                            <td style={{ whiteSpace: 'normal' }}>
                              {worksheetDetail?.tags?.map((e, i) => (
                                <Badge key={i} className="bg-light m-1">
                                  {e?.name}{' '}
                                </Badge>
                              ))}
                            </td>
                          </tr>
                          <tr>
                            <th>Sequence</th>
                            <td>{worksheetDetail?.sequence}</td>
                          </tr>

                          <tr>
                            <th>Is Answer Visible</th>
                            <td>
                              <Badge className={worksheetDetail?.isAnswerVisible ? 'bg-success' : 'bg-danger'}>
                                {worksheetDetail?.isAnswerVisible ? 'Yes' : 'No'}
                              </Badge>
                            </td>
                          </tr>

                          {/* <tr>
                            <th>JSON Content</th>
                          </tr>
                          <tr>
                            <td colSpan={2}>
                              <Input type='textarea' style={{ height: '150px' }} value={worksheetDetail?.jsonContent} />
                            </td>
                          </tr> */}
                        </tbody>
                      </>
                    ) : (
                      <TableError />
                    )}
                  </Table>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col xl="8">
            {isEmpty(worksheetDetail?.subjects) && (
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">Subjects </CardTitle>
                  <Row>
                    <Col xl="12">
                      <div className="table-responsive" style={{ minHeight: 100 }}>
                        <Table className=" table-nowrap table-centered table-borderless">
                          <thead>
                            <tr className="bg-light">
                              <th>ID</th>
                              <th>Name</th>
                              <th>slug</th>
                              {/* <th>Level</th> */}
                              <th>Language</th>
                              <th>Active</th>
                              <th>Deleted</th>
                              <th>Created Date</th>
                            </tr>
                          </thead>
                          <tbody>
                            {worksheetDetail?.subjects?.map((elem, index) => (
                              <tr key={index}>
                                <td>{elem?.id}</td>
                                <td>{elem?.name}</td>
                                <td>{elem?.slug}</td>
                                {/* <td>{invert(DIFFICULTY_LEVEL)[elem?.level]}</td> */}
                                <td>{LANGUAGE_VALUES[elem?.language]}</td>
                                <td>
                                  <Badge className={elem?.active ? 'bg-success' : 'bg-danger'}>
                                    {elem?.active ? 'Yes' : 'No'}
                                  </Badge>
                                </td>
                                <td>
                                  <Badge className={elem?.deleted ? 'bg-success' : 'bg-danger'}>
                                    {elem?.deleted ? 'Yes' : 'No'}
                                  </Badge>
                                </td>
                                <td>{printDateTime(elem?.createdDate)}</td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            )}

            <Row>
              {!isEmpty(worksheetDetail?.worksheetAttachment) && (
                <Col sm={!isEmpty(worksheetDetail?.answerAttachment) ? 6 : 12}>
                  <Card>
                    <CardBody>
                      <CardTitle className="mb-2 ms-2 ">Worksheet Attachments</CardTitle>
                      <div className="dropzone-previews mt-3" id="worksheet-previews">
                        {renderFilePreview(
                          worksheetDetail?.worksheetAttachment || worksheetDetail?.worksheetAttachments
                        )}
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              )}
              {!isEmpty(worksheetDetail?.answerAttachment) && (
                <Col sm={!isEmpty(worksheetDetail?.worksheetAttachment) ? 6 : 12}>
                  <Card>
                    <CardBody>
                      <CardTitle className="mb-2 ms-2 ">Answer Attachments</CardTitle>
                      <div className="dropzone-previews mt-3" id="answer-previews">
                        {renderFilePreview(worksheetDetail?.answerAttachment)}
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              )}
            </Row>

            {!isEmpty(worksheetDetail?.createdBy) && (
              <Card>
                <CardBody>
                  <div className="table-responsive" style={{ minHeight: 50 }}>
                    <Table className="table-nowrap mb-0">
                      <thead className="bg-light">
                        <tr>
                          <th colSpan={2}>Created By</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th>ID</th>
                          <td>{worksheetDetail?.createdBy?.id}</td>
                        </tr>
                        <tr>
                          <th>Full Name</th>
                          <td>{`${worksheetDetail?.createdBy?.firstName} ${worksheetDetail?.createdBy?.lastName}`}</td>
                        </tr>
                        <tr>
                          <th>Email</th>
                          <td>{worksheetDetail?.createdBy?.email}</td>
                        </tr>
                        <tr>
                          <th>Gender</th>
                          <td>{GET_GENDER[worksheetDetail?.createdBy?.gender]}</td>
                        </tr>
                        <tr>
                          <th>Role</th>
                          <td>{GET_USER_ROLE[worksheetDetail?.createdBy?.role]}</td>
                        </tr>
                        <tr>
                          <th>Source</th>
                          <td>{GET_USER_SOURCE[worksheetDetail?.createdBy?.source]}</td>
                        </tr>
                        <tr>
                          <th>Status</th>
                          <td>
                            <Badge className={GET_USER_STATUS_COLOR[worksheetDetail?.createdBy?.status]}>
                              {GET_USER_STATUS[worksheetDetail?.createdBy?.status]}
                            </Badge>
                          </td>
                        </tr>

                        <tr>
                          <th>Search Text</th>
                          <td style={{ whiteSpace: 'normal' }}>{worksheetDetail?.searchText}</td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            )}
          </Col>
          {!isEmpty(worksheetDetail?.topics) && (
            <Col sm={12}>
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">Subject Topics </CardTitle>
                  <Row>
                    <Col xl="12">
                      <div className="table-responsive" style={{ minHeight: 100 }}>
                        <Table className=" table-nowrap table-centered ">
                          <thead>
                            <tr className="bg-light">
                              <th>ID</th>
                              <th>Name</th>
                              <th>Slug</th>
                              <th>Grades</th>
                              <th>Subject</th>
                              <th>Language</th>
                              <th>Parent Topic</th>
                              <th>Active</th>
                              <th>Deleted</th>
                              {/* <th>Created Date</th> */}
                            </tr>
                          </thead>
                          <tbody>
                            {worksheetDetail?.topics?.map((elem, index) => (
                              <>
                                <tr key={index}>
                                  <td>{elem?.id}</td>
                                  <td>{elem?.name}</td>
                                  <td>{elem?.slug}</td>
                                  <td>{elem?.grades?.join(', ')}</td>
                                  <td>{elem?.subject?.name}</td>
                                  <td>{LANGUAGE_VALUES[elem?.language]}</td>
                                  <td>
                                    {!isEmpty(elem?.parentTopics)
                                      ? elem?.parentTopics?.map((e, i) => (
                                          <>
                                            <Badge key={i} className="bg-light my-1">
                                              {e?.name}
                                            </Badge>
                                            <br />
                                          </>
                                        ))
                                      : '-'}
                                  </td>
                                  <td>
                                    <Badge className={elem?.active ? 'bg-success' : 'bg-danger'}>
                                      {elem?.active ? 'Yes' : 'No'}
                                    </Badge>
                                  </td>
                                  <td>
                                    <Badge className={elem?.deleted ? 'bg-success' : 'bg-danger'}>
                                      {elem?.deleted ? 'Yes' : 'No'}
                                    </Badge>
                                  </td>
                                  {/* <td>{printDate(elem?.createdDate)}</td> */}
                                </tr>
                              </>
                            ))}
                          </tbody>
                        </Table>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          )}
        </Row>
      </Container>
    </div>
  );
};

export default WorksheetDetail;
