import { useFormik } from 'formik';
import invert from 'lodash/invert';
import * as moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
// components
import Select from 'react-select';
import {
  Badge,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Table,
} from 'reactstrap';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { isEmpty } from 'lodash';
import TableError from 'components/TableError';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import Loader from '../../../components/Loader';
import Paginate from '../../../components/Paginate';
import CollegeAutoComplete from '../../../components/CollegeAutoComplete';
import config from '../../../config/config';
import useRequest from '../../../hooks/useRequest';
import { useRouter } from '../../../hooks/useRouter';
import {
  ACTIVE_AND_DELETED_ARRAY,
  CollegeReviewRejectReason,
  COLLEGE_REVIEWER_TYPE,
  SORT_VALUES,
} from '../../../utils/constants';
import { checkIfValueExist, convertHtmlToText, printDateTimeTwoLines, titleCaseIfExists } from '../../../utils/helper';
import { collegeClearCache } from '../collegeDetailsHelper';
import { getCities, getCountries, getRegions, getSubAreas } from '../actions';
import { citiesSelector, countriesSelector, regionsSelector } from '../selectors';
import { createStructuredSelector } from 'reselect';
import { useDispatch, useSelector } from 'react-redux';

const stateSelector = createStructuredSelector({
  countries: countriesSelector,
  regions: regionsSelector,
  cities: citiesSelector,
});

const CollegeReviewsList = () => {
  const router = useRouter();
  const history = useHistory();
  const dispatch = useDispatch();
  toast.configure();

  const { countries, regions, cities } = useSelector(stateSelector);

  const [collegeReviews, setCollegeReviews] = useState([]);
  const [collegeReviewsPagination, setCollegeReviewsPagination] = useState({});
  const [selectedCollege, setSelectedCollege] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(null);
  const [isToggle, setIsToggle] = useState(false);
  const [college, setCollege] = useState(false);

  useEffect(() => {
    dispatch(getCountries.trigger({ level: 0, page: 1, size: 500 }));
  }, []);

  const defaultFilters = {
    isCollegeActive: { value: true, label: 'Yes' },
    active: { value: null, label: 'All' },
    deleted: { value: null, label: 'All' },
    isTextPresent: { value: null, label: 'All' },
    startDate: moment().subtract(1, 'year'),
    endDate: moment(),
    email: undefined,

    countryId: undefined,
    regionId: undefined,
    cityId: undefined,
  };
  const [filters, setFilters] = useState(defaultFilters);

  const [sort, setSort] = useState(SORT_VALUES);

  const [getCollegeReviewsRequest, { loading: getCollegeReviewsLoading }] = useRequest({
    url: `/college/admin/review/search`,
    method: 'POST',
    onSuccess: (data) => {
      setCollegeReviewsPagination(data?.pagination);
      setCollegeReviews(data?.results);
    },
  });

  const setPageInUrl = (page) => {
    history.push({
      pathname: router.pathname,
      search: `?${new URLSearchParams({ page }).toString()}`,
    });
  };

  const handleChangePage = (page) => {
    setPageInUrl(page);

    const data = {
      page,
      size: 50,
      ...filters,
      isCollegeActive: checkIfValueExist(filters.isCollegeActive?.value),
      active: checkIfValueExist(filters.active?.value),
      deleted: checkIfValueExist(filters.deleted?.value),
      isTextPresent: checkIfValueExist(filters.isTextPresent?.value),
      ...sort,
    };

    if (filters.countryId == '') {
      data.countryId = undefined;
    } else if (filters.countryId) {
      data.countryId = filters.countryId.id;
    }
    if (filters.regionId == '') {
      data.regionId = undefined;
    } else if (filters.regionId) {
      data.regionId = filters.regionId.id;
    }
    if (filters.cityId == '') {
      data.cityId = undefined;
    } else if (filters.cityId) {
      data.cityId = filters.cityId.id;
    }

    getCollegeReviewsRequest({
      body: data,
    });
  };

  const [markApprovedRequest, { loading: markApprovedLoading }] = useRequest({
    method: 'PUT',
    onSuccess: (data) => {
      handleChangePage(parseInt(router?.query?.page, 10) || 1);
      toast.success('Success! College Review has been approved.');
    },
  });

  const [markUnapprovedRequest, { loading: markUnapprovedLoading }] = useRequest({
    method: 'PUT',
    onSuccess: (data) => {
      handleChangePage(parseInt(router?.query?.page, 10) || 1);
      toast.success('Success! College Review has been UnApproved.');
    },
  });

  const [deleteReviewRequest, { loading: deleteReviewLoading }] = useRequest({
    method: 'PUT',
    onSuccess: (data) => {
      setShowDeleteModal(null);
      handleChangePage(parseInt(router?.query?.page, 10) || 1);
      toast.success('Success! College Review has been deleted.');
    },
  });

  const markApproved = (review) => {
    markApprovedRequest({ url: `/college/admin/review/approve/${review.id}` });
    collegeClearCache({
      removeBodyParam: true,
      urlSlug: review?.college?.slug,
      collegeId: review?.college?.id,
    });
  };

  const markUnapproved = (review) => {
    markUnapprovedRequest({
      url: `/college/admin/review/unapprove/${review.id}`,
    });
  };

  const markDeleted = (review, payload) => {
    deleteReviewRequest({
      url: `/college/admin/review/delete/${review.id}`,
      body: payload,
    });
  };

  useEffect(() => {
    handleChangePage(parseInt(router?.query?.page, 10) || 1);
  }, []);

  const applyFilters = () => {
    handleChangePage(1);
  };

  const clearFilters = () => {
    setPageInUrl(1);

    setFilters({ ...defaultFilters, countryId: '', regionId: '', cityId: '' });
    getCollegeReviewsRequest({
      body: {
        page: 1,
        size: 50,
        ...defaultFilters,
        isCollegeActive: checkIfValueExist(defaultFilters.isCollegeActive?.value),
        active: checkIfValueExist(defaultFilters.active?.value),
        deleted: checkIfValueExist(defaultFilters.deleted?.value),
        isTextPresent: checkIfValueExist(defaultFilters.isTextPresent?.value),
        ...sort,
        countryId: '',
        regionId: '',
        cityId: '',
      },
    });
    setSelectedCollege(null);
  };

  const reviewRejectFormik = useFormik({
    enableReinitialize: true,
    initialValues: {
      rejectReason: '',
      rejectComments: '',
    },
    onSubmit(values) {
      markDeleted(showDeleteModal, values);
      showDeleteModal(null);
    },
    validationSchema: Yup.object().shape({
      rejectReason: Yup.string().required('Reject reason is requried'),
    }),
  });

  const shortString = (data, str, n) => {
    return str.length > n ? (
      <>
        {str.slice(0, n - 1)}
        <a
          onClick={() => {
            setIsToggle(true);
            setCollege(data);
          }}
          className="cursor-pointer  text-info">
          ...more
        </a>
      </>
    ) : (
      str
    );
  };

  const onCountrySelect = (country) => {
    if (!isEmpty(country)) {
      dispatch(
        getRegions.trigger({
          level: 1,
          country: country.name,
          page: 1,
          size: 500,
        })
      );
      setFilters({ ...filters, countryId: country });
    } else {
      setFilters({ ...filters, countryId: undefined });
    }
  };
  const onRegionSelect = (region) => {
    if (!isEmpty(region)) {
      dispatch(
        getCities.trigger({
          level: 3,
          cityOnly: true,
          region: region.name,
          page: 1,
          size: 500,
        })
      );

      setFilters({ ...filters, regionId: region });
    } else {
      setFilters({ ...filters, regionId: undefined });
    }
  };

  const onCitySelect = (city) => {
    if (!isEmpty(city)) {
      dispatch(getSubAreas.trigger({ level: 4, city: city.name, page: 1, size: 500 }));

      setFilters({ ...filters, cityId: city });
    } else {
      setFilters({ ...filters, cityId: undefined });
    }
  };

  useEffect(() => {
    applyFilters();
  }, [sort]);

  return (
    <>
      <div className="page-content">
        <Loader
          isActive={getCollegeReviewsLoading || markApprovedLoading || markUnapprovedLoading || deleteReviewLoading}
        />

        <Container fluid>
          <Breadcrumbs title="Colleges" breadcrumbItem="College Reviews List" />

          <Card>
            <CardBody>
              <div className="mt-4">
                <div className="row">
                  <div className="col-lg-2 mb-4">
                    <label className="form-label">isCollegeActive</label>
                    <Select
                      options={ACTIVE_AND_DELETED_ARRAY}
                      value={filters.isCollegeActive}
                      onChange={(value) => setFilters({ ...filters, isCollegeActive: value })}
                    />
                  </div>
                  <div className="col-lg-2">
                    <label className="form-label">Approved</label>
                    <Select
                      options={ACTIVE_AND_DELETED_ARRAY}
                      value={filters.active}
                      onChange={(value) => setFilters({ ...filters, active: value })}
                    />
                  </div>
                  <div className="col-lg-2">
                    <label className="form-label">Deleted</label>
                    <Select
                      options={ACTIVE_AND_DELETED_ARRAY}
                      value={filters.deleted}
                      onChange={(value) => setFilters({ ...filters, deleted: value })}
                    />
                  </div>
                  <div className="col-lg-2">
                    <label className="form-label">Text Present</label>
                    <Select
                      options={ACTIVE_AND_DELETED_ARRAY}
                      value={filters.isTextPresent}
                      onChange={(value) => setFilters({ ...filters, isTextPresent: value })}
                    />
                  </div>
                  <div className="col-lg-2">
                    <label className="form-label">Created Date - From</label>
                    <Input
                      type="date"
                      name="startDate"
                      placeholder="From Date"
                      value={filters.startDate.format('YYYY-MM-DD')}
                      max={moment().format('YYYY-MM-DD')}
                      onChange={(e) =>
                        setFilters({
                          ...filters,
                          startDate: moment(e.target.value),
                        })
                      }
                    />
                  </div>
                  <div className="col-lg-2">
                    <label className="form-label">Created Date - To</label>
                    <Input
                      type="date"
                      name="endDate"
                      placeholder="To Date"
                      value={filters.endDate.format('YYYY-MM-DD')}
                      max={moment().format('YYYY-MM-DD')}
                      onChange={(e) =>
                        setFilters({
                          ...filters,
                          endDate: moment(e.target.value),
                        })
                      }
                    />
                  </div>
                  {/* <div className="col-lg-2 mb-4">
                    <label className="form-label">Email</label>
                    <Input
                      type="text"
                      name="email"
                      placeholder="Email"
                      value={filters.email}
                      onChange={(e) => setFilters({ ...filters, email: e.target.value })}
                    />
                  </div> */}
                  <div className="col-lg-3">
                    <label className="form-label">College</label>

                    <CollegeAutoComplete
                      selectedCollege={selectedCollege}
                      onSelectCollege={(college) => {
                        if (college?.id > 0) {
                          setFilters({ ...filters, collegeId: college.id });
                        } else {
                          const newFilters = { ...filters };
                          delete newFilters.collegeId;
                          setFilters(newFilters);
                        }
                        setSelectedCollege(college);
                      }}
                    />
                  </div>

                  <Col md={3}>
                    <FormGroup className="mb-4">
                      <Label for="college_country" className="form-label">
                        Country
                      </Label>
                      <Select
                        isClearable
                        id="college_country"
                        options={countries}
                        value={filters.countryId}
                        onChange={onCountrySelect}
                      />
                    </FormGroup>
                  </Col>

                  <Col md={3}>
                    <FormGroup className="mb-4">
                      <Label for="college_state" className="form-label  ">
                        Region/State
                      </Label>

                      <Select
                        isClearable
                        id="college_state"
                        placeholder="Select..."
                        value={filters.regionId}
                        onChange={onRegionSelect}
                        options={regions}
                      />
                    </FormGroup>
                  </Col>

                  <Col md={3}>
                    <FormGroup className="mb-4">
                      <Label for="college_city" className="form-label  ">
                        City
                      </Label>
                      <Select
                        isClearable
                        id="college_city"
                        placeholder="Select..."
                        value={filters.cityId}
                        onChange={onCitySelect}
                        options={cities}
                      />
                    </FormGroup>
                  </Col>
                </div>
              </div>

              <div className="mt-4">
                <a className="btn btn-primary me-4" onClick={applyFilters}>
                  Apply Filters
                </a>
                <a className="" onClick={clearFilters}>
                  Clear
                </a>
              </div>
            </CardBody>
          </Card>

          <Row className="mb-2">
            <Col sm="4">
              <div className="search-box me-2 mb-2 d-inline-block">
                <div className="position-relative font-bold">
                  {collegeReviewsPagination?.totalCount} College Reviews Found
                </div>
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg="12">
              <div className="">
                <div className="table-responsive" style={{ minHeight: 300 }}>
                  <Table className="project-list-table   table-centered table-borderless">
                    <thead>
                      <tr className="table-warning">
                        <th scope="col">Review Id</th>
                        <th scope="col">Name / Email / Phone</th>
                        <th scope="col">Status</th>
                        <th scope="col">Reviewer Type</th>
                        <th scope="col">Review Source</th>
                        <th scope="col">Rating</th>
                        <th scope="col">
                          <a
                            onClick={() => {
                              setSort({
                                sortBy: 'createdDate',
                                sortOrder: sort.sortBy === 'createdDate' ? sort.sortOrder * -1 : -1,
                              });
                            }}>
                            Date
                          </a>
                        </th>
                        <th scope="col">Subject / Message</th>
                        <th scope="col" width="60px" />
                      </tr>
                    </thead>
                    <tbody>
                      {!isEmpty(collegeReviews) ? (
                        collegeReviews?.map((data, index) => (
                          <tr key={index}>
                            <td>{data?.id}</td>
                            <td>
                              <strong>
                                {data?.createdBy?.firstName} {data?.createdBy?.lastName}{' '}
                                {data?.keepAnonymous ? ` - Anonymous` : ''}
                              </strong>
                              <div className="d-flex mt-2">
                                <a
                                  className="me-2 "
                                  onClick={() => {
                                    setFilters({
                                      ...filters,
                                      email: data?.createdBy?.email,
                                    });
                                    getCollegeReviewsRequest({
                                      body: {
                                        page: 1,
                                        size: 100,
                                        ...filters,
                                        active: filters.active?.value,
                                        deleted: filters.deleted?.value,
                                        ...sort,
                                        email: data?.createdBy?.email,
                                      },
                                    });
                                  }}>
                                  <i className="fa fa-filter" />
                                </a>
                                {data?.createdBy?.phoneNumber && (
                                  <>
                                    ({data?.createdBy?.phoneNumber}) <br />
                                  </>
                                )}
                                {data?.createdBy?.email}
                              </div>
                            </td>
                            <td className="text-nowrap">
                              {/* eslint-disable-next-line no-nested-ternary */}
                              {data?.active ? (
                                <Badge className="bg-success m-2">Approved</Badge>
                              ) : data?.deleted ? (
                                <>
                                  <Badge className="bg-danger m-2">Rejected</Badge>
                                  <Badge className="bg-light m-2">
                                    {titleCaseIfExists(invert(CollegeReviewRejectReason)[data?.rejectReason])}
                                  </Badge>
                                </>
                              ) : (
                                <Badge className="bg-warning m-2">Pending</Badge>
                              )}
                            </td>
                            <td className="text-nowrap">{invert(COLLEGE_REVIEWER_TYPE)[data.reviewerType]}</td>
                            <td className="text-nowrap">{data.reviewSource === 1 ? 'SMK' : 'Google'}</td>
                            <td className="text-nowrap">{data.rating}</td>
                            <td className="text-nowrap">{printDateTimeTwoLines(data.createdDate)}</td>

                            <td>
                              <Badge className={`${data?.college?.active ? 'bg-success' : 'bg-danger'} mb-2`}>
                                {data?.college?.active ? 'Active' : ' Inactive'}
                              </Badge>
                              <br className="mb-2" />
                              <a
                                className="me-2"
                                onClick={() => {
                                  setFilters({
                                    ...filters,
                                    collegeId: data?.college?.id,
                                  });
                                  setSelectedCollege(data?.college);

                                  getCollegeReviewsRequest({
                                    body: {
                                      page: 1,
                                      size: 20,
                                      ...filters,
                                      active: filters.active?.value,
                                      deleted: filters.deleted?.value,
                                      ...sort,
                                      collegeId: data?.college?.id,
                                    },
                                  });
                                }}>
                                <i className="fa fa-filter" />
                              </a>
                              #{data?.college?.id} -
                              <a
                                target="_blank"
                                href={`${config.production_url}/college/${data?.college?.slug}`}
                                rel="noreferrer">
                                {convertHtmlToText(data.college?.name)}
                              </a>
                              <br />
                              <strong>{data.title}</strong>
                              <br />
                              {shortString(data, data?.text, 120)}
                            </td>

                            <td>
                              <div className="row-actions">
                                <a className="btn btn-sm btn-success mb-2" onClick={() => markApproved(data)}>
                                  <i className="fa fa-fw fa-check" />
                                </a>

                                <a className="btn btn-sm btn-danger" onClick={() => setShowDeleteModal(data)}>
                                  <i className="fa fa-fw fa-times" />
                                </a>
                              </div>
                              {/* <UncontrolledDropdown direction="left">
                                <DropdownToggle tag="a" caret className="bg-transparent borderless text-dark">
                                  <i className="mdi mdi-dots-horizontal font-size-18" />
                                </DropdownToggle>
                                <DropdownMenu className='dropdown-menu-end'>
                                  <DropdownItem tag="a" onClick={() => markApproved(data)}>
                                    Approve Review
                                  </DropdownItem>
                                  <DropdownItem tag="a" onClick={() => markDeleted(data)}>
                                    Reject Review
                                  </DropdownItem>
                                </DropdownMenu>
                              </UncontrolledDropdown> */}
                            </td>
                          </tr>
                        ))
                      ) : (
                        <TableError />
                      )}
                    </tbody>
                  </Table>
                </div>
              </div>
            </Col>
          </Row>

          <Paginate pageInfo={collegeReviewsPagination} onChangePage={handleChangePage} />
        </Container>
        {}
        <Modal
          isOpen={showDeleteModal}
          role="dialog"
          autoFocus
          centered
          className="exampleModal"
          tabIndex="-1"
          toggle={() => {
            setShowDeleteModal(null);
            reviewRejectFormik.resetForm();
          }}>
          <div className="modal-content">
            <ModalHeader
              className="modalHeader"
              toggle={() => {
                setShowDeleteModal(null);
                reviewRejectFormik.resetForm();
              }}>
              Reason for review rejection
            </ModalHeader>
            <ModalBody>
              <Form onSubmit={reviewRejectFormik.handleSubmit}>
                <FormGroup className="mb-4">
                  <p>
                    <b>Review Id: {showDeleteModal?.id}</b>
                    <br />
                    College: #{showDeleteModal?.college?.id} - {convertHtmlToText(showDeleteModal?.college?.name)}
                  </p>
                </FormGroup>

                <FormGroup className="mb-4">
                  <Label htmlFor="name" className="form-label  ">
                    Reject Reason
                  </Label>

                  <Select
                    id="source"
                    placeholder="Please select an option"
                    options={Object.entries(CollegeReviewRejectReason).map(([key, value]) => ({
                      value,
                      label: titleCaseIfExists(key),
                    }))}
                    value={{
                      label: titleCaseIfExists(
                        invert(CollegeReviewRejectReason)[reviewRejectFormik.values.rejectReason]
                      ),
                      value: reviewRejectFormik.values.rejectReason,
                    }}
                    onChange={(val) => reviewRejectFormik.setFieldValue('rejectReason', val?.value)}
                  />
                  {reviewRejectFormik.errors.rejectReason && (
                    <div className="text-danger">{reviewRejectFormik.errors.rejectReason}</div>
                  )}
                </FormGroup>

                <FormGroup className="mb-4">
                  <Label htmlFor="name" className="form-label  ">
                    Reject Comments
                  </Label>

                  <Input
                    id="rejectComments"
                    type="textarea"
                    rows={3}
                    className="form-control"
                    placeholder="rejectComments"
                    invalid={!!(reviewRejectFormik.touched.rejectComments && reviewRejectFormik.errors.rejectComments)}
                    {...reviewRejectFormik.getFieldProps('rejectComments')}
                  />
                  <FormFeedback>{reviewRejectFormik.errors.rejectComments}</FormFeedback>
                </FormGroup>

                <div className="mt-4">
                  <Button type="submit" color="success">
                    Submit
                  </Button>
                  <Button
                    type="button"
                    className="bg-light ms-2"
                    onClick={() => {
                      setShowDeleteModal(null);
                      reviewRejectFormik.resetForm();
                    }}>
                    Cancel
                  </Button>
                </div>
              </Form>
            </ModalBody>
          </div>
        </Modal>

        <Modal
          isOpen={isToggle}
          role="dialog"
          autoFocus
          centered
          className="exampleModal"
          tabIndex="-1"
          toggle={() => {
            setIsToggle(null);
          }}>
          <div className="modal-content">
            <ModalHeader
              className="modalHeader bg-light"
              toggle={() => {
                setIsToggle(null);
              }}>
              Reason for rejection
            </ModalHeader>
            <ModalBody>
              <div className="p-1">
                <h5>
                  #{college?.college?.id} -
                  <a
                    target="_blank"
                    href={`${config.production_url}/college/${college?.college?.slug}`}
                    rel="noreferrer">
                    {convertHtmlToText(college.college?.name)}
                  </a>
                </h5>
                <strong>{college?.title}</strong>
                <hr />
                {college?.text}
              </div>

              <div className="mt-4 mb-2">
                <a
                  className="btn btn-success me-4"
                  onClick={() => {
                    markApproved(college);
                    setIsToggle(null);
                  }}>
                  Approve
                </a>

                <a
                  className="btn btn-danger"
                  onClick={() => {
                    setShowDeleteModal(college);
                    setIsToggle(null);
                  }}>
                  Reject
                </a>
              </div>
            </ModalBody>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default CollegeReviewsList;
