import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import {
  Badge,
  Card,
  CardBody,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Table,
  UncontrolledDropdown,
} from 'reactstrap';

// Import Breadcrumb
import Breadcrumbs from 'components/Common/Breadcrumb';
import Select from 'react-select';

import { buildURL, checkIfValueExist, printDateTime, printDateTimeTwoLines, showImage } from 'utils/helper';
import { SMKLinks } from 'utils/links';
import { Divider } from '@material-ui/core';
import { toast } from 'react-toastify';
import config from 'config/config';
import TableError from 'components/TableError';
import { invert, isEmpty } from 'lodash';
import confirm from 'reactstrap-confirm';
import Loader from '../../../components/Loader';
import { useRouter } from '../../../hooks/useRouter';
import useRequest from '../../../hooks/useRequest';
import Paginate from '../../../components/Paginate';
import {
  ACTIVE_AND_DELETED_ARRAY,
  BabyNameGenderValue,
  LANGUAGE_VALUES,
  SORT_VALUES,
  USER_GENDER,
  createdByTypeSelect,
} from '../../../utils/constants';

const HealthCareFacilityList = (props) => {
  const router = useRouter();
  const history = useHistory();
  toast.configure();

  const [healthCarefacilityList, sethealthCarefacilityList] = useState([]);
  const [healthCarefacilityPagination, sethealthCarefacilityPagination] = useState({});

  const defaultFilters = {
    active: { value: null, label: 'All' },
    deleted: { value: null, label: 'All' },
    type: { value: undefined, label: 'All' },
  };

  const [filters, setFilters] = useState(defaultFilters);

  const [getFacilityRequest, { loading: getFacilityLoading }] = useRequest({
    url: `/doctor/admin/healthcareFacility/search`,
    method: 'POST',
    onSuccess: (data) => {
      sethealthCarefacilityList(data?.results);
      sethealthCarefacilityPagination(data?.pagination);
    },
  });

  const [deleteHealthcareFacilityRequest, { loading: deleteHealthcareFacilityLoading }] = useRequest({
    method: 'DELETE',
    onSuccess: (data) => {
      handleChangePage(1);
      toast.success(`Success! Healthcare facility has been deleted.`);
    },
  });
  const [updateHealthcareFacilityRequest, { loading: updateHealthcareFacilityLoading }] = useRequest({
    method: 'PUT',
    onSuccess: (data) => {
      handleChangePage(1);
      toast.success(`Success! Healthcare facility has been updated.`);
    },
  });

  const setPageInUrl = (page) => {
    history.push({
      pathname: router.pathname,
      search: `?${new URLSearchParams({ page }).toString()}`,
    });
  };

  const handleChangePage = (page) => {
    setPageInUrl(page);

    getFacilityRequest({
      body: {
        page,
        ...filters,
        ...SORT_VALUES,
        size: 50,
        active: checkIfValueExist(filters.active?.value),
        deleted: checkIfValueExist(filters.deleted?.value),
        type: checkIfValueExist(filters.type?.value),
      },
    });
  };

  const applyFilters = () => {
    handleChangePage(1);
  };

  const clearFilters = () => {
    setPageInUrl(1);

    setFilters({ ...defaultFilters });
    getFacilityRequest({
      body: {
        page: 1,
        size: 50,
        ...defaultFilters,
        active: checkIfValueExist(defaultFilters.active?.value),
        deleted: checkIfValueExist(defaultFilters.deleted?.value),
        type: checkIfValueExist(defaultFilters.type?.value),
        ...SORT_VALUES,
      },
    });
  };

  const handleDeleteHealthCareFacility = async (data) => {
    const result = await confirm({
      title: `${data?.deleted ? 'Undelete' : 'Delete'} Doctor`,
      message: `Are you sure, you want to ${data?.deleted ? 'Undelete' : 'Delete'} this Doctor?`,
      confirmText: 'Confirm',
      confirmColor: 'primary',
      cancelColor: 'link text-danger',
    });

    if (result) {
      if (data?.deleted) {
        updateHealthcareFacilityRequest({
          url: `/doctor/admin/healthcareFacility/${data?.id}`,
          body: {
            deleted: false,
          },
        });
      } else {
        deleteHealthcareFacilityRequest({
          url: `/doctor/admin/healthcareFacility/${data.id}`,
        });
      }
    }
  };

  const handleActiveHealthCareFacility = async (data) => {
    if (data?.active) {
      updateHealthcareFacilityRequest({
        url: `/doctor/admin/healthcareFacility/${data?.id}`,
        body: {
          active: false,
        },
      });
    } else {
      updateHealthcareFacilityRequest({
        url: `/doctor/admin/healthcareFacility/${data?.id}`,
        body: {
          active: true,
        },
      });
    }
  };

  useEffect(() => {
    handleChangePage(parseInt(router?.query?.page, 10) || 1);
  }, []);

  return (
    <>
      <div className="page-content">
        <Loader isActive={getFacilityLoading || updateHealthcareFacilityLoading || deleteHealthcareFacilityLoading} />
        <Container fluid>
          <Breadcrumbs title="Healthcare facility" breadcrumbItem="Healthcare facility list" />

          <Card>
            <CardBody>
              <div className="mt-4">
                <div className="row">
                  <div className="col-lg-2">
                    <label className="form-label">Active</label>
                    <Select
                      options={ACTIVE_AND_DELETED_ARRAY}
                      value={filters.active}
                      onChange={(value) => {
                        setFilters({ ...filters, active: value });
                      }}
                    />
                  </div>
                  <div className="col-lg-2">
                    <label className="form-label">Deleted</label>
                    <Select
                      options={ACTIVE_AND_DELETED_ARRAY}
                      value={filters.deleted}
                      onChange={(value) => setFilters({ ...filters, deleted: value })}
                    />
                  </div>

                  <div className="col-lg-2">
                    <label className="form-label"> Created By Type</label>
                    <Select
                      options={createdByTypeSelect}
                      value={filters.type}
                      onChange={(value) => setFilters({ ...filters, type: value })}
                    />
                  </div>
                </div>
              </div>

              <div className="mt-4">
                <a className="btn btn-primary me-4" onClick={applyFilters}>
                  Apply Filters
                </a>
                <a className="" onClick={clearFilters}>
                  Clear
                </a>
              </div>
            </CardBody>
          </Card>

          <Row className="mb-2 align-items-center">
            <Col sm="4">
              <div className="search-box me-2 mb-2 d-inline-block">
                <div className="position-relative font-bold">
                  {healthCarefacilityPagination?.totalCount} Healthcare facility found
                </div>
              </div>
            </Col>
            <Col sm="8">
              <div className="text-sm-end">
                <Link
                  replace
                  className="btn btn-success btn-rounded waves-effect waves-light mb-2 me-2"
                  to={SMKLinks.ADD_HEALTH_CARE}>
                  <i className="mdi mdi-plus me-1" />
                  Add Healthcare facility
                </Link>
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg="12">
              <div className="">
                <div className="table-responsive" style={{ minHeight: 300 }}>
                  <Table className="project-list-table table-centered table-borderless ">
                    <thead>
                      <tr align="center" className="table-warning">
                        {/* <th scope="col" style={{ width: '100px' }}>
                          #ID
                        </th> */}
                        <th scope="col"></th>
                        <th scope="col">Name</th>
                        <th scope="col">Email</th>
                        <th scope="col">Phone Number</th>
                        <th scope="col">Country</th>
                        <th scope="col">Region</th>
                        <th scope="col">City</th>
                        <th scope="col">Landmark</th>
                        <th scope="col">Created By</th>
                        <th scope="col">Created Date</th>
                        <th scope="col">Active</th>
                        <th scope="col">Deleted</th>
                        <th scope="col" />
                      </tr>
                    </thead>
                    <tbody>
                      {!isEmpty(healthCarefacilityList) ? (
                        healthCarefacilityList?.map((data, index) => {
                          return (
                            <tr key={index} align="center">
                              {/* <td>{data?.id}</td> */}
                              <td>
                                {!isEmpty(data?.images) && (
                                  <a target="_blank" href={showImage(data?.images[0]?.original, 'tpz')}>
                                    <img src={showImage(data?.images[0]?.original, 'tpz')} width={40} height={40} />
                                  </a>
                                )}
                              </td>

                              <td>
                                <Link
                                  to={buildURL(SMKLinks.HEALTH_CARE_DETAILS, {
                                    id: data?.id,
                                  })}>
                                  {data?.name}
                                </Link>
                              </td>
                              <td>{data?.email}</td>
                              <td>{data?.phoneNumber}</td>
                              <td>{data?.address?.country?.displayName}</td>
                              <td>{data?.address?.region?.displayName}</td>
                              <td>{data?.address?.city?.displayName}</td>
                              <td>{data?.address?.landmark}</td>
                              <td>
                                <a href={`/users/profile/${data?.createdById}`}>{data?.createdById}</a>
                              </td>
                              <td>{printDateTimeTwoLines(data?.createdDate)}</td>

                              <td>
                                <Badge className={data?.active ? 'bg-success' : 'bg-danger'}>
                                  {data?.active ? 'Yes' : 'No'}
                                </Badge>
                              </td>
                              <td>
                                <Badge className={data?.deleted ? 'bg-success' : 'bg-danger'}>
                                  {data?.deleted ? 'Yes' : 'No'}
                                </Badge>
                              </td>
                              <td>
                                <UncontrolledDropdown>
                                  <DropdownToggle href="#" className="card-drop " tag="i">
                                    <i role="button" className="mdi mdi-dots-vertical font-size-18 " />
                                  </DropdownToggle>
                                  <DropdownMenu className="dropdown-menu-end">
                                    <DropdownItem onClick={() => handleActiveHealthCareFacility(data)}>
                                      <i
                                        className={`fas  ${
                                          data?.active ? 'fa-ban text-danger' : 'fa-power-off text-success'
                                        }   m-1 pe-3`}
                                      />
                                      {data?.active ? 'Disable' : 'Enable'}
                                    </DropdownItem>
                                    <DropdownItem onClick={() => handleDeleteHealthCareFacility(data)}>
                                      <i className="fas fa-trash-alt text-danger m-1 pe-3" />
                                      {data?.deleted ? 'Undelete' : 'Delete'}
                                    </DropdownItem>
                                    <Divider className="my-2" />

                                    <Link
                                      to={buildURL(SMKLinks.EDIT_HEALTH_CARE, {
                                        id: data?.id,
                                      })}>
                                      <DropdownItem>
                                        <i className="fas fa-pencil-alt text-success m-1 pe-3 " />
                                        Edit
                                      </DropdownItem>
                                    </Link>

                                    <Link
                                      to={buildURL(SMKLinks.HEALTH_CARE_DETAILS, {
                                        id: data?.id,
                                      })}>
                                      <DropdownItem>
                                        <i className="fas fa-eye text-primary m-1 pe-3 " />
                                        Details
                                      </DropdownItem>
                                    </Link>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <TableError />
                      )}
                    </tbody>
                  </Table>
                </div>
              </div>
            </Col>
          </Row>
          <Paginate pageInfo={healthCarefacilityPagination} onChangePage={handleChangePage} />
        </Container>
      </div>
    </>
  );
};

export default HealthCareFacilityList;
