import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import {
  Badge,
  Card,
  CardBody,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Table,
  UncontrolledDropdown,
} from 'reactstrap';

// Import Breadcrumb
import Breadcrumbs from 'components/Common/Breadcrumb';
import Select from 'react-select';
import confirm from 'reactstrap-confirm';

import {
  buildURL,
  checkIfValueExist,
  printDateTime,
  printDateTimeTwoLines,
  printSerialNumbers,
  showImage,
} from 'utils/helper';
import { SMKLinks } from 'utils/links';
import { Divider } from '@material-ui/core';
import { toast } from 'react-toastify';
import config from 'config/config';
import TableError from 'components/TableError';
import { invert, isEmpty } from 'lodash';
import Loader from '../../../components/Loader';
import { useRouter } from '../../../hooks/useRouter';
import useRequest from '../../../hooks/useRequest';
import Paginate from '../../../components/Paginate';
import {
  ACTIVE_AND_DELETED_ARRAY,
  BabyNameGenderValue,
  GENDER,
  GET_GENDER,
  LANGUAGE_VALUES,
  SORT_VALUES,
  USER_GENDER,
  createdByTypeSelect,
} from '../../../utils/constants';

const DoctorsListing = (props) => {
  const router = useRouter();
  const history = useHistory();
  toast.configure();

  const [doctorsList, setdoctorsList] = useState([]);
  const [doctorsPagination, setdoctorsPagination] = useState({});

  const defaultFilters = {
    active: { value: null, label: 'All' },
    deleted: { value: null, label: 'All' },
    createdByType: { value: undefined, label: 'All' },
  };

  const [filters, setFilters] = useState(defaultFilters);

  const [getDoctorsListRequest, { loading: getDoctorsListLoading }] = useRequest({
    url: `/doctor/admin/search`,
    method: 'POST',
    onSuccess: (data) => {
      setdoctorsList(data?.results);
      setdoctorsPagination(data?.pagination);
    },
  });
  const [deleteDoctorRequest, { loading: deleteDoctorLoading }] = useRequest({
    method: 'DELETE',
    onSuccess: (data) => {
      handleChangePage(1);
      toast.success(`Success! Doctor has been deleted.`);
    },
  });
  const [updateDoctorRequest, { loading: updateDoctorLoading }] = useRequest({
    method: 'PUT',
    onSuccess: (data) => {
      handleChangePage(1);
      toast.success(`Success! Doctor has been updated.`);
    },
  });

  const setPageInUrl = (page) => {
    history.push({
      pathname: router.pathname,
      search: `?${new URLSearchParams({ page }).toString()}`,
    });
  };

  const handleChangePage = (page) => {
    setPageInUrl(page);

    getDoctorsListRequest({
      body: {
        page,
        ...SORT_VALUES,
        size: 50,
        active: checkIfValueExist(filters.active?.value),
        deleted: checkIfValueExist(filters.deleted?.value),
        createdBy:
          filters.createdByType?.value !== undefined
            ? {
                type: filters.createdByType?.value,
              }
            : undefined,
      },
    });
  };

  const applyFilters = () => {
    handleChangePage(1);
  };

  const clearFilters = () => {
    setPageInUrl(1);

    setFilters({ ...defaultFilters });
    getDoctorsListRequest({
      body: {
        page: 1,
        size: 50,
        ...defaultFilters,
        active: checkIfValueExist(defaultFilters.active?.value),
        deleted: checkIfValueExist(defaultFilters.deleted?.value),
        createdByType: checkIfValueExist(defaultFilters.createdByType?.value),
        ...SORT_VALUES,
      },
    });
  };

  useEffect(() => {
    handleChangePage(parseInt(router?.query?.page, 10) || 1);
  }, []);

  const handleDeleteDoctor = async (data) => {
    const result = await confirm({
      title: `${data?.deleted ? 'Undelete' : 'Delete'} Doctor`,
      message: `Are you sure, you want to ${data?.deleted ? 'Undelete' : 'Delete'} this Doctor?`,
      confirmText: 'Confirm',
      confirmColor: 'primary',
      cancelColor: 'link text-danger',
    });

    if (result) {
      if (data?.deleted) {
        updateDoctorRequest({
          url: `/doctor/admin/${data?.id}`,
          body: {
            deleted: !data?.deleted,
          },
        });
      } else {
        deleteDoctorRequest({ url: `/doctor/admin/${data.id}` });
      }
    }
  };
  const handleActiveDoctor = (data) => {
    if (data?.active) {
      updateDoctorRequest({
        url: `/doctor/admin/${data?.id}`,
        body: {
          active: false,
        },
      });
    } else {
      updateDoctorRequest({
        url: `/doctor/admin/${data?.id}`,
        body: {
          active: true,
        },
      });
    }
  };

  return (
    <>
      <div className="page-content">
        <Loader isActive={getDoctorsListLoading || deleteDoctorLoading} />
        <Container fluid>
          <Breadcrumbs title="Doctors" breadcrumbItem="Doctors List" />

          <Card>
            <CardBody>
              <div className="mt-4">
                <div className="row">
                  <div className="col-lg-2">
                    <label className="form-label">Active</label>
                    <Select
                      options={ACTIVE_AND_DELETED_ARRAY}
                      value={filters.active}
                      onChange={(value) => {
                        setFilters({ ...filters, active: value });
                      }}
                    />
                  </div>
                  <div className="col-lg-2">
                    <label className="form-label">Deleted</label>
                    <Select
                      options={ACTIVE_AND_DELETED_ARRAY}
                      value={filters.deleted}
                      onChange={(value) => setFilters({ ...filters, deleted: value })}
                    />
                  </div>
                  <div className="col-lg-2">
                    <label className="form-label"> Created By Type</label>
                    <Select
                      options={createdByTypeSelect}
                      value={filters.createdByType}
                      onChange={(value) => setFilters({ ...filters, createdByType: value })}
                    />
                  </div>
                </div>
              </div>

              <div className="mt-4">
                <a className="btn btn-primary me-4" onClick={applyFilters}>
                  Apply Filters
                </a>
                <a className="" onClick={clearFilters}>
                  Clear
                </a>
              </div>
            </CardBody>
          </Card>

          <Row className="mb-2 align-items-center">
            <Col sm="4">
              <div className="search-box me-2 mb-2 d-inline-block">
                <div className="position-relative font-bold">{doctorsPagination?.totalCount} Doctors Found</div>
              </div>
            </Col>
            <Col sm="8">
              <div className="text-sm-end">
                <Link
                  replace
                  className="btn btn-success btn-rounded waves-effect waves-light mb-2 me-2"
                  to={SMKLinks.ADD_DOCTOR}>
                  <i className="mdi mdi-plus me-1" />
                  Add Doctor
                </Link>
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg="12">
              <div className="">
                <div className="table-responsive" style={{ minHeight: 300 }}>
                  <Table className="project-list-table table-centered table-borderless ">
                    <thead>
                      <tr className="table-warning">
                        <th>S.No.</th>
                        <th></th>
                        <th>Doctor Name</th>
                        <th>Gender</th>
                        <th>Email</th>
                        <th>Phone Number</th>
                        <th>Area of expertise</th>
                        <th>Created By</th>
                        <th>Created Date</th>
                        <th>Active</th>
                        <th>Deleted</th>
                        <th />
                      </tr>
                    </thead>
                    <tbody>
                      {!isEmpty(doctorsList) ? (
                        doctorsList?.map((data, index) => {
                          return (
                            <tr key={index}>
                              <td>{printSerialNumbers(doctorsPagination)[index]}</td>
                              <td>
                                {!isEmpty(data?.profileImage) && (
                                  <a target="_blank" href={showImage(data?.profileImage?.original, 'tpz')}>
                                    <img src={showImage(data?.profileImage?.original, 'tpz')} width={40} height={40} />
                                  </a>
                                )}
                              </td>
                              <td>
                                <Link
                                  to={buildURL(SMKLinks.DOCTOR_DETAILS, {
                                    id: data?.id,
                                  })}>
                                  {data?.firstName} {data?.lastName}
                                </Link>
                              </td>
                              <td>{GET_GENDER[data?.gender]}</td>
                              <td>{data?.email}</td>
                              <td>{data?.phoneNumber}</td>
                              <td>{data?.areaOfExpertise}</td>
                              <td>
                                <a href={`/users/profile/${data?.createdById}`}>{data?.createdById}</a>
                              </td>
                              <td>{printDateTimeTwoLines(data?.createdDate)}</td>

                              <td>
                                <Badge className={data?.active ? 'bg-success' : 'bg-danger'}>
                                  {data?.active ? 'Yes' : 'No'}
                                </Badge>
                              </td>
                              <td>
                                <Badge className={data?.deleted ? 'bg-success' : 'bg-danger'}>
                                  {data?.deleted ? 'Yes' : 'No'}
                                </Badge>
                              </td>
                              <td>
                                <UncontrolledDropdown>
                                  <DropdownToggle href="#" className="card-drop " tag="i">
                                    <i role="button" className="mdi mdi-dots-vertical font-size-18 " />
                                  </DropdownToggle>
                                  <DropdownMenu className="dropdown-menu-end">
                                    <DropdownItem onClick={() => handleActiveDoctor(data)}>
                                      <i
                                        className={`fas  ${
                                          data?.active ? 'fa-ban text-danger' : 'fa-power-off text-success'
                                        }   m-1 pe-3`}
                                      />
                                      {data?.active ? 'Disable' : 'Enable'}
                                    </DropdownItem>
                                    <DropdownItem onClick={() => handleDeleteDoctor(data)}>
                                      <i className="fas fa-trash-alt text-danger m-1 pe-3" />
                                      {data?.deleted ? 'Undelete' : 'Delete'}
                                    </DropdownItem>
                                    <Divider className="my-2" />

                                    <Link
                                      to={buildURL(SMKLinks.EDIT_DOCTOR, {
                                        id: data?.id,
                                      })}>
                                      <DropdownItem>
                                        <i className="fas fa-pencil-alt text-success m-1 pe-3 " />
                                        Edit
                                      </DropdownItem>
                                    </Link>
                                    <Link
                                      to={buildURL(SMKLinks.DOCTOR_DETAILS, {
                                        id: data?.id,
                                      })}>
                                      <DropdownItem>
                                        <i className="fas fa-eye text-primary m-1 pe-3 " />
                                        Details
                                      </DropdownItem>
                                    </Link>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <TableError />
                      )}
                    </tbody>
                  </Table>
                </div>
              </div>
            </Col>
          </Row>
          <Paginate pageInfo={doctorsPagination} onChangePage={handleChangePage} />
        </Container>
      </div>
    </>
  );
};

export default DoctorsListing;
