import { Breadcrumbs } from '@material-ui/core';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import 'react-phone-input-2/lib/style.css';
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import Loader from '../../../components/Loader';
import useRequest from '../../../hooks/useRequest';
import { useRouter } from '../../../hooks/useRouter';
import { urlSlugify } from '../../../utils/helper';

const AddElement = () => {
  const router = useRouter();
  toast.configure();

  const [propertyMaster, setPropertyMaster] = useState(null);
  const [element, setElement] = useState(null);
  const [showAddProperty, setShowAddProperty] = useState(false);
  const [selectedProperty, setSelectedProperty] = useState({});

  const [addElementRequest, { loading: addElementLoading }] = useRequest({
    url: '/ptable/admin/element',
    method: 'POST',
    onSuccess: (data) => {
      window.location = '/ptable/elements/list';
      toast.success('Success! Element has been Added.');
    },
    onError: (err) => {
      toast.error(err?.message);
    },
  });

  const [updateElementRequest, { loading: updateElementLoading }] = useRequest({
    method: 'PUT',
    onSuccess: (data) => {
      window.location = `/ptable/element/${element.symbol}`;
      toast.success('Success! Element has been Updated.');
    },
    onError: (err) => {
      toast.error(err?.message);
    },
  });

  const [addElementPropertyRequest, { loading: addElementPropertyLoading }] = useRequest({
    url: '/ptable/admin/createElementProperty/',
    method: 'POST',
    onSuccess: (data) => {
      window.location = `/ptable/element/${element.symbol}`;
      toast.success('Success! Element Property has been Added.');
    },
    onError: (err) => {
      toast.error(err?.message);
    },
  });

  const [updateElementPropertyRequest, { loading: updateElementPropertyLoading }] = useRequest({
    method: 'PUT',
    onSuccess: (data) => {
      toast.success('Success! Element Property has been Updated.');
    },
    onError: (err) => {
      toast.error(err?.message);
    },
  });

  const [getElementRequest, { loading: getElementLoading }] = useRequest({
    method: 'GET',
    onSuccess: (data) => {
      // console.log(data);

      const el = data;

      const properties = {};

      // eslint-disable-next-line no-restricted-syntax
      for (const p of Object.values(el.properties)) {
        const pm = propertyMaster?.find((p1) => p1.property_key === p.property_key);
        properties[p.property_key] = { ...pm, ...p };
      }

      el.properties = properties;

      setElement(el);
    },
  });

  const [getPropertyMasterRequest, { loading: getPropertyMasterLoading }] = useRequest({
    method: 'GET',
    url: `/ptable/admin/getPropertyMaster`,
    onSuccess: (data) => {
      // console.log(data);
      setPropertyMaster(data);
    },
  });

  useEffect(() => {
    if (router?.query?.id) {
      getPropertyMasterRequest();
    }
  }, []);
  useEffect(() => {
    if (propertyMaster) {
      getElementRequest({
        url: `/ptable/admin/getElementDetails/${router?.query?.id}`,
      });
    }
  }, [propertyMaster]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: element
      ? {
          ...element,
        }
      : {
          atomic_number: '',
          category: '',
          phase: '',
          trivial_name: '',
          properties: {},
          active: '',
          deleted: '',
        },
    onSubmit(values) {
      let newValues = { ...values };
      // console.log(values);
      if (element) {
        newValues = { ...element, ...newValues };
        updateElementRequest({
          url: `/ptable/admin/element/${element.id}`,
          data: newValues,
        });
      } else {
        addElementRequest({ data: newValues });
      }
    },
    validationSchema: Yup.object().shape({
      atomic_mass: Yup.string().required('Atomic Mass is requried'),
      category: Yup.string().required('Category is requried'),
      phase: Yup.string().required('Phase is requried'),
    }),
  });

  const handleUpdateProperty = (t) => {
    updateElementPropertyRequest({
      url: `/ptable/admin/updateElementProperty/${t.id}`,
      data: { ...t },
    });
  };

  const handleAddProperty = (e) => {
    e.preventDefault();
    const propName = e.target.propKey.value;
    const propKey = e.target.propKey.value;
    const propValue = e.target.propValue.value;
    const testObj = {};
    testObj[propName] = {
      property_key: propKey,
      value: propValue,
      numeric: false,
    };

    const newObj = { ...formik.values.properties, ...testObj };
    formik.setFieldValue('properties', newObj);
    setShowAddProperty(false);
  };

  const handleEditProperty = (t, val) => {
    const initialData = formik.values.properties;
    const newField = {};
    newField[t.property_key] = {
      id: t.id,
      atomic_number: element.atomic_number,
      main_category: t.main_category,
      property_key: t.property_key,
      property_unit: t.property_unit,
      value: val,
      numeric: t.numeric,
    };
    // console.log(newField);
    const newData = { ...initialData, ...newField };
    // console.log(newData);
    formik.setFieldValue('properties', newData);
  };

  return (
    <div className="page-content">
      <Loader
        isActive={
          getElementLoading ||
          addElementLoading ||
          updateElementLoading ||
          addElementPropertyLoading ||
          getPropertyMasterLoading ||
          updateElementPropertyLoading
        }
      />
      <Container fluid>
        {/* Render Breadcrumbs */}
        <Breadcrumbs title="Elements" breadcrumbItem="Add Element" />

        <Form onSubmit={formik.handleSubmit}>
          <h5 className="mb-4">{element ? 'Update' : 'New'} Element</h5>
          <Card>
            <CardBody>
              <Row>
                <Col md={2}>
                  <FormGroup className="mb-4">
                    <Label htmlFor="atomic_number" className="form-label  ">
                      Atomic Number
                    </Label>
                    <Input
                      id="atomic_number"
                      type="text"
                      className="form-control"
                      disabled
                      value={formik.values.atomic_number}
                    />
                  </FormGroup>
                </Col>
                <Col md={2}>
                  <FormGroup className="mb-4">
                    <Label htmlFor="name" className="form-label  ">
                      Name
                    </Label>
                    <Input id="name" type="text" className="form-control" value={formik.values.name} disabled />
                  </FormGroup>
                </Col>
                <Col md={2}>
                  <FormGroup className="mb-4">
                    <Label htmlFor="symbol" className="form-label  ">
                      Symbol
                    </Label>
                    <Input id="symbol" type="text" className="form-control" disabled value={formik.values.symbol} />
                  </FormGroup>
                </Col>

                <Col md={2}>
                  <FormGroup className="mb-4">
                    <Label htmlFor="symbol" className="form-label  ">
                      Group
                    </Label>
                    <Input id="symbol" type="text" className="form-control" disabled value={formik.values.group} />
                  </FormGroup>
                </Col>

                <Col md={2}>
                  <FormGroup className="mb-4">
                    <Label htmlFor="symbol" className="form-label  ">
                      Period
                    </Label>
                    <Input id="symbol" type="text" className="form-control" disabled value={formik.values.period} />
                  </FormGroup>
                </Col>

                {/* <Col md={2}>
                  <FormGroup className=" mt-4">
                    <ToggleButton
                      label="Active"
                      className="form-control"
                      onChange={(e) => formik.setFieldValue('active', e.target.checked)}
                      checked={formik.values.active}
                    />
                    <Input
                      id="active"
                      type="text"
                      className="form-control"
                      placeholder="Active"
                      invalid={!!(formik.touched.active && formik.errors.active)}
                      {...formik.getFieldProps('active')}
                    />
                    <FormFeedback>{formik.errors.active}</FormFeedback>
                  </FormGroup>
                </Col> */}
              </Row>

              <a
                href={`${process.env.REACT_APP_FRONTEND_BASE_URL}/learn/periodic-table/${urlSlugify(
                  formik.values.symbol
                )}-${urlSlugify(formik.values.name)}`}
                target="_blank"
                rel="noreferrer">
                {formik.values.name} Element Details
              </a>
            </CardBody>
          </Card>

          <h4>Properties</h4>
          <Card>
            <CardBody>
              {/* <div className="d-flex justify-content-between">
                <Label className="form-label  ">Properties:</Label>
                <Button className="success " onClick={() => setShowAddProperty(true)}>
                  Add
                  <i className="fas fa-plus-circle ms-2" />
                </Button>
              </div> */}

              <table className="table table-hover">
                {Object.values(formik.values.properties)
                  .filter((t) => {
                    return !(
                      t.property_key === 'atomic-number' ||
                      t.property_key === 'symbol' ||
                      t.property_key === 'name' ||
                      t.property_key === 'group' ||
                      t.property_key === 'period'
                    );
                  })
                  .sort((a, b) => a.id > b.id)
                  .map((t, i) => {
                    // const pm = propertyMaster?.find((p) => p.property_key === t.property_key);
                    return (
                      <tr key={`_key_${i}`}>
                        <td>{i + 1}</td>
                        <td>{t?.main_category}</td>
                        <td>{t?.property_key}</td>
                        <td>{t?.property_unit}</td>
                        <td>
                          {t.property_key}
                          <Input
                            id={t.property_key}
                            type="text"
                            className="form-control"
                            value={typeof t.value === 'object' ? JSON.stringify(t.value) : t.value}
                            onChange={(e) => handleEditProperty(t, e.target.value)}
                          />
                        </td>
                        <td className="">
                          <div className="mt-3">
                            <a className="btn btn-success" onClick={() => handleUpdateProperty(t)}>
                              Save
                            </a>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
              </table>
            </CardBody>
          </Card>

          {/* {JSON.stringify(formik.errors)} */}

          {/* <Row>
            <Col lg={12} className="mt-2 mb-5">
              <Button type="submit" color="primary">
                Save Element
              </Button>
            </Col>
          </Row> */}
        </Form>
        <Modal
          isOpen={showAddProperty}
          role="dialog"
          autoFocus
          centered
          className="exampleModal"
          tabIndex="-1"
          toggle={() => {
            setShowAddProperty(false);
            setSelectedProperty(null);
          }}>
          <div className="modal-content">
            <ModalHeader
              toggle={() => {
                setShowAddProperty(false);
                setSelectedProperty(null);
              }}>
              Add Property to Element
            </ModalHeader>
            <Form onSubmit={(e) => handleAddProperty(e)}>
              <ModalBody>
                <FormGroup className="mb-4">
                  <Label htmlFor="category" className="form-label  ">
                    Property Key
                  </Label>
                  <Input
                    id="propKey"
                    type="text"
                    className="form-control"
                    placeholder="Property Key"
                    defaultValue={selectedProperty?.propKey}
                  />
                </FormGroup>
                <FormGroup className="mb-4">
                  <Label htmlFor="subCategory" className="form-label  ">
                    Property Value
                  </Label>
                  <Input
                    id="propValue"
                    type="text"
                    className="form-control"
                    placeholder="Property Value"
                    defaultValue={selectedProperty?.propValue}
                  />
                </FormGroup>
              </ModalBody>
              <ModalFooter>
                <Button type="submit" color="primary">
                  Save
                </Button>
              </ModalFooter>
            </Form>
          </div>
        </Modal>
      </Container>
    </div>
  );
};

AddElement.propTypes = {};

export default AddElement;
