import React, { useEffect, useState } from 'react';
import { Card, CardBody, CardTitle } from 'reactstrap';
import { Link } from 'react-router-dom';
import { printCurrencyTooltip, printNumber } from '../../../utils/helper';
import ReactApexChart from 'react-apexcharts';
import { toast } from 'react-toastify';
import useRequest from '../../../hooks/useRequest';

const UsersByCountry = ({ startDate, endDate }) => {
  const [users, setUsers] = useState([]);
  const [chartLabels, setChartLabels] = useState([]);
  const [seriesData, setSeriesData] = useState([]);
  const [getUsersPercentageByCountryRequest, { loading: getUsersPercentageByCountryLoading }] = useRequest({
    url: '/patient-tracking/admin-tools/app-country-percentage',
    method: 'POST',
    onSuccess: (data) => {
      setUsers(data?.results);
    },
    onError: (err) => {
      toast.error(err?.message?.message || err?.message);
    },
  });

  useEffect(() => {
    getUsersPercentageByCountryRequest({
      body: {
        startDate,
        endDate,
      },
    });
  }, [startDate, endDate]);

  useEffect(() => {
    setChartLabels(users.map((e) => e.country));
    setSeriesData([
      {
        data: users?.map((item) => item.percentage),
      },
    ]);
  }, [users]);

  const options = {
    chart: {
      height: 350,
      type: 'bar',
      toolbar: {
        show: true,
      },
    },
    colors: ['#0C9F7E'],
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 5,
      },
    },
    xaxis: {
      type: 'category',
      categories: chartLabels,
      title: {
        text: 'Countries',
        style: {
          fontSize: '12px',
        },
      },
    },
    yaxis: {
      title: {
        text: 'Users %',
        style: {
          fontSize: '14px',
          fontWeight: 'bold',
        },
      },
      min: 0,
      max: 100,
    },
    legend: {
      show: false,
    },
    fill: {
      colors: ['#a8d1fe', '#ffffff'],
    },
    stroke: {
      show: true,
      colors: ['#a8d1fe'],
      width: 1,
    },
  };

  return (
    <>
      <Card>
        <CardBody>
          <CardTitle className="mb-4">% Users By Country</CardTitle>
          {/* <div className="d-flex flex-wrap justify-content-between mt-4 total-by-gender">
            {users?.map((e, i) => {
              return (
                <div className="pe-md-1 mb-md-2 mb-3" key={i}>
                  <div className="widget-metric">
                    <h6 className="widget-metric-title">{e.country}</h6>
                    <h3 className="widget-metric-value">{e.percentage}%</h3>
                  </div>
                </div>
              );
            })}
          </div> */}
          <ReactApexChart options={options} series={seriesData} type="bar" width={400} />
        </CardBody>
      </Card>
    </>
  );
};

export default UsersByCountry;
