import BABY_NAME_LINKS from './links';
import BabynamesList from './BabynamesList';
import { USER_ROLE } from '../../utils/constants';
import AddBabynames from './AddBabyname/AddBabynames';
import SuggestedBabynamesList from './SuggestedBabynames/SuggestedBabynamesList';
import BabynameMetaDataList from './BabynameMetaData/BabynameMetaDataList';
import BabynameSeoMetaDataList from './BabynameSeoMetaData/BabynameSeoMetaDataList';
import BabynamePronunciationList from './BabyNamePronunciation/BabynamePronunciationList';
import BabynameMeaningOriginList from './BabynameMeaningOrigin/BabynameMeaningOriginList';
import BabyNameDetail from './BabyNameDetail/BabyNameDetail';
import UpdateBabyNameMeaningOrigin from './BabynameMeaningOrigin/UpdateBabyNameMeaningOrigin';
import UpdateBabynamePronunciation from './BabyNamePronunciation/UpdateBabynamePronunciation';
import UpdateBabynameSeoMetaData from './BabynameSeoMetaData/UpdateBabynameSeoMetaData';
import UpdateBabyNameMetaData from './BabynameMetaData/UpdateBabyNameMetaData';
import EditBabyname from './EditBabyname/EditBabyname';
import TranslateBabyName from './TranslateBabyname/translateBabyname';
import TranslateBabyNameList from './TranslateBabyname/translateList';

export default [
  {
    path: BABY_NAME_LINKS.BABY_NAME_DETAILS,
    exact: true,
    component: BabyNameDetail,
    role: [USER_ROLE.ADMIN],
  },
  {
    path: BABY_NAME_LINKS.ADD_BABY_NAME,
    exact: true,
    component: AddBabynames,
    role: [USER_ROLE.ADMIN],
  },
  {
    path: BABY_NAME_LINKS.EDIT_BABY_NAME,
    exact: true,
    component: EditBabyname,
    role: [USER_ROLE.ADMIN],
  },
  {
    path: BABY_NAME_LINKS.TRANSLATE_BABY_NAME,
    exact: true,
    component: TranslateBabyName,
    role: [USER_ROLE.ADMIN],
  },
  {
    path: BABY_NAME_LINKS.TRANSLATE_BABY_NAME_LIST,
    exact: true,
    component: TranslateBabyNameList,
    role: [USER_ROLE.ADMIN],
  },
  {
    path: BABY_NAME_LINKS.BABY_NAME_LISTING,
    exact: true,
    component: BabynamesList,
    role: [USER_ROLE.ADMIN],
  },
  {
    path: BABY_NAME_LINKS.SUGGESTED_BABY_NAME_LISTING,
    exact: true,
    component: SuggestedBabynamesList,
    role: [USER_ROLE.ADMIN],
  },
  {
    path: BABY_NAME_LINKS.ADD_BABY_NAME_META_DATA,
    exact: true,
    component: UpdateBabyNameMetaData,
    role: [USER_ROLE.ADMIN],
  },
  {
    path: BABY_NAME_LINKS.EDIT_BABY_NAME_META_DATA,
    exact: true,
    component: UpdateBabyNameMetaData,
    role: [USER_ROLE.ADMIN],
  },
  {
    path: BABY_NAME_LINKS.TRANSLATE_BABY_NAME_META_DATA,
    exact: true,
    component: UpdateBabyNameMetaData,
    role: [USER_ROLE.ADMIN],
  },
  {
    path: BABY_NAME_LINKS.BABY_NAME_META_DATA,
    exact: true,
    component: BabynameMetaDataList,
    role: [USER_ROLE.ADMIN],
  },
  {
    path: BABY_NAME_LINKS.EDIT_BABY_NAME_SEO_META_DATA,
    exact: true,
    component: UpdateBabynameSeoMetaData,
    role: [USER_ROLE.ADMIN],
  },
  {
    path: BABY_NAME_LINKS.ADD_BABY_NAME_SEO_META_DATA,
    exact: true,
    component: UpdateBabynameSeoMetaData,
    role: [USER_ROLE.ADMIN],
  },
  {
    path: BABY_NAME_LINKS.BABY_NAME_SEO_META_DATA,
    exact: true,
    component: BabynameSeoMetaDataList,
    role: [USER_ROLE.ADMIN],
  },
  {
    path: BABY_NAME_LINKS.EDIT_BABY_NAME_PRONUNCIATION,
    exact: true,
    component: UpdateBabynamePronunciation,
    role: [USER_ROLE.ADMIN],
  },
  {
    path: BABY_NAME_LINKS.ADD_BABY_NAME_PRONUNCIATION,
    exact: true,
    component: UpdateBabynamePronunciation,
    role: [USER_ROLE.ADMIN],
  },
  {
    path: BABY_NAME_LINKS.BABY_NAME_PRONUNCIATION,
    exact: true,
    component: BabynamePronunciationList,
    role: [USER_ROLE.ADMIN],
  },
  {
    path: BABY_NAME_LINKS.ADD_BABY_NAME_MEANING_ORIGIN,
    exact: true,
    component: UpdateBabyNameMeaningOrigin,
    role: [USER_ROLE.ADMIN],
  },
  {
    path: BABY_NAME_LINKS.EDIT_BABY_NAME_MEANING_ORIGIN,
    exact: true,
    component: UpdateBabyNameMeaningOrigin,
    role: [USER_ROLE.ADMIN],
  },
  {
    path: BABY_NAME_LINKS.BABY_NAME_MEANING_ORIGIN,
    exact: true,
    component: BabynameMeaningOriginList,
    role: [USER_ROLE.ADMIN],
  },
];
