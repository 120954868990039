import Breadcrumbs from 'components/Common/Breadcrumb';
import Loader from 'components/Loader';
import MiniCards from 'components/MiniCard/mini-card';
import useRequest from 'hooks/useRequest';
import map from 'lodash/map';
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Badge, Card, CardBody, CardTitle, Col, Container, Row, Table } from 'reactstrap';
import { GENDER_VALUES, LANGUAGE_VALUES } from 'utils/constants';
import { buildURL, printDateTime } from 'utils/helper';
import { SMKLinks } from 'utils/links';

const BabyNameDetail = (props) => {
  const { id: babyNameId } = useParams();
  const [originList, setOriginList] = useState([]);
  const [babyDetails, setbabyDetails] = useState([]);

  const [getBabyNameDetails, { loading: getBabyNameLoading }] = useRequest({
    url: `/baby-name/admin/${babyNameId}`,
    method: 'GET',
    onSuccess: (data) => {
      setbabyDetails(data);
    },
    onError: (err) => {
      console.warn(err);
    },
  });

  const [getbabyNameMetaRequest, { loading: getbabyNameMetaLoading }] = useRequest({
    url: `/baby-name/admin/metaInfo/search`,
    method: 'POST',
    onSuccess: (data) => {
      setOriginList(data?.results);
    },
  });

  useEffect(() => {
    getBabyNameDetails();
    getbabyNameMetaRequest({
      body: {
        page: 1,
        size: 1000,
        sortBy: 'id',
        sortOrder: -1,
      },
    });
  }, []);

  const miniCards = [
    {
      title: 'User ID',
      iconClass: 'bxs-user-circle',
      text: `${babyDetails?.id || '-'} `,
    },
    // {
    //   title: 'Approved',
    //   iconClass: 'bx-power-off',
    //   text: `${babyDetails?.approved ? 'Yes' : 'No'}`,
    //   textColor: `${babyDetails?.approved ? 'text-success' : 'text-danger'}`,
    // },
    // {
    //   title: 'Deleted',
    //   iconClass: 'bx-trash',
    //   text: `${babyDetails?.deleted ? 'Yes' : 'No'}`,
    //   textColor: `${babyDetails?.deleted ? 'text-success' : 'text-danger'}`,
    // },
    {
      title: 'View Count',
      iconClass: 'bx-up-arrrow',
      text: `${babyDetails?.viewCount || '-'} `,
    },
    {
      title: 'Favourite Count',
      iconClass: 'bx-heart',
      text: `${babyDetails?.favouriteCount || '-'} `,
    },
  ];

  return (
    <>
      <div className="page-content">
        <Loader isActive={getBabyNameLoading || getbabyNameMetaLoading} />
        <Container fluid>
          <Breadcrumbs title="BabyName" breadcrumbItem="BabyName Detail" />

          <Row>
            <Col xl="4">
              <Card className="overflow-hidden">
                {/* <div className="bg-soft-primary">
                                    <Row>
                                        <Col xs="7">
                                            <div className="text-primary p-3">
                                                <h5 className="text-primary">Welcome {babyDetails?.name} !</h5>
                                                <p>It will seem like simplified</p>
                                            </div>
                                        </Col>
                                        <Col xs="5" className="align-self-end">
                                            <img src={profileBanner} alt="" className="img-fluid" />
                                        </Col>
                                    </Row>
                                </div> */}
                <CardBody className="p-3">
                  <Row className="align-items-center">
                    <Col sm="3">
                      <div className="avatar-md profile-babyDetails-wid m-auto">
                        <i className="bx bxs-user-circle" style={{ fontSize: '70px', color: '#3460BB' }} />
                      </div>
                    </Col>
                    <Col sm="9">
                      <h5 className="font-size-15 text-truncate">{babyDetails?.name}</h5> ({babyDetails?.id || '-'})
                      <p className="text-muted mb-0 text-truncate">{babyDetails?.gender?.name}</p>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  <CardTitle className="py-3 card-title text-center bg-light mb-0">Publish Status</CardTitle>

                  <div className="table-responsive" style={{ minHeight: 200 }}>
                    <Table className="table-nowrap mb-0">
                      <tbody>
                        <tr>
                          <th>Published :</th>
                          <td>
                            <Badge className={babyDetails?.published ? 'bg-success' : 'bg-danger'}>
                              {babyDetails?.published ? 'Yes' : 'No'}
                            </Badge>
                          </td>
                        </tr>
                        <tr>
                          <th>Active :</th>
                          <td>
                            <Badge className={babyDetails?.active ? 'bg-success' : 'bg-danger'}>
                              {babyDetails?.active ? 'Yes' : 'No'}
                            </Badge>
                          </td>
                        </tr>
                        <tr>
                          <th>Deleted :</th>
                          <td>
                            <Badge className={babyDetails?.deleted ? 'bg-success' : 'bg-danger'}>
                              {babyDetails?.deleted ? 'Yes' : 'No'}
                            </Badge>
                          </td>
                        </tr>
                        <tr>
                          <th>Created Date :</th>
                          <td>{printDateTime(babyDetails?.createdDate)}</td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  <CardTitle className="py-3 card-title text-center bg-light mb-0">Basic Information</CardTitle>
                  <p className="text-muted mb-4 mt-3">
                    {babyDetails?.description ? ` Description : ${babyDetails?.description}` : ''}
                  </p>
                  <div className="table-responsive" style={{ minHeight: 200 }}>
                    <Table className="table-nowrap mb-0">
                      <tbody>
                        <tr>
                          <th>Name :</th>
                          <td>{babyDetails?.name}</td>
                        </tr>
                        <tr>
                          <th>Language :</th>
                          <td>{LANGUAGE_VALUES[babyDetails?.gender?.language]}</td>
                        </tr>
                        <tr>
                          <th>Meaning :</th>
                          <td>
                            <div className="text-truncate" style={{ width: '200px' }}>
                              {babyDetails?.meaning}
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <th>Gender :</th>
                          <td>{babyDetails?.gender?.name}</td>
                        </tr>
                        <tr>
                          <th>Religion :</th>
                          <td>
                            {babyDetails?.religions?.length > 1
                              ? babyDetails?.religions?.map((e) => `${e?.name}, `)
                              : babyDetails?.religions?.map((e) => e?.name)}
                          </td>
                        </tr>
                        <tr>
                          <th>Origins :</th>
                          <td>
                            {babyDetails?.origins?.length > 1
                              ? babyDetails?.origins?.map((e) => `${e?.name}, `)
                              : babyDetails?.origins?.map((e) => e?.name)}
                          </td>
                        </tr>

                        <tr>
                          <th>Nakshatra :</th>
                          <td>{babyDetails?.nakshatra?.name}</td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>

            <Col xl="8">
              <Row>
                {map(miniCards, (card, key) => (
                  <MiniCards
                    title={card?.title}
                    text={card?.text}
                    textColor={card?.textColor}
                    iconClass={card?.iconClass}
                    key={`_card_${key}`}
                  />
                ))}
              </Row>
              <Card>
                <CardBody>
                  <CardTitle className="py-3 card-title text-center bg-light mb-4">More Information </CardTitle>
                  <Row>
                    <Col xl="12">
                      <div className="table-responsive" style={{ minHeight: 300 }}>
                        <table className="table">
                          <tbody>
                            <tr>
                              <th>Meanings :</th>
                              <td>
                                {babyDetails?.meanings?.length > 1
                                  ? babyDetails?.meanings?.map((e) => `${e?.name}, `)
                                  : babyDetails?.meanings?.map((e) => e?.name)}
                              </td>
                            </tr>

                            <tr>
                              <th>Spell :</th>
                              <td>{babyDetails?.spell || '-'}</td>
                            </tr>
                            <tr>
                              <th>Syllable :</th>
                              <td>{babyDetails?.syllable || '-'}</td>
                            </tr>
                            <tr>
                              <th>Phonetic :</th>
                              <td>{babyDetails?.phonetic || '-'}</td>
                            </tr>
                            <tr>
                              <th>Pronunciation :</th>
                              <td>{babyDetails?.pronunciation || '-'}</td>
                            </tr>

                            <tr>
                              <th>Word Count :</th>
                              <td>{babyDetails?.wordCount}</td>
                            </tr>
                            <tr>
                              <th>Character Count :</th>
                              <td>{babyDetails?.characterCount}</td>
                            </tr>
                            <tr>
                              <th>Syllable Count :</th>
                              <td>{babyDetails?.syllableCount}</td>
                            </tr>
                            <tr>
                              <th>Pythagorean Numerology :</th>
                              <td>{babyDetails?.pythagoreanNumerology}</td>
                            </tr>
                            <tr>
                              <th>Indian Numerology :</th>
                              <td>{babyDetails?.indianNumerology}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row className="mt-2">
            <Col xl="12">
              <Card>
                <CardBody>
                  <CardTitle className="py-3 card-title text-center bg-light mb-0">Meaning Origins</CardTitle>
                  <div className="table-responsive" style={{ minHeight: 200 }}>
                    <Table className="table-nowrap mb-0">
                      <thead>
                        <tr>
                          <th>#ID</th>
                          <th>Baby Name</th>
                          <th>Gender</th>
                          <th>Origin</th>
                          <th>Pronounced</th>
                          <th>Script</th>
                          <th>Source</th>
                          <th>Country</th>
                        </tr>
                      </thead>
                      <tbody>
                        {babyDetails?.meaningOrigins?.map((meaningOrigin) => (
                          <tr>
                            <td>{meaningOrigin?.id}</td>
                            <td>
                              <Link
                                to={buildURL(SMKLinks.EDIT_BABY_NAME_MEANING_ORIGIN, {
                                  id: meaningOrigin?.id,
                                })}>
                                {babyDetails?.name}
                              </Link>
                            </td>
                            <td>{GENDER_VALUES[meaningOrigin?.gender]}</td>
                            <td>{originList?.find((e) => e?.id === meaningOrigin?.originId)?.name}</td>
                            <td>{meaningOrigin?.pronounced}</td>
                            <td>{meaningOrigin?.script}</td>
                            <td>{meaningOrigin?.source}</td>
                            <td>{meaningOrigin?.countryId}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

BabyNameDetail.propTypes = {};

export default BabyNameDetail;
