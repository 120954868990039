import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';
import { createStructuredSelector } from 'reselect';
import { useLocation } from 'react-router';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import IconButton from '@material-ui/core/IconButton';
import SvgIcon from '@material-ui/core/SvgIcon';
import Modal from '@material-ui/core/Modal';
import isEmpty from 'lodash/isEmpty';
import { createTheme, Grid, TextField } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import InputAdornment from '@material-ui/core/InputAdornment';
import Button from '@material-ui/core/Button';
import { urlSlugify } from '../../../utils/helper';
import { createCategoryDoneSelector, workSheetsSubjectsSelector, workSheetsTopicsSelector } from '../selectors';
import { getWorksheetSubjectTopics, createWorksheetCategory } from '../actions';
import saga from '../saga';
import reducer from '../reducer';

// import DateFnsUtils from '@date-io/date-fns';

const theme = createTheme();

const useStyles = makeStyles(() => ({
  root: {
    padding: theme?.spacing(3),
  },

  tabs: {
    background: '#f7f7f7',
  },

  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme?.spacing(2, 4, 3),
  },
  formControl: {
    marginTop: theme?.spacing(1),
    marginBottom: theme?.spacing(1),
    width: '100%',
  },
}));

const useTreeItemStyles = makeStyles(() => ({
  root: {
    color: theme.palette.text.secondary,
    '&:hover > $content': {
      backgroundColor: theme.palette.action.hover,
    },
    '&:focus > $content, &$selected > $content': {
      backgroundColor: `var(--tree-view-bg-color, ${theme.palette.grey[400]})`,
      color: 'var(--tree-view-color)',
    },
    '&:focus > $content $label, &:hover > $content $label, &$selected > $content $label': {
      backgroundColor: 'transparent',
    },

    // width: 600,
  },
  content: {
    color: theme.palette.text.secondary,
    // borderTopRightRadius: theme.spacing(2),
    // borderBottomRightRadius: theme.spacing(2),
    paddingRight: theme.spacing(1),
    fontWeight: theme.typography.fontWeightMedium,
    '$expanded > &': {
      fontWeight: theme.typography.fontWeightRegular,
    },
  },
  group: {
    marginLeft: theme.spacing(2),
    '& $content': {
      paddingLeft: theme.spacing(2),
    },
  },
  expanded: {},
  selected: {},
  label: {
    fontWeight: 'inherit',
    color: 'inherit',
  },
  labelRoot: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0.5, 0),
  },
  labelIcon: {
    marginRight: theme.spacing(1),
  },
  labelText: {
    fontWeight: 400,
    fontSize: 16,
    flexGrow: 1,
  },
  labelSubText: {
    color: '#aaaaaa',
    marginLeft: theme.spacing(1),
  },
}));

const stateSelector = createStructuredSelector({
  subjectList: workSheetsSubjectsSelector,
  topicList: workSheetsTopicsSelector,
  createCategoryDone: createCategoryDoneSelector,
});

function StyledTreeItem(props) {
  const classes = useTreeItemStyles();
  const { topic, setSelectedTopic, color, bgColor, ...other } = props;

  const topicAdd = (e, topicObj) => {
    e.preventDefault();
    setSelectedTopic(topicObj);
  };

  return (
    // <TreeItem
    //   label={
    //     <div className={classes.labelRoot}>
    //       <Typography variant="body" className={classes.labelText}>
    //         {topic.name}{' '}
    //         <Typography variant="body" className={classes.labelSubText}>
    //           {topic.slug} - {topic.id}
    //         </Typography>
    //       </Typography>

    //       <Typography variant="caption" color="inherit">
    //         <IconButton color="primary" onClick={(event) => topicAdd(event, topic)}>
    //           <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
    //             {/* tslint:disable-next-line: max-line-length */}
    //             <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 12.977h-4.923v4.896q0 .401-.281.682t-.682.281v0q-.375 0-.669-.281t-.294-.682v-4.896h-4.923q-.401 0-.682-.294t-.281-.669v0q0-.401.281-.682t.682-.281h4.923v-4.896q0-.401.294-.682t.669-.281v0q.401 0 .682.281t.281.682v4.896h4.923q.401 0 .682.281t.281.682v0q0 .375-.281.669t-.682.294z" />
    //           </SvgIcon>
    //         </IconButton>
    //       </Typography>
    //     </div>
    //   }
    //   style={{
    //     '--tree-view-color': color,
    //     '--tree-view-bg-color': bgColor,
    //   }}
    //   classes={{
    //     root: classes.root,
    //     content: classes.content,
    //     expanded: classes.expanded,
    //     selected: classes.selected,
    //     group: classes.group,
    //     label: classes.label,
    //   }}
    //   {...other}
    // />
    <></>
  );
}

StyledTreeItem.propTypes = {
  topic: PropTypes.object.isRequired,
  setShowTopicAdd: PropTypes.func.isRequired,
};

function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  const top = 50 + rand();
  const left = 50 + rand();

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const WorksheetCategories = (props) => {
  const classes = useStyles();

  // useInjectReducer({ key, reducer });
  // useInjectSaga({ key, saga });
  const dispatch = useDispatch();
  const location = useLocation();
  const query = queryString.parse(props.location.search);
  const { subjectList, topicList, createCategoryDone } = useSelector(stateSelector);

  const [value, setValue] = useState({});
  const [modalStyle] = useState(getModalStyle);

  const [selectedTopic, setSelectedTopic] = useState({});
  const [showTopicAdd, setShowTopicAdd] = useState(false);
  const [showParentTopicAdd, setShowParentTopicAdd] = useState(false);
  const [newTopic, setNewTopic] = useState({ title: '', slug: '' });

  const handleTextChange = (prop) => (event) => {
    let newObj = { ...newTopic };
    newObj = { ...newObj, [prop]: event.target.value };
    if (prop === 'title') {
      newObj = { ...newObj, slug: urlSlugify(event.target.value) };
    }
    setNewTopic(newObj);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const onTopicSelectHandler = (topic) => {
    console.log('topic', topic);
    setSelectedTopic(topic);
    setShowTopicAdd(true);
  };

  const submitParentTopicForm = (e) => {
    const payload = {
      name: newTopic.title,
      slug: newTopic.slug,
      subject: value,
    };
    dispatch(createWorksheetCategory.trigger({ payload }));
  };

  const submitForm = (e) => {
    e.preventDefault();
    const payload = {
      name: newTopic.title,
      slug: `${newTopic.slug}-${selectedTopic.rootTopic ? selectedTopic.rootTopic.slug : selectedTopic.slug}`,
      subject: selectedTopic.subject,
      parentTopic: selectedTopic,
      rootTopic: selectedTopic.rootTopic ? selectedTopic.rootTopic : selectedTopic,
    };
    dispatch(createWorksheetCategory.trigger({ payload }));
  };

  useEffect(() => {
    dispatch(getWorksheetSubjectTopics.trigger());
  }, [location]);

  useEffect(() => {
    if (createCategoryDone) {
      dispatch(getWorksheetSubjectTopics.trigger());
      setShowTopicAdd(false);
      setShowParentTopicAdd(false);
      setNewTopic({ title: '', slug: '' });
    }
  }, [createCategoryDone]);

  return (
    <Card className={classes.root}>
      <CardHeader title="WorkSheet Categories" />
      <CardContent style={{ flex: 1 }}>
        <Tabs
          value={value}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleChange}
          aria-label="disabled tabs example"
          className={classes.tabs}>
          {subjectList &&
            subjectList.map((s) => {
              return <Tab label={s.name} value={s} />;
            })}
        </Tabs>

        {/* <TreeView
          className={classes.root}
          defaultExpanded={['3']}
          defaultCollapseIcon={<ArrowDropDownIcon />}
          defaultExpandIcon={<ArrowRightIcon />}
          defaultEndIcon={<div style={{ width: 24 }} />}>
          {!isEmpty(value) && (
            <div style={{ float: 'right', marginBottom: '10px' }}>
              <Button
                size="small"
                color="primary"
                variant="contained"
                style={{ marginLeft: '5px', padding: '5px' }}
                onClick={() => setShowParentTopicAdd(true)}>
                Add Parent Category
              </Button>
            </div>
          )}
          {topicList &&
            topicList
              .filter((t) => t.subject.id === value.id && !t.parentTopic)
              .map((t) => {
                return (
                  <StyledTreeItem nodeId={t.id} topic={t} setSelectedTopic={onTopicSelectHandler}>
                    {topicList
                      .filter((t1) => t1.parentTopic && t1.parentTopic.id === t.id)
                      .map((t1) => {
                        return (
                          <StyledTreeItem nodeId={t1.id} topic={t1} setSelectedTopic={onTopicSelectHandler}>
                            {topicList
                              .filter((t2) => t2.parentTopic && t2.parentTopic.id === t1.id)
                              .map((t2) => {
                                return (
                                  <StyledTreeItem nodeId={t2.id} topic={t2} setSelectedTopic={onTopicSelectHandler}>
                                    {topicList
                                      .filter((t3) => t3.parentTopic && t3.parentTopic.id === t2.id)
                                      .map((t3) => {
                                        return (
                                          <StyledTreeItem
                                            nodeId={t3.id}
                                            topic={t3}
                                            setSelectedTopic={onTopicSelectHandler}>
                                            {topicList
                                              .filter((t4) => t4.parentTopic && t4.parentTopic.id === t3.id)
                                              .map((t4) => {
                                                return (
                                                  <StyledTreeItem
                                                    nodeId={t4.id}
                                                    topic={t4}
                                                    setSelectedTopic={onTopicSelectHandler}
                                                  />
                                                );
                                              })}
                                          </StyledTreeItem>
                                        );
                                      })}
                                  </StyledTreeItem>
                                );
                              })}
                          </StyledTreeItem>
                        );
                      })}
                  </StyledTreeItem>
                );
              })}
        </TreeView> */}
      </CardContent>

      <Modal
        open={showTopicAdd}
        onClose={() => setShowTopicAdd(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description">
        <div style={modalStyle} className={classes.paper}>
          <h2>Add a new topic</h2>
          <FormControl className={classes.formControl}>
            <TextField
              className={classes.textField}
              fullWidth
              label="Title"
              type="text"
              variant="outlined"
              value={newTopic.title}
              onChange={handleTextChange('title')}
              helperText="Please provide the topic title"
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <TextField
              className={classes.textField}
              fullWidth
              label="Slug"
              type="text"
              variant="outlined"
              value={newTopic.slug}
              onChange={handleTextChange('slug')}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {'-'}
                    {!isEmpty(selectedTopic) && selectedTopic.rootTopic
                      ? selectedTopic.rootTopic.slug
                      : selectedTopic.slug}
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>
          <div style={{ float: 'right' }}>
            <Button
              size="small"
              color="primary"
              variant="contained"
              style={{ marginLeft: '5px', padding: '5px' }}
              onClick={submitForm}>
              Create
            </Button>
          </div>
        </div>
      </Modal>

      <Modal
        open={showParentTopicAdd}
        onClose={() => setShowTopicAdd(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description">
        <div style={modalStyle} className={classes.paper}>
          <h2>Add a parent topic</h2>
          <FormControl className={classes.formControl}>
            <TextField
              className={classes.textField}
              fullWidth
              label="Title"
              type="text"
              variant="outlined"
              value={newTopic.title}
              onChange={handleTextChange('title')}
              helperText="Please provide the topic title"
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <TextField
              className={classes.textField}
              fullWidth
              label="Slug"
              type="text"
              variant="outlined"
              value={newTopic.slug}
              onChange={handleTextChange('slug')}
            />
          </FormControl>
          <div style={{ float: 'right' }}>
            <Button
              size="small"
              color="primary"
              variant="contained"
              style={{ marginLeft: '5px', padding: '5px' }}
              onClick={submitParentTopicForm}>
              Create
            </Button>
          </div>
        </div>
      </Modal>
    </Card>
  );
};

export default WorksheetCategories;
