import React from 'react';
import TpzTaxonomyListing from './components/tpzTaxonomyList';
import SmkTaxonomyListing from './components/smkTaxonomyList';
import { useRouter } from 'hooks/useRouter';

const TaxonomyListing = () => {
  const router = useRouter();
  const isTpzRoute = router?.location?.pathname?.includes('tpz/taxonomy');

  return isTpzRoute ? <TpzTaxonomyListing /> : <SmkTaxonomyListing />;
};

export default TaxonomyListing;
