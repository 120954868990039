import PropTypes from 'prop-types';
import React from 'react';

import { connect } from 'react-redux';
import { Switch } from 'react-router-dom';

// Import Routes all
import { authRoutes, userRoutes } from './routes/allRoutes';

// Import all middleware
import Authmiddleware from './routes/middleware/Authmiddleware';

// layouts Format
import { Route } from 'react-router-dom';
import NonAuthLayout from './components/NonAuthLayout';
import VerticalLayout from './components/VerticalLayout';

// Import scss
import './assets/scss/theme.scss';

// Import Firebase Configuration file

import fakeBackend from './helpers/AuthType/fakeBackend';

// Activating fake backend
fakeBackend();

const firebaseConfig = {};

// init firebase backend
// initFirebaseBackend(firebaseConfig)

const App = (props) => {
  function getLayout() {
    const layoutCls = VerticalLayout;

    // switch (props.layout.layoutType) {
    //   case 'horizontal':
    //     layoutCls = HorizontalLayout;
    //     break;
    //   default:
    //     layoutCls = VerticalLayout;
    //     break;
    // }
    return layoutCls;
  }

  const Layout = getLayout();
  return (
    <>
      {/* <Router> */}
      <Switch>
        {authRoutes.map((route, idx) => (
          <Authmiddleware
            path={route.path}
            layout={NonAuthLayout}
            component={route.component}
            key={idx}
            isAuthProtected={false}
          />
        ))}

        {userRoutes.map((route, idx) => (
          <Authmiddleware
            path={route.path}
            layout={Layout}
            component={route.component}
            key={idx}
            isAuthProtected
            exact
          />
        ))}

        <Route
          render={() => (
            <div className="d-flex align-items-center justify-content-center vh-100">
              <div className="text-center">
                <h1 className="display-1 fw-bold text-dark">404</h1>
                <p className="fs-3">
                  <span className="text-danger">Opps!</span> Page not found.
                </p>
                <p className="lead">The page you’re looking for doesn’t exist.</p>
                <a href="/dashboard" className="btn btn-primary">
                  Go Home
                </a>
              </div>
            </div>
          )}
        />
      </Switch>
      {/* </Router> */}
    </>
  );
};

App.propTypes = {
  layout: PropTypes.any,
};

const mapStateToProps = (state) => {
  return {
    layout: state.Layout,
  };
};

export default connect(mapStateToProps, null)(App);
