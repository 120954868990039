import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import map from 'lodash/map';
import {
  Badge,
  Card,
  CardBody,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Label,
  Row,
  Table,
  UncontrolledDropdown,
} from 'reactstrap';

// Import Breadcrumb
import Breadcrumbs from 'components/Common/Breadcrumb';

// Import Image
import { createStructuredSelector } from 'reselect';
import { useRouter } from 'hooks/useRouter';
import { isEmpty } from 'lodash';
import TableError from 'components/TableError';
import useRequest from 'hooks/useRequest';
import Loader from 'components/Loader';
import { Divider } from '@material-ui/core';
import { toast } from 'react-toastify';
import {
  ACTION_TAKEN_ARRAY,
  ACTIVE_AND_DELETED_ARRAY,
  LANGUAGE_VALUES,
  TOPIC_STATUS_STING,
} from '../../../utils/constants';
import { SMKLinks } from '../../../utils/links';
import { buildURL, printDateTimeTwoLines } from '../../../utils/helper';
import Paginate from '../../../components/Paginate';
import { topicListPaginationSelector, topicListSelector } from '../selectors';
import { getForumTopic } from '../actions';
import Select from 'react-select';

const stateSelector = createStructuredSelector({
  topics: topicListSelector,
  topicsPagination: topicListPaginationSelector,
});

const TopicsList = (props) => {
  const dispatch = useDispatch();
  const router = useRouter();
  const history = useHistory();

  const { topics, topicsPagination } = useSelector(stateSelector);
  toast.configure();

  const [markApproveAndUnpublishRequest, { loading: markApproveAndUnpublishLoading }] = useRequest({
    method: 'PUT',
    onSuccess: (data) => {
      handleChangePage(parseInt(router?.query?.page, 10) || 1);
      toast.success(`Success! Topic has been ${data?.status !== 0 ? 'UnPublished' : 'Approved'}.`);
    },
  });

  const [deleteUnDeleteRequest, { loading: deleteUnDeleteLoading }] = useRequest({
    method: 'PUT',
    onSuccess: (data) => {
      handleChangePage(parseInt(router?.query?.page, 10) || 1);
      toast.success(`Success!  Topic has been ${data?.deleted ? 'deleted' : 'Undeleted'}.`);
    },
  });

  const defaultFilters = {
    active: null,
    deleted: null,
    title: '',
    id: '',
  };

  const [filters, setFilters] = useState(defaultFilters);
  const [sort, setSort] = useState({
    sortBy: 'id',
    sortOrder: -1,
  });

  const setPageInUrl = (page) => {
    history.push({
      pathname: router.pathname,
      search: `?${new URLSearchParams({ page }).toString()}`,
    });
  };

  const handleChangePage = (page) => {
    setPageInUrl(page);
    dispatch(
      getForumTopic.trigger({
        page,
        size: 20,
        ...filters,
        ...sort,
        active: filters?.active?.value,
        deleted: filters?.deleted?.value,
      })
    );
  };

  const applyFilters = () => {
    handleChangePage(parseInt(router?.query?.page, 10) || 1);
  };

  const clearFilters = () => {
    setFilters({
      ...defaultFilters,
    });
    dispatch(getForumTopic.trigger({ page: 1, size: 20, ...defaultFilters, ...sort }));
  };

  useEffect(() => {
    handleChangePage(parseInt(router?.query?.page, 10) || 1);
  }, []);

  const topicStatusColors = {
    0: 'bg-warning',
    1: 'bg-success',
    2: 'bg-danger',
  };

  const handleApprove = (data) => {
    data?.status !== 0
      ? markApproveAndUnpublishRequest({
          url: `/forum/admin/topic/unpublish`,
          body: {
            id: data?.id,
          },
        })
      : markApproveAndUnpublishRequest({
          url: `/forum/admin/topic/approve`,
          body: {
            id: data?.id,
            status: 1,
            withReAssignment: true,
            comments: data?.comments,
          },
        });
  };
  const handleDelete = (data) => {
    deleteUnDeleteRequest({
      url: `/forum/admin/topic/delete`,
      body: {
        id: data?.id,
        deleted: !data?.deleted,
      },
    });
  };

  return (
    <>
      <div className="page-content">
        <Loader isActive={deleteUnDeleteLoading || markApproveAndUnpublishLoading} />
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Topics" breadcrumbItem="Topics List" />

          <Card>
            <CardBody>
              <div className="mt-4">
                <div className="row">
                  <div className="col-lg-2 mb-4">
                    <label className="form-label">Active</label>
                    <Select
                      options={ACTIVE_AND_DELETED_ARRAY}
                      value={filters.active}
                      onChange={(value) => setFilters({ ...filters, active: value })}
                    />
                  </div>
                  <div className="col-lg-2 mb-4">
                    <label className="form-label">Deleted</label>
                    <Select
                      options={ACTIVE_AND_DELETED_ARRAY}
                      value={filters.deleted}
                      onChange={(value) => setFilters({ ...filters, deleted: value })}
                    />
                  </div>

                  <div className="col-lg-3 mb-4">
                    <label className="form-label">Topic ID</label>
                    <Input
                      type="number"
                      placeholder="Topic ID..."
                      value={filters.id}
                      onChange={(e) => setFilters({ ...filters, id: e?.target?.value })}
                    />
                  </div>
                  <div className="col-lg-3 mb-4">
                    <label className="form-label">Title</label>
                    <Input
                      type="text"
                      placeholder="Title..."
                      value={filters.title}
                      onChange={(e) => setFilters({ ...filters, title: e?.target?.value })}
                    />
                  </div>
                </div>
              </div>

              <div className="mt-4">
                <a className="btn btn-primary me-4" onClick={applyFilters}>
                  Apply Filters
                </a>
                <a className="" onClick={clearFilters}>
                  Clear
                </a>
              </div>
            </CardBody>
          </Card>

          <Row className="mb-2">
            <Col sm="4">
              <div className="search-box me-2 mb-2 d-inline-block">
                <div className="position-relative font-bold">{topicsPagination?.totalCount} Topics Found</div>
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg="12">
              <div className="">
                <div className="table-responsive" style={{ minHeight: 300 }}>
                  <Table className="project-list-table table-nowrap table-centered table-borderless">
                    <thead>
                      <tr className="table-warning">
                        <th scope="col">Topic Id</th>
                        <th scope="col">
                          Title
                          <br /> Slug
                        </th>
                        <th scope="col">Created By</th>
                        <th scope="col">Created On</th>
                        <th scope="col">
                          View <br /> Count
                        </th>
                        <th scope="col">
                          Follow <br /> Count
                        </th>
                        <th scope="col">Language</th>
                        <th scope="col">Status</th>
                        <th scope="col">Is Featured</th>
                        <th scope="col">Active</th>
                        <th scope="col">Deleted</th>
                        <th scope="col" />
                      </tr>
                    </thead>
                    <tbody>
                      {!isEmpty(topics) ? (
                        map(topics, (topic, index) => {
                          return (
                            <React.Fragment key={index}>
                              <tr>
                                <td>
                                  <Link
                                    to={buildURL(SMKLinks.TOPIC_DETAIL, {
                                      id: topic.id,
                                    })}>
                                    {topic.id}
                                  </Link>
                                </td>

                                <td>
                                  <Link
                                    to={buildURL(SMKLinks.TOPIC_EDIT, {
                                      id: topic?.id,
                                    })}
                                    className="text-primary">
                                    {topic.title}
                                  </Link>
                                  <br />
                                  {topic.slug}
                                </td>
                                <td>
                                  <div className="text-truncate ">
                                    {topic.createdBy ? topic.createdBy.firstName : ''}
                                  </div>
                                </td>
                                <td>{printDateTimeTwoLines(topic.createdDate)}</td>
                                <td>{topic?.viewCount}</td>
                                <td>{topic?.followCount}</td>
                                <td>
                                  <Badge className={`${topic?.language === 0 ? 'bg-info' : 'bg-warning'} p-1`}>
                                    {LANGUAGE_VALUES[topic?.language]}
                                  </Badge>
                                </td>
                                <td>
                                  <Badge className={` ${topicStatusColors[topic?.status]}`}>
                                    {TOPIC_STATUS_STING[topic.status]}
                                  </Badge>
                                </td>
                                <td align="center">
                                  <Badge className={topic?.isFeatured ? 'bg-success' : 'bg-danger'}>
                                    {topic?.isFeatured ? 'Yes' : 'No'}
                                  </Badge>
                                </td>
                                <td>
                                  <Badge className={topic?.active ? 'bg-success' : 'bg-danger'}>
                                    {topic?.active ? 'Yes' : 'No'}
                                  </Badge>
                                </td>
                                <td>
                                  <Badge className={topic?.deleted ? 'bg-success' : 'bg-danger'}>
                                    {topic?.deleted ? 'Yes' : 'No'}
                                  </Badge>
                                </td>
                                <td>
                                  <UncontrolledDropdown>
                                    <DropdownToggle href="#" className="card-drop cursor-pointer" tag="i">
                                      <i role="button" className="mdi mdi-dots-vertical font-size-18 " />
                                    </DropdownToggle>
                                    <DropdownMenu className="dropdown-menu-end">
                                      <DropdownItem onClick={() => handleApprove(topic)}>
                                        <i
                                          className={`fas ${
                                            topic?.status !== 0
                                              ? 'fa-trash-alt text-danger'
                                              : 'fa-power-off text-success'
                                          }     m-1 pe-3`}
                                        />
                                        {topic?.status !== 0 ? 'Unpublish' : 'Approve'}
                                      </DropdownItem>

                                      {!topic?.deleted && (
                                        <DropdownItem onClick={() => handleDelete(topic)}>
                                          <i
                                            className={`fas fa-trash-alt ${
                                              topic?.deleted ? 'text-success' : 'text-danger'
                                            } m-1 pe-3`}
                                          />
                                          {topic?.deleted ? 'Undelete' : 'Delete'}
                                        </DropdownItem>
                                      )}

                                      <Divider className="my-2" />

                                      <DropdownItem
                                        href={buildURL(SMKLinks.TOPIC_DETAIL, {
                                          id: topic.id,
                                        })}>
                                        <i className="fas fa-eye text-primary m-1 pe-3 " />
                                        Details
                                      </DropdownItem>
                                      <DropdownItem
                                        href={buildURL(SMKLinks.TOPIC_EDIT, {
                                          id: topic.id,
                                        })}>
                                        <i className="fas fa-pencil-alt text-success m-1 pe-3 " />
                                        Edit
                                      </DropdownItem>
                                    </DropdownMenu>
                                  </UncontrolledDropdown>
                                </td>
                              </tr>
                              {!isEmpty(JSON.parse(topic?.jsonContent)) && (
                                <tr>
                                  <td></td>
                                  <td colSpan={2} style={{ maxWidth: 500, whiteSpace: 'pre-wrap' }}>
                                    <Label className="form-label border-bottom fw-bold">Description</Label>
                                    <h6 className="mb-0 mt-1" style={{ lineHeight: '20px' }}>
                                      {JSON.parse(topic?.jsonContent)?.blocks[0]?.text}
                                    </h6>
                                  </td>
                                  <td colSpan={20}></td>
                                </tr>
                              )}
                            </React.Fragment>
                          );
                        })
                      ) : (
                        <TableError />
                      )}
                    </tbody>
                  </Table>
                </div>
              </div>
            </Col>
          </Row>
          <Paginate pageInfo={topicsPagination} onChangePage={handleChangePage} />
        </Container>
      </div>
    </>
  );
};

export default TopicsList;
