export const AUTH_TOKEN_LS_KEY = 'adminAuthToken';

export const SCHOOL_EDUCATION = 'School Education';

// Regex for validations
// export const NAME_REGEX = /^[A-Za-z\s]{1,}[.]{0,1}[A-Za-z\s]{0,}$/;
export const NAME_REGEX = /^[a-zA-Z'-. ]+$/;
export const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const PHONE_REGEX = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
export const NUMBER_REGEX = /^[0-9]*$/;
export const LATITUDE_REGEX = /^-?([1-8]?[1-9]|[1-9]0)\.{1}\d{1,6}/g;
export const LONGITUDE_REGEX = /^-?([1-8]?[1-9]|[1-9]0)\.{1}\d{1,6}/g;
export const WEBSITE_REGEX = /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm;

export const DIGITS_REGEX = /[0-9]/;
// password regex
export const INCLUDES_UPPERCASE = /(?=.*?[A-Z])/;
export const INCLUDES_NUMBER = /(?=.*?[0-9])/;
export const INCLUDES_SYMBOL = /(?=.*?[#?!@$%^&*-])/;

export const POWERED_BY = 'Powered by SchoolMyKids Technologies';
export const BRAND_NAME = 'SchoolMyKids';
export const BRAND_TAG_LINE = '';

export const SortOrder = {
  ASC: 'ASC',
  DESC: 'DESC',
};

export const RADIX = 10;

export const isDevMode = process.env.REACT_APP_DEV_MODE || process.env.NODE_ENV !== 'production';

export const COOKIE_OPTIONS = {
  path: '/',
  httpOnly: false,
  secure: !isDevMode,
};
export const COOKIE_OPTIONS_SERVER_SIDE = {
  path: '/',
  httpOnly: true,
  secure: !isDevMode,
};
export const SMK_SESSION_COOKIE = 'smk_session';
export const SMK_REFRESH_SESSION_COOKIE = 'smk_refresh_session';
export const APP_SOURCE = 'app_source';

export const SMK_ANONYMOUS_COOKIE = 'smk_anonymous';

export const RESTART_ON_REMOUNT = '@@saga-injector/restart-on-remount';
export const DAEMON = '@@saga-injector/daemon';
export const ONCE_TILL_UNMOUNT = '@@saga-injector/once-till-unmount';

export const BACKEND_BASE_URL = process.env.REACT_APP_BACKEND_BASE_URL;
export const API_BASE_URL = process.env.REACT_APP_BACKEND_BASE_API_URL;

export const POST_STATUS = {
  DRAFT: 0,
  PENDING_FOR_APPROVAL: 1,
  APPROVED: 2,
  REJECTED: 3,
};

export const BABY_NAME_META_DATA_TYPE = {
  0: 'Gender',
  1: 'Nakshatra',
  2: 'Rashi',
  3: 'Religion',
  4: 'Origin',
  5: 'Meaning words',
};

export const POST_STATUS_STING = {
  0: 'Draft',
  1: 'Pending',
  2: 'Approved',
  3: 'Rejected',
};

export const TOPIC_STATUS = {
  PENDING_FOR_APPROVAL: 0,
  APPROVED: 1,
  REJECTED: 2,
};

export const TOPIC_STATUS_STING = {
  0: 'Pending',
  1: 'Approved',
  2: 'Rejected',
};

export const GENDER = {
  ALL_GENDER: 0,
  FEMALE: 1,
  MALE: 2,
  UNDISCLOSED: 3,
};
export const SCHOOL_GENDER_VALUES = {
  BOTH: 0,
  GIRLS: 1,
  BOYS: 2,
};
export const USER_GENDER = {
  Female: 1,
  Male: 2,
  UnDisclosed: 3,
};

export const GET_GENDER = {
  0: 'All Gender',
  1: 'Female',
  2: 'Male',
  3: 'Undisclosed',
};

export const MODULE_TYPE = {
  // NOT TO BE USED ------------------
  // GENERAL: 1,
  // ACTIVITY: 2,
  // STORY: 4,
  // REVIEWS: 6,
  // DIY: 10,
  // RECIPE: 11,
  // BABY_NAME_PRONUNCIATION: 12,
  // WORKSHEET_GENERATOR: 13,
  // CALCULATOR: 14,
  // TPZ_APP: 15,
  // SMK_APP: 16,
  // DICTIONARY: 17,
  // SUBSCRIPTION: 20,
  // SMK_BLOG_ASSETS: 22,
  // TPZ_BLOG_ASSETS: 23,

  // COMMON ------------------
  ARTICLE: 3, // ASSET

  // app banner, taxonomy, solid food, forum cateogry, calculator, calculator category, subscription offer
  METADATA: 26, // ASSET

  FORUM: 5, // ATTACHMENT
  PROFILE: 21, // ATTACHMENT

  // SMK ------------------
  SCHOOL: 7, // ASSET
  COLLEGE: 25, // ASSET
  WORKSHEET: 8, // ATTACHMENT - should be ASSET

  WHITEBOARD: 24, // ATTACHMENT

  // TPZ ------------------
  BABY_NAMES: 9, // ASSET

  // TODO: once a threshold is reached move this to separate table and use UUID
  PATIENT_TRACKING: 18, // ATTACHMENT
  MEMORIES: 19, // ATTACHMENT
};

// export const MODULE_TYPE = {
//   GENERAL: 'general',
//   ACTIVITY: 'activity',
//   ARTICLE: 'article',
//   STORY: 'story',
//   FORUM: 'forum',
//   REVIEWS: 'reviews',
//   SCHOOL: 'school',
//   WORKSHEET: 'worksheet',
//   BABY_NAMES: 'baby_names',
//   DIY: 'diy',
//   RECIPE: 'recipe',
//   BABY_NAME_PRONUNCIATION: 'baby_name_pronunciation',
//   WORKSHEET_GENERATOR: 'worksheet_generator',
//   CALCULATOR: 'calculator',
//   TPZ_APP: 'tpz_app',
//   SMK_APP: 'smk_app',
//   DICTIONARY: 'dictionary',
//   BABY_TRACKING: 'baby_tracking',
//   MEMORIES: 'memories',
//   SUBSCRIPTION: 'subscription',
//   PROFILE: 'profile',
//   ASSETS: 'assets',
// };

export const GRADES = {
  Preschool: 1,
  Kindergarten: 2,
  First: 3,
  Second: 4,
  Third: 5,
  Fourth: 6,
  Fifth: 7,
  Sixth: 8,
  Seventh: 9,
  Eighth: 10,
};

export const DIFFICULTY_LEVEL = {
  Low: 1,
  Medium: 2,
  High: 3,
};

export const WORKSHEET_STATUS = {
  PENDING_FOR_APPROVAL: 1,
  APPROVED: 2,
  REJECTED: 3,
};

export const AGE_GROUP = {
  Infants: 1,
  Toddlers: 2,
  Kids: 3,
};

export const WORKSHEET_TYPE = {
  PRINTABLE_WORKSHEETS: 'printable_worksheets',
  WORKSHEET_GENERATOR: 'worksheet_generator',
  LIVE_WORKSHEETS: 'live_worksheets',
};

// export const AGE_GROUP = {
//   INFANTS: { value: 1, label: 'INFANTS' },
//   TODDLERS: { value: 2, label: 'TODDLERS' },
//   KIDS: { value: 3, label: 'KIDS' },
// };
export const USER_ROLE = {
  ADMIN: 0,
  STAFF: 1,
  USER: 2,
  ARTICLE_AUTHOR: 3,
  ARTICLE_REVIEWER: 4,
  SCHOOL_ADMIN: 5,
  ACTIVITY_ORGANIZER: 6,
  OFFLINE_USERS: 7,
  SMK_DOCTOR: 8,
};

export const GET_USER_ROLE = {
  0: 'ADMIN',
  1: 'STAFF',
  2: 'USER',
  3: 'ARTICLE_AUTHOR',
  4: 'ARTICLE_REVIEWER',
  5: 'SCHOOL_ADMIN',
  6: 'ACTIVITY_ORGANIZER',
  7: 'OFFLINE_USERS',
  8: 'SMK_DOCTOR',
};

export const GET_USER_SOURCE = {
  0: 'SIGNUP',
  1: 'SUBSCRIBE',
  2: 'ADMISSION_INQUIRY',
  3: 'ACTIVITY_INQUIRY',
  4: 'FORUM',
  5: 'COMMENTS',
  6: 'FACEBOOK',
  7: 'GOOGLE',
  8: 'DIGITAL_CAMPAIGN',
  9: 'OFFLINE_CAMPAIGN',
  10: 'REVIEW',
  11: 'CONTACT_INQUIRY',
  12: 'ADMIN',
  13: 'APPLE',
};

export const SCHOOL_REVIEWER_TYPE = {
  CURRENT_STUDENT: 1,
  RECENTLY_PASSED_STUDENT: 2,
  PARENT: 3,
  SCHOOL_ALUMNI: 4,
  SCHOOL_EMPLOYEE: 5,
  OTHERS: 6,
};

export const USER_SOURCE = {
  SIGNUP: 0,
  SUBSCRIBE: 1,
  ADMISSION_INQUIRY: 2,
  ACTIVITY_INQUIRY: 3,
  FORUM: 4,
  COMMENTS: 5,
  FACEBOOK: 6,
  GOOGLE: 7,
  DIGITAL_CAMPAIGN: 8,
  OFFLINE_CAMPAIGN: 9,
  REVIEW: 10,
  CONTACT_INQUIRY: 11,
  ADMIN: 12,
  APPLE: 13,
};

export const USER_STATUS = {
  INVITED: 0,
  ACTIVE: 1,
  DISABLED: 2,
};
export const GET_USER_STATUS = {
  0: 'Invited',
  1: 'Active',
  2: 'Disabled',
};

export const SchoolMasterType = {
  BOARDING_TYPE: 0,
  BOARDING_SUB_TYPE: 1,
  MANAGEMENT_TYPE: 2,
  INFRASTRUCTURE_FACILITIES_TYPE: 3,
  SCHOOL_CLUB: 4,
  LANGUAGE_OF_INSTRUCTION: 5,
  SCHOOL_RANKING_BODY: 6,
  SCHOOL_LEADERSHIP: 7,
  BOARDING_GENDER_TYPE: 8,
  DAYCARE_TYPE: 9,
};

export const PhoneNumberType = {
  LANDLINE: 'landline',
  MOBILE: 'mobile',
};

export const EmailType = {
  INFO: 'info',
  ADMISSIONS: 'admissions',
};

export const BoardsType = {
  NATIONAL: 0,
  INTERNATIONAL: 1,
  BOTH: 2,
};

export const AdmissionContactPersonType = {
  ADMISSION_HEAD: 'Admission Head',
  ADMISSION_INCHARGE: 'Admission Incharge',
  ADMISSION_COUNSELLOR: 'Admission Counsellor',
  CONTACT_PERSON: 'Contact Person',
};

export const SchoolHolidaysType = {
  SUMMER: 'Summer',
  WINTER: 'Winter',
  AUTUMN: 'Autumn',
  HOLIDAYS: 'Holidays',
};

export const SeniorSecondaryStreams = [
  { name: 'Science with Mathematics' },
  { name: 'Science without Mathematics' },
  { name: 'Commerce' },
  { name: 'Humanities' },
  { name: 'Vocational Courses' },
  { name: 'General' },
];

export const SchoolShifts = [{ name: 'Shift 1 (Morning)' }, { name: 'Shift 2 (Afternoon)' }];

export const { PUBLIC_URL } = process.env;

export const PermissionEntityEnum = {
  MASTERDATA: 'masterdata',

  USER: 'user',
  CONTACT_INQUIRY: 'contact_inquiry',

  SCHOOL: 'school',
  SCHOOL_REVIEW: 'school_review',
  SCHOOL_INQUIRY: 'school_inquiry',

  BABYNAME: 'babyname',

  WORKSHEET: 'worksheet',
  ACTIVITIES: 'activities',

  BLOG: 'blog',
  PTABLE: 'ptable',
  COLLEGE: 'college',
  ARTICLE: 'article',
};

export const SchoolReviewRejectReason = {
  INAPPROPRIATE: 'INAPPROPRIATE',
  MARKETING_CONTENT: 'MARKETING_CONTENT',
  DUPLICATE: 'DUPLICATE',
  REQUESTED_BY_SCHOOL: 'REQUESTED_BY_SCHOOL',
  REQUESTED_BY_REVIEWER: 'REQUESTED_BY_REVIEWER',
  OTHER: 'OTHER',
};

export const BabyNameMetaInfoType = {
  GENDER: 0,
  NAKSHATRA: 1,
  RASHI: 2,
  RELIGION: 3,
  ORIGIN: 4,
  MEANING_WORDS: 5,
};

export const BabyNameGenderValue = {
  0: 'Unisex',
  1: 'Girl',
  2: 'Boy',
};

export const BABY_NAME_TYPE = [
  {
    value: 0,
    label: 'Gender',
  },
  {
    value: 1,
    label: 'Nakshatra',
  },
  {
    value: 2,
    label: 'Rashi',
  },
  {
    value: 3,
    label: 'Religion',
  },
  {
    value: 4,
    label: 'Origin',
  },
  {
    value: 5,
    label: 'Meaning words',
  },
];

export const LANGUAGE_ARRAY = [
  {
    value: 0,
    label: 'English',
  },
  {
    value: 1,
    label: 'Hindi',
  },
];
export const LANGUAGE_ARRAY_WITH_STRING = [
  {
    value: 'en',
    label: 'English',
  },
  {
    value: 'hi',
    label: 'Hindi',
  },
];

export const LANGUAGE_VALUES = {
  0: 'English',
  1: 'Hindi',
};

export const GENDER_VALUES = {
  0: 'Unisex',
  1: 'Girl',
  2: 'Boy',
};

export const BABY_NAMES_GENDER_ARRAY = [
  {
    label: 'Unisex',
    value: 0,
  },
  {
    label: 'Girl',
    value: 1,
  },
  {
    label: 'Boy',
    value: 2,
  },
];

export const HINDI_BABY_NAMES_GENDER_ARRAY = [
  {
    label: 'उभयलिंगी',
    value: 0,
  },
  {
    label: 'लड़की',
    value: 1,
  },
  {
    label: 'लड़का',
    value: 2,
  },
];

export const GENDER_ARRAY = [
  {
    label: 'All Gender',
    value: 0,
  },
  {
    label: 'Female',
    value: 1,
  },
  {
    label: 'Male',
    value: 2,
  },
];

export const FEATURED_TYPE = {
  ZERO_LISTING: 'zero_listing',
  BANNER300: 'banner_300',
  BANNER720: 'banner_720',
  FEATURED_LISTING: 'featured_listing',
  SPONSORED_LISTING: 'sponsored_listing',
};

export const BANNER_TYPE = {
  PAGE_BANNER: 'PAGE_BANNER',
  POPUP_BANNER: 'POPUP_BANNER',
  TEXT_AD: 'TEXT_AD',
  BANNER_728: 'BANNER_728',
  BANNER_300: 'BANNER_300',
  FOOTER_BANNER: 'FOOTER_BANNER',
};

export const ACTIVE_AND_DELETED_ARRAY = [
  { value: null, label: 'All' },
  { value: true, label: 'Yes' },
  { value: false, label: 'No' },
];
export const YES_AND_NO_ARRAY = [
  { value: true, label: 'Yes' },
  { value: false, label: 'No' },
];

export const ACTION_TAKEN_ARRAY = [
  { value: -1, label: 'All' },
  { value: true, label: 'Yes' },
  { value: false, label: 'No' },
];

export const SORT_VALUES = {
  sortBy: 'id',
  sortOrder: -1,
};

export const LOCATION_LEVEL_ARRAY = [
  {
    value: 0,
    label: 'Country',
  },
  {
    value: 1,
    label: 'Region',
  },
  {
    value: 2,
    label: 'District',
  },
  {
    value: 3,
    label: 'City / Metro',
  },
  {
    value: 4,
    label: 'Subarea/Locality',
  },
];

export const WEEK_DAYS = {
  sunday: 0,
  monday: 1,
  tuesday: 2,
  wednesday: 3,
  thursday: 4,
  friday: 5,
  saturday: 6,
};
export const CURRENCY_ARRAY = [
  { label: 'INR', value: 'inr' },
  { label: 'USD', value: 'usd' },
];

export const contactInquirySources = {
  GENERAL_INQUIRY: 0,
  EDITORIAL: 1,
  ADVERTISEMENT: 2,
  PUBLIC_RELATIONS: 3,
  SCHOOL_LISTINGS: 4,
  CAREEER: 5,
  TPZ_FEEDBACK: 10,
  TPZ_FEATURE_SUGGESTION: 11,
  TPZ_CUSTOMER_SUPPORT: 12,
};

export const createdByTypeSelect = [
  {
    label: 'All',
    value: undefined,
  },
  {
    label: 'Admin',
    value: USER_ROLE.ADMIN,
  },
  {
    label: 'Staff',
    value: USER_ROLE.STAFF,
  },
  {
    label: 'Other user',
    value: USER_ROLE.USER,
  },
];

export const taxonomyType = {
  BABY_TRACKING_ACTIVITY_TYPE: 'baby_tracking_activity_type',
  AGE_GROUP: 'age_group',
  MEDICINE_TYPE: 'medicine_type',
  MEDICINE_SYSTEM: 'medicine_system',
  MEDICINE_CLASSFICATION: 'medicine_classfication',
  MEDICAL_SYMPTOM: 'medical_symptom',
  SOLID_FOOD_TYPE: 'solid_food_type',
  SOLID_FOOD_REACTION: 'solid_food_reaction',
  VACCINATION_COUNTRY: 'vaccination_country',
  VACCINATION_AGAINST: 'vaccination_against',
  MILESTONE_CATEGORY: 'milestone_category',
  CALCULATOR_CATEGORY: 'calculator_category',
  CHRONIC_DISEASES: 'chronic_diseases',
};

export const medicineTypes = {
  ALLOPATHY: 'allopathy',
  HOMEOPATHY: 'homeopathy',
  UNANI: 'unani',
  AYURVEDIC: 'ayurvedic',
};
export const medicineStrengthUnits = {
  G: 'g',
  MG: 'mg',
  OZ: 'oz',
  ML: 'ml',
  FL_OZ: 'fl_oz',
  DROPS: 'drops',
  PCS: 'pcs',
  TSP: 'tsp',
  TBSP: 'tbsp',
};

export const SOURCE = [
  { label: 'SchoolMyKids', value: 'schoolmykids' },
  { label: 'The ParentZ', value: 'theparentz' },
  { label: 'Mediwatcher', value: 'mediwatcher' },
];

export const appBannerSections = [
  { label: 'Full Page Banner', value: 'full_page' },
  { label: 'Popup Banner', value: 'popup_banner' },
  { label: 'Top Banner', value: 'top_banner' },
  { label: 'Mid Banner', value: 'mid_banner' },
  { label: 'Bottom Banner', value: 'bottom_banner' },
  { label: 'Background Banner', value: 'background_banner' },
  { label: 'Patient Switcher Banner', value: 'patient_switcher' },
];

export const sortBySearchData = [
  { label: 'ID', value: 'id' },
  { label: 'Name', value: 'name' },
  { label: 'Slug', value: 'slug' },
  { label: 'Create Date', value: 'createdDate' },
  { label: 'Age group', value: 'ageGroup' },
];

export const VaccinationUsualSiteArray = [
  {
    label: 'Thigh',
    value: 'thigh',
  },
  {
    label: 'Left thigh',
    value: 'left-thigh',
  },
  {
    label: 'By mouth',
    value: 'by-mouth',
  },
  {
    label: 'Upper arm/thigh',
    value: 'upper-arm-thigh',
  },
  {
    label: 'Both nostrils',
    value: 'both-nostrils',
  },
  {
    label: 'Upper arm',
    value: 'upper-arm',
  },
  {
    label: 'Left Upper arm',
    value: 'left-upper-arm',
  },
  {
    label: 'Right Upper arm',
    value: 'right-upper-arm',
  },
];

export const PAYMENT_INTERVAL = {
  monthly: 'monthly',
  yearly: 'yearly',
};

export const SUBSCRIPTION_MODULE_TYPE = {
  SCHOOL: 7,
  WORKSHEET: 8,
  BABY_TRACKING: 18,
  WHITEBOARD: 23,
};

export const NotificationActionEnum = {
  OPEN_APP: 'open_app',
  TRIGGER_ADD_BUTTON_CLICK: 'trigger_add_button_click',
  OPEN_MODULE_ADD: 'open_module_add',
  OPEN_MODULE_EDIT: 'open_module_edit',
  OPEN_MODULE_DETAIL: 'open_module_details',
  OPEN_WEB_VIEW: 'open_web_view',
  OPEN_BROWSER: 'open_browser',
  TRIGGER_EMAIL_SEND: 'trigger_email_send',
  SHOW_MESSAGE_TEXT: 'show_message_text',
  SHOW_MESSAGE_HTML: 'show_message_html',
  TRIGGER_APP_REVIEW: 'trigger_app_review',
  TRIGGER_FEEDBACK: 'trigger_feedback',
};

export const schoolImagesTypes = {
  logo: 'logo',
  banner: 'banner',
  featured: 'featured',
  image: 'image',
  og_image: 'og_image',
};

export const DISCOUNT_TYPES = {
  PERCENTAGE: 'percentage',
  ABSOLUTE: 'absolute',
};
export const SUBSCRIPTION_STATUS_TYPES = {
  PAYMENT_PENDING: 'payment_pending',
  ACTIVE: 'active',
  EXPIRED: 'expired',
};

export const COLLEGE_GENDER_VALUES = {
  BOTH: 0,
  GIRLS: 1,
  BOYS: 2,
};

export const CollegeMasterType = {
  BOARDING_TYPE: 'boarding_type',
  BOARDING_SUB_TYPE: 'boarding_sub_type',
  MANAGEMENT_TYPE: 'management_type',
  INFRASTRUCTURE_FACILITIES_TYPE: 'infrastructure_facilities_type',
  LANGUAGE_OF_INSTRUCTION: 'language_of_instruction',
  RANKING_BODY: 'ranking_body',
  RECOGNIZING_BODY: 'recognizing_body',
  COURSE_LEVEL: 'course_level',
  COURSE_TYPE: 'course_type',
  COURSE_DEGREE: 'course_degree',
  COLLEGE_CLUB: 'college_club',
  BOARDING_GENDER_TYPE: 'boarding_gender_type',
};

export const CollegeMetaDataContentAttribute = {
  SUMMARY: 'summary',
  DESCRIPTION: 'description',
  CONTENT: 'content',
  ABOUT: 'about',
  ADMISSION: 'admission',
  FEE: 'fee',
  CURRICULAM: 'curriculam',
  CURRICULAR: 'curricular',
  SYLLABUS: 'syllabus',
};

export const BannerScreenEnum = {
  BABY_DASHBOARD: 'baby_dashboard',
  MEDICINE_DASHBOARD: 'medicine_dashboard',
  MILESTONE_DASHBOARD: 'milestone_dashboard',
  VACCINATION_DASHBOARD: 'vaccination_dashboard',
  PATIENT_DASHBOARD: 'patient_dashboard',
  MEMORIES_DASHBOARD: 'memories_dashboard',
  CALENDAR_DASHBOARD: 'calendar_dashboard',
  MORE: 'more',
  SUBSCRIPTION: 'subscription',
  USER_PROFILE: 'user_profile',
  REFER_A_FRIEND: 'refer-a-friend',
  BUY_SUBSCRIPTION: 'buy_subscription',
};

export const CollegeShifts = [{ name: 'Shift 1 (Morning)' }, { name: 'Shift 2 (Afternoon)' }];

export const AppSourceEnum = {
  SCHOOLYKIDS: 'schoolmykids',
  THEPARENTZ: 'theparentz',
  SUPERPETS: 'superpets',
  MEDIWATCHER: 'mediwatcher',
};

export const CollegeReviewRejectReason = {
  INAPPROPRIATE: 'INAPPROPRIATE',
  MARKETING_CONTENT: 'MARKETING_CONTENT',
  DUPLICATE: 'DUPLICATE',
  REQUESTED_BY_COLLEGE: 'REQUESTED_BY_COLLEGE',
  REQUESTED_BY_REVIEWER: 'REQUESTED_BY_REVIEWER',
  OTHER: 'OTHER',
};

export const COLLEGE_REVIEWER_TYPE = {
  CURRENT_STUDENT: 1,
  RECENTLY_PASSED_STUDENT: 2,
  PARENT: 3,
  COLLEGE_ALUMNI: 4,
  COLLEGE_EMPLOYEE: 5,
  OTHERS: 6,
};

export const COLLEGE_SEAT_CATEGORY = {
  UR: 'ur',
  'UR PwD': 'ur-pwd',
  'General EWS': 'general-ews',
  'General EWS PwD': 'general-ews-pwd',
  OBC: 'obc',
  'OBC PwD': 'obc-pwd',
  SC: 'sc',
  'SC PwD': 'sc-pwd',
  ST: 'st',
  'ST PwD': 'st-pwd',
};

export const COLLEGE_SEAT_TYPE = {
  All: 'all',
  'All India Quota': 'all-india-quota',
  'State Quota': 'state-quota',
  NRI: 'nri',
  MANAGEMENT: 'management',
  Others: 'others',
};

export const COLLEGE_FEE_TYPE = {
  'Total Fees': 'total-fees',
  'Admission Fee': 'admission-fee',
  'Tution Fee': 'tutorial-fee',
  'Registration Fee': 'registration-fee',
  'Hostel Fee': 'hostel-fee',
  'Examination Fee': 'examination-fee',
  'Other Fee': 'other-fee',
};

export const CURRENCY_TYPE = {
  INR: 'inr',
  USD: 'usd',
};

export const CURRENT_YEAR = new Date().getFullYear();

export const CollegeSeatSummaryType = [{ name: 'pgApproved' }, { name: 'ugApproved' }];
