import { PUBLIC_URL } from '../../utils/constants';

export default {
  // ADD_USER: `${PUBLIC_URL}/users/add`,
  // EDIT_USER: `${PUBLIC_URL}/user/edit/:id`,
  USER_PROFILE: `${PUBLIC_URL}/users/profile/:id`,
  // USERS_LISTING: `${PUBLIC_URL}/users`,

  USER_SUBSCRIPTIONS_LISTING: `${PUBLIC_URL}/user/subscription-list`,
  USER_SUBSCRIPTION_DETAILS: `${PUBLIC_URL}/user/subscription/:id`,

  // SMK
  SMK_USERS_LISTING: `${PUBLIC_URL}/smk/users/list`,
  SMK_ADD_USER: `${PUBLIC_URL}/smk/user/add`,
  SMK_EDIT_USER: `${PUBLIC_URL}/smk/user/edit/:id`,

  // TPZ
  TPZ_USERS_LISTING: `${PUBLIC_URL}/tpz/users/list`,
  TPZ_ADD_USER: `${PUBLIC_URL}/tpz/user/add`,
  TPZ_EDIT_USER: `${PUBLIC_URL}/tpz/user/edit/:id`,
};
