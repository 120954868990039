import TableError from 'components/TableError';
import config from 'config/config';
import isEmpty from 'lodash/isEmpty';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import Select from 'react-select';
import {
  Badge,
  Card,
  CardBody,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Input,
  Label,
  Row,
  Table,
  UncontrolledDropdown,
} from 'reactstrap';
import { createStructuredSelector } from 'reselect';
import { ACTIVE_AND_DELETED_ARRAY, AppSourceEnum, SORT_VALUES, USER_ROLE, createdByTypeSelect } from 'utils/constants';
import { userSelector } from '../../../common/globalComponents/selectors';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import Paginate from '../../../components/Paginate';
import { useRouter } from '../../../hooks/useRouter';
import {
  buildURL,
  checkIfValueExist,
  convertHtmlToText,
  printDateTimeTwoLines,
  printSerialNumbers,
} from '../../../utils/helper';
import { SMKLinks } from '../../../utils/links';
import {
  citiesSelector,
  countriesSelector,
  regionsSelector,
  subAreasSelector,
  collegeListSelector,
  collegesPaginationSelector,
} from '../selectors';
import { getColleges, getCities, getCountries, getRegions, getSubAreas, getCollegesGroups } from '../actions';
import moment from 'moment';
import useRequest from '../../../hooks/useRequest';

const stateSelector = createStructuredSelector({
  colleges: collegeListSelector,
  collegesPagination: collegesPaginationSelector,
  user: userSelector,
  countries: countriesSelector,
  regions: regionsSelector,
  cities: citiesSelector,
  subAreas: subAreasSelector,
});

const CollegesList = () => {
  const dispatch = useDispatch();
  const router = useRouter();
  const history = useHistory();
  const { colleges, collegesPagination, user, countries, regions, cities, subAreas } = useSelector(stateSelector);

  const defaultFilters = {
    active: { value: null, label: 'All' },
    deleted: { value: null, label: 'All' },
    startDate: moment().subtract(25, 'years'),
    endDate: moment(),
    // id: undefined,
    name: undefined,
    createdBy: undefined,

    // university: undefined,
    // college_group: undefined,

    countryId: undefined,
    regionId: undefined,
    cityId: undefined,
    subAreaId: undefined,
  };
  const [filters, setFilters] = useState(defaultFilters);
  const [sort, setSort] = useState(SORT_VALUES);

  const [staffUsers, setStaffUsers] = useState([]);
  const [createdByType, setCreatedByType] = useState({
    value: undefined,
    label: 'All',
  });
  const [getStaffUsersRequest, { loading: getStaffUsersLoading }] = useRequest({
    url: `/user/admin/search`,
    body: {
      active: true,
      deleted: false,
      staffOnly: true,
      type: createdByType?.value,
      page: 1,
      size: 500,
      appSource: AppSourceEnum.SCHOOLYKIDS,
    },
    method: 'POST',
    onSuccess: (data) => {
      setStaffUsers(
        data?.results
          ?.filter((e) => e?.role === USER_ROLE.STAFF)
          ?.map((d) => {
            return {
              ...d,
              label: `${d.firstName} ${d.lastName}\n${d.email}`,
              value: d.id,
            };
          })
      );
    },
    onError: (err) => {
      console.log(`Error occurred: ${err}`);
    },
  });

  useEffect(() => {
    getStaffUsersRequest();
    dispatch(
      getCollegesGroups.trigger({
        page: 1,
        size: 1000,
        sortBy: 'id',
        sortOrder: 0,
        active: true,
        deleted: false,
      })
    );
  }, []);

  useEffect(() => {
    getStaffUsersRequest();
  }, [createdByType]);

  const setPageInUrl = (page) => {
    history.push({
      pathname: router.pathname,
      search: `?${new URLSearchParams({ page }).toString()}`,
    });
  };

  const handleChangePage = (page) => {
    setPageInUrl(page);
    const data = {
      page,
      size: 25,
      ...filters,
      active: checkIfValueExist(filters.active?.value),
      deleted: checkIfValueExist(filters.deleted?.value),
      name: checkIfValueExist(filters.name),
      // id: checkIfValueExist(filters.id),
      ...sort,
    };
    /*
    if (filters.university === '') {
      data.university = undefined;
    } else if (filters.board) {
      data.university = filters.university.slug;
    }

    if (filters.college_group == '') {
      data.college_group = undefined;
    } else if (filters.college_group) {
      data.college_group = filters.college_group.slug;
    } */

    if (filters.countryId == '') {
      data.countryId = undefined;
    } else if (filters.countryId) {
      data.countryId = filters.countryId.id;
    }
    if (filters.regionId == '') {
      data.regionId = undefined;
    } else if (filters.regionId) {
      data.regionId = filters.regionId.id;
    }
    if (filters.cityId == '') {
      data.cityId = undefined;
    } else if (filters.cityId) {
      data.cityId = filters.cityId.id;
    }
    if (filters.subAreaId == '') {
      data.subAreaId = undefined;
    } else if (filters.subAreaId) {
      data.subAreaId = filters.subAreaId.id;
    }

    if (filters.createdBy) {
      delete data.createdBy;
      data.userId = filters.createdBy.id;
      data.createdByUser = true;
    }
    dispatch(getColleges.trigger(data));
  };

  const applyFilters = () => {
    handleChangePage(1);
  };

  const clearFilters = () => {
    setPageInUrl(1);
    setCreatedByType({ value: undefined, label: 'All' });
    setFilters({
      ...defaultFilters,
      // id: '',
      name: '',
      createdBy: '',
      countryId: '',
      regionId: '',
      cityId: '',
      subAreaId: '',
    });
    dispatch(
      getColleges.trigger({
        page: 1,
        size: 50,
        ...defaultFilters,
        active: checkIfValueExist(defaultFilters.active?.value),
        deleted: checkIfValueExist(defaultFilters.deleted?.value),
        // id: defaultFilters.id,
        ...sort,
      })
    );
  };

  useEffect(() => {
    handleChangePage(parseInt(router?.query?.page, 10) || 1);
  }, []);

  useEffect(() => {
    applyFilters();
  }, [sort]);

  const onCreatedBySelect = (createdBy) => {
    if (!isEmpty(createdBy)) {
      setFilters({ ...filters, createdBy });
    }
  };

  useEffect(() => {
    dispatch(getCountries.trigger({ level: 0, page: 1, size: 500 }));
  }, []);

  const onCountrySelect = (country) => {
    if (!isEmpty(country)) {
      dispatch(
        getRegions.trigger({
          level: 1,
          country: country.name,
          page: 1,
          size: 500,
        })
      );
      setFilters({ ...filters, countryId: country });
    } else {
      setFilters({ ...filters, countryId: undefined });
    }
  };
  const onRegionSelect = (region) => {
    if (!isEmpty(region)) {
      dispatch(
        getCities.trigger({
          level: 3,
          cityOnly: true,
          region: region.name,
          page: 1,
          size: 500,
        })
      );

      setFilters({ ...filters, regionId: region });
    } else {
      setFilters({ ...filters, regionId: undefined });
    }
  };

  const onCitySelect = (city) => {
    if (!isEmpty(city)) {
      dispatch(getSubAreas.trigger({ level: 4, city: city.name, page: 1, size: 500 }));

      setFilters({ ...filters, cityId: city });
    } else {
      setFilters({ ...filters, cityId: undefined });
    }
  };

  const onSubAreaSelect = (subArea) => {
    if (!isEmpty(subArea)) {
      setFilters({ ...filters, subAreaId: subArea });
    } else {
      setFilters({ ...filters, subAreaId: undefined });
    }
  };

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Colleges" breadcrumbItem="Colleges List" />

          <Card>
            <CardBody className="mt-4">
              <Row>
                <Col md={3}>
                  <FormGroup className="mb-4">
                    <label className="form-label">Published</label>
                    <Select
                      options={ACTIVE_AND_DELETED_ARRAY}
                      value={filters.active}
                      onChange={(value) => {
                        setFilters({ ...filters, active: value });
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col md={3}>
                  <FormGroup className="mb-4">
                    <label className="form-label">Deleted</label>
                    <Select
                      options={ACTIVE_AND_DELETED_ARRAY}
                      value={filters.deleted}
                      onChange={(value) => setFilters({ ...filters, deleted: value })}
                    />
                  </FormGroup>
                </Col>
                <Col md={3}>
                  <FormGroup className="mb-4">
                    <label htmlFor="collegeId" className="form-label">
                      College ID
                    </label>
                    <Input
                      type="text"
                      id="collegeId"
                      placeholder="College ID"
                      value={filters.id}
                      onChange={(e) => setFilters({ ...filters, id: e.target.value })}
                    />
                  </FormGroup>
                </Col>
                <Col md={3}>
                  <FormGroup className="mb-4">
                    <label className="form-label">College Name</label>
                    <Input
                      type="text"
                      name="name"
                      placeholder="College Name"
                      value={filters.name}
                      onChange={(e) => setFilters({ ...filters, name: e.target.value })}
                    />
                  </FormGroup>
                </Col>
                {user?.entityPermissions?.college?.admin && (
                  <Col md={3}>
                    <FormGroup className="mb-4">
                      <Label for="college_createdByType" className="form-label">
                        Created By Type
                      </Label>
                      <Select
                        id="college_createdByType"
                        options={createdByTypeSelect}
                        value={createdByType}
                        onChange={(value) => setCreatedByType(value)}
                      />
                    </FormGroup>
                  </Col>
                )}
                {user?.entityPermissions?.college?.admin && (
                  <Col md={3}>
                    <FormGroup className="mb-4">
                      <Label for="college_createdBy" className="form-label">
                        Created By
                      </Label>
                      <Select
                        id="college_createdBy"
                        options={staffUsers}
                        value={filters.createdBy}
                        onChange={onCreatedBySelect}
                      />
                    </FormGroup>
                  </Col>
                )}
                <Col md={3}>
                  <FormGroup className="mb-4">
                    <label htmlFor="startDate" className="form-label">
                      Created Date - From
                    </label>
                    <Input
                      type="date"
                      id="startDate"
                      placeholder="From Date"
                      value={filters.startDate.format('YYYY-MM-DD')}
                      max={moment().format('YYYY-MM-DD')}
                      onChange={(e) =>
                        setFilters({
                          ...filters,
                          startDate: moment(e.target.value),
                        })
                      }
                    />
                  </FormGroup>
                </Col>
                <Col md={3}>
                  <FormGroup className="mb-4">
                    <label htmlFor="endDate" className="form-label">
                      Created Date - To
                    </label>
                    <Input
                      type="date"
                      id="endDate"
                      placeholder="To Date"
                      value={filters.endDate.format('YYYY-MM-DD')}
                      max={moment().format('YYYY-MM-DD')}
                      onChange={(e) =>
                        setFilters({
                          ...filters,
                          endDate: moment(e.target.value),
                        })
                      }
                    />
                  </FormGroup>
                </Col>
                <Col md={3}>
                  <FormGroup className="mb-4">
                    <Label for="college_country" className="form-label">
                      Country
                    </Label>
                    <Select
                      isClearable
                      id="school_country"
                      options={countries}
                      value={filters.countryId}
                      onChange={onCountrySelect}
                    />
                  </FormGroup>
                </Col>
                <Col md={3}>
                  <FormGroup className="mb-4">
                    <Label for="school_state" className="form-label  ">
                      Region/State
                    </Label>

                    <Select
                      isClearable
                      id="school_state"
                      placeholder="Select..."
                      value={filters.regionId}
                      onChange={onRegionSelect}
                      options={regions}
                    />
                  </FormGroup>
                </Col>
                <Col md={3}>
                  <FormGroup className="mb-4">
                    <Label for="school_city" className="form-label  ">
                      City
                    </Label>
                    <Select
                      isClearable
                      id="school_city"
                      placeholder="Select..."
                      value={filters.cityId}
                      onChange={onCitySelect}
                      options={cities}
                    />
                  </FormGroup>
                </Col>
                <Col md={3}>
                  <FormGroup className="mb-4">
                    <Label for="school_area" className="form-label  ">
                      SubArea
                    </Label>
                    <Select
                      isClearable
                      id="school_area"
                      placeholder="Select..."
                      value={filters.subAreaId}
                      onChange={onSubAreaSelect}
                      options={subAreas}
                    />
                  </FormGroup>
                </Col>
              </Row>

              <div className="mt-4">
                <a className="btn btn-primary me-4" onClick={applyFilters}>
                  Apply Filters
                </a>
                <a className="" onClick={clearFilters}>
                  Clear
                </a>
              </div>
            </CardBody>
          </Card>

          <Row className="mb-2">
            <Col sm="4">
              <div className="search-box me-2 mb-2 d-inline-block">
                <div className="position-relative font-bold">{collegesPagination?.totalCount} Colleges Found</div>
              </div>
            </Col>
            <Col sm="8">
              <div className="text-sm-end">
                {user?.entityPermissions?.college?.create && (
                  <Link
                    replace
                    className="btn btn-success btn-rounded waves-effect waves-light mb-2 me-2"
                    to={SMKLinks.ADD_COLLEGE}>
                    <i className="mdi mdi-plus me-1" />
                    Add College
                  </Link>
                )}
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg="12">
              <div className="">
                <div className="table-responsive" style={{ minHeight: 300 }}>
                  <Table className="project-list-table table-nowrap table-centered table-borderless">
                    <thead>
                      <tr className="table-warning">
                        <th scope="col">
                          <a
                            onClick={() => {
                              setSort({
                                sortBy: 'id',
                                sortOrder: sort.sortBy === 'id' ? sort.sortOrder * -1 : -1,
                              });
                            }}>
                            #Id
                          </a>
                        </th>
                        <th scope="col" />
                        <th scope="col">Logo </th>
                        <th scope="col">
                          <a
                            onClick={() => {
                              setSort({
                                sortBy: 'name',
                                sortOrder: sort.sortBy === 'name' ? sort.sortOrder * -1 : -1,
                              });
                            }}>
                            College Name
                          </a>
                        </th>
                        <th scope="col">
                          <a
                            onClick={() => {
                              setSort({
                                sortBy: 'createdDate',
                                sortOrder: sort.sortBy === 'createdDate' ? sort.sortOrder * -1 : -1,
                              });
                            }}>
                            Created Date
                          </a>
                        </th>
                        <th scope="col">Active</th>
                        <th scope="col">Deleted</th>
                        <th scope="col" />
                      </tr>
                    </thead>
                    <tbody>
                      {!isEmpty(colleges) ? (
                        colleges?.map((data, index) => (
                          <>
                            <tr key={`_test_${data.id}_`}>
                              <td>{data.id}</td>
                              <td>
                                {data.active ? (
                                  <i title="Active" className=" bx bx-check-shield font-size-24 text-success" />
                                ) : (
                                  <i title="InActive" className=" bx bx-error-alt font-size-24 text-warning ms-1" />
                                )}
                                {data?.address?.location?.latitude && (
                                  <i className=" bx bxs-map-pin font-size-24 text-primary ms-1" />
                                )}
                              </td>
                              <td>
                                {data?.logoUrl && (
                                  <a target="_blank" href={data?.logoUrl} rel="noreferrer">
                                    <img src={data?.logoUrl} height="35" className="d-block m-auto" />
                                  </a>
                                )}
                              </td>

                              <td>
                                <div>
                                  <Link
                                    to={buildURL(SMKLinks.EDIT_COLLEGE, {
                                      id: data.id,
                                    })}>
                                    {data.displayName
                                      ? convertHtmlToText(data.displayName)
                                      : convertHtmlToText(data.name)}
                                  </Link>

                                  {!isEmpty(data.slug) && (
                                    <span className="ms-2">
                                      <a
                                        target="_blank"
                                        rel="noreferrer"
                                        href={`${config.frontendBaseUrl}/college/${data.slug}`}>
                                        <i className="bx bx-link-external fs-4" />
                                      </a>
                                    </span>
                                  )}
                                </div>

                                {data?.slug}
                              </td>

                              <td>{printDateTimeTwoLines(data.createdDate)}</td>
                              <td>
                                <Badge className={data.active ? 'bg-success' : 'bg-danger'}>
                                  {data?.active ? 'Yes' : 'No'}
                                </Badge>
                              </td>
                              <td>
                                <Badge className={data.deleted ? 'bg-success' : 'bg-danger'}>
                                  {data?.deleted ? 'Yes' : 'No'}
                                </Badge>
                              </td>

                              <td>
                                <UncontrolledDropdown>
                                  <DropdownToggle href="#" className="card-drop" tag="i">
                                    <i role="button" className="mdi mdi-dots-vertical font-size-18" />
                                  </DropdownToggle>
                                  <DropdownMenu className="dropdown-menu-end">
                                    <Link
                                      to={buildURL(SMKLinks.EDIT_COLLEGE, {
                                        id: data.id,
                                      })}>
                                      <DropdownItem>
                                        <i className="fas fa-pencil-alt text-success m-1 pe-3 " />
                                        Edit
                                      </DropdownItem>
                                    </Link>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </td>
                            </tr>

                            <tr>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td colSpan={12}>
                                {!isEmpty(data?.externalIds) &&
                                  data?.externalIds?.map((elem) => (
                                    <div className="mb-2" key={`_test_${elem.id}`}>
                                      <b>{elem?.id && elem?.id !== 'undefined' ? elem?.id : ''} -</b>{' '}
                                      {elem?.name && elem?.name !== 'undefined' ? elem?.name : ''}
                                    </div>
                                  ))}
                              </td>
                            </tr>
                          </>
                        ))
                      ) : (
                        <TableError />
                      )}
                    </tbody>
                  </Table>
                </div>
              </div>
            </Col>
          </Row>

          <Paginate pageInfo={collegesPagination} onChangePage={handleChangePage} />
        </Container>
      </div>
    </>
  );
};

export default CollegesList;
