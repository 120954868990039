import { USER_ROLE } from 'utils/constants';
import TPZ_LINKS from './links';
import MedicinesListing from './medicine';
import UpdateMedicine from './medicine/updateMedicine';
import UpdateSolidFood from './solidFood/updateSolidFood';
import SolidFoodListing from './solidFood';
import MilestoneListing from './milestone';
import UpdateMilestone from './milestone/updateMilestone';
import VaccinationListing from './vaccination';
import UpdateVaccination from './vaccination/updateVaccination';
import MemoriesMilestoneListing from './milestone/MemoriesMilestonesList';
import appDashboard from './appDashboard';

const TPZ_ROUTES = [
  // Medicines
  {
    path: TPZ_LINKS.MEDICINES_LISTING,
    exact: true,
    component: MedicinesListing,
    role: [USER_ROLE.ADMIN],
  },
  {
    path: TPZ_LINKS.ADD_MEDICINE,
    exact: true,
    component: UpdateMedicine,
    role: [USER_ROLE.ADMIN],
  },
  {
    path: TPZ_LINKS.EDIT_MEDICINE,
    exact: true,
    component: UpdateMedicine,
    role: [USER_ROLE.ADMIN],
  },

  // Solid food
  {
    path: TPZ_LINKS.SOLID_FOOD_LISTING,
    exact: true,
    component: SolidFoodListing,
    role: [USER_ROLE.ADMIN],
  },
  {
    path: TPZ_LINKS.ADD_SOLID_FOOD,
    exact: true,
    component: UpdateSolidFood,
    role: [USER_ROLE.ADMIN],
  },
  {
    path: TPZ_LINKS.EDIT_SOLID_FOOD,
    exact: true,
    component: UpdateSolidFood,
    role: [USER_ROLE.ADMIN],
  },

  // Vaccination
  {
    path: TPZ_LINKS.VACCINATION_LISTING,
    exact: true,
    component: VaccinationListing,
    role: [USER_ROLE.ADMIN],
  },
  {
    path: TPZ_LINKS.ADD_VACCINATION,
    exact: true,
    component: UpdateVaccination,
    role: [USER_ROLE.ADMIN],
  },
  {
    path: TPZ_LINKS.EDIT_VACCINATION,
    exact: true,
    component: UpdateVaccination,
    role: [USER_ROLE.ADMIN],
  },

  // Milestone
  {
    path: TPZ_LINKS.MILESTONE_LISTING,
    exact: true,
    component: MilestoneListing,
    role: [USER_ROLE.ADMIN],
  },
  {
    path: TPZ_LINKS.MEMORIES_MILESTONE_LISTING,
    exact: true,
    component: MemoriesMilestoneListing,
    role: [USER_ROLE.ADMIN],
  },
  {
    path: TPZ_LINKS.ADD_MILESTONE,
    exact: true,
    component: UpdateMilestone,
    role: [USER_ROLE.ADMIN],
  },
  {
    path: TPZ_LINKS.EDIT_MILESTONE,
    exact: true,
    component: UpdateMilestone,
    role: [USER_ROLE.ADMIN],
  },
  {
    path: TPZ_LINKS.TPZ_APP_DASHBOARD,
    exact: true,
    component: appDashboard,
    role: [USER_ROLE.ADMIN],
  },

];

export { TPZ_ROUTES, TPZ_LINKS };
