import { PUBLIC_URL } from 'utils/constants';

export default {
  // FAQ
  FAQ_LIST: `${PUBLIC_URL}/faqs`,
  FAQ_ADD: `${PUBLIC_URL}/faq/add`,
  FAQ_EDIT: `${PUBLIC_URL}/faq/edit/:id`,

  // Page
  STATIC_PAGES_LIST: `${PUBLIC_URL}/static-pages`,
  STATIC_PAGES_ADD: `${PUBLIC_URL}/static-page/add`,
  STATIC_PAGES_EDIT: `${PUBLIC_URL}/static-page/edit/:id`,

  // Static Content
  STATIC_CONTENT_LIST: `${PUBLIC_URL}/static-content`,
  STATIC_CONTENT_ADD: `${PUBLIC_URL}/static-content/add`,
  STATIC_CONTENT_EDIT: `${PUBLIC_URL}/static-content/edit/:id`,
};
