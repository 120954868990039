import TableError from 'components/TableError';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toastify';
import {
  Badge,
  Card,
  CardBody,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Row,
  Table,
  UncontrolledDropdown,
} from 'reactstrap';
import { ACTIVE_AND_DELETED_ARRAY, LANGUAGE_ARRAY_WITH_STRING, SORT_VALUES } from 'utils/constants';
import { SMKLinks } from 'utils/links';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import Loader from '../../../components/Loader';
import Paginate from '../../../components/Paginate';
import useRequest from '../../../hooks/useRequest';
import { useRouter } from '../../../hooks/useRouter';
import { buildURL, checkIfValueExist, printSerialNumbers } from '../../../utils/helper';
import { checkUserPermission, hasAdminPermission } from '../helper';
import { useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { userSelector } from 'common/globalComponents/selectors';

const stateSelector = createStructuredSelector({
  loggedInUser: userSelector,
});

const BlogTagsList = () => {
  const router = useRouter();
  const history = useHistory();
  toast.configure();
  const { loggedInUser } = useSelector(stateSelector);

  const [tagsList, setTagsList] = useState([]);
  const [tagsListPagination, setTagsListPagination] = useState({});

  const isArticleAdmin = hasAdminPermission(loggedInUser, 'article');

  const defaultFilters = {
    active: { value: null, label: 'All' },
    deleted: { value: null, label: 'All' },
    name: '',
    language: LANGUAGE_ARRAY_WITH_STRING[0],
  };
  const [filters, setFilters] = useState(defaultFilters);

  const [getTagsListRequest, { loading: getTagsListLoading }] = useRequest({
    url: `/article/admin/tags/search`,
    method: 'POST',
    onSuccess: (data) => {
      setTagsList(data?.results);
      setTagsListPagination(data?.pagination);
    },
  });

  const [markActiveInActiveRequest, { loading: markActiveInActiveLoading }] = useRequest({
    method: 'PUT',
    onSuccess: (data) => {
      handleChangePage(parseInt(router?.query?.page, 10) || 1);
      toast.success(`Success! Blog Tag has been ${data?.active ? 'active' : 'Inactive'}.`);
    },
  });

  const [deleteUnDeleteRequest, { loading: deleteUnDeleteLoading }] = useRequest({
    method: 'PUT',
    onSuccess: (data) => {
      handleChangePage(parseInt(router?.query?.page, 10) || 1);
      toast.success(`Success!  Blog Tag has been ${data?.deleted ? 'deleted' : 'Undeleted'}.`);
    },
  });

  const setPageInUrl = (page) => {
    history.push({
      pathname: router.pathname,
      search: `?${new URLSearchParams({ page }).toString()}`,
    });
  };

  const handleChangePage = (page) => {
    setPageInUrl(page);

    getTagsListRequest({
      body: {
        page,
        size: 20,
        ...filters,
        active: checkIfValueExist(filters?.active?.value),
        deleted: checkIfValueExist(filters?.deleted?.value),
        language: checkIfValueExist(filters?.language?.value),
        ...SORT_VALUES,
      },
    });
  };

  const applyFilters = () => {
    handleChangePage(1);
  };

  const clearFilters = () => {
    setPageInUrl(1);

    setFilters({ ...defaultFilters, id: '' });
    getTagsListRequest({
      body: {
        page: 1,
        size: 20,
        ...defaultFilters,
        active: checkIfValueExist(defaultFilters?.active?.value),
        deleted: checkIfValueExist(defaultFilters?.deleted?.value),
        language: checkIfValueExist(defaultFilters?.language?.value),
        ...SORT_VALUES,
      },
    });
  };

  const handleActive = (data) => {
    markActiveInActiveRequest({
      url: `/article/admin/tags/${data?.id}`,
      body: {
        active: data?.active ? false : true,
      },
    });
  };
  const handleDelete = (data) => {
    deleteUnDeleteRequest({
      url: `/article/admin/tags/${data?.id}`,
      body: {
        deleted: data?.deleted ? false : true,
      },
    });
  };

  useEffect(() => {
    handleChangePage(parseInt(router?.query?.page, 10) || 1);
  }, []);

  return (
    <>
      <div className="page-content">
        <Loader isActive={getTagsListLoading || markActiveInActiveLoading || deleteUnDeleteLoading} />

        <Container fluid>
          <Breadcrumbs title="Blog" breadcrumbItem="Blog Tags list" />

          <Card>
            <CardBody>
              <div className="mt-4">
                <div className="row">
                  <div className="col-lg-2 mb-4">
                    <label className="form-label">Active</label>
                    <Select
                      options={ACTIVE_AND_DELETED_ARRAY}
                      value={filters.active}
                      onChange={(value) => setFilters({ ...filters, active: value })}
                    />
                  </div>
                  <div className="col-lg-2 mb-4">
                    <label className="form-label">Deleted</label>
                    <Select
                      options={ACTIVE_AND_DELETED_ARRAY}
                      value={filters.deleted}
                      onChange={(value) => setFilters({ ...filters, deleted: value })}
                    />
                  </div>

                  <div className="col-lg-2 mb-4">
                    <label className="form-label">Language</label>
                    <Select
                      options={LANGUAGE_ARRAY_WITH_STRING}
                      value={filters.language}
                      onChange={(value) => setFilters({ ...filters, language: value })}
                    />
                  </div>

                  <div className="col-lg-3 mb-4">
                    <label htmlFor="name" className="form-label">
                      Name
                    </label>
                    <Input
                      type="text"
                      name="name"
                      placeholder="Name..."
                      value={filters.name}
                      onChange={(e) => setFilters({ ...filters, name: e.target.value })}
                    />
                  </div>
                </div>
              </div>
              <div className="mt-4 pt-2">
                <a className="btn btn-primary me-4" onClick={applyFilters}>
                  Apply Filters
                </a>
                <a className="" onClick={clearFilters}>
                  Clear
                </a>
              </div>
            </CardBody>
          </Card>

          <Row className="mb-2 align-items-center">
            <Col sm="4">
              <div className="search-box me-2 mb-2 d-inline-block">
                <div className="position-relative font-bold">{tagsListPagination?.totalCount} Blog Tags found</div>
              </div>
            </Col>
            {isArticleAdmin && (
              <Col sm="8">
                <div className="text-sm-end">
                  <Link
                    replace
                    className="btn btn-success btn-rounded waves-effect waves-light mb-2 me-2"
                    to={SMKLinks.ADD_BLOG_TAG}>
                    <i className="mdi mdi-plus me-1" />
                    Add Blog Tag
                  </Link>
                </div>
              </Col>
            )}
          </Row>

          <Row>
            <Col lg="12">
              <div className="">
                <div className="table-responsive" style={{ minHeight: 300 }}>
                  <Table className="project-list-table   table-centered table-borderless">
                    <thead>
                      <tr className="table-warning">
                        <th scope="col">Id</th>
                        <th scope="col">Name</th>
                        <th scope="col">Language </th>
                        <th scope="col">Active</th>
                        <th scope="col">Deleted</th>
                        {isArticleAdmin && <th scope="col" />}
                      </tr>
                    </thead>
                    <tbody>
                      {!isEmpty(tagsList) ? (
                        tagsList?.map((data, index) => (
                          <tr key={index} className={!data?.active ? 'bg-danger' : ''}>
                            <td>{printSerialNumbers(tagsListPagination)[index]}</td>
                            <td>
                              {isArticleAdmin ? (
                                <Link
                                  to={buildURL(SMKLinks.EDIT_BLOG_TAG, {
                                    id: data?.id,
                                  })}>
                                  {data?.name}
                                </Link>
                              ) : (
                                data?.name
                              )}
                              <br />
                              {data?.slug}
                            </td>
                            <td>{LANGUAGE_ARRAY_WITH_STRING?.find((e) => e?.value === data?.language)?.label}</td>
                            <td>
                              <Badge className={data?.active ? 'bg-success' : 'bg-danger'}>
                                {data?.active ? 'Yes' : 'No'}
                              </Badge>
                            </td>
                            <td>
                              <Badge className={data?.deleted ? 'bg-success' : 'bg-danger'}>
                                {data?.deleted ? 'Yes' : 'No'}
                              </Badge>
                            </td>
                            {isArticleAdmin && (
                              <td>
                                <UncontrolledDropdown>
                                  <DropdownToggle href="#" className="card-drop " tag="i">
                                    <i role="button" className="mdi mdi-dots-vertical font-size-18 " />
                                  </DropdownToggle>
                                  <DropdownMenu className="dropdown-menu-end">
                                    <DropdownItem onClick={() => handleActive(data)}>
                                      <i
                                        className={`fas  ${
                                          data?.active ? 'fa-ban text-danger' : 'fa-power-off text-success'
                                        }   m-1 pe-3`}
                                      />
                                      {data?.active ? 'Disable' : 'Enable'}
                                    </DropdownItem>
                                    {/* <DropdownItem onClick={() => handleDelete(data)}>
                                    <i className={`fas fa-trash-alt text-danger m-1 pe-3`} />
                                    {data?.deleted ? 'Undelete' : 'Delete'}
                                  </DropdownItem>
                                  <Divider className="my-2" /> */}

                                    <Link
                                      to={buildURL(SMKLinks.EDIT_BLOG_TAG, {
                                        id: data?.id,
                                      })}>
                                      <DropdownItem>
                                        <i className="fas fa-pencil-alt text-success m-1 pe-3 " />
                                        Edit
                                      </DropdownItem>
                                    </Link>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </td>
                            )}
                          </tr>
                        ))
                      ) : (
                        <TableError />
                      )}
                    </tbody>
                  </Table>
                </div>
              </div>
            </Col>
          </Row>

          <Paginate pageInfo={tagsListPagination} onChangePage={handleChangePage} />
        </Container>
      </div>
    </>
  );
};

export default BlogTagsList;
