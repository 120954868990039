import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Select from 'react-select';
import {
  Badge,
  Card,
  CardBody,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Input,
  Label,
  Row,
  Table,
  UncontrolledDropdown,
} from 'reactstrap';
import Breadcrumbs from '../../../../components/Common/Breadcrumb';
import Loader from '../../../../components/Loader';
import Paginate from '../../../../components/Paginate';
import useRequest from '../../../../hooks/useRequest';
import { useRouter } from '../../../../hooks/useRouter';
import { buildURL, checkIfValueExist, printDate } from '../../../../utils/helper';
import { toast } from 'react-toastify';
import { SMKLinks } from 'utils/links';
import { Divider } from '@material-ui/core';
import { getCities, getCountries, getSubAreas, getRegions } from '../../actions';
import { useDispatch, useSelector } from 'react-redux';
import { countriesSelector, regionsSelector, citiesSelector, subAreasSelector } from '../../selectors';
import { createStructuredSelector } from 'reselect';
import { isEmpty } from 'lodash';
import TableError from 'components/TableError';
import { ACTIVE_AND_DELETED_ARRAY, BANNER_TYPE, SORT_VALUES } from 'utils/constants';
import moment from 'moment';

const stateSelector = createStructuredSelector({
  countries: countriesSelector,
  regions: regionsSelector,
  cities: citiesSelector,
  subAreas: subAreasSelector,
});

const FeatureBannerList = () => {
  const router = useRouter();
  const history = useHistory();
  toast.configure();
  const dispatch = useDispatch();
  const { countries, regions, cities, subAreas } = useSelector(stateSelector);

  const [featureBannerList, setfeatureBannerList] = useState([]);
  const [featureBannerListPagination, setfeatureBannerListPagination] = useState({});

  const selectTypeOptions = [];

  for (const [key, value] of Object.entries(BANNER_TYPE)) {
    selectTypeOptions?.push({
      label: key,
      value: value,
    });
  }

  const defaultFilters = {
    active: { value: null, label: 'All' },
    deleted: { value: null, label: 'All' },
    startDate: moment().subtract(10, 'years'),
    endDate: moment(),
    id: undefined,
    type: '',

    country: '',
    region: '',
    city: '',
    subArea: '',
  };
  const [filters, setFilters] = useState(defaultFilters);

  const [getfeatureBannerListRequest, { loading: getfeatureBannerListLoading }] = useRequest({
    url: `/common/featuredBanner/admin/search`,
    method: 'POST',
    onSuccess: (data) => {
      setfeatureBannerList(data?.results);
      setfeatureBannerListPagination(data?.pagination);
    },
  });

  const [markActiveInActiveRequest, { loading: markActiveInActiveLoading }] = useRequest({
    method: 'PUT',
    onSuccess: (data) => {
      handleChangePage(parseInt(router?.query?.page, 10) || 1);
      toast.success(`Success! Feature Banner has been ${data?.active ? 'active' : 'Inactive'}.`);
    },
  });

  const [deleteUnDeleteRequest, { loading: deleteUnDeleteLoading }] = useRequest({
    method: 'PUT',
    onSuccess: (data) => {
      handleChangePage(parseInt(router?.query?.page, 10) || 1);
      toast.success(`Success!  Feature Banner has been ${data?.deleted ? 'deleted' : 'Undeleted'}.`);
    },
  });

  const setPageInUrl = (page) => {
    history.push({
      pathname: router.pathname,
      search: `?${new URLSearchParams({ page }).toString()}`,
    });
  };

  const handleChangePage = (page) => {
    setPageInUrl(page);

    getfeatureBannerListRequest({
      body: {
        page,
        size: 50,
        ...filters,
        active: checkIfValueExist(filters?.active?.value),
        deleted: checkIfValueExist(filters?.deleted?.value),
        type: filters?.type?.value,
        id: filters?.id,
        country: filters?.country?.label,
        region: filters?.region?.label,
        city: undefined,
        city_district: filters?.city?.label,
        subArea: filters?.subArea?.label,
        ...SORT_VALUES,
      },
    });
  };

  const applyFilters = () => {
    handleChangePage(1);
  };

  const clearFilters = () => {
    setPageInUrl(1);

    setFilters({ ...defaultFilters, id: '' });
    getfeatureBannerListRequest({
      body: {
        page: 1,
        size: 50,
        ...defaultFilters,
        type: defaultFilters.type?.value,
        active: checkIfValueExist(defaultFilters?.active?.value),
        deleted: checkIfValueExist(defaultFilters?.deleted?.value),
        ...SORT_VALUES,
      },
    });
  };

  const handleActive = (data) => {
    markActiveInActiveRequest({
      url: `/common/featuredBanner/admin/${data?.id}`,
      body: {
        active: data?.active ? false : true,
      },
    });
  };
  const handleDelete = (data) => {
    deleteUnDeleteRequest({
      url: `/common/featuredBanner/admin/${data?.id}`,
      body: {
        deleted: data?.deleted ? false : true,
      },
    });
  };

  useEffect(() => {
    handleChangePage(parseInt(router?.query?.page, 10) || 1);
  }, []);

  const onCountrySelect = (country) => {
    if (!isEmpty(country)) {
      dispatch(
        getRegions.trigger({
          level: 1,
          country: country.name,
          page: 1,
          size: 500,
        })
      );
      setFilters({ ...filters, country: country });
    } else {
      setFilters({ ...filters, country: undefined });
    }
  };
  const onRegionSelect = (region) => {
    if (!isEmpty(region)) {
      dispatch(
        getCities.trigger({
          level: 3,
          cityOnly: true,
          region: region.name,
          page: 1,
          size: 500,
        })
      );

      setFilters({ ...filters, region: region });
    } else {
      setFilters({ ...filters, region: undefined });
    }
  };

  const onCitySelect = (city) => {
    if (!isEmpty(city)) {
      dispatch(getSubAreas.trigger({ level: 4, city: city.name, page: 1, size: 500 }));

      setFilters({ ...filters, city: city });
    } else {
      setFilters({ ...filters, city: undefined });
    }
  };

  const onSubAreaSelect = (subArea) => {
    if (!isEmpty(subArea)) {
      setFilters({ ...filters, subArea: subArea });
    } else {
      setFilters({ ...filters, subArea: undefined });
    }
  };

  useEffect(() => {
    dispatch(getCountries.trigger({ level: 0, page: 1, size: 500 }));
  }, []);

  return (
    <>
      <div className="page-content">
        <Loader isActive={getfeatureBannerListLoading || markActiveInActiveLoading || deleteUnDeleteLoading} />

        <Container fluid>
          <Breadcrumbs title="Others" breadcrumbItem="Feature Banner list" />

          <Card>
            <CardBody>
              <div className="mt-4">
                <div className="row">
                  <div className="col-lg-3">
                    <label className="form-label">Active</label>
                    <Select
                      options={ACTIVE_AND_DELETED_ARRAY}
                      value={filters.active}
                      onChange={(value) => setFilters({ ...filters, active: value })}
                    />
                  </div>
                  <div className="col-lg-3">
                    <label className="form-label">Deleted</label>
                    <Select
                      options={ACTIVE_AND_DELETED_ARRAY}
                      value={filters.deleted}
                      onChange={(value) => setFilters({ ...filters, deleted: value })}
                    />
                  </div>
                  <div className="col-lg-3">
                    <label className="form-label">Type</label>
                    <Select
                      isClearable
                      options={selectTypeOptions}
                      value={filters.type}
                      onChange={(value) => setFilters({ ...filters, type: value })}
                    />
                  </div>

                  <div className="col-lg-3">
                    <label htmlFor="Id" className="form-label">
                      Feature Banner ID
                    </label>
                    <Input
                      type="number"
                      name="Id"
                      placeholder="Feature banner Id..."
                      value={filters.id}
                      onChange={(e) => setFilters({ ...filters, id: Number(e.target.value) })}
                    />
                  </div>
                </div>

                <Row className="mt-4">
                  <Col md={3}>
                    <FormGroup className="mb-4">
                      <Label htmlFor="school_country" className="form-label">
                        Country
                      </Label>
                      <Select
                        isClearable
                        id="school_country"
                        options={countries}
                        value={filters.country}
                        onChange={onCountrySelect}
                      />
                    </FormGroup>
                  </Col>

                  <Col md={3}>
                    <FormGroup className="mb-4">
                      <Label htmlFor="school_state" className="form-label  ">
                        Region/State
                      </Label>

                      <Select
                        isClearable
                        id="school_state"
                        placeholder="Select..."
                        value={filters.region}
                        onChange={onRegionSelect}
                        options={regions}
                      />
                    </FormGroup>
                  </Col>

                  <Col md={3}>
                    <FormGroup className="mb-4">
                      <Label htmlFor="school_city" className="form-label  ">
                        City
                      </Label>
                      <Select
                        isClearable
                        id="school_city"
                        placeholder="Select..."
                        value={filters.city}
                        onChange={onCitySelect}
                        options={cities}
                      />
                    </FormGroup>
                  </Col>

                  <Col md={3}>
                    <FormGroup className="mb-4">
                      <Label htmlFor="school_area" className="form-label  ">
                        SubArea
                      </Label>
                      <Select
                        isClearable
                        id="school_area"
                        placeholder="Select..."
                        value={filters.subArea}
                        onChange={onSubAreaSelect}
                        options={subAreas}
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <div className="col-lg-3 mb-4">
                    <label htmlFor="startDate" className="form-label">
                      Created Date - From
                    </label>
                    <Input
                      type="date"
                      id="startDate"
                      placeholder="From Date"
                      value={filters.startDate.format('YYYY-MM-DD')}
                      max={moment().format('YYYY-MM-DD')}
                      onChange={(e) => setFilters({ ...filters, startDate: moment(e.target.value) })}
                    />
                  </div>
                  <div className="col-lg-3 mb-4">
                    <label htmlFor="endDate" className="form-label">
                      Created Date - To
                    </label>
                    <Input
                      type="date"
                      id="endDate"
                      placeholder="To Date"
                      value={filters.endDate.format('YYYY-MM-DD')}
                      max={moment().format('YYYY-MM-DD')}
                      onChange={(e) => setFilters({ ...filters, endDate: moment(e.target.value) })}
                    />
                  </div>
                </Row>
              </div>
              <div className="mt-4 pt-2">
                <a className="btn btn-primary me-4" onClick={applyFilters}>
                  Apply Filters
                </a>
                <a className="" onClick={clearFilters}>
                  Clear
                </a>
              </div>
            </CardBody>
          </Card>

          <Row className="mb-2 align-items-center">
            <Col sm="4">
              <div className="search-box me-2 mb-2 d-inline-block">
                <div className="position-relative font-bold">
                  {featureBannerListPagination?.totalCount} Feature Banner Data Found
                </div>
              </div>
            </Col>
            <Col sm="8">
              <div className="text-sm-end">
                <Link
                  replace
                  className="btn btn-success btn-rounded waves-effect waves-light mb-2 me-2"
                  to={SMKLinks.ADD_FEATURE_BANNER}>
                  <i className="mdi mdi-plus me-1" />
                  Add Feature Banner
                </Link>
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg="12">
              <div className="">
                <div className="table-responsive" style={{ minHeight: 300 }}>
                  <Table className="project-list-table   table-centered table-borderless">
                    <thead>
                      <tr className="table-warning">
                        <th scope="col">ID </th>
                        <th scope="col">Type</th>
                        <th scope="col">Country</th>
                        <th scope="col">Region</th>
                        <th scope="col">City</th>
                        <th scope="col">SubArea</th>
                        <th scope="col">Created Date</th>
                        <th scope="col">Expiry Date</th>
                        <th scope="col">Active</th>
                        <th scope="col">Deleted</th>
                        <th scope="col" />
                      </tr>
                    </thead>
                    <tbody>
                      {!isEmpty(featureBannerList) ? (
                        featureBannerList?.map((data, index) => (
                          <tr key={index}>
                            <td> {data?.id}</td>
                            <td>
                              <Link
                                to={buildURL(SMKLinks.EDIT_FEATURE_BANNER, {
                                  id: data?.id,
                                })}>
                                {BANNER_TYPE[data?.type]}
                              </Link>
                            </td>
                            <td>{data?.country?.name}</td>
                            <td>{data?.region?.name}</td>
                            <td>{data?.city?.name}</td>
                            <td>{data?.subArea?.name}</td>
                            <td>{printDate(data?.createdDate)}</td>
                            <td>{printDate(data?.expiryDate)}</td>

                            <td>
                              <Badge className={data?.active ? 'bg-success' : 'bg-danger'}>
                                {data?.active ? 'Yes' : 'No'}
                              </Badge>
                            </td>
                            <td>
                              <Badge className={data?.deleted ? 'bg-success' : 'bg-danger'}>
                                {data?.deleted ? 'Yes' : 'No'}
                              </Badge>
                            </td>
                            <td>
                              <UncontrolledDropdown>
                                <DropdownToggle href="#" className="card-drop " tag="i">
                                  <i role="button" className="mdi mdi-dots-vertical font-size-18 " />
                                </DropdownToggle>
                                <DropdownMenu className="dropdown-menu-end">
                                  <DropdownItem onClick={() => handleActive(data)}>
                                    <i
                                      className={`fas  ${
                                        data?.active ? 'fa-ban text-danger' : 'fa-power-off text-success'
                                      }   m-1 pe-3`}
                                    />
                                    {data?.active ? 'Disable' : 'Enable'}
                                  </DropdownItem>
                                  <DropdownItem onClick={() => handleDelete(data)}>
                                    <i className={`fas fa-trash-alt text-danger m-1 pe-3`} />
                                    {data?.deleted ? 'Undelete' : 'Delete'}
                                  </DropdownItem>
                                  <Divider className="my-2" />

                                  <Link
                                    to={buildURL(SMKLinks.EDIT_FEATURE_BANNER, {
                                      id: data?.id,
                                    })}>
                                    <DropdownItem>
                                      <i className="fas fa-pencil-alt text-success m-1 pe-3 " />
                                      Edit
                                    </DropdownItem>
                                  </Link>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <TableError />
                      )}
                    </tbody>
                  </Table>
                </div>
              </div>
            </Col>
          </Row>

          <Paginate pageInfo={featureBannerListPagination} onChangePage={handleChangePage} />
        </Container>
      </div>
    </>
  );
};

export default FeatureBannerList;
