import React, { useEffect, useState } from 'react';
import { Card, CardBody, CardTitle, Badge } from 'reactstrap';
import { Link } from 'react-router-dom';
import useRequest from '../../../hooks/useRequest';
import { toast } from 'react-toastify';
import { printDateTime } from '../../../utils/helper';

const ActivityHistory = ({ startDate, endDate }) => {
  const size = 20;
  const [activities, setActivities] = useState([]);
  const [getLatestUsersActivityHistoryRequest, { loading: getLatestUsersActivityHistoryLoading }] = useRequest({
    url: '/patient-tracking/admin-tools/app-latest-activity-history',
    method: 'POST',
    onSuccess: (data) => {
      setActivities(data?.results);
    },
    onError: (err) => {
      toast.error(err?.message?.message || err?.message);
    },
  });

  useEffect(() => {
    getLatestUsersActivityHistoryRequest({
      body: {
        startDate,
        endDate,
        size,
      },
    });
  }, [startDate, endDate]);

  return (
    <>
      <Card>
        <CardBody>
          <CardTitle className="mb-4">Top Activity History</CardTitle>
          <div className="table-responsive" style={{ minHeight: 300, maxHeight: 300 }}>
            <table className="table table-centered table-nowrap mb-0">
              <thead className="thead-light">
                <tr>
                  <th>Id</th>
                  <th>User Name</th>
                  <th>Activity Name</th>
                  <th>Created Date</th>
                  <th>Start Date</th>
                  <th>End Date</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {activities.map((item, key) => (
                  <tr key={`_tr_${key}`}>
                    <td>
                      <Link to="#" className="text-body font-weight-bold">
                        {item.id}
                      </Link>
                    </td>
                    <td>{item.display_name}</td>
                    <td>{item.activity_type}</td>
                    <td>{printDateTime(item.created_date)}</td>
                    <td>{printDateTime(item.start_date)}</td>
                    <td>{printDateTime(item.end_date)}</td>
                    <td>
                      <Badge className={`font-size-12 badge-soft-${item.badgeClass}`} pill>
                        {item.status}
                      </Badge>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </CardBody>
      </Card>
    </>
  );
};

export default ActivityHistory;
