import React, { useEffect } from 'react';
import { Button, Card, CardBody, CardTitle, Col, Form, FormFeedback, FormGroup, Input, Label, Row } from 'reactstrap';
import Select from 'react-select';
import Creatable from 'react-select/creatable';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import isEmpty from 'lodash/isEmpty';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
// actions
import codes from 'country-calling-code';
import { useHistory } from 'react-router-dom';
import { SMKLinks } from 'utils/links';
import { schoolClearCache } from 'containers/school/schoolDetailsHelper';
import { getCountries, getRegions, getCities, getSubAreas, updateSchoolContactDetails } from '../../actions';
// selectors
import {
  citiesSelector,
  countriesSelector,
  regionsSelector,
  subAreasSelector,
  schoolDetailSelector,
} from '../../selectors';
// constants
import { PhoneNumberType, EmailType, PHONE_REGEX, EMAIL_REGEX, NUMBER_REGEX, RADIX } from '../../../../utils/constants';
// helpers
import { titleCaseIfExists } from '../../../../utils/helper';
import Loader from '../../../../components/Loader';
import useRequest from '../../../../hooks/useRequest';
import { userSelector } from '../../../../common/globalComponents/selectors';

const stateSelector = createStructuredSelector({
  countries: countriesSelector,
  regions: regionsSelector,
  cities: citiesSelector,
  subAreas: subAreasSelector,
  schoolDetail: schoolDetailSelector,
  user: userSelector,
});

const ContactDetails = () => {
  const { user, countries, regions, cities, subAreas, schoolDetail } = useSelector(stateSelector);

  const dispatch = useDispatch();
  const history = useHistory();

  const cleanUpNumbers = (numbers) => {
    const newNumbers = [];

    for (let i = 0; i < numbers.length; i++) {
      let { number } = numbers[i];

      number = String(number)
        .trim()
        .replace(/[\W]+/g, '');

      if (number.indexOf('0') === 0) {
        number = number.substr(1);
      }

      const country = schoolDetail?.address?.country;

      if (country?.id > 0) {
        // append country code
        const countryCode = codes?.find((c) => c.isoCode2?.toLowerCase() === country?.shortCode?.toLowerCase());

        if (number.indexOf(countryCode?.countryCodes[0]) !== 0) {
          number = countryCode?.countryCodes[0] + number;
        }
      }

      newNumbers.push({ ...numbers[i], number });
    }

    return newNumbers;
  };

  const getInitialValues = () => {
    const { basicInfo, address } = schoolDetail;
    const values = {};

    values.numbers = !isEmpty(basicInfo?.phoneNumbers)
      ? cleanUpNumbers(basicInfo?.phoneNumbers)
      : [{ type: '', dialCode: '', number: '' }];
    values.emails = !isEmpty(basicInfo?.emails) ? basicInfo?.emails : [{ type: '', email: '' }];
    values.website = basicInfo?.website || '';
    values.country = {};
    values.region = {};
    values.city = {};
    values.metro = {};
    values.district = {};
    values.subArea = {};
    values.locality = {};
    values.postalCode = address.postalCode || '';
    values.fullAddress = address.fullAddress || '';
    values.latitude = address?.location?.latitude || '';
    values.longitude = address?.location?.longitude || '';
    return values;
  };

  const [updateContactDetailsRequest, { loading: updateContactDetailsLoading }] = useRequest({
    url: '/school/admin/edit/updateContactDetails',
    method: 'POST',
    onSuccess: (data) => {
      schoolClearCache({
        urlSlug: schoolDetail?.slug,
        schoolId: schoolDetail?.id,
      });

      window.location.reload();
    },
  });

  const isEmptyValue = (val) => {
    return val || undefined;
  };

  const formik = useFormik({
    validateOnChange: true,
    validateOnBlur: false,
    initialValues: getInitialValues(),
    onSubmit(values) {
      const data = {
        schoolId: isEmptyValue(schoolDetail.id),
        basicInfo: {
          // filter out empty entries
          phoneNumbers: isEmptyValue(values.numbers.filter((n) => n.type && n.number)),
          emails: isEmptyValue(values.emails.filter((n) => n.type && n.email)),
          website: values.website,
        },
        address: {
          country: {
            id: isEmptyValue(values.country.id),
            name: isEmptyValue(values.country.name),
          },
          region: {
            id: isEmptyValue(values.region.id),
            name: isEmptyValue(values.region.name),
          },
          city: {
            id: isEmptyValue(values.city.id),
            name: isEmptyValue(values.city.name),
          },
          metro: {
            id: isEmptyValue(values.metro?.id),
            name: isEmptyValue(values.metro?.name),
          },
          district: {
            id: isEmptyValue(values.district.id),
            name: isEmptyValue(values.district.name),
          },
          subArea: {
            id: isEmptyValue(values.subArea.id),
            name: isEmptyValue(values.subArea.name),
          },
          locality: {
            id: isEmptyValue(values.locality.id),
            name: isEmptyValue(values.locality.name),
          },
          postalCode: values.postalCode || null,
          fullAddress: isEmptyValue(values.fullAddress),
          location: {
            latitude: parseFloat(values.latitude) || 0,
            longitude: parseFloat(values.longitude) || 0,
          },
        },
      };

      updateContactDetailsRequest({ data });
    },
    validationSchema: Yup.object().shape({
      numbers: Yup.array().of(
        Yup.object().shape({
          type: Yup.string().when('number', {
            is: (val) => !isEmpty(val), // val -> number
            then: Yup.string().test('typeTest', 'Please select type', (val) => !isEmpty(val)),
          }),
          number: Yup.string().matches(PHONE_REGEX, 'Please provide valid number'),
        })
      ),
      emails: Yup.array().of(
        Yup.object().shape({
          type: Yup.string().when('email', {
            is: (val) => !isEmpty(val), // val -> email
            then: Yup.string().test('typeTest', 'Please select type', (val) => !isEmpty(val)),
          }),
          email: Yup.string().matches(EMAIL_REGEX, 'Please provide valid email'),
        })
      ),
      website: Yup.string()
        .matches(/^(http|https):\/\/.*$/, 'Website must start with http or https')
        .nullable(),
      country: Yup.object(),
      // .test('countryTest', 'Please select country', (val) => !isEmpty(val)),
      region: Yup.object(),
      // .test('regionTest', 'Please select region/state', (val) => !isEmpty(val)),
      city: Yup.object(),
      metro: Yup.object(),
      // .test('cityTest', 'Please select city', (val) => !isEmpty(val)),
      subArea: Yup.object(),
      // .test('subAreaTest', 'Please select country', (val) => !isEmpty(val)),
      locality: Yup.object(),
      postalCode: Yup.string().nullable(),
      fullAddress: Yup.string().nullable(),
      latitude: Yup.string().nullable(),
      longitude: Yup.string().nullable(),
    }),
  });

  useEffect(() => {
    const { address } = schoolDetail;

    dispatch(getCountries.trigger({ level: 0, page: 1, size: 500 }));

    if (!isEmpty(address.country)) {
      formik.setFieldValue('country', {
        ...address.country,
        value: address.country.id,
        label: address.country.name,
      });
      dispatch(
        getRegions.trigger({
          level: 1,
          country: address.country.name,
          page: 1,
          size: 500,
        })
      );
    }
    if (!isEmpty(address.region)) {
      formik.setFieldValue('region', {
        ...address.region,
        value: address.region.id,
        label: address.region.name,
      });
      dispatch(
        getCities.trigger({
          level: 3,
          cityOnly: false,
          region: address.region.name,
          page: 1,
          size: 500,
        })
      );
    }

    let name;
    if (!isEmpty(address.district)) {
      name = address.district.name;
      formik.setFieldValue('district', {
        ...address.district,
        value: address.district.id,
        label: address.district.name,
      });
    }
    if (!isEmpty(address.city)) {
      name = address.city.name;
      formik.setFieldValue('city', {
        ...address.city,
        value: address.city.id,
        label: address.city.name,
      });
    }
    if (!isEmpty(address.metro)) {
      name = address.metro.name;
      formik.setFieldValue('metro', {
        ...address.metro,
        value: address.metro.id,
        label: address.metro.name,
      });
    }
    dispatch(getSubAreas.trigger({ level: 4, city: name, page: 1, size: 500 }));
    if (!isEmpty(address.subArea)) {
      formik.setFieldValue('subArea', {
        ...address.subArea,
        value: address.subArea.id,
        label: address.subArea.name,
      });
    }
    if (!isEmpty(address.locality)) {
      formik.setFieldValue('locality', {
        ...address.locality,
        value: address.locality.id,
        label: address.locality.name,
      });
    }
  }, []);

  const handleAddField = (key) => {
    const values = formik.values[`${key}s`];

    // Prevent add field if any errors of previous row values are empty
    if (
      values.length > 0 &&
      // Check for errors in emails/numbers
      (!isEmpty(formik.errors[`${key}s`]) ||
        // Check if last row has email/number
        isEmpty(values[values.length - 1][key]) ||
        // Check if last row has type
        isEmpty(values[values.length - 1].type))
    ) {
      return;
    }

    formik.setFieldValue(`${key}s`, [...values, { type: '', [key]: '' }]);
  };

  const handleRemoveField = (index, key) => {
    const values = formik.values[`${key}s`].slice();
    values.splice(index, 1);
    formik.setFieldValue(`${key}s`, values);
  };

  const handleChangeType = (index, key, type) => {
    const array = formik.values[`${key}s`].slice();
    array[index] = {
      ...array[index],
      type,
    };
    formik.setFieldValue(`${key}s`, array);
  };

  const handleChangeDialCode = (index, key, dialCode) => {
    const array = formik.values[`${key}s`].slice();
    array[index] = {
      ...array[index],
      dialCode,
    };
    formik.setFieldValue(`${key}s`, array);
  };

  const handleChangeValue = (index, key, value) => {
    const array = formik.values[`${key}s`].slice();
    array[index] = { ...array[index], [key]: value };
    formik.setFieldValue(`${key}s`, array);
  };

  const handleCountrySelect = (country) => {
    if (!isEmpty(country)) {
      dispatch(
        getRegions.trigger({
          level: 1,
          country: country.name,
          page: 1,
          size: 500,
        })
      );
      formik.setFieldValue('country', country);
    } else {
      formik.setFieldValue('country', {});
    }

    formik.setFieldValue('region', {});
    formik.setFieldValue('district', {});
    formik.setFieldValue('city', {});
    formik.setFieldValue('metro', {});
    formik.setFieldValue('subArea', {});
    formik.setFieldValue('locality', {});
    formik.setFieldValue('postalCode', '');
  };

  const handleRegionSelect = (region) => {
    if (!isEmpty(region)) {
      dispatch(
        getCities.trigger({
          level: 3,
          cityOnly: false,
          region: region.name,
          page: 1,
          size: 500,
        })
      );

      formik.setFieldValue('region', region);
    } else {
      formik.setFieldValue('region', {});
    }

    formik.setFieldValue('district', {});
    formik.setFieldValue('city', {});
    formik.setFieldValue('metro', {});
    formik.setFieldValue('subArea', {});
    formik.setFieldValue('locality', {});
    formik.setFieldValue('postalCode', '');
  };

  const handleDistrictSelect = (district) => {
    if (!isEmpty(district)) {
      dispatch(
        getSubAreas.trigger({
          level: 4,
          city: district.name,
          page: 1,
          size: 500,
        })
      );

      formik.setFieldValue('district', district);
    } else {
      formik.setFieldValue('district', {});
    }

    formik.setFieldValue('city', {});
    formik.setFieldValue('subArea', {});
    formik.setFieldValue('locality', {});
    formik.setFieldValue('postalCode', '');
  };

  const handleCitySelect = (city) => {
    if (!isEmpty(city)) {
      if (!city.id && !city.name) {
        city.name = city.label;
      }
      dispatch(
        getSubAreas.trigger({
          level: 4,
          city: city.name,
          page: 1,
          size: 500,
        })
      );
      formik.setFieldValue('city', city);
    } else {
      formik.setFieldValue('city', {});
    }

    formik.setFieldValue('subArea', {});
    formik.setFieldValue('locality', {});
    formik.setFieldValue('postalCode', '');
  };

  const handleMetroSelect = (metro) => {
    if (!isEmpty(metro)) {
      formik.setFieldValue('metro', metro);
    } else {
      formik.setFieldValue('metro', {});
    }
  };

  const handleSubAreaSelect = (subArea) => {
    if (!isEmpty(subArea)) {
      if (!subArea.id && !subArea.name) {
        subArea.name = subArea.label;
      }
      formik.setFieldValue('subArea', subArea);
    } else {
      formik.setFieldValue('subArea', {});
    }

    formik.setFieldValue('locality', {});
    formik.setFieldValue('postalCode', '');
  };

  const handleLocalitySelect = (locality) => {
    if (!isEmpty(locality)) {
      formik.setFieldValue('locality', locality);
    } else {
      formik.setFieldValue('locality', {});
    }
    formik.setFieldValue('postalCode', '');
  };

  const renderPhoneNumbers = () => {
    const types = Object.keys(PhoneNumberType).map((key) => ({
      value: PhoneNumberType[key],
      label: titleCaseIfExists(key),
    }));

    return formik.values.numbers.map((num, i) => (
      <Row key={i}>
        <Col sm={4}>
          <FormGroup>
            <Select
              options={types}
              value={types.find((t) => t.value === num.type)}
              onChange={(t) => handleChangeType(i, 'number', t.value)}
            />
            {!isEmpty(formik.errors.numbers) && (
              <div className="invalid-feedback d-block">{formik.errors.numbers[i]?.type}</div>
            )}
          </FormGroup>
        </Col>
        <Col sm={6}>
          <FormGroup>
            <PhoneInput
              country="in"
              isValid={isEmpty(formik.errors.numbers) || isEmpty(formik.errors.numbers[i]?.number)}
              enableSearch
              countryCodeEditable
              preferredCountries={['in', 'us', 'sg', 'uk', 'au', 'ae']}
              value={num.number}
              onChange={(value, a) => {
                handleChangeDialCode(i, 'number', a?.dialCode);
                handleChangeValue(i, 'number', value);
              }}
            />

            {!isEmpty(formik.errors.numbers) && (
              <div className="invalid-feedback d-block">{formik.errors.numbers[i]?.number}</div>
            )}
          </FormGroup>
        </Col>
        <Col sm={1}>
          <FormGroup>
            <Button color="danger" onClick={() => handleRemoveField(i, 'number')}>
              <i className="bx bx-trash" />
            </Button>
          </FormGroup>
        </Col>
      </Row>
    ));
  };

  const renderEmails = () => {
    const types = Object.keys(EmailType).map((key) => ({
      value: EmailType[key],
      label: titleCaseIfExists(key),
    }));

    return formik.values.emails.map((email, i) => (
      <Row key={i}>
        <Col sm={4}>
          <FormGroup className="mb-4">
            <Select
              options={types}
              value={types.find((t) => t.value === email.type)}
              onChange={(t) => handleChangeType(i, 'email', t.value)}
            />
            {!isEmpty(formik.errors.emails) && (
              <div className="invalid-feedback d-block">{formik.errors.emails[i]?.type}</div>
            )}
          </FormGroup>
        </Col>
        <Col sm={6}>
          <FormGroup className="mb-4">
            <Input
              className="form-control"
              id="schooldesc"
              rows="3"
              placeholder="Email..."
              value={email.email}
              onChange={(e) => handleChangeValue(i, 'email', e.target.value)}
            />
            {!isEmpty(formik.errors.emails) && (
              <div className="invalid-feedback d-block">{formik.errors.emails[i]?.email}</div>
            )}
          </FormGroup>
        </Col>
        <Col sm={1}>
          <FormGroup>
            <Button color="danger" onClick={() => handleRemoveField(i, 'email')}>
              <i className="bx bx-trash" />
            </Button>
          </FormGroup>
        </Col>
      </Row>
    ));
  };

  return (
    <Form onSubmit={formik.handleSubmit}>
      <Loader isActive={updateContactDetailsLoading} />
      <h5 className="mb-4">Contact Details</h5>
      {/* {`Old => ${JSON.stringify(schoolDetail?.basicInfo?.phoneNumbers)}`} */}
      {/* <br /> */}
      {/* {`New => ${JSON.stringify(formik.values.numbers)}`} */}
      <Row>
        <Col md={12}>
          <Card>
            <CardBody>
              <Label htmlFor="school_phoneNumbers" className="form-label  ">
                Phone Number
              </Label>
              {renderPhoneNumbers()}
              <Row>
                <Col>
                  <Button className="my-4 w-100" color="light" onClick={() => handleAddField('number')}>
                    <i className="bx bx-plus me-2" />
                    Add Phone Number
                  </Button>
                </Col>
              </Row>

              <Label htmlFor="school_emails" className="form-label  ">
                Email
              </Label>

              {renderEmails()}
              <Row>
                <Col>
                  <Button className="my-4 w-100" color="light" onClick={() => handleAddField('email')}>
                    <i className="bx bx-plus me-2" />
                    Add Email
                  </Button>
                </Col>
              </Row>

              <FormGroup className="mb-4">
                <Label htmlFor="school_website" className="form-label  ">
                  Website
                </Label>
                <Input
                  id="school_website"
                  type="text"
                  className="form-control"
                  placeholder="URL..."
                  invalid={!!(formik.touched.website && formik.errors.website)}
                  {...formik.getFieldProps('website')}
                />
                <FormFeedback>{formik.errors.website}</FormFeedback>
              </FormGroup>
            </CardBody>
          </Card>
        </Col>
        <Col md={12}>
          <Card>
            <CardBody>
              <CardTitle>Address</CardTitle>

              <Row>
                <Col md={6}>
                  <FormGroup className="mb-4">
                    <Label htmlFor="school_country" className="form-label  ">
                      Country
                    </Label>
                    <Select
                      id="school_country"
                      value={!isEmpty(formik.values.country) ? formik.values.country : null}
                      options={countries}
                      onChange={handleCountrySelect}
                    />
                    {formik.touched.country && <div className="invalid-feedback d-block">{formik.errors.country}</div>}
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup className="mb-4">
                    <Label htmlFor="school_region" className="form-label  ">
                      Region/State
                    </Label>
                    <Select
                      id="school_region"
                      value={!isEmpty(formik.values.region) ? formik.values.region : null}
                      options={regions}
                      onChange={handleRegionSelect}
                    />
                    {formik.touched.region && <div className="invalid-feedback d-block">{formik.errors.region}</div>}
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup className="mb-4">
                    <Label htmlFor="school_district" className="form-label  ">
                      District
                    </Label>
                    <Select
                      isClearable
                      id="school_district"
                      value={!isEmpty(formik.values.district) ? formik.values.district : null}
                      // NOTE: cities and districts too
                      options={cities?.filter((c) => c.level === 2)}
                      onChange={handleDistrictSelect}
                    />
                    {formik.touched.district && (
                      <div className="invalid-feedback d-block">{formik.errors.district}</div>
                    )}
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup className="mb-4">
                    <Label htmlFor="school_city" className="form-label  ">
                      City
                    </Label>
                    <Creatable
                      isClearable
                      id="school_city"
                      value={!isEmpty(formik.values.city) ? formik.values.city : null}
                      options={cities?.filter((c) => c.level === 3)}
                      onChange={handleCitySelect}
                    />
                    {formik.touched.city && <div className="invalid-feedback d-block">{formik.errors.city}</div>}
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup className="mb-4">
                    <Label htmlFor="school_subarea" className="form-label  ">
                      SubArea
                    </Label>
                    <Creatable
                      isClearable
                      id="school_subarea"
                      value={!isEmpty(formik.values.subArea) ? formik.values.subArea : null}
                      options={subAreas}
                      onChange={handleSubAreaSelect}
                    />
                    {formik.touched.subArea && <div className="invalid-feedback d-block">{formik.errors.subArea}</div>}
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup className="mb-4">
                    <Label htmlFor="school_locality" className="form-label  ">
                      Locality
                    </Label>
                    <Select
                      isClearable
                      id="school_locality"
                      value={!isEmpty(formik.values.locality) ? formik.values.locality : null}
                      // NOTE: subareas has localities too
                      options={subAreas}
                      onChange={handleLocalitySelect}
                    />
                    {formik.touched.locality && (
                      <div className="invalid-feedback d-block">{formik.errors.locality}</div>
                    )}
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup className="mb-4">
                    <Label htmlFor="school_metro" className="form-label  ">
                      Metro
                    </Label>
                    <Select
                      isClearable
                      id="school_metro"
                      value={!isEmpty(formik.values.metro) ? formik.values.metro : null}
                      options={cities}
                      onChange={handleMetroSelect}
                    />
                    {formik.touched.metro && <div className="invalid-feedback d-block">{formik.errors.metro}</div>}
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup className="mb-4">
                    <Label htmlFor="school_pincode" className="form-label  ">
                      Pincode
                    </Label>
                    <Input
                      id="school_pincode"
                      type="text"
                      className="form-control"
                      placeholder="Pincode..."
                      invalid={!!(formik.touched.postalCode && formik.errors.postalCode)}
                      {...formik.getFieldProps('postalCode')}
                    />
                    <FormFeedback>{formik.errors.postalCode}</FormFeedback>
                  </FormGroup>
                </Col>

                <Col md={12}>
                  <FormGroup className="mb-4">
                    <Label htmlFor="school_address" className="form-label  ">
                      Address
                    </Label>
                    <Input
                      id="school_address"
                      type="textarea"
                      className="form-control"
                      rows="3"
                      placeholder="Address..."
                      invalid={!!(formik.touched.fullAddress && formik.errors.fullAddress)}
                      {...formik.getFieldProps('fullAddress')}
                    />
                    <FormFeedback>{formik.errors.fullAddress}</FormFeedback>
                  </FormGroup>
                </Col>

                <Col md={6}>
                  <FormGroup className="mb-4">
                    <Label htmlFor="latitude" className="form-label  ">
                      Latitude
                    </Label>
                    <Input
                      id="latitude"
                      type="text"
                      className="form-control mb-1"
                      placeholder="Latitude..."
                      invalid={!!(formik.touched.latitude && formik.errors.latitude)}
                      {...formik.getFieldProps('latitude')}
                    />
                    Ref: 20.12345678
                    <FormFeedback>{formik.errors.latitude}</FormFeedback>
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup className="mb-4">
                    <Label htmlFor="longitude" className="form-label  ">
                      Longitude
                    </Label>
                    <Input
                      id="longitude"
                      type="text"
                      className="form-control mb-1"
                      placeholder="Longitude..."
                      invalid={!!(formik.touched.longitude && formik.errors.longitude)}
                      {...formik.getFieldProps('longitude')}
                    />
                    Ref: 20.12345678
                    <FormFeedback>{formik.errors.longitude}</FormFeedback>
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
      {(user?.entityPermissions?.school.admin ||
        (schoolDetail.editable &&
          (schoolDetail?.createdBy?.id === user.id || schoolDetail?.createdBy?.teamLead?.id === user.id))) && (
        <div className="mb-4">
          <Button type="submit" color="primary" className="me-3 ">
            Update Information
          </Button>
          <a
            href="#"
            className="ms-4 text-dark"
            onClick={() => {
              history.replace(SMKLinks.SCHOOL_LISTING);
            }}>
            Cancel
          </a>
        </div>
      )}
    </Form>
  );
};

ContactDetails.propTypes = {};

export default ContactDetails;
