import Loader from 'components/Loader';
import SlugInput from 'components/SlugInput';
import { useFormik } from 'formik';
import isEmpty from 'lodash/isEmpty';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toastify';
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap';
import { BABY_NAMES_GENDER_ARRAY, HINDI_BABY_NAMES_GENDER_ARRAY, LANGUAGE_ARRAY } from 'utils/constants';
import * as Yup from 'yup';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import useRequest from '../../../hooks/useRequest';
import { titleCaseIfExists } from '../../../utils/helper';
import { SMKLinks } from '../../../utils/links';

const EditBabyname = () => {
  const history = useHistory();
  toast.configure();

  const { id: babyNameId } = useParams();

  const [babyDetails, setBabyDetails] = useState({});
  const [nakshatraList, setNakshatraList] = useState([]);
  const [rashiList, setRashiList] = useState([]);
  const [religionList, setReligionList] = useState([]);
  const [originList, setOriginList] = useState([]);

  const [meaningWordsList, setMeaningWordsList] = useState([]);

  const [editBabyNameRequest, { loading: editBabyNameLoading }] = useRequest({
    url: `/baby-name/admin/${babyNameId}`,
    method: 'GET',
    onSuccess: (data) => {
      setBabyDetails(data);
    },
    onError: (err) => {
      console.log(`Error occurred: ${err}`);
    },
  });

  const [updateBabyNameRequest, { loading: updateBabyNameLoading }] = useRequest({
    url: `/baby-name/admin/${babyNameId}`,
    method: 'PUT',
    onSuccess: (data) => {
      history.replace(SMKLinks.BABY_NAME_LISTING);
      toast.success('Success! Baby Name has been updated.');
    },
    onError: (err) => {
      toast.error(err?.message?.message);
      console.log(`Error occurred: ${err}`);
    },
  });

  const [getbabyNameMetaRequest, { loading: getbabyNameMetaLoading }] = useRequest({
    url: `/baby-name/admin/metaInfo/search`,
    method: 'POST',
    onSuccess: (data) => {
      const reply = data?.results;
      getInitialValuesOfDropdowns(reply);
    },
  });

  const getInitialFormValues = () => {
    const values = { ...babyDetails };
    values.active = babyDetails?.active;
    values.approved = babyDetails?.approved;
    values.name = babyDetails?.name;
    values.slug = babyDetails?.slug;
    values.meaning = babyDetails?.meaning;
    values.description = babyDetails?.description;
    values.gender = babyDetails?.genderValue;

    values.language = {
      label: titleCaseIfExists(babyDetails?.language == 1 ? 'Hindi' : 'English'),
      value: babyDetails?.language,
      ...babyDetails?.language,
    };

    values.rashi = {
      label: titleCaseIfExists(babyDetails?.rashi?.name),
      value: babyDetails?.rashi?.id,
      ...babyDetails?.rashi,
    };
    values.nakshatra = {
      label: titleCaseIfExists(babyDetails?.nakshatra?.name),
      value: babyDetails?.nakshatra?.id,
      ...babyDetails?.nakshatra,
    };
    values.origins = babyDetails?.origins?.map((origin) => ({
      label: titleCaseIfExists(origin?.name),
      value: origin?.id,
      ...origin,
    }));
    values.religions = babyDetails?.religions?.map((religion) => ({
      label: titleCaseIfExists(religion?.name),
      value: religion?.id,
      ...religion,
    }));
    values.meanings = babyDetails?.meanings?.map((meaning) => ({
      label: titleCaseIfExists(meaning?.name),
      value: meaning?.id,
      ...meaning,
    }));

    if (values.nakshatra.value == undefined) {
      values.nakshatra = [];
    }
    if (values.rashi.value == undefined) {
      values.rashi = [];
    }

    return values;
  };

  const isEmptyValue = (val) => {
    return val !== '' && val !== null ? val : undefined;
  };

  const formik = useFormik({
    validateOnChange: true,
    validateOnBlur: false,
    initialValues: getInitialFormValues(),
    enableReinitialize: true,
    onSubmit: (values) => {
      const babynameUpdatePayload = {
        language: values?.language?.value,
        genderValue: values?.gender,
        name: isEmptyValue(values?.name),
        slug: isEmptyValue(values?.slug),
        meaning: isEmptyValue(values?.meaning),
        spell: isEmptyValue(values?.spell),
        syllable: isEmptyValue(values?.syllable),
        phonetic: isEmptyValue(values?.phonetic),
        pronunciation: isEmptyValue(values?.pronunciation),
        // rashi: values?.rashi,
        nakshatra: isEmptyValue(values.nakshatra),
        religions: isEmptyValue(values.religions),
        meanings: isEmptyValue(values.meanings),
        origins: isEmptyValue(values.origins),
        published: values.published,
        active: values.active,
        approved: values.approved,
        description: values.description,
      };

      updateBabyNameRequest({
        body: {
          ...babynameUpdatePayload,
        },
      });
    },
    validationSchema: Yup.object().shape({
      name: Yup.string()
        .required('Please provide babyname name')
        .min(2, 'Name should be at least 2 characters long')
        .max(128, 'Name should be at most 128 characters long')
        .nullable(true),
      description: Yup.string()
        // .required('Please provide baby name description')
        .min(2, 'Description should be at least 2 characters long')
        .max(1024, 'Description should be at most 1024 characters long')
        .nullable(true),
      gender: Yup.string()
        .required('Please provide gender')
        .nullable(true),
      // meanings: Yup.array().required().min(1, 'Please provide meanings'),
      origins: Yup.array()
        .required()
        .min(1, 'Please provide origin'),
      // nakshatra: Yup.object()
      //   .required('Please provide nakshatra')
      //   .nullable(true),
      // religions: Yup.array()
      //   .required()
      //   .min(1, 'Please provide religion'),
      meaning: Yup.string()
        .required()
        .min(10, 'Meaning should be at least 10 characters long')
        .max(512, 'Meaning should be at most 512 characters long')
        .nullable(true),
    }),
  });

  useEffect(() => {
    editBabyNameRequest();
    getbabyNameMetaRequest({
      body: {
        page: 1,
        size: 1000,
        sortBy: 'id',
        sortOrder: -1,
      },
    });
  }, []);

  const getInitialValuesOfDropdowns = (reply) => {
    setNakshatraList(
      reply
        .filter((t) => t.type === 1 && t.language === formik.values.language?.value)
        .map((r) => ({ ...r, label: r.name, value: r.id }))
    );
    setRashiList(
      reply
        .filter((t) => t.type === 2 && t.language === formik.values.language?.value)
        .map((r) => ({ ...r, label: r.name, value: r.id }))
    );
    setReligionList(
      reply
        .filter((t) => t.type === 3 && t.language === formik.values.language?.value)
        .map((r) => ({ ...r, label: r.name, value: r.id }))
    );
    setOriginList(
      reply
        .filter((t) => t.type === 4 && t.language === formik.values.language?.value)
        .map((r) => ({ ...r, label: r.name, value: r.id }))
    );
    setMeaningWordsList(
      reply
        .filter((t) => t.type === 5 && t.language === formik.values.language?.value)
        .map((r) => ({ ...r, label: r.name, value: r.id }))
    );
  };

  useEffect(() => {
    getbabyNameMetaRequest({
      body: {
        page: 1,
        size: 1000,
        sortBy: 'id',
        sortOrder: -1,
      },
    });
  }, [formik.values.language]);

  return (
    <>
      {babyDetails && (
        <div className="page-content">
          <Loader isActive={getbabyNameMetaLoading || updateBabyNameLoading || editBabyNameLoading} />

          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs title="Babynames" breadcrumbItem="Edit Babyname" />

            <Form onSubmit={formik.handleSubmit}>
              <Row>
                <Col md={9}>
                  <Card>
                    <CardBody>
                      <Row className="align-items-center">
                        <Col md={6}>
                          <FormGroup className="mb-4">
                            <Label for="language" className="form-label">
                              Language
                            </Label>
                            <Select
                              id="language"
                              options={LANGUAGE_ARRAY}
                              value={formik.values.language}
                              onChange={(language) => formik.setFieldValue('language', language)}
                            />
                            {formik.touched.language && (
                              <div className="invalid-feedback d-block">{formik.errors.language}</div>
                            )}
                          </FormGroup>
                        </Col>

                        <Col md={6}>
                          <FormGroup className="mb-4">
                            <Label for="baby_gender" className="form-label">
                              Gender
                            </Label>
                            <Select
                              id="baby_gender"
                              options={
                                formik.values.language?.value === 1
                                  ? HINDI_BABY_NAMES_GENDER_ARRAY
                                  : BABY_NAMES_GENDER_ARRAY
                              }
                              value={
                                formik.values.language?.value === 1
                                  ? HINDI_BABY_NAMES_GENDER_ARRAY?.find((e) => e?.value === formik.values.gender)
                                  : BABY_NAMES_GENDER_ARRAY?.find((e) => e?.value === formik.values.gender)
                              }
                              onChange={(gender) => formik.setFieldValue('gender', gender?.value)}
                            />
                            {formik.touched.gender && (
                              <div className="invalid-feedback d-block">{formik.errors.gender}</div>
                            )}
                          </FormGroup>
                        </Col>

                        <Col md={12}>
                          <FormGroup className="mb-4">
                            <Label for="babyname" className="form-label  ">
                              Baby Name
                            </Label>
                            <Input
                              id="babyname"
                              type="text"
                              className="form-control"
                              placeholder="Baby Name..."
                              label="Baby Name"
                              invalid={!!(formik.touched.name && formik.errors.name)}
                              {...formik.getFieldProps('name')}
                              error={!isEmpty(formik.errors.name)}
                            />
                            <FormFeedback>{formik.errors.name}</FormFeedback>
                          </FormGroup>
                        </Col>

                        <Col md={12} className="mb-4">
                          <SlugInput
                            formik={formik}
                            ID={babyNameId}
                            nameValue={formik.values.name}
                            formGroupClassName="mb-0"
                          />
                        </Col>

                        <Col md={12}>
                          <FormGroup className="mb-4">
                            <Label for="babyname_meaning" className="form-label  ">
                              Meaning
                            </Label>
                            <Input
                              id="babyname_meaning"
                              name="babyname_meaning"
                              type="text"
                              rows="2"
                              className="form-control"
                              placeholder="Baby name meaning..."
                              invalid={!!(formik.touched.meaning && formik.errors.meaning)}
                              {...formik.getFieldProps('meaning')}
                            />
                            <FormFeedback>{formik.errors.meaning}</FormFeedback>
                          </FormGroup>
                        </Col>

                        <Col md={6}>
                          <FormGroup className="mb-4">
                            <Label for="baby_meanings" className="form-label  ">
                              Meanings
                            </Label>
                            <Select
                              id="baby_meanings"
                              placeholder="Select..."
                              value={formik.values.meanings}
                              onChange={(meaning) => formik.setFieldValue('meanings', meaning)}
                              options={meaningWordsList}
                              isMulti
                            />
                            {formik.touched.meanings && (
                              <div className="invalid-feedback d-block">{formik.errors.meanings}</div>
                            )}
                          </FormGroup>
                        </Col>

                        <Col md={6}>
                          <FormGroup className="mb-4">
                            <Label for="baby_origin" className="form-label  ">
                              Origins
                            </Label>
                            <Select
                              id="baby_origin"
                              placeholder="Select..."
                              value={formik.values.origins}
                              onChange={(origin) => formik.setFieldValue('origins', origin)}
                              options={originList}
                              isMulti
                              isClearable
                            />
                            {formik.touched.origins && (
                              <div className="invalid-feedback d-block">{formik.errors.origins}</div>
                            )}
                          </FormGroup>
                        </Col>
                        <Col md={6}>
                          <FormGroup className="mb-4">
                            <Label for="baby_religion" className="form-label  ">
                              Religions
                            </Label>
                            <Select
                              id="baby_religion"
                              placeholder="Select..."
                              value={formik.values.religions}
                              onChange={(religion) => formik.setFieldValue('religions', religion)}
                              options={religionList}
                              isMulti
                              isClearable
                            />
                            {formik.touched.religions && (
                              <div className="invalid-feedback d-block">{formik.errors.religions}</div>
                            )}
                          </FormGroup>
                        </Col>

                        <Col md={6}>
                          <FormGroup className="mb-4">
                            <Label for="baby_rashi" className="form-label  ">
                              Rashi
                            </Label>
                            <Select
                              id="baby_rashi"
                              placeholder="Select..."
                              value={formik.values.rashi}
                              onChange={(rashi) => formik.setFieldValue('rashi', rashi)}
                              options={rashiList}
                              isClearable
                            />
                            {formik.touched.rashi && (
                              <div className="invalid-feedback d-block">{formik.errors.rashi}</div>
                            )}
                          </FormGroup>
                        </Col>
                        <Col md={6}>
                          <FormGroup className="mb-4">
                            <Label for="baby_nakshatra" className="form-label  ">
                              Nakshatra
                            </Label>
                            <Select
                              id="baby_nakshatra"
                              placeholder="Select..."
                              value={formik.values.nakshatra}
                              onChange={(nakshatra) => formik.setFieldValue('nakshatra', nakshatra)}
                              options={nakshatraList}
                              isClearable
                            />
                            {formik.touched.nakshatra && (
                              <div className="invalid-feedback d-block">{formik.errors.nakshatra}</div>
                            )}
                          </FormGroup>
                        </Col>

                        <Col md={12}>
                          <FormGroup className="mb-4">
                            <Label for="similarNames" className="form-label  ">
                              Similar Names
                            </Label>
                            <Select
                              id="similarNames"
                              placeholder="Select..."
                              value={formik.values.similarNames}
                              onChange={(similarNames) => formik.setFieldValue('similarNames', similarNames)}
                              options={[]}
                            />
                            {formik.touched.similarNames && (
                              <div className="invalid-feedback d-block">{formik.errors.similarNames}</div>
                            )}
                          </FormGroup>
                        </Col>

                        <Col md={12}>
                          <FormGroup className="mb-3">
                            <Label for="description" className="form-label  ">
                              Description
                            </Label>
                            <Input
                              id="description"
                              type="textarea"
                              rows={10}
                              className="form-control"
                              placeholder="Baby name description..."
                              label="description"
                              invalid={!!(formik.touched.description && formik.errors.description)}
                              {...formik.getFieldProps('description')}
                              error={!isEmpty(formik.errors.description)}
                            />
                            <FormFeedback>{formik.errors.description}</FormFeedback>
                          </FormGroup>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>

                  <Card>
                    <CardBody>
                      <div className="px-3">
                        <Row>
                          <Col>
                            <FormGroup className="mb-3">
                              <Label for="syllableCount" className="form-label  ">
                                Syllable Count
                              </Label>
                              <h5>{babyDetails?.syllableCount}</h5>
                            </FormGroup>
                          </Col>

                          <Col>
                            <FormGroup className="mb-3">
                              <Label for="wordCount" className="form-label  ">
                                Word Count
                              </Label>
                              <h5>{babyDetails?.wordCount}</h5>
                            </FormGroup>
                          </Col>

                          <Col>
                            <FormGroup className="mb-3">
                              <Label for="characterCount" className="form-label  ">
                                Character Count
                              </Label>
                              <h5>{babyDetails?.characterCount}</h5>
                            </FormGroup>
                          </Col>

                          <Col>
                            <FormGroup className="mb-3">
                              <Label for="pythagoreanNumerology" className="form-label  ">
                                Pythagorean Numerology
                              </Label>
                              <h5>{babyDetails?.pythagoreanNumerology}</h5>
                            </FormGroup>
                          </Col>

                          <Col>
                            <FormGroup className="mb-3">
                              <Label for="indianNumerology" className="form-label  ">
                                Indian Numerology
                              </Label>
                              <h5>{babyDetails?.indianNumerology}</h5>
                            </FormGroup>
                          </Col>
                        </Row>
                      </div>
                    </CardBody>
                  </Card>

                  {babyDetails?.famousPeople?.length > 0 && (
                    <div className="my-5 pt-5">
                      <h4 className="bg-success text-center p-3 rounded-2 text-white mb-4">Famous People</h4>
                      {babyDetails?.famousPeople?.map((p, index) => {
                        return (
                          <Card>
                            <CardBody>
                              <CardTitle>
                                {' '}
                                {index + 1}. {p.name}
                              </CardTitle>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: p.description,
                                }}
                              />
                            </CardBody>
                          </Card>
                        );
                      })}
                    </div>
                  )}
                </Col>
                <Col md={3}>
                  <Card>
                    <CardBody>
                      <Button type="submit" color="primary" className="mb-3 w-100">
                        Save Name
                      </Button>
                      <Button
                        color="light"
                        className="w-100"
                        onClick={() => {
                          history.replace(SMKLinks.BABY_NAME_LISTING);
                        }}>
                        Cancel
                      </Button>
                    </CardBody>
                  </Card>
                  <Card>
                    <CardBody>
                      <div>
                        <CardTitle className="mb-3">Publish</CardTitle>
                        {[true, false].map((option, i) => (
                          <div key={`approved_${i}`} className="form-check mb-3 d-inline-block me-4">
                            <input
                              type="radio"
                              id={`approved_${option}`}
                              name="babyname_approved"
                              className="form-check-input"
                              checked={formik.values.approved === option}
                              onChange={() => formik.setFieldValue('approved', option)}
                            />
                            <label className="form-check-label" htmlFor={`approved_${option}`}>
                              {option ? 'Yes' : 'No'}
                            </label>
                          </div>
                        ))}
                      </div>
                      <div>
                        <CardTitle className="mb-3">Active </CardTitle>
                        {[true, false].map((option, i) => (
                          <div key={`active_${i}`} className="form-check mb-3 d-inline-block me-4">
                            <input
                              type="radio"
                              id={`active_${option}`}
                              name="babyname_active"
                              className="form-check-input"
                              checked={formik.values.active === option}
                              onChange={() => formik.setFieldValue('active', option)}
                            />
                            <label className="form-check-label" htmlFor={`active_${option}`}>
                              {option ? 'Yes' : 'No'}
                            </label>
                          </div>
                        ))}
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Form>
          </Container>
        </div>
      )}
    </>
  );
};

export default EditBabyname;
