import TextEditor from 'components/TextEditor';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Button, Card, CardBody, FormGroup, Input, Label } from 'reactstrap';

const BlogFAQ = ({ formik, blogdetails }) => {
  const [faqSections, setFaqSections] = useState([]);

  const handleUpdateValue = (index, type, value) => {
    const updatedFaqSections = [...faqSections];
    updatedFaqSections[index][type] = value;
    setFaqSections(updatedFaqSections);
  };

  const addFaqSection = () => {
    setFaqSections([...faqSections, { title: '', description: '' }]);
  };

  const removeFaqSection = (index) => {
    const updatedFaqSections = faqSections.filter((_, i) => i !== index);
    setFaqSections(updatedFaqSections);
  };

  useEffect(() => {
    formik.setFieldValue('faqs', !isEmpty(faqSections) && !isEmpty(faqSections[0]?.title) ? faqSections : []);
  }, [faqSections]);

  useEffect(() => {
    if (!isEmpty(blogdetails) && !isEmpty(blogdetails?.faqs)) {
      setFaqSections(blogdetails?.faqs);
    }
  }, [blogdetails]);

  return (
    <div className="mb-5">
      <Card className="border">
        <CardBody>
          <h5 className="border-bottom pb-3 mb-4">FAQ Items</h5>
          {!isEmpty(faqSections) &&
            faqSections?.map((section, index) => (
              <div key={index} className="mb-4 border border-dark rounded-2 p-4">
                <div className="row mb-3 d-flex justify-content-end">
                  <div className="col-md-3 text-end">
                    <Button outline color="danger" onClick={() => removeFaqSection(index)}>
                      Remove
                    </Button>
                  </div>
                </div>

                <FormGroup className="mb-4">
                  <Label for={`faqTitle-${index}`}>Title</Label>
                  <Input
                    placeholder="Enter title"
                    type="text"
                    id={`faqTitle-${index}`}
                    value={section.title}
                    onChange={(e) => handleUpdateValue(index, 'title', e.target.value)}
                  />
                </FormGroup>
                <FormGroup className="mb-4">
                  <Label for={`faqDescription-${index}`}>Description</Label>
                  <TextEditor
                    height="100"
                    simple
                    initialValue={section.description}
                    onChange={(val) => handleUpdateValue(index, 'description', val)}
                  />
                </FormGroup>
              </div>
            ))}
          <Button className="ps-0" color="link" onClick={addFaqSection}>
            <i className="bx bx-plus me-3" />
            Add FAQ
          </Button>
        </CardBody>
      </Card>
    </div>
  );
};

export default React.memo(BlogFAQ);
