import { USER_ROLE } from '../../utils/constants';
import CONTACT_INQUIRY_LINKS from './links';
import ContactInquiryList from './InquiryList';
import AppInquiryList from './appInquiryList';

export default [
  {
    path: CONTACT_INQUIRY_LINKS.CONTACT_INQUIRY_LISTING,
    exact: true,
    component: ContactInquiryList,
    role: [USER_ROLE.ADMIN],
  },
  {
    path: CONTACT_INQUIRY_LINKS.APP_INQUIRY_LISTING,
    exact: true,
    component: AppInquiryList,
    role: [USER_ROLE.ADMIN],
  },
];
