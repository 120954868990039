import { PUBLIC_URL } from '../../utils/constants';

export default {
  BLOG_LIST: `${PUBLIC_URL}/blog/list`,
  ADD_BLOG: `${PUBLIC_URL}/blog/add`,
  EDIT_BLOG: `${PUBLIC_URL}/blog/edit/:id`,

  BLOG_CATEGORIES_LIST: `${PUBLIC_URL}/blog/category/list`,
  ADD_BLOG_CATEGORY: `${PUBLIC_URL}/blog/category/add`,
  EDIT_BLOG_CATEGORY: `${PUBLIC_URL}/blog/category/edit/:id`,

  BLOG_TAGS_LIST: `${PUBLIC_URL}/blog/tag/list`,
  ADD_BLOG_TAG: `${PUBLIC_URL}/blog/tag/add`,
  EDIT_BLOG_TAG: `${PUBLIC_URL}/blog/tag/edit/:id`,
};
