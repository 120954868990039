import React, { useEffect, useState } from 'react';
import { Form, FormFeedback, FormGroup, Input, Label, Button, Card, CardBody, Row, Container, Col } from 'reactstrap';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import { PAYMENT_INTERVAL, SUBSCRIPTION_MODULE_TYPE } from 'utils/constants';
import { Link } from 'react-router-dom';
import { titleCaseIfExists } from 'utils/helper';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useHistory, useParams } from 'react-router-dom';
import Loader from 'components/Loader';
import { isEmpty } from 'lodash';
import useRequest from 'hooks/useRequest';
import { SMKLinks } from 'utils/links';
import { toast } from 'react-toastify';
import Breadcrumbs from 'components/Common/Breadcrumb';
import moment from 'moment';

const AddUpdateSubscriptionPlan = () => {
  const { id: subscriptionPlanID } = useParams();
  const history = useHistory();
  const [offersList, setOffersList] = useState([]);
  const [subscriptionPlanDetails, setSubscriptionPlanDetails] = useState({});

  const primaryModuleTypes = Object.keys(SUBSCRIPTION_MODULE_TYPE)?.map((elem) => ({
    label: titleCaseIfExists(elem),
    value: SUBSCRIPTION_MODULE_TYPE[elem],
  }));
  const paymentIntervalTypes = Object.keys(PAYMENT_INTERVAL)?.map((elem) => ({
    label: titleCaseIfExists(elem),
    value: PAYMENT_INTERVAL[elem],
  }));
  const currencyTypes = [
    { label: 'INR', value: 'INR' },
    { label: 'USD', value: 'USD' },
  ];

  const [getSubscriptionOfferRequest, { loading: getSubscriptionOfferLoading }] = useRequest({
    url: `/subscription/offers/admin/subscription-offer/search`,
    method: 'POST',
    body: {
      page: 1,
      size: 1000,
      sortBy: 'id',
      sortOrder: 0,
    },
    onSuccess: (data) => {
      setOffersList(
        data?.results?.map((e) => ({
          label: e?.name,
          value: e?.id,
        }))
      );
    },
    onError: (err) => {
      toast.error(err?.message);
      console.log(err);
    },
  });

  const [getSubscriptionPlanRequest, { loading: getSubscriptionPlanLoading }] = useRequest({
    url: `/subscription/admin/subscription-plan/${subscriptionPlanID}`,
    method: 'GET',
    onSuccess: (data) => {
      setSubscriptionPlanDetails(data);
    },
    onError: (err) => {
      toast.error(err?.message);
      console.log(err);
    },
  });

  const [addSubscriptionPlanRequest, { loading: addSubscriptionPlanLoading }] = useRequest({
    url: `/subscription/admin/subscription-plan`,
    method: 'POST',
    onSuccess: (data) => {
      const url = SMKLinks.SUBSCRIPTION_PLANS;
      history.replace(url);
      toast.success('Success! Subscription Plan has been added.');
    },
    onError: (err) => {
      toast.error(err?.message);
      console.log(err);
    },
  });

  const [updateSubscriptionPlanRequest, { loading: updateSubscriptionPlanLoading }] = useRequest({
    url: `/subscription/admin/subscription-plan/${subscriptionPlanID}`,
    method: 'PUT',
    onSuccess: (data) => {
      const url = SMKLinks.SUBSCRIPTION_PLANS;
      history.replace(url);
      toast.success('Success! Subscription Plan has been updated.');
    },
    onError: (err) => {
      toast.error(err?.message);
      console.log(err);
    },
  });

  const editInitialValues = () => ({
    isActive: subscriptionPlanDetails?.active,
    isDeleted: subscriptionPlanDetails?.deleted,
    primaryModule: subscriptionPlanDetails?.primaryModule,
    name: subscriptionPlanDetails?.name,
    description: subscriptionPlanDetails?.description,
    paymentInterval: subscriptionPlanDetails?.paymentInterval,
    duration: subscriptionPlanDetails?.duration,
    price: subscriptionPlanDetails?.price,
    currency: subscriptionPlanDetails?.currency,
    offerId: subscriptionPlanDetails?.offerId,
    expiryDate: !isEmpty(subscriptionPlanDetails?.expiryDate) ? new Date(subscriptionPlanDetails?.expiryDate) : null,

    //  counts
    whiteboards: subscriptionPlanDetails?.limits?.whiteboards,
    whiteboardPages: subscriptionPlanDetails?.limits?.whiteboardPages,
    whiteboardInvitees: subscriptionPlanDetails?.limits?.whiteboardInvitees,
    worksheetGenerateDaily: subscriptionPlanDetails?.limits?.worksheetGenerateDaily,
    worksheetDownloadDaily: subscriptionPlanDetails?.limits?.worksheetDownloadDaily,
    worksheetDownloadMonthly: subscriptionPlanDetails?.limits?.worksheetDownloadMonthly,
    claimSchools: subscriptionPlanDetails?.limits?.claimSchools,
    memories: subscriptionPlanDetails?.limits?.memories,
    memoriesImages: subscriptionPlanDetails?.limits?.memoriesImages,
    babies: subscriptionPlanDetails?.limits?.babies,
    caregivers: subscriptionPlanDetails?.limits?.caregivers,
    analyticsAllowed: subscriptionPlanDetails?.limits?.analyticsAllowed,
  });

  const getInitialValues = () => ({
    isActive: true,
    isDeleted: false,
    primaryModule: '',
    name: '',
    description: '',
    paymentInterval: '',
    duration: '',
    price: '',
    expiryDate: '',
    currency: 'INR',
    offerId: '',

    //  counts
    whiteboards: '',
    whiteboardPages: '',
    whiteboardInvitees: '',
    worksheetGenerateDaily: '',
    worksheetDownloadDaily: '',
    worksheetDownloadMonthly: '',
    claimSchools: '',
    memories: '',
    memoriesImages: '',
    babies: '',
    caregivers: '',
    analyticsAllowed: false,
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: isEmpty(subscriptionPlanID) ? { ...getInitialValues() } : { ...editInitialValues() },

    onSubmit: (values) => {
      const payload = {
        active: values?.isActive,
        deleted: values?.isDeleted,
        name: values?.name,
        primaryModule: values?.primaryModule,
        description: values?.description,
        paymentInterval: values?.paymentInterval,
        duration: values?.duration,
        price: values?.price,
        currency: values?.currency,
        offerId: values?.offerId == undefined ? '' : values?.offerId,
        expiryDate: moment(values?.expiryDate)?.format('YYYY-MM-DD'),
        limits: {
          whiteboards: values?.whiteboards,
          whiteboardPages: values?.whiteboardPages,
          whiteboardInvitees: values?.whiteboardInvitees,

          worksheetGenerateDaily: values?.worksheetGenerateDaily,
          worksheetDownloadDaily: values?.worksheetDownloadDaily,
          worksheetDownloadMonthly: values?.worksheetDownloadMonthly,

          claimSchools: values?.claimSchools,
          memories: values?.memories,
          memoriesImages: values?.memoriesImages,
          babies: values?.babies,
          caregivers: values?.caregivers,

          analyticsAllowed: values?.analyticsAllowed,
        },
      };

      isEmpty(subscriptionPlanID)
        ? addSubscriptionPlanRequest({
            body: payload,
          })
        : updateSubscriptionPlanRequest({
            body: payload,
          });
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Please Enter name'),
      primaryModule: Yup.string().required('Please Select Primary Module'),
      description: Yup.string().required('Please Enter Description'),
      paymentInterval: Yup.string().required('Please Enter Payment Interval'),
      duration: Yup.number()
        .min(0)
        .required('Please Enter Duration'),
      price: Yup.number()
        .min(0)
        .required('Please Enter price'),
      expiryDate: Yup.string().required('Please Enter Expiry Date'),
      currency: Yup.string().required('Please Enter Currency'),

      // limits
      whiteboards: Yup.number()
        .min(0)
        .required('Please Enter Whiteboards Limit'),
      whiteboardPages: Yup.number()
        .min(0)
        .required('Please Enter Whiteboard Pages Limit'),
      whiteboardInvitees: Yup.number()
        .min(0)
        .required('Please Enter Whiteboard Invitees Limit'),
      worksheetGenerateDaily: Yup.number()
        .min(0)
        .required('Please Enter Worksheet Generate Daily Limit'),
      worksheetDownloadDaily: Yup.number()
        .min(0)
        .required('Please Enter Worksheet Download Daily Limit'),
      worksheetDownloadMonthly: Yup.number()
        .min(0)
        .required('Please Enter Worksheet Download Monthly Limit'),
      claimSchools: Yup.number()
        .min(0)
        .required('Please Enter Claim Schools Limit'),
      memories: Yup.number()
        .min(0)
        .required('Please Enter Memories Limit'),
      memoriesImages: Yup.number()
        .min(0)
        .required('Please Enter Memories Images Limit'),
      babies: Yup.number()
        .min(0)
        .required('Please Enter Babies Limit'),
      caregivers: Yup.number()
        .min(0)
        .required('Please Enter Care Givers Limit'),
    }),
  });

  useEffect(() => {
    if (!isEmpty(subscriptionPlanID)) {
      getSubscriptionPlanRequest();
    }
  }, [subscriptionPlanID]);

  useEffect(() => {
    getSubscriptionOfferRequest();
  }, []);

  return (
    <div className="page-content">
      <Loader isActive={addSubscriptionPlanLoading || getSubscriptionPlanLoading || updateSubscriptionPlanLoading} />
      <Container fluid>
        <Breadcrumbs
          title="Subscription Plan"
          breadcrumbItem={`${isEmpty(subscriptionPlanID) ? 'Add' : 'Edit'}  Subscription Plan`}
        />

        <Form onSubmit={formik.handleSubmit}>
          <Row>
            <Col md={9}>
              <Card>
                <CardBody>
                  <Row className="mx-0">
                    <CustomInput
                      name="name"
                      type="text"
                      placeholder="Name"
                      col={8}
                      className={'ps-0'}
                      formik={formik}
                    />

                    <FormGroup className="mb-4 col-md-4">
                      <Label for="paymentInterval" htmlFor="grade" className="form-label ">
                        Primary module
                      </Label>
                      <div className="position-relative">
                        <Select
                          options={primaryModuleTypes}
                          onChange={(e) => formik.setFieldValue('primaryModule', e?.value)}
                          value={primaryModuleTypes?.find((e) => e?.value === formik?.values?.primaryModule)}
                          onBlur={formik.handleBlur}
                          placeholder="Select Primary Module..."
                        />
                        {formik.touched.primaryModule && formik.errors.primaryModule ? (
                          <FormFeedback className="d-block">{formik.errors.primaryModule}</FormFeedback>
                        ) : null}
                      </div>
                    </FormGroup>

                    <CustomInput
                      name="description"
                      type="textarea"
                      rows={5}
                      placeholder="Description"
                      col={12}
                      className={'ps-0'}
                      formik={formik}
                    />

                    <h5 className="ps-0 b mb-4 border-bottom pb-2">Payments</h5>
                    <FormGroup className="mb-4 col-md-6">
                      <Label for="paymentInterval" htmlFor="grade" className="form-label ">
                        Payment Interval
                      </Label>
                      <div className="position-relative">
                        <Select
                          options={paymentIntervalTypes}
                          onChange={(e) => formik.setFieldValue('paymentInterval', e?.value)}
                          value={paymentIntervalTypes?.find((e) => e?.value === formik?.values?.paymentInterval)}
                          onBlur={formik.handleBlur}
                          placeholder="Select Payment Interval..."
                        />
                        {formik.touched.paymentInterval && formik.errors.paymentInterval ? (
                          <FormFeedback className="d-block">{formik.errors.paymentInterval}</FormFeedback>
                        ) : null}
                      </div>
                    </FormGroup>

                    <CustomInput
                      name="duration"
                      type="number"
                      placeholder={`Duration ( ${formik.values.paymentInterval === 'yearly' ? 'Years' : 'Months'})`}
                      col={6}
                      formik={formik}
                    />
                    <FormGroup className="mb-4 col-md-6">
                      <Label for="currency" htmlFor="grade" className="form-label ">
                        Currency
                      </Label>
                      <div className="position-relative">
                        <Select
                          options={currencyTypes}
                          onChange={(e) => formik.setFieldValue('currency', e?.value)}
                          value={currencyTypes?.find((e) => e?.value === formik?.values?.currency)}
                          onBlur={formik.handleBlur}
                          placeholder="Select Currency..."
                        />
                        {formik.touched.currency && formik.errors.currency ? (
                          <FormFeedback className="d-block">{formik.errors.currency}</FormFeedback>
                        ) : null}
                      </div>
                    </FormGroup>

                    <CustomInput name="price" type="number" placeholder="Price" col={6} formik={formik} />

                    <FormGroup className="mb-4 col-md-12">
                      <Label for="expiryDate" htmlFor="expiryDate" className="form-label ">
                        Expiry Date
                      </Label>
                      <div className="position-relative">
                        <DatePicker
                          minDate={new Date()}
                          className="form-control"
                          selected={formik.values.expiryDate}
                          onChange={(date) => formik.setFieldValue('expiryDate', date)}
                          placeholderText="Select Date..."
                          dateFormat="dd-MM-yyyy"
                        />
                        {formik.touched.expiryDate && formik.errors.expiryDate ? (
                          <FormFeedback className="d-block">{formik.errors.expiryDate}</FormFeedback>
                        ) : null}
                      </div>
                    </FormGroup>

                    <h5 className="ps-0 b mb-4 border-bottom pb-2 mt-4">Limits</h5>

                    <Col md={4}>
                      {['analyticsAllowed']?.map((elem, index) => (
                        <FormGroup className={'mb-0'} key={index}>
                          <Label className="mb-3">Analytics Allowed </Label>
                          <div className="d-flex align-items-center">
                            {[true, false].map((option, i) => (
                              <div key={`${elem}_${i}`} className="form-check d-inline-block me-4">
                                <input
                                  type="radio"
                                  id={`${elem}_${option}`}
                                  name={elem}
                                  className="form-check-input"
                                  checked={formik.values[elem] === option}
                                  onChange={() => formik.setFieldValue(`${elem}`, option)}
                                />
                                <label className="form-check-label" htmlFor={`${elem}_${option}`}>
                                  {option ? 'Yes' : 'No'}
                                </label>
                              </div>
                            ))}
                          </div>
                        </FormGroup>
                      ))}
                    </Col>
                    <CustomInput name="whiteboards" type="number" placeholder="Whiteboards" col={4} formik={formik} />
                    <CustomInput
                      name="whiteboardPages"
                      type="number"
                      placeholder="Whiteboard Pages"
                      col={4}
                      formik={formik}
                    />
                    <CustomInput
                      name="whiteboardInvitees"
                      type="number"
                      placeholder="Whiteboard Invitees"
                      col={4}
                      formik={formik}
                    />
                    <CustomInput
                      name="worksheetGenerateDaily"
                      type="number"
                      placeholder="Worksheet Generate Daily"
                      col={4}
                      formik={formik}
                    />
                    <CustomInput
                      name="worksheetDownloadDaily"
                      type="number"
                      placeholder="Worksheet Download Daily"
                      col={4}
                      formik={formik}
                    />
                    <CustomInput
                      name="worksheetDownloadMonthly"
                      type="number"
                      placeholder="Worksheet Download Monthly"
                      col={4}
                      formik={formik}
                    />
                    <CustomInput
                      name="claimSchools"
                      type="number"
                      placeholder="Claim Schools"
                      col={4}
                      formik={formik}
                    />
                    <CustomInput name="memories" type="number" placeholder="Memories" col={4} formik={formik} />
                    <CustomInput
                      name="memoriesImages"
                      type="number"
                      placeholder="Memories Images"
                      col={4}
                      formik={formik}
                    />
                    <CustomInput name="babies" type="number" placeholder="Babies" col={4} formik={formik} />
                    <CustomInput name="caregivers" type="number" placeholder="Care givers" col={4} formik={formik} />
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col md={3}>
              <Card className="mb-4">
                <CardBody>
                  <Button color={'primary'} className="w-100" size="md" type="submit">
                    {!isEmpty(subscriptionPlanID) ? 'Update' : 'Add Subscription Plan'}
                  </Button>
                  <Link to={'/subscription-plans'} className="btn btn-light w-100 mt-3">
                    Cancel
                  </Link>
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  {['isActive', 'isDeleted']?.map((elem, index) => (
                    <FormGroup className={`mb-4`} key={index}>
                      <Label className="mb-3">{elem} </Label>
                      <div className="d-flex align-items-center">
                        {[true, false].map((option, i) => (
                          <div key={`${elem}_${i}`} className="form-check d-inline-block me-4">
                            <input
                              type="radio"
                              id={`${elem}_${option}`}
                              name={elem}
                              className="form-check-input"
                              checked={formik.values[elem] === option}
                              onChange={() => formik.setFieldValue(`${elem}`, option)}
                            />
                            <label className="form-check-label" htmlFor={`${elem}_${option}`}>
                              {option ? 'Yes' : 'No'}
                            </label>
                          </div>
                        ))}
                      </div>
                    </FormGroup>
                  ))}
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  <Label for="offerId" htmlFor="offerId" className="form-label ">
                    Offers
                  </Label>
                  <div className="position-relative">
                    <Select
                      isClearable
                      options={offersList}
                      onChange={(e) => formik.setFieldValue('offerId', e?.value)}
                      value={offersList?.find((e) => e?.value === formik?.values?.offerId)}
                      onBlur={formik.handleBlur}
                      placeholder="Select Offer..."
                    />
                    {formik.touched.offerId && formik.errors.offerId ? (
                      <FormFeedback className="d-block">{formik.errors.offerId}</FormFeedback>
                    ) : null}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Form>
      </Container>
    </div>
  );
};

const CustomInput = ({ name, type, placeholder, col, className, rows, formik }) => {
  return (
    <FormGroup className={`mb-4 col-md-${col} ${className ? className : ''}`}>
      <Label htmlFor={name} className="form-label ">
        {placeholder}
      </Label>
      <Input
        name={name}
        type={type}
        className="form-control"
        placeholder={`Enter ${placeholder}`}
        onChange={formik?.handleChange}
        value={formik?.values[name]}
        rows={rows}
        invalid={formik?.touched[name] && formik?.errors[name] ? true : false}
      />
      {formik?.touched[name] && formik?.errors[name] && (
        <FormFeedback className="d-block">{formik.errors[name]}</FormFeedback>
      )}
    </FormGroup>
  );
};

export default AddUpdateSubscriptionPlan;
