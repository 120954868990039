import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Button,
  Label,
  Input,
  Container,
  Form,
  FormFeedback,
  CardTitle,
} from 'reactstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import isEmpty from 'lodash/isEmpty';
import Select from 'react-select';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Loader from 'components/Loader';
import Breadcrumbs from '../../../../components/Common/Breadcrumb';
import useRequest from '../../../../hooks/useRequest';
import { SMKLinks } from '../../../../utils/links';
import { LANGUAGE_ARRAY, MODULE_TYPE } from 'utils/constants';
import SlugInput from 'components/SlugInput';
import { titleCaseIfExists } from 'utils/helper';

const UpdateCategories = () => {
  const history = useHistory();
  const { id: categoryId } = useParams();

  toast.configure();

  const [categoryList, setcategoryList] = useState();
  const [categorydetails, setcategorydetails] = useState();
  const selectTypeOptions = [];

  for (const [key, value] of Object.entries(MODULE_TYPE)) {
    selectTypeOptions?.push({
      label: titleCaseIfExists(key),
      value: value,
    });
  }

  const rootCategory = categoryList?.filter((e) => e?.parentCategory === null);
  const rootCategorySelect = rootCategory?.map((e) => ({
    value: e?.id,
    label: e?.name,
  }));
  const parentCategorySelect = categoryList?.map((e) => ({
    value: e?.id,
    label: e?.name,
  }));

  const [categoryListRequest, { loading: categoryListLoading }] = useRequest({
    method: 'POST',
    url: `/common/admin/category/search`,
    onSuccess: (data) => {
      setcategoryList(data?.results);
    },
  });

  const [getCategoryRequest, { loading: getCategoryLoading }] = useRequest({
    url: `/common/admin/category/${categoryId}`,
    method: 'GET',
    onSuccess: (data) => {
      setcategorydetails(data);
    },
    onError: (err) => {
      toast.error(err?.message);
      console.log(err);
    },
  });

  const [addCategoryRequest, { loading: addCategoryLoading }] = useRequest({
    url: `/common/admin/category`,
    method: 'POST',
    onSuccess: (data) => {
      const url = SMKLinks.CATEGORIES_LIST;
      history.replace(url);
      toast.success(`Success! Category ( ${data?.id} ) has been added.`);
    },
    onError: (err) => {
      toast.error(err?.message);
      console.log(err);
    },
  });

  const [updateCategoryRequest, { loading: updateCategoryLoading }] = useRequest({
    url: `/common/admin/category/${categoryId}`,
    method: 'PUT',
    onSuccess: (data) => {
      const url = SMKLinks.CATEGORIES_LIST;
      history.replace(url);
      toast.success(`Success! Category ( ${data?.id} ) has been updated.`);
    },
    onError: (err) => {
      toast.error(err?.message);
      console.log(err);
    },
  });

  const SOURCE_LIST = [
    { value: 'schoolmykids', label: 'Schoolmykids' },
    { value: 'theparentz', label: 'theParentz' },
  ];
  const editInitialValues = () => {
    const fortypeObj = selectTypeOptions?.filter((e) => e?.value === categorydetails?.forType);
    const languageObj = LANGUAGE_ARRAY?.filter((e) => e?.value === categorydetails?.language);
    const sourceObj = SOURCE_LIST?.filter((e) => e?.value === categorydetails?.frontend_app_name);
    const parentCategoryObj = parentCategorySelect?.filter((e) => e?.value === categorydetails?.parentCategory?.id);
    const rootCategoryObj = rootCategorySelect?.filter((e) => e?.value === categorydetails?.rootCategory?.id);

    return {
      id: categorydetails?.id,
      active: categorydetails?.active,
      deleted: categorydetails?.deleted,

      name: categorydetails?.name,
      slug: categorydetails?.slug,
      description: categorydetails?.description,

      forType: !isEmpty(fortypeObj)
        ? {
            label: fortypeObj[0]?.label,
            value: fortypeObj[0]?.value,
          }
        : null,
      language: !isEmpty(languageObj)
        ? {
            label: languageObj[0]?.label,
            value: languageObj[0]?.value,
          }
        : null,
      frontend_app_name: !isEmpty(sourceObj)
        ? {
            label: sourceObj[0]?.label,
            value: sourceObj[0]?.value,
          }
        : null,
      parentCategory: !isEmpty(parentCategoryObj)
        ? {
            label: parentCategoryObj[0]?.label,
            value: parentCategoryObj[0]?.value,
          }
        : null,
      rootCategory: !isEmpty(rootCategoryObj)
        ? {
            label: rootCategoryObj[0]?.label,
            value: rootCategoryObj[0]?.value,
          }
        : null,
    };
  };

  const getInitialValues = () => ({
    active: true,
    deleted: false,
    name: '',
    slug: '',
    description: '',
    forType: '',
    parentCategory: '',
    rootCategory: '',
    language: { value: 0, label: 'English' },
    frontend_app_name: '',
  });

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: false,
    initialValues: isEmpty(categoryId) ? { ...getInitialValues() } : { ...editInitialValues() },
    onSubmit: (values) => {
      const data = {
        active: values?.active,
        deleted: values?.deleted,
        forType: values?.forType?.value,
        frontend_app_name: values?.frontend_app_name?.value || null,
        language: values?.language?.value,

        name: values?.name,
        slug: values?.slug,
        description: values?.description,
        parentCategory: !isEmpty(values?.parentCategory)
          ? {
              id: values?.parentCategory?.value,
            }
          : null,
        rootCategory: !isEmpty(values?.rootCategory)
          ? {
              id: values?.rootCategory?.value,
            }
          : null,
      };

      isEmpty(categoryId)
        ? addCategoryRequest({
            body: {
              ...data,
            },
          })
        : updateCategoryRequest({
            body: {
              ...data,
            },
          });
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required('Please provide name'),
      slug: Yup.string().required('Please provide slug'),
      // description: Yup.string().required('Please provide description').nullable(true),
      forType: Yup.object()
        .required('Please provide for type')
        .nullable(true),
    }),
  });

  useEffect(() => {
    if (!isEmpty(categoryId)) {
      getCategoryRequest();
    }
  }, [categoryId]);

  useEffect(() => {
    categoryListRequest({
      body: {
        page: 1,
        size: 500,
        sortBy: 'id',
        sortOrder: 0,
      },
    });
  }, []);

  return (
    <div className="page-content">
      <Loader isActive={addCategoryLoading || getCategoryLoading || updateCategoryLoading || categoryListLoading} />
      <Container fluid>
        <Breadcrumbs title="Location" breadcrumbItem={`${isEmpty(categoryId) ? 'Add' : 'Edit'}  Category`} />

        <Form onSubmit={formik.handleSubmit}>
          <Row>
            <Col md={9}>
              <Card>
                <CardBody>
                  <Row className="align-items-center">
                    {categoryId && (
                      <Col md={6}>
                        <FormGroup className="mb-4">
                          <Label className="form-label  ">Category ID</Label>
                          <Input
                            id="id"
                            style={{ background: 'whitesmoke' }}
                            disabled
                            className="form-control"
                            {...formik.getFieldProps('id')}
                          />
                        </FormGroup>
                      </Col>
                    )}

                    <Col md={6}>
                      <FormGroup className="mb-4">
                        <Label for="language" className="form-label  ">
                          Language
                        </Label>
                        <Select
                          id="language"
                          options={LANGUAGE_ARRAY}
                          value={formik?.values?.language}
                          onChange={(language) => formik.setFieldValue('language', language)}
                        />
                        <FormFeedback>{formik.errors.language}</FormFeedback>
                      </FormGroup>
                    </Col>

                    <Col md={6}>
                      <FormGroup className="mb-4">
                        <Label htmlFor="type" className="form-label  ">
                          For Type
                        </Label>
                        <Select
                          id="type"
                          isClearable
                          options={selectTypeOptions}
                          value={formik.values.forType}
                          onChange={(forType) => formik.setFieldValue('forType', forType)}
                        />
                        {formik.touched.forType && (
                          <div className="invalid-feedback d-block">{formik.errors.forType}</div>
                        )}
                      </FormGroup>
                    </Col>

                    <Col md={6}>
                      <FormGroup className="mb-4">
                        <Label for="frontend_app_name" className="form-label  ">
                          Frontend app name
                        </Label>
                        <Select
                          isClearable
                          id="frontend_app_name"
                          options={SOURCE_LIST}
                          value={formik?.values?.frontend_app_name}
                          onChange={(frontend_app_name) => formik.setFieldValue('frontend_app_name', frontend_app_name)}
                        />
                        <FormFeedback>{formik.errors.frontend_app_name}</FormFeedback>
                      </FormGroup>
                    </Col>

                    <Col md={6}>
                      <FormGroup className="mb-4">
                        <Label for="parentCategory" className="form-label  ">
                          Parent Category
                        </Label>
                        <Select
                          isClearable
                          id="parentCategory"
                          options={parentCategorySelect}
                          value={formik?.values?.parentCategory}
                          onChange={(parentCategory) => formik.setFieldValue('parentCategory', parentCategory)}
                        />
                        <FormFeedback>{formik.errors.parentCategory}</FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup className="mb-4">
                        <Label for="rootCategory" className="form-label  ">
                          Root Category
                        </Label>
                        <Select
                          isClearable
                          id="rootCategory"
                          options={rootCategorySelect}
                          value={formik?.values?.rootCategory}
                          onChange={(rootCategory) => formik.setFieldValue('rootCategory', rootCategory)}
                        />
                        <FormFeedback>{formik.errors.rootCategory}</FormFeedback>
                      </FormGroup>
                    </Col>

                    <Col md={12}>
                      <FormGroup className="mb-4">
                        <Label for="name" className="form-label  ">
                          Name
                        </Label>
                        <Input
                          id="name"
                          name="name"
                          type="text"
                          className="form-control"
                          placeholder="Name.."
                          invalid={!!(formik.touched.name && formik.errors.name)}
                          {...formik.getFieldProps('name')}
                        />
                        <FormFeedback>{formik.errors.name}</FormFeedback>
                      </FormGroup>
                    </Col>

                    <Col md={12} className="mb-4">
                      <SlugInput
                        formik={formik}
                        ID={categoryId}
                        nameValue={formik.values.name}
                        formGroupClassName={'mt-0'}
                      />
                    </Col>

                    <Col md={12}>
                      <FormGroup className="mb-4">
                        <Label for="description" className="form-label  ">
                          Description
                        </Label>
                        <Input
                          id="description"
                          name="description"
                          style={{ height: '100px' }}
                          type="textarea"
                          className="form-control"
                          placeholder="Description.."
                          invalid={!!(formik.touched.description && formik.errors.description)}
                          {...formik.getFieldProps('description')}
                        />
                        <FormFeedback>{formik.errors.description}</FormFeedback>
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col md={3}>
              <Card>
                <CardBody>
                  <Button type="submit" color="primary" className="mb-3 w-100">
                    {categoryId ? 'Save' : 'Add'}
                  </Button>

                  <Button
                    className="w-100"
                    color="light"
                    onClick={() => {
                      history.replace(SMKLinks.CATEGORIES_LIST);
                    }}>
                    Cancel
                  </Button>
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  <div>
                    <CardTitle className="mb-3">Active </CardTitle>
                    {[true, false].map((option, i) => (
                      <div key={`active_${i}`} className="form-check mb-3 d-inline-block me-4">
                        <input
                          type="radio"
                          id={`active_${option}`}
                          name="active"
                          className="form-check-input"
                          checked={formik.values.active === option}
                          onChange={() => formik.setFieldValue('active', option)}
                        />
                        <label className="form-check-label" htmlFor={`active_${option}`}>
                          {option ? 'Yes' : 'No'}
                        </label>
                      </div>
                    ))}
                  </div>

                  <div>
                    <CardTitle className="mb-3">Delete </CardTitle>
                    {[true, false].map((option, i) => (
                      <div key={`deleted_${i}`} className="form-check mb-3 d-inline-block me-4">
                        <input
                          type="radio"
                          id={`deleted_${option}`}
                          name="deleted"
                          className="form-check-input"
                          checked={formik.values.deleted === option}
                          onChange={() => formik.setFieldValue('deleted', option)}
                        />
                        <label className="form-check-label" htmlFor={`deleted_${option}`}>
                          {option ? 'Yes' : 'No'}
                        </label>
                      </div>
                    ))}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Form>
      </Container>
    </div>
  );
};

export default UpdateCategories;
