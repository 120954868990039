// Import Breadcrumb
import { Divider } from '@material-ui/core';
import classNames from 'classnames';
import Breadcrumbs from 'components/Common/Breadcrumb';
import TableError from 'components/TableError';
import { useFormik } from 'formik';
import { isEmpty } from 'lodash';
import invert from 'lodash/invert';
import * as moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toastify';
import {
  Badge,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
  UncontrolledDropdown,
} from 'reactstrap';
import { createStructuredSelector } from 'reselect';
import * as Yup from 'yup';
import { userSelector } from '../../../common/globalComponents/selectors';
import Loader from '../../../components/Loader';
import Paginate from '../../../components/Paginate';
import useRequest from '../../../hooks/useRequest';
import { useRouter } from '../../../hooks/useRouter';
import { ACTIVE_AND_DELETED_ARRAY, SORT_VALUES, SOURCE, USER_ROLE, USER_SOURCE } from '../../../utils/constants';
import {
  buildURL,
  checkIfValueExist,
  isValidPassword,
  printDateTimeTwoLines,
  titleCaseIfExists,
} from '../../../utils/helper';
import { SMKLinks } from '../../../utils/links';
import UpdateCreatedbyModal from 'components/UpdateCreatedbyModal';
// import { useNavigate } from 'react-router-dom';

const stateSelector = createStructuredSelector({
  user: userSelector,
});

const UsersList = (props) => {
  const { user } = useSelector(stateSelector);
  const router = useRouter();

  const isTpzRoute = router?.location?.pathname?.includes('tpz');
  const appSource = isTpzRoute ? 'tpz' : 'smk';
  const appSourcePrint = appSource?.toUpperCase();

  // const navigate = useNavigate();

  const history = useHistory();
  toast.configure();

  const [roles, setRoles] = useState([]);
  const [users, setUsers] = useState([]);
  const [usersPagination, setUsersPagination] = useState({});

  const [showSelectRole, setShowSelectRole] = useState(false);
  const [showChangePassword, setShowChangePassword] = useState(false);

  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedUserRoles, setSelectedUserRoles] = useState(null);

  const [showTeamLeader, setShowTeamLeader] = useState(false);
  const [selectedTeamLeader, setSelectedTeamLeader] = useState(null);
  const [showCreatedByModal, setShowCreatedByModal] = useState(false);
  const [currentUserId, setcurrentUserId] = useState('');

  const defaultFilters = {
    active: { value: null, label: 'All' },
    deleted: { value: null, label: 'All' },
    isAppInstalled: { value: null, label: 'All' },
    unsubscribed: { value: null, label: 'All' },
    startDate: moment().subtract(5, 'years'),
    endDate: moment(),
    id: undefined,
    email: undefined,
    source: undefined,
    type: undefined,
    roleId: 0,
  };

  const [filters, setFilters] = useState(defaultFilters);
  const [sort, setSort] = useState({
    sortBy: 'id',
    sortOrder: 1,
  });
  const pageOptions = {
    sizePerPage: 10,
    totalSize: 50, // replace later with size(users),
    custom: true,
  };

  const [getUserListRequest, { loading: getUserListLoading }] = useRequest({
    url: `/user/admin/search`,
    method: 'POST',
    onSuccess: (data) => {
      setUsersPagination(data?.pagination);
      setUsers(data?.results);
    },
    onError: (err) => {
      toast.error(err?.message);
    },
  });
  const [getUserRolesRequest, { loading: getUserRolesLoading }] = useRequest({
    url: `/user/admin/roles/search`,
    method: 'POST',
    onSuccess: (data) => {
      setRoles(data?.results);
    },
    onError: (err) => {
      toast.error(err?.message);
    },
  });

  const [assignUserRolesRequest, { loading: assignUserRolesLoading }] = useRequest({
    method: 'POST',
    body: selectedUserRoles?.map((e) => ({ id: e?.id })),
    onSuccess: (data) => {
      // console.log(data);
      toast.success('Success! Roles has been Added.');
      setUsers([data, ...users.filter((u) => u.id !== data?.id)]);
      // handleChangePage(router.query.page)
      handleChangePage(1);

      setSelectedUser(null);
      setSelectedUserRoles(null);

      setShowSelectRole(false);
    },
    onError: (err) => {
      toast.error(err?.message);
    },
  });

  const [assignTeamLeaderRequest, { loading: assignTeamLeaderLoading }] = useRequest({
    method: 'POST',
    onSuccess: (data) => {
      // console.log(data);
      setUsers([data, ...users.filter((u) => u.id !== data?.id)]);
      setSelectedUser(null);
      setSelectedTeamLeader(null);
      setShowTeamLeader(false);
      toast.success('Success! Team lead has been added.');
    },
    onError: (err) => {
      toast.error(err?.message);
    },
  });

  const [deleteTeamLeaderRequest, { loading: deleteTeamLeaderLoading }] = useRequest({
    method: 'POST',
    onSuccess: (data) => {
      // console.log(data);
      toast.success('Success! Team lead has been removed.');
      setUsers([data, ...users.filter((u) => u.id !== data?.id)]);
    },
    onError: (err) => {
      toast.error(err?.message);
    },
  });

  const [changePasswordRequest, { loading: changePasswordLoading }] = useRequest({
    url: '/user/admin/changePassword',
    method: 'PUT',
    onSuccess: (data) => {
      console.log(data);
      setSelectedUser(null);
      setShowChangePassword(false);
    },
    onError: (err) => {
      toast.error(err?.message);
    },
  });

  const [toggleEnableUserRequest, { loading: toggleEnableUserLoading }] = useRequest({
    method: 'PUT',
    onSuccess: (data) => {
      console.log(data);
      toast.success('Success! User has been Enabled.');
      handleChangePage(1);
    },
    onError: (err) => {
      toast.error(err?.message);
    },
  });

  const [whiteListforTesting, { loading: whiteListforTestingLoadng }] = useRequest({
    method: 'PUT',
    onSuccess: (data) => {
      console.log(data);
      toast.success('Success! User has been added.');
      handleChangePage(1);
    },
    onError: (err) => {
      toast.error(err?.message);
    },
  });

  const [toggleDeletedUserRequest, { loading: toggleDeletedUserLoading }] = useRequest({
    method: 'PUT',
    onSuccess: (data) => {
      // console.log(data);
      toast.success('Success! User has been deleted.');
      handleChangePage(1);
    },
    onError: (err) => {
      toast.error(err?.message);
    },
  });

  const setPageInUrl = (page) => {
    history.push({
      pathname: router.pathname,
      search: `?${new URLSearchParams({ page }).toString()}`,
    });
  };

  const handleChangePage = (page) => {
    setPageInUrl(page);

    getUserListRequest({
      body: {
        page,
        size: 50,
        ...filters,
        active: checkIfValueExist(filters.active?.value),
        deleted: checkIfValueExist(filters.deleted?.value),
        unsubscribed: checkIfValueExist(filters.unsubscribed?.value),
        isAppInstalled: checkIfValueExist(filters.isAppInstalled?.value),
        roleId: checkIfValueExist(filters?.roleId?.id),
        ...SORT_VALUES,
        appSource: isTpzRoute ? SOURCE[1]?.value : SOURCE[0]?.value,
      },
    });
  };

  useEffect(() => {
    handleChangePage(1);
    getUserRolesRequest({ body: { page: 1, size: 500 } });
  }, []);

  const applyFilters = () => {
    handleChangePage(1);
  };

  const clearFilters = () => {
    setPageInUrl(1);

    setFilters({
      ...defaultFilters,
      id: '',
      email: '',
      source: '',
      type: '',
    });

    getUserListRequest({
      body: {
        page: 1,
        size: 50,
        ...defaultFilters,
        active: checkIfValueExist(defaultFilters.active?.value),
        deleted: checkIfValueExist(defaultFilters.deleted?.value),
        unsubscribed: checkIfValueExist(defaultFilters.unsubscribed?.value),
        isAppInstalled: checkIfValueExist(defaultFilters.isAppInstalled?.value),
        ...SORT_VALUES,
        appSource: isTpzRoute ? SOURCE[1]?.value : SOURCE[0]?.value,
      },
    });
  };

  const asignUserRoles = () => {
    assignUserRolesRequest({
      url: `/user/admin/assignRole/${selectedUser.id}`,
    });
  };
  const asignTeamLeader = () => {
    assignTeamLeaderRequest({
      url: `/user/admin/assignTeamLead/${selectedUser.id}/${selectedTeamLeader.value}`,
    });
  };

  const deleteTeamLeader = (user) => {
    deleteTeamLeaderRequest({ url: `/user/admin/removeTeamLead/${user?.id}` });
  };

  const toggleEnable = (data) => {
    toggleEnableUserRequest({ url: `/user/admin/toggleEnable/${data?.id}` });
  };
  const togglewhiteListforTesting = (data) => {
    whiteListforTesting({
      url: `/user/admin/${data?.id}/toggleWhitelistForTesting`,
    });
  };
  const toggleDeleted = (data) => {
    toggleDeletedUserRequest({
      url: `/user/admin/${data?.id}`,
      body: {
        id: data?.id,
        deleted: !data?.deleted,
      },
    });
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      newPassword: '',
      confirmPassword: '',
    },
    onSubmit(values) {
      changePasswordRequest({
        data: {
          ...values,
          email: selectedUser.email,
          id: selectedUser?.id,
          appSource: isTpzRoute ? SOURCE[1]?.value : SOURCE[0]?.value,
        },
      });
    },
    validationSchema: Yup.object().shape({
      newPassword: Yup.string()
        .required('New Password is requried')
        .test('new-password-test', 'Please provide valid password', isValidPassword),
      confirmPassword: Yup.string()
        .required('Confirm Password is requried')
        .test('confirm-new-password-test', 'Please provide valid password', function(val) {
          const { newPassword } = this.parent;
          let message = '';
          if (!isValidPassword(val)) {
            message = 'Please provide valid password';
          } else if (val !== newPassword) {
            message = 'Passwords do not match';
          } else {
            return true;
          }
          return this.createError({ message });
        }),
    }),
  });

  return (
    <>
      <div className="page-content">
        <Loader
          isActive={
            getUserListLoading ||
            getUserRolesLoading ||
            assignUserRolesLoading ||
            assignTeamLeaderLoading ||
            deleteTeamLeaderLoading ||
            toggleEnableUserLoading ||
            changePasswordLoading ||
            toggleDeletedUserLoading ||
            whiteListforTestingLoadng
          }
        />

        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title={`${appSourcePrint} Users`} breadcrumbItem={`${appSourcePrint} Users List`} />

          <Card>
            <CardBody>
              <div className="mt-4">
                <div className="row">
                  <div className="col-md-2 mb-4">
                    <label className="form-label">Published</label>
                    <Select
                      options={ACTIVE_AND_DELETED_ARRAY}
                      value={filters.active}
                      onChange={(value) => {
                        setFilters({ ...filters, active: value });
                      }}
                    />
                  </div>
                  <div className="col-md-2 mb-4">
                    <label className="form-label">Deleted</label>
                    <Select
                      options={ACTIVE_AND_DELETED_ARRAY}
                      value={filters.deleted}
                      onChange={(value) => setFilters({ ...filters, deleted: value })}
                    />
                  </div>
                  <div className="col-md-2 mb-4">
                    <label className="form-label">Unsubscribed</label>
                    <Select
                      options={ACTIVE_AND_DELETED_ARRAY}
                      value={filters.unsubscribed}
                      onChange={(value) => setFilters({ ...filters, unsubscribed: value })}
                    />
                  </div>

                  <div className="col-md-2 mb-4">
                    <Label htmlFor="status" className="form-label  ">
                      Is App Installed
                    </Label>
                    <Select
                      options={[
                        { value: null, label: 'All' },
                        { value: true, label: 'Yes' },
                        { value: false, label: 'No' },
                      ]}
                      value={filters.isAppInstalled}
                      onChange={(value) => setFilters({ ...filters, isAppInstalled: value })}
                    />
                  </div>

                  <div className="col-md-3 mb-4">
                    <label className="form-label">Created Date - From</label>
                    <Input
                      type="date"
                      name="startDate"
                      placeholder="From Date"
                      value={filters.startDate.format('YYYY-MM-DD')}
                      max={moment().format('YYYY-MM-DD')}
                      onChange={(e) =>
                        setFilters({
                          ...filters,
                          startDate: moment(e.target.value),
                        })
                      }
                    />
                  </div>

                  <div className="col-md-3 mb-4">
                    <label className="form-label">Created Date - To</label>
                    <Input
                      type="date"
                      name="endDate"
                      placeholder="To Date"
                      value={filters.endDate.format('YYYY-MM-DD')}
                      max={moment().format('YYYY-MM-DD')}
                      onChange={(e) =>
                        setFilters({
                          ...filters,
                          endDate: moment(e.target.value),
                        })
                      }
                    />
                  </div>

                  <div className="col-md-3 mb-4">
                    <label className="form-label">User Id</label>
                    <Input
                      type="number"
                      name="userId"
                      placeholder="User Id"
                      value={filters.id}
                      onChange={(e) => setFilters({ ...filters, id: e.target.value })}
                    />
                  </div>

                  <div className="col-md-3 mb-4">
                    <label className="form-label">Email</label>
                    <Input
                      type="text"
                      name="email"
                      placeholder="Email"
                      value={filters.email}
                      onChange={(e) => setFilters({ ...filters, email: e.target.value })}
                    />
                  </div>

                  <div className="col-md-3 mb-4">
                    <Label htmlFor="role" className="form-label  ">
                      Type
                    </Label>
                    <Select
                      id="role"
                      placeholder="Please select an option"
                      options={Object.entries(USER_ROLE).map(([key, value]) => ({
                        value,
                        label: titleCaseIfExists(key),
                      }))}
                      value={
                        filters?.type
                          ? {
                              label: titleCaseIfExists(invert(USER_ROLE)[filters.type]),
                              value: filters.type,
                            }
                          : ''
                      }
                      onChange={(val) => setFilters({ ...filters, type: val?.value })}
                    />
                  </div>
                  <div className="col-md-3 mb-4">
                    <Label htmlFor="source" className="form-label  ">
                      Source
                    </Label>
                    <Select
                      id="source"
                      placeholder="Please select an option"
                      options={Object.entries(USER_SOURCE).map(([key, value]) => ({
                        value,
                        label: titleCaseIfExists(key),
                      }))}
                      value={
                        filters?.source === 0 || filters?.source
                          ? {
                              label: titleCaseIfExists(invert(USER_SOURCE)[filters.source]),
                              value: filters.source,
                            }
                          : null
                      }
                      onChange={(val) => setFilters({ ...filters, source: val?.value })}
                    />
                  </div>
                  <div className="col-md-3 mb-4">
                    <Label htmlFor="status" className="form-label  ">
                      Roles
                    </Label>
                    <Select
                      id="roleId"
                      placeholder="Please select an option"
                      options={roles.map((s) => ({
                        ...s,
                        value: s.id,
                        label: s.name,
                      }))}
                      value={filters?.roleId}
                      onChange={(val) => setFilters({ ...filters, roleId: val })}
                    />
                  </div>
                </div>
              </div>

              <div className="mt-4">
                <a className="btn btn-primary me-4" onClick={applyFilters}>
                  Apply Filters
                </a>
                <a className="" onClick={clearFilters}>
                  Clear
                </a>
              </div>
            </CardBody>
          </Card>

          <Row className="mb-2">
            <Col sm="4">
              <div className="search-box me-2 mb-2 d-inline-block">
                <div className="position-relative font-bold">{usersPagination?.totalCount} Users Found</div>
              </div>
            </Col>
            <Col sm="8">
              <div className="text-sm-end">
                {user.entityPermissions?.user?.create && (
                  <Link
                    replace
                    className="btn btn-success btn-rounded waves-effect waves-light mb-2 me-2"
                    to={isTpzRoute ? SMKLinks.TPZ_ADD_USER : SMKLinks.SMK_ADD_USER}>
                    <i className="mdi mdi-plus me-1" />
                    Add User
                  </Link>
                )}
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg="12">
              <div className="">
                <div className="table-responsive" style={{ minHeight: 300 }}>
                  <Table className="project-list-table table-nowrap table-centered table-borderless">
                    <thead>
                      <tr className="table-warning">
                        <th scope="col">#Id</th>
                        {/* <th scope="col">Image</th> */}
                        <th scope="col">App Installed </th>
                        <th scope="col">Name / Email</th>
                        <th scope="col">App Source</th>
                        <th scope="col">Source</th>
                        <th scope="col">Location</th>
                        <th scope="col">User Type</th>
                        <th scope="col">Created Date</th>
                        <th scope="col">User Roles</th>
                        <th scope="col">Assigned Team Leader</th>
                        <th scope="col">White listed For Testing</th>
                        <th scope="col">App Installed</th>
                        <th scope="col">Unsubscribed</th>
                        <th scope="col">Active</th>
                        <th scope="col">Deleted</th>

                        {/* <th scope="col">Last LoggedIn Date</th> */}
                        <th scope="col" />
                      </tr>
                    </thead>
                    <tbody>
                      {!isEmpty(users) ? (
                        users?.map((data, index) => (
                          <tr key={index}>
                            <td>{data.id}</td>
                            {/* <td><img src={data.socialImageUrl} height="30" /></td> */}
                            <td align="center">
                              <i
                                className={classNames(
                                  'bx bx-mobile fs-3 ',
                                  { 'text-success': data?.isAppInstalled },
                                  { 'text-secondary': !data?.isAppInstalled }
                                )}
                              />

                              <i
                                className={classNames(
                                  'bx bx-check-circle fs-3 ms-2',
                                  { 'text-success': data?.active },
                                  { 'text-danger': !data?.active }
                                )}
                              />
                            </td>
                            <td>
                              <p className="mb-0 font-weight-medium">
                                <Link
                                  to={buildURL(SMKLinks.USER_PROFILE, {
                                    id: data.id,
                                  })}>
                                  {`${data?.firstName} ${data?.lastName || ''}`}
                                </Link>
                              </p>
                              {data?.email}
                            </td>
                            <td>{titleCaseIfExists(data.appSource)}</td>
                            <td>{titleCaseIfExists(invert(USER_SOURCE)[data.source])}</td>
                            <td>
                              {!isEmpty(data?.location?.countryName) && (
                                <>
                                  {data?.location?.countryName} ({data?.location?.countryCode})
                                  <br />
                                  {data?.location?.state}
                                </>
                              )}
                            </td>
                            {/* <td align="center">{invert(USER_ROLE)[data.role] ? invert(USER_ROLE)[data.role] : '-'}</td>
                              <td align="center">{data.roles?.length > 0 ? data.roles?.map((r) => r.name)?.join(', ') : '-'}</td>
                              <td>{printDateTimeTwoLines(data.createdDate)}</td>
                              <td align="center">{data.teamLead ? data?.teamLead?.displayName : '-'}</td>
                              <td align="center">{data.lastLoggedInDate ? printDateTimeTwoLines(data.lastLoggedInDate) : '-'}</td> */}
                            <td align="center">{invert(USER_ROLE)[data.role]}</td>
                            <td>{printDateTimeTwoLines(data.createdDate)}</td>
                            <td align="center">
                              {data.roles?.map((r) => (
                                <>
                                  <Badge className="bg-light p-2 my-1 rounded-pill">{r.name}</Badge>
                                  <br />
                                </>
                              ))}{' '}
                            </td>
                            <td align="center">
                              {data?.teamLead?.displayName && (
                                <Badge className="bg-light p-1 my-1 rounded-2">{data?.teamLead?.displayName}</Badge>
                              )}
                            </td>
                            <td align="center">
                              <Badge className={data?.whitelistedForTesting ? 'bg-success' : 'bg-danger'}>
                                {data?.whitelistedForTesting ? 'Yes' : 'No'}
                              </Badge>
                            </td>
                            <td align="center">
                              <Badge className={data?.isAppInstalled ? 'bg-success' : 'bg-danger'}>
                                {data?.isAppInstalled ? 'Yes' : 'No'}
                              </Badge>
                            </td>
                            <td align="center">
                              <Badge className={data?.unsubscribed ? 'bg-success' : 'bg-danger'}>
                                {data?.unsubscribed ? 'Yes' : 'No'}
                              </Badge>
                            </td>
                            <td>
                              <Badge className={data?.active ? 'bg-success' : 'bg-danger'}>
                                {data?.active ? 'Yes' : 'No'}
                              </Badge>
                            </td>
                            <td>
                              <Badge className={data?.deleted ? 'bg-success' : 'bg-danger'}>
                                {data?.deleted ? 'Yes' : 'No'}
                              </Badge>
                            </td>
                            {/* <td align="center">{printDateTimeTwoLines(data.lastSuccessfulLoggedDate)}</td> */}

                            <td>
                              <UncontrolledDropdown>
                                <DropdownToggle href="#" className="card-drop" tag="i">
                                  <i role="button" className="mdi mdi-dots-vertical font-size-18" />
                                </DropdownToggle>
                                <DropdownMenu className="dropdown-menu-end">
                                  <DropdownItem
                                    onClick={() => {
                                      window.location = `/${isTpzRoute ? 'tpz' : 'smk'}/user/edit/${data.id}`;
                                    }}>
                                    <i className="fas fa-pencil-alt text-success m-1 pe-3 " />
                                    Edit User
                                  </DropdownItem>

                                  <DropdownItem
                                    onClick={() => {
                                      setSelectedUser(data);
                                      setShowChangePassword(true);
                                    }}>
                                    <i className="fas fa-pencil-alt text-success m-1 pe-3 " />
                                    Change Password
                                  </DropdownItem>

                                  <DropdownItem
                                    onClick={() => {
                                      setSelectedUser(data);
                                      setSelectedUserRoles(data?.roles);
                                      setShowSelectRole(true);
                                    }}>
                                    <i className="fas fa-user-cog text-primary m-1 pe-3 " />
                                    Assign Roles
                                  </DropdownItem>

                                  <DropdownItem
                                    onClick={() => {
                                      setSelectedUser(data);
                                      setSelectedTeamLeader(true);
                                      setShowTeamLeader(true);
                                    }}>
                                    <i className="fas fa-user-cog text-primary m-1 pe-3 " />
                                    Assign Team Leader
                                  </DropdownItem>

                                  {data?.teamLead ? (
                                    <DropdownItem
                                      onClick={() => {
                                        setSelectedUser(data);
                                        deleteTeamLeader(data);
                                      }}>
                                      <i className="fas fa-trash-alt text-danger m-1 pe-3 " />
                                      Remove Team Leader
                                    </DropdownItem>
                                  ) : (
                                    ''
                                  )}
                                  <Divider className="my-2" />
                                  <DropdownItem onClick={() => toggleEnable(data)}>
                                    <i
                                      className={`fas ${data?.active ? 'fa-trash' : 'fa-power-off'} 
                                      ${data?.active ? 'text-danger' : 'text-success'} m-1 pe-3`}
                                    />
                                    {data?.active ? 'Disable' : 'Enable'}
                                  </DropdownItem>

                                  <DropdownItem onClick={() => toggleDeleted(data)}>
                                    <i className="fas fa-trash-alt text-danger m-1 pe-3 " />
                                    {data?.deleted ? 'Undeleted' : 'Deleted'}
                                  </DropdownItem>
                                  <DropdownItem
                                    onClick={() => {
                                      togglewhiteListforTesting(data);
                                    }}>
                                    <i className="fas fa-user-cog text-primary m-1 pe-3 " />
                                    white List for Testing
                                  </DropdownItem>
                                  <DropdownItem
                                    onClick={() => {
                                      setShowCreatedByModal(true);
                                      setcurrentUserId(data?.id);
                                    }}>
                                    <i className="fas fa-pencil-alt text-success m-1 pe-3 " />
                                    Change CreatedBy
                                  </DropdownItem>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <TableError />
                      )}
                    </tbody>
                  </Table>
                </div>
              </div>
            </Col>
          </Row>

          <Modal
            isOpen={showSelectRole}
            role="dialog"
            autoFocus
            centered
            className=""
            tabIndex="-1"
            toggle={() => {
              setShowSelectRole(false);
              setSelectedUser(null);
              setSelectedUserRoles(null);
            }}>
            <div className="modal-content">
              <ModalHeader
                className="modalHeader"
                toggle={() => {
                  setShowSelectRole(false);
                  setSelectedUser(null);
                  setSelectedUserRoles(null);
                }}>
                Select User Roles
              </ModalHeader>
              <ModalBody>
                <FormGroup className="mb-4">
                  <Label htmlFor="name" className="form-label  ">
                    User
                  </Label>
                  <p>
                    <b>
                      {selectedUser?.firstName} {selectedUser?.lastName || ''}
                    </b>
                    <br />
                    {selectedUser?.email}
                  </p>
                </FormGroup>

                <FormGroup className="mb-4">
                  <Label htmlFor="name" className="form-label  ">
                    Roles
                  </Label>
                  <Select
                    styles={{
                      menu: (provided) => ({ ...provided, zIndex: 9999 }),
                    }}
                    isClearable
                    isMulti
                    id="user_roles"
                    placeholder="Please select user roles"
                    options={roles.map((s) => ({
                      ...s,
                      value: s.id,
                      label: s.name,
                    }))}
                    value={selectedUserRoles?.map((s) => ({
                      ...s,
                      value: s.id,
                      label: s.name,
                    }))}
                    onChange={(val) => {
                      setSelectedUserRoles(val);
                    }}
                  />
                </FormGroup>
              </ModalBody>
              <ModalFooter>
                <Button type="button" color="success" onClick={() => asignUserRoles()}>
                  Save
                </Button>
              </ModalFooter>
            </div>
          </Modal>

          <Modal
            isOpen={selectedTeamLeader}
            role="dialog"
            autoFocus
            centered
            className="exampleModal"
            tabIndex="-1"
            toggle={() => {
              setShowTeamLeader(false);
              setSelectedUser(null);
              setSelectedTeamLeader(null);
            }}>
            <div className="modal-content">
              <ModalHeader
                className="modalHeader"
                toggle={() => {
                  setShowTeamLeader(false);
                  setSelectedUser(null);
                  setSelectedTeamLeader(null);
                }}>
                Select Team Leader
              </ModalHeader>
              <ModalBody>
                <FormGroup className="my-3">
                  <Label className="form-label ">User</Label>
                  <Select
                    styles={{
                      menu: (provided) => ({ ...provided, zIndex: 9999 }),
                    }}
                    isClearable
                    id="team_leader"
                    placeholder="Please select team leader"
                    options={users.map((s) => ({
                      label: s.firstName,
                      value: s.id,
                    }))}
                    value={selectedTeamLeader}
                    onChange={(val) => setSelectedTeamLeader(val)}
                  />
                </FormGroup>
              </ModalBody>
              <ModalFooter>
                <Button type="button" color="success" onClick={() => asignTeamLeader()}>
                  Save
                </Button>
              </ModalFooter>
            </div>
          </Modal>

          <Modal
            isOpen={showChangePassword}
            role="dialog"
            autoFocus
            centered
            className="exampleModal"
            tabIndex="-1"
            toggle={() => {
              setShowChangePassword(false);
              setSelectedUser(null);
            }}>
            <div className="modal-content">
              <ModalHeader
                className="modalHeader"
                toggle={() => {
                  setShowChangePassword(false);
                  setSelectedUser(null);
                }}>
                Change Password
              </ModalHeader>
              <ModalBody>
                <Form onSubmit={formik.handleSubmit}>
                  <Card>
                    <CardBody className="py-0">
                      <FormGroup className="mb-4">
                        <Label htmlFor="name" className="form-label  ">
                          User
                        </Label>
                        <p>
                          <b>
                            {selectedUser?.firstName} {selectedUser?.lastName || ''}
                          </b>
                          <br />
                          {selectedUser?.email}
                        </p>
                      </FormGroup>
                      <Row>
                        <Col md={6}>
                          <FormGroup className="mb-4">
                            <Label htmlFor="newPassword" className="form-label  ">
                              New Password
                            </Label>
                            <Input
                              id="newPassword"
                              type="text"
                              className="form-control"
                              placeholder="newPassword"
                              invalid={!!(formik.touched.newPassword && formik.errors.newPassword)}
                              {...formik.getFieldProps('newPassword')}
                            />
                            <FormFeedback>{formik.errors.newPassword}</FormFeedback>
                          </FormGroup>
                        </Col>
                        <Col md={6}>
                          <FormGroup className="mb-4">
                            <Label htmlFor="confirmPassword" className="form-label  ">
                              Confirm Password
                            </Label>
                            <Input
                              id="confirmPassword"
                              type="text"
                              className="form-control"
                              placeholder="Confirm Password"
                              invalid={!!(formik.touched.confirmPassword && formik.errors.confirmPassword)}
                              {...formik.getFieldProps('confirmPassword')}
                            />
                            <FormFeedback>{formik.errors.confirmPassword}</FormFeedback>
                          </FormGroup>
                        </Col>
                      </Row>

                      <div className="text-end mt-3">
                        <Button type="submit" color="success">
                          Change Password
                        </Button>
                      </div>
                    </CardBody>
                  </Card>
                </Form>
              </ModalBody>
            </div>
          </Modal>

          <Paginate pageInfo={usersPagination} onChangePage={handleChangePage} />
        </Container>

        <UpdateCreatedbyModal
          isTpzRoute={isTpzRoute}
          setShowCreatedByModal={setShowCreatedByModal}
          showCreatedByModal={showCreatedByModal}
          apiUrl={`/user/admin/updateOwner/${currentUserId}`}
        />
      </div>
    </>
  );
};

export default UsersList;
