import React from 'react';
import { useSelector } from 'react-redux';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { createStructuredSelector } from 'reselect';
import profileImg from '../../assets/images/profile-img.png';
import avatar2 from '../../assets/images/users/avatar-2.jpg';
import { isLoggedInSelector, userSelector } from '../../common/globalComponents/selectors';
import { titleCaseIfExists } from 'utils/helper';

const stateSelector = createStructuredSelector({
  isLoggedIn: isLoggedInSelector,
  user: userSelector,
});

const WelcomeComp = () => {
  const { user, isLoggedIn } = useSelector(stateSelector);

  return (
    <>
      <Card className="overflow-hidden">
        <div className="bg-soft-primary">
          <Row>
            <Col xs="7">
              <div className="text-primary p-3">
                <h5 className="text-primary">Welcome Back !</h5>
                <p>{titleCaseIfExists(user?.appSource)} Dashboard</p>
              </div>
            </Col>
            <Col xs="5" className="align-self-end">
              <img src={profileImg} alt="Profile Image" className="img-fluid" />
            </Col>
          </Row>
        </div>
        <CardBody className="pt-0">
          <Row>
            <Col sm="12">
              <div className="avatar-md profile-user-wid mb-3">
                <img src={user?.socialImageUrl || avatar2} alt="" className="img-thumbnail rounded-circle" />
              </div>
              <h5 className="font-size-15 text-truncate">{isLoggedIn ? `${user?.firstName} ${user?.lastName}` : ''}</h5>
              <p className="text-muted mb-0 text-truncate">{user?.email}</p>
              <p className="text-muted mb-0 text-truncate">
                {user?.roles?.map((e, i) => (i === 0 ? e?.name : e?.name + ', '))}
              </p>
              <p className="text-muted mb-0 mt-4 text-truncate">
                <b>App source :</b> {user?.appSource}
              </p>
            </Col>

            {/* <Col sm="8">
              <div className="pt-4">
                <Row>
                  <Col xs="6">
                    <h5 className="font-size-15">125</h5>
                    <p className="text-muted mb-0">Projects</p>
                  </Col>
                  <Col xs="6">
                    <h5 className="font-size-15">$1245</h5>
                    <p className="text-muted mb-0">Revenue</p>
                  </Col>
                </Row>
                <div className="mt-4">
                  <Link to="" className="btn btn-primary waves-effect waves-light btn-sm">
                    View Profile <i className="mdi mdi-arrow-right ms-1" />
                  </Link>
                </div>
              </div>
            </Col> */}
          </Row>
        </CardBody>
      </Card>
    </>
  );
};
export default WelcomeComp;
