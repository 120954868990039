import { USER_ROLE } from '../../utils/constants';
import GENERATE_SITEMAP_LINKS from './links';
import SmkSitemap from './Sitemap/smkSitemap';
import TheParentzSitemap from './Sitemap/theParentzSitemap';

export default [
  {
    path: GENERATE_SITEMAP_LINKS.GENERATE_SITEMAP,
    exact: true,
    component: SmkSitemap,
    role: [USER_ROLE.ADMIN],
  },
  {
    path: GENERATE_SITEMAP_LINKS.GENERATE_THE_PARENTZ_SITEMAP,
    exact: true,
    component: TheParentzSitemap,
    role: [USER_ROLE.ADMIN],
  },
];
