import { PUBLIC_URL } from '../../utils/constants';

export default {
  BABY_NAME_DETAILS: `${PUBLIC_URL}/babynames/details/:id`,
  ADD_BABY_NAME: `${PUBLIC_URL}/babynames/add`,
  EDIT_BABY_NAME: `${PUBLIC_URL}/babynames/edit/:id`,
  TRANSLATE_BABY_NAME: `${PUBLIC_URL}/babynames/translate/:id/en=:en`,
  TRANSLATE_BABY_NAME_LIST: `${PUBLIC_URL}/babynames/translate`,

  BABY_NAME_LISTING: `${PUBLIC_URL}/babynames/list`,
  SUGGESTED_BABY_NAME_LISTING: `${PUBLIC_URL}/babynames/suggested-names`,

  ADD_BABY_NAME_META_DATA: `${PUBLIC_URL}/babynames/add/meta-data`,
  EDIT_BABY_NAME_META_DATA: `${PUBLIC_URL}/babynames/edit/meta-data/:id`,
  TRANSLATE_BABY_NAME_META_DATA: `${PUBLIC_URL}/babynames/translate/meta-data/:id/en=:en`,
  BABY_NAME_META_DATA: `${PUBLIC_URL}/babynames/babynames-meta-data`,

  ADD_BABY_NAME_SEO_META_DATA: `${PUBLIC_URL}/babynames/add/seo-meta-data`,
  EDIT_BABY_NAME_SEO_META_DATA: `${PUBLIC_URL}/babynames/edit/seo-meta-data/:id`,
  BABY_NAME_SEO_META_DATA: `${PUBLIC_URL}/babynames/babynames-seo-meta-data`,

  ADD_BABY_NAME_PRONUNCIATION: `${PUBLIC_URL}/babynames/add/pronunciation`,
  EDIT_BABY_NAME_PRONUNCIATION: `${PUBLIC_URL}/babynames/edit/pronunciation/:id`,
  BABY_NAME_PRONUNCIATION: `${PUBLIC_URL}/babynames/babynames-pronunciation`,

  ADD_BABY_NAME_MEANING_ORIGIN: `${PUBLIC_URL}/babynames/add/meaning-origin`,
  EDIT_BABY_NAME_MEANING_ORIGIN: `${PUBLIC_URL}/babynames/edit/meaning-origin/:id`,
  BABY_NAME_MEANING_ORIGIN: `${PUBLIC_URL}/babynames/babynames-meaning-origin`,
};
