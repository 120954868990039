import axios from 'axios';
import { isEmpty } from 'lodash';
import AuthService from 'common/auth.service';
import { isDevMode } from './constants';
import processConfig from '../config/config';
import { useDispatch } from 'react-redux';
import { getUser } from 'containers/authentication/actions';

export const headers = {
  'Content-Type': 'application/json',
  'Access-Control-Allow-Origin': '*',
};
const instance = axios.create();

const authService = new AuthService();

instance.interceptors.request.use();
instance.defaults.withCredentials = !isDevMode;

/*
 * This function return the response from remote server
 * @param {Object} config
 */
function fetchResponse(config) {
  config.url = encodeURI(config.url);

  const source = authService.getAppSourceCookie();
  const API_KEY =
    source === 'theparentz'
      ? process.env.REACT_APP_TPZ_API_KEY
      : source === 'mediwatcher'
      ? process.env.REACT_APP_MEDIWATCHER_API_KEY
      : process.env.REACT_APP_API_KEY;

  config.headers = {
    'x-api-key': API_KEY,
    ...config.headers,
  };

  return instance(config)
    .then((response) => {
      return response?.data;
    })
    .catch(async (error) => {
      console.log('===================== ERROR =====================');

      const logout = () => {
        authService.unsetSessionCookie();
        authService.unsetRefreshSessionCookie();
        authService.unsetAppSourceCookie();
        window.location.reload();
      };

      // if (error?.response?.status === 401 ) {
      if (error?.response?.status === 401 && error?.response?.data?.path?.includes('/user/me')) {
        const token = authService.getRefreshSessionCookie();
        const headers = { Authorization: `Bearer ${token}`, 'x-api-key': API_KEY };
        await fetch(`${processConfig.apiEndpoint}/user/refresh-token?device=0`, {
          method: 'GET',
          headers: headers,
        })
          .then((res) => res.json())
          .then((response) => {
            if (response?.success === false) {
              logout();
            } else {
              const { accessToken, refreshToken } = response;
              if (accessToken && refreshToken) {
                authService.setSessionCookie(accessToken);
                authService.setRefreshSessionCookie(refreshToken);
                window.location.reload();
              }
            }
          })
          .catch((err) => {
            //--------------- Logout ----------------
            logout();
          });
      }

      // let loadingError = error
      if (!error.response) {
        return { data: {}, success: false, message: 'Check your Internet' };
      }
      const { data, status } = error.response || {};

      // SESSION EXPIRED - SESSION TOKEN EXPIRED
      if (status === 440) {
        logout();
      }

      return data;
    });
}

// FIXME: NOT WORKING IN PRODUCTION
function getHeaders() {
  if (!isEmpty(authService.getSessionCookie())) {
    headers.Authorization = `Bearer ${authService.getSessionCookie()}`;
  }

  if (!isEmpty(authService.getAnonymousCookie())) {
    headers.Anonymous = authService.getAnonymousCookie();
  } else {
    authService.unsetAnonymousCookie();
  }

  return headers;
}

// export const api = (config) => fetchResponse({ headers: getHeaders(), ...config });
export const api = (config, header) => fetchResponse({ ...config, headers: { ...getHeaders(), ...header } });

export const get = (url) => {
  return instance.get(url);
};

export const setAxiosCookie = (cookie) => {
  if (cookie !== undefined && cookie !== 'undefined') {
    instance.defaults.headers.cookie = cookie;
  }
};

export const setAxiosLocaleHeader = (code) => {
  if (code !== undefined && code !== 'undefined') {
    instance.defaults.headers.locale = code;
    instance.defaults.headers['accept-language'] = code;
  }
};
