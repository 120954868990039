import { call, put, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import {
  createWorksheet,
  getWorksheetDetail,
  getWorksheetList,
  getWorksheetSearchList,
  getWorksheetSubjectTopics,
  setWorksheetDelete,
  setWorksheetPublish,
  setWorksheetReject,
  createWorksheetCategory,
  clearWorksheetDetails,
} from './actions';
import { api } from '../../utils/axios';
import { buildURL } from '../../utils/helper';
import config from '../../config/config';

import { SMKLinks } from '../../utils/links';

export const GET_WORKSHEET_SUBJECTS_API_URL = `${config.apiEndpoint}/curriculum/admin/subject/search`;
export const GET_WORKSHEET_TOPICS_API_URL = `${config.apiEndpoint}/curriculum/admin/subjectTopic/search`;

export const CREATE_WORKSHEET_API_URL = `${config.apiEndpoint}/worksheet/admin`;

export const GET_WORKSHEET_LIST_API_URL = `${config.apiEndpoint}/worksheet/admin/search`;

export const GET_WORKSHEET_DETAIL_API_URL = `${config.apiEndpoint}/worksheet/admin/`;

export const SET_WORKSHEET_DELETE_API_URL = `${config.apiEndpoint}/worksheet/admin/delete`;

export const SET_WORKSHEET_APPROVE_API_URL = `${config.apiEndpoint}/worksheet/admin/approve`;

export const SET_WORKSHEET_REJECT_API_URL = `${config.apiEndpoint}/worksheet/admin/reject`;

export const CREATE_TOPIC_SUBJECT_API_URL = `${config.apiEndpoint}/admin/subjectTopic`;

//

const setWorksheetDeleteCall = (id, deleted, comments) =>
  api({
    method: 'PUT',
    url: SET_WORKSHEET_DELETE_API_URL,
    data: { id, deleted, comments },
  });

const setWorksheetApproveCall = (id, active, isAnswerVisible, comments) =>
  api({
    method: 'PUT',
    url: SET_WORKSHEET_APPROVE_API_URL,
    data: { id, active, isAnswerVisible, comments },
  });

const setWorksheetRejectCall = (id, comments) =>
  api({
    method: 'PUT',
    url: SET_WORKSHEET_REJECT_API_URL,
    data: { id, comments },
  });

const getWorksheetSubjectsCall = (data) =>
  api({
    method: 'POST',
    url: GET_WORKSHEET_SUBJECTS_API_URL,
    data,
  });

const getWorksheetTopicsCall = (data) =>
  api({
    method: 'POST',
    url: GET_WORKSHEET_TOPICS_API_URL,
    data,
  });

const createWorksheetCall = (data) =>
  api({
    method: 'POST',
    url: CREATE_WORKSHEET_API_URL,
    data,
  });

const getWorksheetListCall = (filters) =>
  api({
    method: 'POST',
    url: GET_WORKSHEET_LIST_API_URL,
    data: filters,
  });

const getWorksheetDetailCall = (id) =>
  api({
    method: 'GET',
    url: GET_WORKSHEET_DETAIL_API_URL + id,
    data: {},
  });

const createTopicSubjectCall = (data) =>
  api({
    method: 'POST',
    url: CREATE_TOPIC_SUBJECT_API_URL,
    data,
  });

function* getWorksheetSubjectTopicsRequest() {
  try {
    yield put(getWorksheetSubjectTopics.request());

    const payload = {
      active: true,
      page: 1,
      size: 100,
      sortBy: 'slug',
      sortOrder: 0,
    };
    const response = yield call(getWorksheetSubjectsCall, payload);
    const response1 = yield call(getWorksheetTopicsCall, { ...payload, size: 1000 });

    const subjects = response?.results;
    const topics = response1?.results;

    yield put(getWorksheetSubjectTopics.success({ subjects, topics }));
  } catch (error) {
    yield put(getWorksheetSubjectTopics.failure(error));
  } finally {
    yield put(getWorksheetSubjectTopics.fulfill());
  }
}

function* createWorksheetRequest(action) {
  try {
    yield put(createWorksheet.request({ isLoading: true }));
    const { worksheetObj, isDuplicate, isEditMode } = action.payload;
    const response = yield call(createWorksheetCall, worksheetObj);
    if (response) {
      if (isDuplicate) {
        yield put(push(buildURL(SMKLinks.WORKSHEET_ADD, {}, { refId: response?.id })));
      } else {
        if (isEditMode) {
          yield put(
            push(
              buildURL(SMKLinks.WORKSHEET_EDIT, {
                id: worksheetObj?.id,
              })
            )
          );
        } else {
          yield put(
            push(
              buildURL(SMKLinks.WORKSHEET_LISTING, {
                id: response?.id,
              })
            )
          );
        }
      }
      yield put(createWorksheet.success());
    }
  } catch (error) {
    yield put(createWorksheet.failure(error));
  } finally {
    yield put(createWorksheet.fulfill({ isLoading: false }));
  }
}

function* getWorksheetListRequest(action) {
  try {
    yield put(getWorksheetList.request({ isLoading: true }));
    const response = yield call(getWorksheetListCall, action.payload);
    yield put(getWorksheetList.success({ ...response }));

    // if (response.success) {
    //   yield put(getWorksheetList.success({ ...response.data }));
    // } else {
    //   yield put(setGlobalError.success(response));
    // }
  } catch (error) {
    yield put(getWorksheetList.failure(error));
  } finally {
    yield put(getWorksheetList.fulfill({ isLoading: false }));
  }
}

function* getWorksheetSearchListRequest(action) {
  try {
    yield put(getWorksheetSearchList.request());
    const response = yield call(getWorksheetListCall, action.payload);
    yield put(getWorksheetSearchList.success({ ...response }));

    // if (response.success) {
    //   yield put(getWorksheetSearchList.success({ ...response.data }));
    // } else {
    //   yield put(setGlobalError.success(response));
    // }
  } catch (error) {
    yield put(getWorksheetSearchList.failure(error));
  } finally {
    yield put(getWorksheetSearchList.fulfill());
  }
}

function* getWorksheetDetailRequest(action) {
  try {
    yield put(clearWorksheetDetails.trigger());
    yield put(getWorksheetDetail.request({ isLoading: true }));

    const response = yield call(getWorksheetDetailCall, action.payload.id);
    yield put(getWorksheetDetail.success({ ...response }));
    // if (response.success) {
    //   yield put(getWorksheetDetail.success({ ...response }));
    // } else {
    //   yield put(setGlobalError.success(response));
    // }
  } catch (error) {
    yield put(getWorksheetDetail.failure(error));
  } finally {
    yield put(getWorksheetDetail.fulfill({ isLoading: false }));
  }
}

function* setWorksheetApproveRequest(action) {
  try {
    yield put(setWorksheetPublish.request({ isLoading: true }));
    const { id, active, isAnswerVisible, comments, detailPage } = action.payload;
    const response = yield call(setWorksheetApproveCall, id, active, isAnswerVisible, comments);
    if (response) {
      yield put(setWorksheetPublish.success({ ...response, detailPage }));
    }
  } catch (error) {
    yield put(setWorksheetPublish.failure(error));
  } finally {
    yield put(setWorksheetPublish.fulfill({ isLoading: false }));
  }
}

function* setWorksheetRejectRequest(action) {
  try {
    yield put(setWorksheetReject.request({ isLoading: true }));
    const { id, comments, detailPage } = action.payload;
    const response = yield call(setWorksheetRejectCall, id, comments);
    if (response) {
      yield put(setWorksheetReject.success({ ...response, detailPage }));
    }
  } catch (error) {
    yield put(setWorksheetReject.failure(error));
  } finally {
    yield put(setWorksheetReject.fulfill({ isLoading: false }));
  }
}

function* setWorksheetDeleteRequest(action) {
  try {
    yield put(setWorksheetDelete.request({ isLoading: true }));
    const { id, deleted, comments, detailPage } = action.payload;
    const response = yield call(setWorksheetDeleteCall, id, deleted, comments);
    if (response) {
      yield put(setWorksheetDelete.success({ ...response, detailPage }));
    }
  } catch (error) {
    yield put(setWorksheetDelete.failure(error));
  } finally {
    yield put(setWorksheetDelete.fulfill({ isLoading: false }));
  }
}

function* createWorksheetCategoryRequest(action) {
  try {
    yield put(createWorksheetCategory.request({ isLoading: true }));
    const { payload } = action.payload;
    const response = yield call(createTopicSubjectCall, payload);
    if (response) {
      yield put(createWorksheetCategory.success());
    } else {
      alert(response.message);
    }
  } catch (error) {
    yield put(createWorksheetCategory.failure(error));
  } finally {
    yield put(createWorksheetCategory.fulfill({ isLoading: false }));
  }
}

export default function* worksheetSaga() {
  yield takeLatest(getWorksheetSubjectTopics.TRIGGER, getWorksheetSubjectTopicsRequest);
  yield takeLatest(createWorksheet.TRIGGER, createWorksheetRequest);
  yield takeLatest(getWorksheetList.TRIGGER, getWorksheetListRequest);
  yield takeLatest(getWorksheetSearchList.TRIGGER, getWorksheetSearchListRequest);
  yield takeLatest(getWorksheetDetail.TRIGGER, getWorksheetDetailRequest);
  yield takeLatest(setWorksheetPublish.TRIGGER, setWorksheetApproveRequest);
  yield takeLatest(setWorksheetReject.TRIGGER, setWorksheetRejectRequest);
  yield takeLatest(setWorksheetDelete.TRIGGER, setWorksheetDeleteRequest);
  yield takeLatest(createWorksheetCategory.TRIGGER, createWorksheetCategoryRequest);
}
