import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Button,
  Label,
  Input,
  Container,
  Form,
  FormFeedback,
  CardTitle,
} from 'reactstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import isEmpty from 'lodash/isEmpty';
import Select from 'react-select';
import { Link, useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Loader from 'components/Loader';
import Breadcrumbs from '../../../../components/Common/Breadcrumb';
import useRequest from '../../../../hooks/useRequest';
import { SMKLinks } from '../../../../utils/links';
import { createStructuredSelector } from 'reselect';
import { useDispatch, useSelector } from 'react-redux';
import { countriesSelector, regionsSelector, citiesSelector, subAreasSelector } from '../../selectors';
import { getCities, getCountries, getRegions, getSubAreas } from '../../actions';
import { BANNER_TYPE } from 'utils/constants';
import moment from 'moment';

const stateSelector = createStructuredSelector({
  countries: countriesSelector,
  regions: regionsSelector,
  cities: citiesSelector,
  subAreas: subAreasSelector,
});

const UpdateFeatureBanner = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id: featureBannerID } = useParams();

  toast.configure();

  const [featureBannerDetails, setfeatureBannerDetails] = useState();
  const { countries, regions, cities, subAreas } = useSelector(stateSelector);
  const [expiryDate, setExpiryDate] = useState(moment());

  const selectTypeOptions = [];

  for (const [key, value] of Object.entries(BANNER_TYPE)) {
    selectTypeOptions?.push({
      label: key,
      value: value,
    });
  }

  const [getFeatureBannerRequest, { loading: getFeatureBannerLoading }] = useRequest({
    url: `/common/featuredBanner/admin/${featureBannerID}`,
    method: 'GET',
    onSuccess: (data) => {
      setfeatureBannerDetails(data);
    },
    onError: (err) => {
      toast.error(err?.message);
      console.log(err);
    },
  });

  const [addFeatureBannerRequest, { loading: addFeatureBannerLoading }] = useRequest({
    url: `/common/featuredBanner/admin`,
    method: 'POST',
    onSuccess: (data) => {
      const url = SMKLinks.FEATURE_BANNER_LIST;
      history.replace(url);
      toast.success(`Success! Feature Banner ( ${data?.id} ) has been added.`);
    },
    onError: (err) => {
      toast.error(err?.message);
      console.log(err);
    },
  });

  const [updateFeatureBannerRequest, { loading: updateFeatureBannerLoading }] = useRequest({
    url: `/common/featuredBanner/admin/${featureBannerID}`,
    method: 'PUT',
    onSuccess: (data) => {
      const url = SMKLinks.FEATURE_BANNER_LIST;
      history.replace(url);
      toast.success(`Success! Feature Banner ( ${data?.id} ) has been updated.`);
    },
    onError: (err) => {
      toast.error(err?.message);
      console.log(err);
    },
  });

  const isJSON = (val) => {
    try {
      const parseObj = JSON.parse(val);
      return typeof parseObj === 'object' || typeof parseObj === 'Array' ? true : false;
    } catch (err) {
      toast.error(err);
      return false;
    }
  };

  const convertJSON = (val) => {
    try {
      const parseObj = JSON.parse(val);
      return parseObj;
    } catch (err) {
      toast.error(err);
    }
  };

  const editInitialValues = () => {
    const typeObj = selectTypeOptions?.filter((e) => e?.value === featureBannerDetails?.type);
    const countryObj = countries?.filter((e) => e?.value === featureBannerDetails?.country?.id);
    const regionObj = regions?.filter((e) => e?.value === featureBannerDetails?.region?.id);
    const cityObj = cities?.filter((e) => e?.value === featureBannerDetails?.city?.id);

    const metaDataJSON = !isEmpty(featureBannerDetails?.metaData)
      ? JSON.stringify(featureBannerDetails?.metaData, null, 2)
      : '';
    const pagesJSON = !isEmpty(featureBannerDetails?.pages) ? JSON.stringify(featureBannerDetails?.pages, null, 2) : '';

    return {
      id: featureBannerDetails?.id,
      active: featureBannerDetails?.active,
      deleted: featureBannerDetails?.deleted,
      version: featureBannerDetails?.version,

      metaData: metaDataJSON,
      pages: pagesJSON,

      type: !isEmpty(typeObj)
        ? {
            label: typeObj[0]?.label,
            value: typeObj[0]?.value,
          }
        : undefined,
      country: !isEmpty(countryObj)
        ? {
            label: countryObj[0]?.label,
            value: countryObj[0]?.value,
          }
        : undefined,
      region: !isEmpty(regionObj)
        ? {
            label: regionObj[0]?.label,
            value: regionObj[0]?.value,
          }
        : undefined,
      city: !isEmpty(cityObj)
        ? {
            label: cityObj[0]?.label,
            value: cityObj[0]?.value,
          }
        : undefined,
    };
  };

  const getInitialValues = () => ({
    active: true,
    deleted: false,
    version: '',
    type: '',
    metaData: '',
    pages: '',

    country: '',
    region: '',
    city: '',
    subArea: '',
  });

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: false,
    initialValues: isEmpty(featureBannerID) ? { ...getInitialValues() } : { ...editInitialValues() },
    onSubmit: (values) => {
      const data = {
        active: values?.active,
        deleted: values?.deleted,
        type: values?.type?.value,
        expiryDate: expiryDate,

        metaData: convertJSON(values?.metaData),
        pages: convertJSON(values?.pages),

        country: values?.country?.id
          ? {
              id: values?.country?.id,
            }
          : undefined,
        region: values?.region?.id
          ? {
              id: values?.region?.id,
            }
          : undefined,
        city: values?.city?.id
          ? {
              id: values?.city?.id,
            }
          : undefined,
        subArea: values?.subArea?.id
          ? {
              id: values?.subArea?.id,
            }
          : undefined,
      };

      if (isJSON(values.pages) && isJSON(values.metaData)) {
        isEmpty(featureBannerID)
          ? addFeatureBannerRequest({
              body: {
                ...data,
              },
            })
          : updateFeatureBannerRequest({
              body: {
                ...data,
              },
            });
      } else {
        toast.error('Please enter valid JSON...');
      }
    },
    validationSchema: Yup.object().shape({
      type: Yup.object()
        .required('Please provide type')
        .nullable(true),
    }),
  });

  useEffect(() => {
    dispatch(getCountries.trigger({ level: 0, page: 1, size: 500 }));
  }, []);

  useEffect(() => {
    featureBannerID && setExpiryDate(moment(featureBannerDetails?.expiryDate));
  }, [featureBannerDetails?.expiryDate]);

  useEffect(() => {
    if (!isEmpty(featureBannerID)) {
      getFeatureBannerRequest();
      dispatch(
        getCities.trigger({
          level: 3,
          page: 1,
          size: 500,
        })
      );
      dispatch(
        getRegions.trigger({
          level: 1,
          page: 1,
          size: 500,
        })
      );
    }
  }, [featureBannerID]);

  const onCountrySelect = (country) => {
    if (!isEmpty(country)) {
      dispatch(
        getRegions.trigger({
          level: 1,
          country: country.name,
          page: 1,
          size: 500,
        })
      );
      formik.setFieldValue('country', country);
      formik.setFieldValue('region', {});
      formik.setFieldValue('city', {});
      formik.setFieldValue('subArea', {});
    } else {
      formik.setFieldValue('country', '');
    }
  };
  const onRegionSelect = (region) => {
    if (!isEmpty(region)) {
      dispatch(
        getCities.trigger({
          level: 3,
          cityOnly: true,
          region: region.name,
          page: 1,
          size: 500,
        })
      );

      formik.setFieldValue('region', region);
      formik.setFieldValue('city', {});
      formik.setFieldValue('subArea', {});
    } else {
      formik.setFieldValue('region', '');
    }
  };
  const onCitySelect = (city) => {
    if (!isEmpty(city)) {
      dispatch(getSubAreas.trigger({ level: 4, city: city.name, page: 1, size: 500 }));

      formik.setFieldValue('city', city);
      formik.setFieldValue('subArea', {});
    } else {
      formik.setFieldValue('city', '');
    }
  };
  const onSubAreaSelect = (subArea) => {
    if (!isEmpty(subArea)) {
      formik.setFieldValue('subArea', subArea);
    } else {
      formik.setFieldValue('subArea', '');
    }
  };

  return (
    <>
      <div className="page-content">
        <Loader isActive={addFeatureBannerLoading || getFeatureBannerLoading || updateFeatureBannerLoading} />
        <Container fluid>
          <Breadcrumbs
            title="Location"
            breadcrumbItem={`${isEmpty(featureBannerID) ? 'Add' : 'Edit'}  Feature Banner`}
          />

          <Form onSubmit={formik.handleSubmit}>
            <Row>
              <Col md={9}>
                <Card>
                  <CardBody>
                    <Row className="align-items-center">
                      {featureBannerID && (
                        <Col md={4}>
                          <FormGroup className="mb-4">
                            <Label className="form-label  ">Feature Banner ID</Label>
                            <Input
                              id="id"
                              style={{ background: 'whitesmoke' }}
                              disabled
                              className="form-control"
                              {...formik.getFieldProps('id')}
                            />
                          </FormGroup>
                        </Col>
                      )}

                      <Col md={featureBannerID ? 4 : 6}>
                        <FormGroup className="mb-4">
                          <Label htmlFor="type" className="form-label  ">
                            Type
                          </Label>
                          <Select
                            id="type"
                            isClearable
                            options={selectTypeOptions}
                            value={formik.values.type}
                            onChange={(type) => formik.setFieldValue('type', type)}
                          />
                          {formik.touched.type && <div className="invalid-feedback d-block">{formik.errors.type}</div>}
                        </FormGroup>
                      </Col>

                      <Col md={featureBannerID ? 4 : 6}>
                        <FormGroup className="mb-4">
                          <Label htmlFor="expiryDate" className="form-label  ">
                            Expiry Date
                          </Label>
                          <Input
                            type="date"
                            id="expiryDate"
                            placeholder="Expiry Date"
                            value={expiryDate.format('YYYY-MM-DD')}
                            // min={moment().format('YYYY-MM-DD')}
                            onChange={(e) => setExpiryDate(moment(e.target.value))}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup className="mb-4">
                          <Label htmlFor="school_country" className="form-label">
                            Country
                          </Label>
                          <Select
                            isClearable
                            id="school_country"
                            options={countries}
                            value={formik?.values?.country}
                            onChange={onCountrySelect}
                          />
                        </FormGroup>
                      </Col>

                      <Col md={6}>
                        <FormGroup className="mb-4">
                          <Label htmlFor="school_state" className="form-label  ">
                            Region/State
                          </Label>

                          <Select
                            isClearable
                            id="school_state"
                            placeholder="Select..."
                            value={formik?.values?.region}
                            onChange={onRegionSelect}
                            options={regions}
                          />
                        </FormGroup>
                      </Col>

                      <Col md={6}>
                        <FormGroup className="mb-4">
                          <Label htmlFor="school_city" className="form-label  ">
                            City
                          </Label>
                          <Select
                            isClearable
                            id="school_city"
                            placeholder="Select..."
                            value={formik?.values?.city}
                            onChange={onCitySelect}
                            options={cities}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup className="mb-4">
                          <Label htmlFor="school_area" className="form-label  ">
                            SubArea
                          </Label>
                          <Select
                            id="school_area"
                            placeholder="Select..."
                            value={formik.values.subArea}
                            onChange={onSubAreaSelect}
                            options={subAreas}
                          />
                          {formik.touched.subArea && (
                            <div className="invalid-feedback d-block">{formik.errors.subArea}</div>
                          )}
                        </FormGroup>
                      </Col>

                      <Col md={12}>
                        <FormGroup className="mb-4">
                          <Label htmlFor="version" className="form-label  ">
                            Version
                          </Label>
                          <Input
                            id="version"
                            name="version"
                            type="text"
                            className="form-control"
                            placeholder="Version..."
                            invalid={!!(formik.touched.version && formik.errors.version)}
                            {...formik.getFieldProps('version')}
                          />
                          <FormFeedback>{formik.errors.version}</FormFeedback>
                        </FormGroup>
                      </Col>

                      <Col md={12}>
                        <FormGroup className="mb-4">
                          <Label htmlFor="metaData" className="form-label  ">
                            Meta Data
                          </Label>
                          <Input
                            id="metaData"
                            name="metaData"
                            style={{ height: '100px' }}
                            type="textarea"
                            cols={50}
                            rows={5}
                            className="form-control"
                            placeholder="Enter Meta Data JSON..."
                            invalid={!!(formik.touched.metaData && formik.errors.metaData)}
                            {...formik.getFieldProps('metaData')}
                          />
                          <FormFeedback>{formik.errors.metaData} </FormFeedback>
                        </FormGroup>
                      </Col>
                      <Col md={12}>
                        <FormGroup className="mb-4">
                          <Label htmlFor="pages" className="form-label  ">
                            Pages
                          </Label>
                          <Input
                            id="pages"
                            name="pages"
                            style={{ height: '100px' }}
                            type="textarea"
                            cols={50}
                            rows={5}
                            className="form-control"
                            placeholder="Enter Pages JSON..."
                            invalid={!!(formik.touched.pages && formik.errors.pages)}
                            {...formik.getFieldProps('pages')}
                          />
                          <FormFeedback>{formik.errors.pages} </FormFeedback>
                        </FormGroup>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col md={3}>
                <Card>
                  <CardBody>
                    <Button type="submit" color="primary" className="w-100 mb-3">
                      {featureBannerID ? 'Save' : 'Add'}
                    </Button>

                    <Button
                      className="w-100"
                      color="light"
                      onClick={() => {
                        history.replace(SMKLinks.FEATURE_BANNER_LIST);
                      }}>
                      Cancel
                    </Button>
                  </CardBody>
                </Card>

                <Card>
                  <CardBody>
                    <div>
                      <CardTitle className="mb-3">Active </CardTitle>
                      {[true, false].map((option, i) => (
                        <div key={`active_${i}`} className="form-check mb-3 d-inline-block me-4">
                          <input
                            type="radio"
                            id={`active_${option}`}
                            name="active"
                            className="form-check-input"
                            checked={formik.values.active === option}
                            onChange={() => formik.setFieldValue('active', option)}
                          />
                          <label className="form-check-label" htmlFor={`active_${option}`}>
                            {option ? 'Yes' : 'No'}
                          </label>
                        </div>
                      ))}
                    </div>

                    <div>
                      <CardTitle className="mb-3">Delete </CardTitle>
                      {[true, false].map((option, i) => (
                        <div key={`deleted_${i}`} className="form-check mb-3 d-inline-block me-4">
                          <input
                            type="radio"
                            id={`deleted_${option}`}
                            name="deleted"
                            className="form-check-input"
                            checked={formik.values.deleted === option}
                            onChange={() => formik.setFieldValue('deleted', option)}
                          />
                          <label className="form-check-label" htmlFor={`deleted_${option}`}>
                            {option ? 'Yes' : 'No'}
                          </label>
                        </div>
                      ))}
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Form>
        </Container>
      </div>
    </>
  );
};

export default UpdateFeatureBanner;
