import Loader from 'components/Loader';
import SlugInput from 'components/SlugInput';
import TextEditor from 'components/TextEditor';
import { useFormik } from 'formik';
import isEmpty from 'lodash/isEmpty';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toastify';
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap';
import { BABY_NAME_TYPE, LANGUAGE_ARRAY } from 'utils/constants';
import * as Yup from 'yup';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import useRequest from '../../../hooks/useRequest';
import { SMKLinks } from '../../../utils/links';

const UpdateBabyNameMetaData = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id: babyNameId, en: isTranslate } = useParams();

  toast.configure();
  const [babyNameDetails, setBabyNameDetails] = useState();
  const [babyNameList, setBabyNameList] = useState();
  const [enParentObj, setEnParentObj] = useState();
  const metDataTranslationParentObj = babyNameList?.filter((e) => e?.id === babyNameDetails?.enId);

  useEffect(() => {
    if (!isEmpty(metDataTranslationParentObj)) {
      setEnParentObj(metDataTranslationParentObj[0]);
    }
  }, [metDataTranslationParentObj]);

  const [getbabyNameMetaListRequest, { loading: getbabyNameMetaListLoading }] = useRequest({
    url: `/baby-name/admin/metaInfo/search`,
    method: 'POST',
    onSuccess: (data) => {
      setBabyNameList(data?.results);
    },
  });

  const [addBabyNameRequest, { loading: addBabyNameLoading }] = useRequest({
    url: `/baby-name/admin/metaInfo`,
    method: 'POST',
    onSuccess: (data) => {
      if (data?.success === false || data?.error) {
        data?.error ? toast.error(data?.error) : toast.error(data?.message?.message);
      } else {
        const url = SMKLinks.BABY_NAME_META_DATA;
        history.replace(url);
        toast.success('Success! Baby Name meta data has been added.');
      }
    },
    onError: (err) => {
      toast.error(err?.message);
      console.log(err);
    },
  });
  const [getBabyNameRequest, { loading: getBabyNameLoading }] = useRequest({
    url: `/baby-name/admin/metaInfo/${babyNameId}`,
    method: 'GET',
    onSuccess: (data) => {
      setBabyNameDetails(data);
    },
    onError: (err) => {
      toast.error(err?.message);
      console.log(err);
    },
  });

  const [updateBabyNameRequest, { loading: updateBabyNameLoading }] = useRequest({
    url: `/baby-name/admin/metaInfo/${babyNameId}`,
    method: 'PUT',
    onSuccess: (data) => {
      if (data?.success === false || data?.error) {
        data?.error ? toast.error(data?.error) : toast.error(data?.message?.message);
      } else {
        const url = SMKLinks.BABY_NAME_META_DATA;
        history.replace(url);
        toast.success('Success! Baby Name meta data has been updated.');
      }
    },
    onError: (err) => {
      toast.error(err?.message);
      console.log(err);
    },
  });

  const editInitialValues = () => {
    const filteredTypeObj = BABY_NAME_TYPE?.find((e) => e?.value == babyNameDetails?.type);
    const filteredLanguageObj = isTranslate
      ? LANGUAGE_ARRAY?.find((e) => e?.value == 1)
      : LANGUAGE_ARRAY?.find((e) => e?.value == babyNameDetails?.language);

    return {
      active: babyNameDetails?.active,
      deleted: babyNameDetails?.deleted,
      popular: babyNameDetails?.popular,
      name: babyNameDetails?.name,
      slug: babyNameDetails?.slug,
      summary: !isEmpty(babyNameDetails?.summary) ? babyNameDetails?.summary : '',
      description: !isEmpty(babyNameDetails?.description) ? babyNameDetails?.description : '',
      content: !isEmpty(babyNameDetails?.content) ? babyNameDetails?.content : '',

      type: !isEmpty(filteredTypeObj) && filteredTypeObj,
      language: !isEmpty(filteredLanguageObj) && filteredLanguageObj,
    };
  };

  const getInitialValues = () => ({
    active: true,
    deleted: false,
    popular: false,
    name: '',
    slug: '',
    summary: '',
    description: '',
    content: '',
    type: '',
    language: { label: 'English', value: 0 },
  });

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: false,
    initialValues: isEmpty(babyNameId) ? { ...getInitialValues() } : { ...editInitialValues() },
    onSubmit: (values) => {
      const data = {
        active: values?.active,
        deleted: values?.deleted,
        popular: values?.popular,
        name: values?.name,
        slug: values?.slug,
        summary: values?.summary,
        description: values?.description,
        content: values?.content,
        type: values?.type?.value,
        language: values?.language?.value,
      };
      if (isEmpty(babyNameId)) {
        addBabyNameRequest({
          body: {
            ...data,
          },
        });
      } else if (isTranslate) {
        addBabyNameRequest({
          body: {
            ...data,
            en: { id: Number(babyNameId) },
            id: null,
          },
        });
      } else {
        updateBabyNameRequest({
          body: {
            ...data,
          },
        });
      }
    },
    validationSchema: Yup.object().shape({
      name: Yup.string()
        .required('Please provide babyname name')
        .min(2, 'Name should be at least 2 characters long')
        .max(128, 'Name should be at most 128 characters long'),
      // description: Yup.string().required('Please Provide description'),
      language: Yup.object()
        .required('Please provide language')
        .nullable(true),
      type: Yup.object()
        .required('Please provide type')
        .nullable(true),
    }),
  });

  useEffect(() => {
    if (!isEmpty(babyNameId)) {
      getBabyNameRequest();
    }
  }, [babyNameId]);

  useEffect(() => {
    getbabyNameMetaListRequest({
      body: {
        page: 1,
        size: 100000,
        sortBy: 'id',
        sortOrder: 0,
      },
    });
  }, []);

  return (
    <div className="page-content">
      <Loader
        isActive={addBabyNameLoading || getBabyNameLoading || updateBabyNameLoading || getbabyNameMetaListLoading}
      />
      <Container fluid>
        <Breadcrumbs
          title="Babynames"
          breadcrumbItem={`${isEmpty(babyNameId) ? 'Add' : isTranslate ? 'Translate' : 'Edit'}  Babyname meta data`}
        />

        <Form onSubmit={formik.handleSubmit}>
          <Row>
            <Col md={9}>
              <Card>
                <CardBody>
                  <Row className="align-items-center">
                    <Col md={6}>
                      <FormGroup className="mb-4">
                        <Label for="language" className="form-label">
                          Language
                        </Label>
                        <Select
                          id="language"
                          isClearable
                          options={isTranslate ? LANGUAGE_ARRAY?.filter((e) => e?.value === 1) : LANGUAGE_ARRAY}
                          value={formik.values.language}
                          onChange={(language) => formik.setFieldValue('language', language)}
                        />
                        {formik.touched.language && (
                          <div className="invalid-feedback d-block">{formik.errors.language}</div>
                        )}
                      </FormGroup>
                    </Col>

                    <Col md={6}>
                      <FormGroup className="mb-4">
                        <Label for="type" className="form-label">
                          Type
                        </Label>
                        <Select
                          id="type"
                          options={BABY_NAME_TYPE}
                          value={formik.values.type}
                          onChange={(type) => formik.setFieldValue('type', type)}
                          isClearable
                        />
                        {formik.touched.type && <div className="invalid-feedback d-block">{formik.errors.type}</div>}
                      </FormGroup>
                    </Col>

                    <Col md={6}>
                      <FormGroup className="mb-4">
                        <Label for="name" className="form-label  ">
                          Name
                        </Label>
                        <Input
                          id="name"
                          type="text"
                          className="form-control"
                          placeholder=" Name..."
                          label=" Name"
                          invalid={!!(formik.touched.name && formik.errors.name)}
                          {...formik.getFieldProps('name')}
                          error={!isEmpty(formik.errors.name)}
                        />
                        <FormFeedback>{formik.errors.name}</FormFeedback>
                      </FormGroup>
                    </Col>

                    <Col md={6}>
                      <FormGroup className="mb-4">
                        <SlugInput
                          formik={formik}
                          ID={babyNameId}
                          nameValue={formik.values.name}
                          formGroupClassName={'mt-0'}
                        />
                      </FormGroup>
                    </Col>

                    <Col md={12}>
                      <FormGroup className="mb-4">
                        <Label for="summary" className="form-label  ">
                          Summary
                        </Label>
                        <Input
                          id="summary"
                          name="summary"
                          style={{ height: '100px' }}
                          type="textarea"
                          className="form-control"
                          placeholder="Summary..."
                          invalid={!!(formik.touched.summary && formik.errors.summary)}
                          {...formik.getFieldProps('summary')}
                        />
                        <FormFeedback>{formik.errors.summary}</FormFeedback>
                      </FormGroup>
                    </Col>

                    <Col md={12}>
                      <FormGroup className="mb-4">
                        <Label for="description" className="form-label  ">
                          Description
                        </Label>
                        <TextEditor
                          simple
                          height={200}
                          initialValue={formik.values?.description}
                          onChange={(val) => formik.setFieldValue('description', val)}
                        />

                        <FormFeedback>{formik.errors.description}</FormFeedback>
                      </FormGroup>
                    </Col>

                    <Col md={12}>
                      <FormGroup className="mb-4 ">
                        <Label for="content" className="form-label  ">
                          Content
                        </Label>
                        <TextEditor
                          simple
                          height={200}
                          initialValue={formik.values?.content}
                          onChange={(val) => formik.setFieldValue('content', val)}
                        />

                        <FormFeedback>{formik.errors.content}</FormFeedback>
                      </FormGroup>
                    </Col>
                  </Row>

                  {!isEmpty(enParentObj) && (
                    <div>
                      <span> ID : {enParentObj?.en?.id}</span>
                      <br />
                      <span> Name : {enParentObj?.en?.name}</span>
                      <br />
                      <span> Slug : {enParentObj?.en?.slug || '-'}</span>
                      <br />
                    </div>
                  )}
                </CardBody>
              </Card>
            </Col>

            <Col md={3}>
              <Card>
                <CardBody>
                  <div>
                    <Button type="submit" color="primary" className="w-100">
                      Save Name
                    </Button>
                  </div>
                  <div>
                    <Button
                      className="w-100 mt-3"
                      color="light"
                      onClick={() => {
                        history.replace(SMKLinks.BABY_NAME_META_DATA);
                      }}>
                      Cancel
                    </Button>
                  </div>
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  <div className="border-bottom mb-3">
                    <CardTitle className="mb-3">Active </CardTitle>
                    {[true, false].map((option, i) => (
                      <div key={`active_${i}`} className="form-check mb-2 d-inline-block me-4">
                        <input
                          type="radio"
                          id={`active_${option}`}
                          name="active"
                          className="form-check-input"
                          checked={formik.values.active === option}
                          onChange={() => formik.setFieldValue('active', option)}
                        />
                        <label className="form-check-label" htmlFor={`active_${option}`}>
                          {option ? 'Yes' : 'No'}
                        </label>
                      </div>
                    ))}
                  </div>
                  <div className="border-bottom mb-3">
                    <CardTitle className="mb-3">Delete </CardTitle>
                    {[true, false].map((option, i) => (
                      <div key={`deleted_${i}`} className="form-check d-inline-block me-4">
                        <input
                          type="radio"
                          id={`deleted_${option}`}
                          name="deleted"
                          className="form-check-input"
                          checked={formik.values.deleted === option}
                          onChange={() => formik.setFieldValue('deleted', option)}
                        />
                        <label className="form-check-label" htmlFor={`deleted_${option}`}>
                          {option ? 'Yes' : 'No'}
                        </label>
                      </div>
                    ))}
                  </div>

                  <CardTitle className="mb-3">Popular </CardTitle>
                  {[true, false].map((option, i) => (
                    <div key={`popular_${i}`} className="form-check d-inline-block me-4">
                      <input
                        type="radio"
                        id={`popular_${option}`}
                        name="popular"
                        className="form-check-input"
                        checked={formik.values.popular === option}
                        onChange={() => formik.setFieldValue('popular', option)}
                      />
                      <label className="form-check-label" htmlFor={`popular_${option}`}>
                        {option ? 'Yes' : 'No'}
                      </label>
                    </div>
                  ))}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Form>
      </Container>
    </div>
  );
};

export default UpdateBabyNameMetaData;
