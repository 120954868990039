import profileBanner from 'assets/images/profile-img.png';
import Breadcrumbs from 'components/Common/Breadcrumb';
import Loader from 'components/Loader';
import MiniCards from 'components/MiniCard/mini-card';
import TimingsTableRows from 'components/TimingsTableRows';
import 'flatpickr/dist/themes/material_blue.css';
import { useFormik } from 'formik';
import useRequest from 'hooks/useRequest';
import { useRouter } from 'hooks/useRouter';
import { isEmpty } from 'lodash';
import map from 'lodash/map';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Select from 'react-select';
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Row,
  Table,
} from 'reactstrap';
import { CURRENCY_ARRAY, GET_GENDER, WEEK_DAYS } from 'utils/constants';
import { printDateTime, showImage, titleCaseIfExists } from 'utils/helper';
import * as Yup from 'yup';
import config from '../../../config/config';
import dummyUserIcon from 'assets/images/dummy-user.svg';
import { toast } from 'react-toastify/dist';

const DoctorDetail = () => {
  const { id: doctorId } = useParams();
  const [doctorDetails, setDoctorDetails] = useState([]);
  const router = useRouter();

  const [locationDetails, setLocationDetails] = useState();
  const [doctorLocationDetails, setdoctorLocationDetails] = useState();
  const [healthCarefacilityList, sethealthCarefacilityList] = useState();

  const [addRowInTimings, setAddRowInTimings] = useState({
    sunday: [],
    monday: [],
    tuesday: [],
    wednesday: [],
    thursday: [],
    friday: [],
    saturday: [],
  });

  const addRowTable = (weekDay) => {
    const data = {
      day: '',
      startTime: '',
      endTime: '',
    };
    setAddRowInTimings({
      ...addRowInTimings,
      [weekDay]: [...addRowInTimings[weekDay], data],
    });
  };
  const tableRowRemove = (index, weekDay) => {
    const dataRow = addRowInTimings[weekDay];
    dataRow.splice(index, 1);
    setAddRowInTimings({
      ...addRowInTimings,
      [weekDay]: dataRow,
    });
  };

  const [getDoctorLocationDetails, { loading: getDoctorLocationLoading }] = useRequest({
    url: `/doctor/admin/${doctorId}/locations`,
    method: 'GET',
    onSuccess: (data) => {
      setdoctorLocationDetails(data);
    },
    onError: (err) => {
      console.warn(err);
    },
  });
  const [getDoctorDetails, { loading: getDoctorLoading }] = useRequest({
    url: `/doctor/admin/${doctorId}`,
    method: 'GET',
    onSuccess: (data) => {
      setDoctorDetails(data);
    },
    onError: (err) => {
      console.warn(err);
    },
  });

  useEffect(() => {
    getDoctorDetails();
  }, []);

  const miniCards = [
    {
      title: 'Doctor name',
      iconClass: 'bxs-user-circle',
      text: `${`${doctorDetails?.firstName} ${doctorDetails?.lastName}` || '-'} `,
    },
    {
      title: 'Active',
      iconClass: 'bx-power-off',
      text: `${doctorDetails?.active ? 'Yes' : 'No'}`,
      textColor: `${doctorDetails?.active ? 'text-success' : 'text-danger'}`,
    },
    {
      title: 'Deleted',
      iconClass: 'bx-trash',
      text: `${doctorDetails?.deleted ? 'Yes' : 'No'}`,
      textColor: `${doctorDetails?.deleted ? 'text-success' : 'text-danger'}`,
    },
  ];

  // location

  const [getHealthcareFacilityRequest, { loading: getHealthcareFacilityLoading }] = useRequest({
    url: `/doctor/admin/healthcareFacility/search`,
    method: 'POST',
    onSuccess: (data) => {
      sethealthCarefacilityList(data?.results);
    },
  });

  const [addDoctorLocationRequest, { loading: addDoctorLocationLoading }] = useRequest({
    url: `/doctor/admin/${doctorId}/location`,
    method: 'POST',
    onSuccess: (data) => {
      window.location.reload();
      toast.success('Success! Location has been added.');
    },
    onError: (err) => {
      toast.error(err?.message);
      console.log(err);
    },
  });

  const [updateDoctorLocationRequest, { loading: updateDoctorLocationLoading }] = useRequest({
    url: `/doctor/admin/${doctorId}/location/${locationDetails?.id}`,
    method: 'PUT',
    onSuccess: (data) => {
      window.location.reload();
      toast.success('Success! Location has been updated.');
    },
    onError: (err) => {
      toast.error(err?.message);
      console.log(err);
    },
  });

  const editInitialValues = () => {
    const healthCareValue = healthCarefacilityList?.find((e) => e?.id === locationDetails?.healthcareFacility?.id);

    return {
      active: locationDetails?.active,
      deleted: locationDetails?.deleted,
      healthcareFacility: {
        label: healthCareValue?.name,
        value: healthCareValue?.id,
      },
      timings: locationDetails?.timings,
      defaultCharges: locationDetails?.defaultCharges,
      notes: locationDetails?.notes,
    };
  };

  const getInitialValues = () => ({
    active: true,
    deleted: false,
    healthcareFacility: '',
    timings: '',
    defaultCharges: '',
    registrationFee: '',
    firstVisit: '',
    followUpVisit: '',
    notes: '',
    registrationFeeCurrency: { label: 'INR', value: 'inr' },
    firstVisitCurrency: { label: 'INR', value: 'inr' },
    followUpVisitCurrency: { label: 'INR', value: 'inr' },
  });

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: false,
    initialValues: isEmpty(locationDetails) ? { ...getInitialValues() } : { ...editInitialValues() },
    onSubmit: (values) => {
      const data = {
        active: values?.active,
        deleted: values?.deleted,
        doctor: {
          id: doctorId,
        },
        healthcareFacility: {
          id: values?.healthcareFacility?.value,
        },
        timings: values?.timings,
        defaultCharges: values?.defaultCharges,
        notes: values?.notes,
      };

      isEmpty(locationDetails)
        ? addDoctorLocationRequest({
            body: data,
          })
        : updateDoctorLocationRequest({
            body: data,
          });
    },
    validationSchema: Yup.object().shape({
      healthcareFacility: Yup.object().required('Please provide Healthcare facility'),
      // timings: Yup.string().required('Please provide timings'),
      // defaultCharges: Yup.string().required('Please provide defaultCharges'),
      // notes: Yup.string().required('Please provide notes'),
    }),
  });

  useEffect(() => {
    getDoctorLocationDetails();
    getHealthcareFacilityRequest({
      body: {
        page: 1,
        size: 5000,
        sortBy: 'id',
        sortOrder: 0,
      },
    });
  }, []);

  return (
    <>
      <div className="page-content">
        <Loader
          isActive={
            getDoctorLoading || updateDoctorLocationLoading || addDoctorLocationLoading || getHealthcareFacilityLoading
          }
        />
        <Container fluid>
          <Breadcrumbs title="Doctor" breadcrumbItem="Doctor Detail" />

          <Row>
            <Col xl="4">
              <Card>
                <CardBody>
                  <div className="d-flex">
                    <div className="me-3">
                      <img
                        src={
                          !isEmpty(doctorDetails?.profileImage?.filename)
                            ? showImage(doctorDetails.profileImage?.filename, 'tpz')
                            : dummyUserIcon
                        }
                        className="avatar-md rounded-circle img-thumbnail"
                      />
                    </div>
                    <div className="flex-grow-1 align-self-center">
                      <div className="text-muted">
                        <h5 className="mb-1"> {`${doctorDetails?.firstName} ${doctorDetails?.lastName}`}</h5>
                        <p className="mb-0">{doctorDetails?.email}</p>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>

              <Card>
                <CardBody>
                  <CardTitle className="py-3 card-title text-center bg-light mb-0">Basic Information</CardTitle>

                  <div className="table-responsive" style={{ minHeight: 200 }}>
                    <Table className="table-nowrap mb-0 table-striped table-white">
                      <tbody>
                        <tr>
                          <th>First name :</th>
                          <td>{doctorDetails?.firstName}</td>
                        </tr>
                        <tr>
                          <th>Last name :</th>
                          <td>{doctorDetails?.lastName}</td>
                        </tr>
                        <tr>
                          <th>Gender :</th>
                          <td>{GET_GENDER[doctorDetails?.gender]}</td>
                        </tr>
                        <tr>
                          <th>Email :</th>
                          <td>{doctorDetails?.email}</td>
                        </tr>
                        <tr>
                          <th>Phone number :</th>
                          <td>{doctorDetails?.phoneNumber}</td>
                        </tr>
                        <tr>
                          <th>Created Date :</th>
                          <td>{printDateTime(doctorDetails?.createdDate)}</td>
                        </tr>
                        <tr>
                          <th>Active :</th>
                          <td>
                            <Badge className={doctorDetails?.active ? 'bg-success' : 'bg-danger'}>
                              {doctorDetails?.active ? 'Yes' : 'No'}
                            </Badge>
                          </td>
                        </tr>
                        <tr>
                          <th>Deleted :</th>
                          <td>
                            <Badge className={doctorDetails?.deleted ? 'bg-success' : 'bg-danger'}>
                              {doctorDetails?.deleted ? 'Yes' : 'No'}
                            </Badge>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>

            <Col xl="8">
              <Row>
                {map(miniCards, (card, key) => (
                  <MiniCards
                    title={card?.title}
                    text={card?.text}
                    textColor={card?.textColor}
                    iconClass={card?.iconClass}
                    key={`_card_${key}`}
                  />
                ))}
              </Row>
              <Card>
                <CardBody className=" px-4">
                  <CardTitle className="py-3 card-title text-center bg-light mb-4">Add location</CardTitle>
                  <Form onSubmit={formik.handleSubmit}>
                    <Row>
                      <Col md={6} className="d-flex align-items-center">
                        <CardTitle className="mb-3 me-5">Active</CardTitle>
                        {[true, false].map((option, i) => (
                          <div key={`active_${i}`} className="form-check mb-3 d-inline-block me-4 ms-2">
                            <input
                              type="radio"
                              id={`active_${option}`}
                              name="active"
                              className="form-check-input"
                              checked={formik.values.active === option}
                              onChange={() => formik.setFieldValue('active', option)}
                            />
                            <label className="form-check-label" htmlFor={`active_${option}`}>
                              {option ? 'Yes' : 'No'}
                            </label>
                          </div>
                        ))}
                      </Col>
                      <Col md={6} className="d-flex align-items-center">
                        <CardTitle className="mb-3 me-5">Delete </CardTitle>
                        {[true, false].map((option, i) => (
                          <div key={`deleted_${i}`} className="form-check mb-3 d-inline-block me-4 ms-2">
                            <input
                              type="radio"
                              id={`deleted_${option}`}
                              name="deleted"
                              className="form-check-input"
                              checked={formik.values.deleted === option}
                              onChange={() => formik.setFieldValue('deleted', option)}
                            />
                            <label className="form-check-label" htmlFor={`deleted_${option}`}>
                              {option ? 'Yes' : 'No'}
                            </label>
                          </div>
                        ))}
                      </Col>
                    </Row>
                    <Row className="mt-4 mb-3">
                      <Col md={6} className="d-flex ">
                        <Label className="form-label mb-0">Healthcare Facility</Label>
                      </Col>
                      <Col md={6}>
                        <Select
                          id="healthcareFacility"
                          options={healthCarefacilityList?.map((e) => ({
                            label: e?.name,
                            value: e?.id,
                          }))}
                          value={formik.values.healthcareFacility}
                          onChange={(value) => formik.setFieldValue('healthcareFacility', value)}
                        />
                        <FormFeedback className="d-block">{formik.errors.healthcareFacility}</FormFeedback>
                      </Col>
                    </Row>

                    <hr className="mb-4" />

                    <h4 className="mb-4">Charges</h4>
                    <Row className="my-4">
                      <Col md={6} className="d-flex ">
                        <Label className="form-label mb-0">Registration Fees</Label>
                      </Col>
                      <Col md={6}>
                        <Row>
                          <Col md={4}>
                            <Select
                              id="registrationFeeCurrency"
                              options={CURRENCY_ARRAY}
                              value={formik.values.registrationFeeCurrency}
                              onChange={(value) => formik.setFieldValue('registrationFeeCurrency', value)}
                            />
                          </Col>
                          <Col md={8}>
                            <Input
                              id="registrationFee"
                              type="number"
                              className="form-control"
                              placeholder="Amount..."
                              invalid={!!(formik.touched.registrationFee && formik.errors.registrationFee)}
                              {...formik.getFieldProps('registrationFee')}
                              error={!isEmpty(formik.errors.registrationFee)}
                            />
                            <FormFeedback className="d-block">{formik.errors.registrationFee}</FormFeedback>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row className="my-4">
                      <Col md={6} className="d-flex ">
                        <Label className="form-label mb-0">First visit</Label>
                      </Col>
                      <Col md={6}>
                        <Row>
                          <Col md={4}>
                            <Select
                              id="firstVisitCurrency"
                              options={CURRENCY_ARRAY}
                              value={formik.values.firstVisitCurrency}
                              onChange={(value) => formik.setFieldValue('firstVisitCurrency', value)}
                            />
                          </Col>
                          <Col md={8}>
                            <Input
                              id="firstVisit"
                              type="number"
                              className="form-control"
                              placeholder="Amount..."
                              invalid={!!(formik.touched.firstVisit && formik.errors.firstVisit)}
                              {...formik.getFieldProps('firstVisit')}
                              error={!isEmpty(formik.errors.firstVisit)}
                            />
                            <FormFeedback className="d-block">{formik.errors.firstVisit}</FormFeedback>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row className="my-4">
                      <Col md={6} className="d-flex ">
                        <Label className="form-label mb-0">Follow-up visit</Label>
                      </Col>
                      <Col md={6}>
                        <Row>
                          <Col md={4}>
                            <Select
                              id="followUpVisitCurrency"
                              options={CURRENCY_ARRAY}
                              value={formik.values.followUpVisitCurrency}
                              onChange={(value) => formik.setFieldValue('followUpVisitCurrency', value)}
                            />
                          </Col>
                          <Col md={8}>
                            <Input
                              id="followUpVisit"
                              type="number"
                              className="form-control"
                              placeholder="Amount..."
                              invalid={!!(formik.touched.followUpVisit && formik.errors.followUpVisit)}
                              {...formik.getFieldProps('followUpVisit')}
                              error={!isEmpty(formik.errors.followUpVisit)}
                            />
                            <FormFeedback className="d-block">{formik.errors.followUpVisit}</FormFeedback>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row className="my-4">
                      <Col md={6} className="d-flex ">
                        <Label className="form-label mb-0">Follow-up validaity (days)</Label>
                      </Col>
                      <Col md={6}>
                        <Select
                          id="followUpValidity"
                          options={Array(60)
                            .fill('numbers')
                            ?.map((e, i) => ({
                              label: i + 1,
                              value: i + 1,
                            }))}
                          value={formik.values.followUpValidity}
                          onChange={(value) => formik.setFieldValue('followUpValidity', value)}
                        />
                      </Col>
                    </Row>
                    <hr />
                    <h4 className="mb-4">Timings</h4>

                    <Table className="table-nowrap mb-0 table-white">
                      <tbody>
                        {Object.keys(WEEK_DAYS)?.map((weekDay) => (
                          <>
                            <tr>
                              <td>{titleCaseIfExists(weekDay)}</td>
                              <td colSpan={6} />
                              <td style={{ verticalAlign: 'middle' }}>
                                {addRowInTimings[weekDay]?.length < 3 && (
                                  <button onClick={() => addRowTable(weekDay)} className="btn btn-light rounded-circle">
                                    <i className="bx bx-plus" />
                                  </button>
                                )}
                              </td>
                            </tr>
                            <TimingsTableRows
                              rows={addRowInTimings[weekDay]}
                              tableRowRemove={tableRowRemove}
                              formik={formik}
                              addRowTable={addRowTable}
                              weekDay={weekDay}
                            />
                          </>
                        ))}
                      </tbody>
                    </Table>

                    <hr />

                    <Row className="my-4">
                      <Col md={6} className="d-flex ">
                        <Label className="form-label mb-0">Notes</Label>
                      </Col>
                      <Col md={6}>
                        <Input
                          id="notes"
                          type="textarea"
                          rows="3"
                          className="form-control"
                          placeholder="Notes..."
                          invalid={!!(formik.touched.notes && formik.errors.notes)}
                          {...formik.getFieldProps('notes')}
                          error={!isEmpty(formik.errors.notes)}
                        />
                        <FormFeedback className="d-block">{formik.errors.notes}</FormFeedback>
                      </Col>
                    </Row>
                    <Button type="submit" color="primary" className="w-100 mt-4">
                      {isEmpty(locationDetails) ? 'Add' : 'Update'} Location
                    </Button>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row className="mt-2">
            <Col xl="12">
              <Card>
                <CardBody>
                  <CardTitle className="py-3 card-title text-center bg-light mb-0">Location List</CardTitle>
                  <div className="table-responsive" style={{ minHeight: 200 }}>
                    <Table className="table-nowrap mb-0 table-striped table-white">
                      <thead>
                        <tr>
                          <th>Healthcare facility name</th>
                          <th>Default charges</th>
                          <th>Timings</th>
                          <th>Active</th>
                          <th>Deleted</th>
                        </tr>
                      </thead>
                      <tbody>
                        {doctorLocationDetails?.map((location) => (
                          <tr>
                            <td>
                              <div
                                className="text-primary"
                                style={{ cursor: 'pointer' }}
                                onClick={() => setLocationDetails(location)}>
                                {location?.healthcareFacility?.name}
                              </div>
                            </td>
                            <td>{location?.defaultCharges}</td>
                            <td>{location?.timing}</td>

                            <td>
                              <Badge className={location?.active ? 'bg-success' : 'bg-danger'}>
                                {location?.active ? 'Yes' : 'No'}
                              </Badge>
                            </td>
                            <td>
                              <Badge className={location?.deleted ? 'bg-success' : 'bg-danger'}>
                                {location?.deleted ? 'Yes' : 'No'}
                              </Badge>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

DoctorDetail.propTypes = {
  currentPost: PropTypes.object,
};

export default DoctorDetail;
