import TableError from 'components/TableError';
import { showImageError } from 'containers/blog/helper';
import { isEmpty } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  Card,
  CardBody,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Row,
  Table,
  UncontrolledDropdown,
} from 'reactstrap';
import { ACTIVE_AND_DELETED_ARRAY, MODULE_TYPE, SORT_VALUES } from 'utils/constants';
import { SMKLinks } from 'utils/links';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import Loader from '../../../components/Loader';
import Paginate from '../../../components/Paginate';
import useRequest from '../../../hooks/useRequest';
import { useRouter } from '../../../hooks/useRouter';
import { checkIfValueExist, showImage, titleCaseIfExists } from '../../../utils/helper';
import Select from 'react-select';

const MediaList = () => {
  const router = useRouter();
  const history = useHistory();
  toast.configure();

  const isTpzRoute = router?.location?.pathname?.includes('tpz/media');

  const forTypeEnumList = {
    ARTICLE: 3,
    METADATA: 26,
    ...(isTpzRoute
      ? {
          BABY_NAMES: 9,
          MEMORIES: 19,
        }
      : {
          SCHOOL: 7,
          COLLEGE: 25,
          WORKSHEET: 8,
        }),
  };

  const moduleForTypeList = Object.keys(forTypeEnumList)?.map((e) => ({
    label: titleCaseIfExists(e),
    value: forTypeEnumList[e],
  }));

  const [mediaList, setMediaList] = useState([]);
  const [mediaPagination, setMediaPagination] = useState({});

  const defaultFilters = {
    active: { value: true, label: 'Yes' },
    deleted: { value: false, label: 'No' },
    startDate: moment().subtract(10, 'years'),
    endDate: moment(),
    name: '',
    forType: moduleForTypeList[0],
  };
  const [filters, setFilters] = useState(defaultFilters);

  const [getMediaListRequest, { loading: getMediaListLoading }] = useRequest({
    method: 'POST',
    url: '/asset/admin/search',
    onSuccess: (data) => {
      setMediaList(data?.results);
      setMediaPagination(data?.pagination);
    },
  });

  const [deleteAssetRequest, { loading: deleteAssetLoading }] = useRequest({
    method: 'DELETE',
    onSuccess: () => {
      handleChangePage(parseInt(router?.query?.page, 10) || 1);
      toast.success(`Success! Asset has been deleted`);
    },
  });

  const setPageInUrl = (page) => {
    history.push({
      pathname: router.pathname,
      search: `?${new URLSearchParams({ page }).toString()}`,
    });
  };

  const handleChangePage = (page) => {
    setPageInUrl(page);
    getMediaListRequest({
      body: {
        // ...filters,
        page,
        size: 20,
        forType: filters?.forType?.value,
        active: checkIfValueExist(filters?.active?.value),
        deleted: checkIfValueExist(filters?.deleted?.value),
        name: checkIfValueExist(filters?.name),
        ...SORT_VALUES,
      },
    });
  };
  const applyFilters = () => {
    handleChangePage(1);
  };

  const clearFilters = () => {
    setPageInUrl(1);
    setFilters({ ...filters, name: '', forType: defaultFilters?.forType });
    getMediaListRequest({
      body: {
        page: 1,
        size: 20,
        // ...defaultFilters,
        active: checkIfValueExist(defaultFilters?.active?.value),
        deleted: checkIfValueExist(defaultFilters?.deleted?.value),
        forType: defaultFilters?.forType?.value,
        name: '',
        ...SORT_VALUES,
      },
    });
  };

  useEffect(() => {
    handleChangePage(parseInt(router?.query?.page, 10) || 1);
  }, []);

  const handleDelete = (data) => {
    deleteAssetRequest({
      url: `/asset/admin/${data?.id}`,
    });
  };

  return (
    <>
      <div className="page-content">
        <Loader isActive={getMediaListLoading || deleteAssetLoading} />

        <Container fluid>
          <Breadcrumbs title="Media List" breadcrumbItem="Media List" />

          <Card>
            <CardBody>
              <div className="mt-4">
                <div className="row">
                  <div className="col-lg-2 mb-4">
                    <label className="form-label">Active</label>
                    <Select
                      options={ACTIVE_AND_DELETED_ARRAY}
                      value={filters.active}
                      onChange={(value) => setFilters({ ...filters, active: value })}
                    />
                  </div>
                  <div className="col-lg-2 mb-4">
                    <label className="form-label">Deleted</label>
                    <Select
                      options={ACTIVE_AND_DELETED_ARRAY}
                      value={filters.deleted}
                      onChange={(value) => setFilters({ ...filters, deleted: value })}
                    />
                  </div>

                  <div className="col-lg-3 mb-4">
                    <label htmlFor="startDate" className="form-label">
                      Created Date - From
                    </label>
                    <Input
                      type="date"
                      id="startDate"
                      placeholder="From Date"
                      value={filters.startDate.format('YYYY-MM-DD')}
                      max={moment().format('YYYY-MM-DD')}
                      onChange={(e) => setFilters({ ...filters, startDate: moment(e.target.value) })}
                    />
                  </div>
                  <div className="col-lg-3 mb-4">
                    <label htmlFor="endDate" className="form-label">
                      Created Date - To
                    </label>
                    <Input
                      type="date"
                      id="endDate"
                      placeholder="To Date"
                      value={filters.endDate.format('YYYY-MM-DD')}
                      max={moment().format('YYYY-MM-DD')}
                      onChange={(e) => setFilters({ ...filters, endDate: moment(e.target.value) })}
                    />
                  </div>

                  <div className="col-lg-3 mb-4">
                    <label htmlFor="forType" className="form-label">
                      For Type
                    </label>
                    <Select
                      options={moduleForTypeList}
                      placeholder="Select type..."
                      value={filters.forType}
                      onChange={(e) => setFilters({ ...filters, forType: e })}
                    />
                  </div>

                  <div className="col-lg-3 mb-4">
                    <label htmlFor="name" className="form-label">
                      Name
                    </label>
                    <Input
                      type="text"
                      name="name"
                      placeholder="Name..."
                      value={filters.name}
                      onChange={(e) => setFilters({ ...filters, name: e.target.value })}
                    />
                  </div>
                </div>
              </div>
              <div className="mt-4 pt-2">
                <a className="btn btn-primary me-4" onClick={applyFilters}>
                  Apply Filters
                </a>
                <a className="" onClick={clearFilters}>
                  Clear
                </a>
              </div>
            </CardBody>
          </Card>

          <Row className="mb-2 align-items-center">
            <Col sm="4">
              <div className="search-box me-2 mb-2 d-inline-block">
                <div className="position-relative font-bold">{mediaPagination?.totalCount} Media Found</div>
              </div>
            </Col>
            <Col sm="8">
              <div className="text-sm-end">
                <Link
                  replace
                  className="btn btn-success btn-rounded waves-effect waves-light mb-2 me-2"
                  to={!isTpzRoute ? SMKLinks.ADD_SMK_MEDIA : SMKLinks.ADD_TPZ_MEDIA}>
                  <i className="mdi mdi-plus me-1" />
                  Add Media
                </Link>
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg="12">
              <div className="">
                <div className="table-responsive" style={{ minHeight: 300 }}>
                  <Table className="project-list-table   table-centered table-borderless">
                    <thead>
                      <tr className="table-warning">
                        <th scope="col" style={{ width: '60px' }}>
                          Id
                        </th>
                        <th scope="col">Image</th>
                        <th scope="col">Name</th>
                        <th scope="col">Type</th>
                        <th scope="col"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {!isEmpty(mediaList) ? (
                        mediaList?.map((elem, index) => (
                          <tr key={index}>
                            <td>{elem?.id}</td>
                            <td>
                              <a
                                href={showImage(elem?.original, elem?.original?.includes('media/smk') ? 'smk' : 'tpz')}
                                target="_blank">
                                <img
                                  src={showImage(elem?.original, elem?.original?.includes('media/smk') ? 'smk' : 'tpz')}
                                  width={50}
                                  height={50}
                                  onError={(e) => {
                                    showImageError(e);
                                  }}
                                />
                              </a>
                            </td>
                            <td>
                              <div className="d-flex align-items-center">{elem?.name}</div>
                              <div className="mt-2">
                                <a
                                  target="_blank"
                                  href={showImage(
                                    elem?.original,
                                    elem?.original?.includes('media/smk') ? 'smk' : 'tpz'
                                  )}>
                                  {showImage(elem?.original, elem?.original?.includes('media/smk') ? 'smk' : 'tpz')}
                                </a>
                              </div>
                            </td>
                            <td>{elem?.type}</td>
                            <td>
                              <UncontrolledDropdown>
                                <DropdownToggle href="#" className="card-drop " tag="i">
                                  <i role="button" className="mdi mdi-dots-vertical font-size-18 " />
                                </DropdownToggle>
                                <DropdownMenu className="dropdown-menu-end">
                                  <DropdownItem onClick={() => handleDelete(elem)}>
                                    <i className="fas fa-trash-alt text-danger m-1 pe-3" />
                                    Delete
                                  </DropdownItem>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <TableError />
                      )}
                    </tbody>
                  </Table>
                </div>
              </div>
            </Col>
          </Row>

          {!isEmpty(mediaList) && <Paginate pageInfo={mediaPagination} onChangePage={handleChangePage} />}
        </Container>
      </div>
    </>
  );
};

export default MediaList;
