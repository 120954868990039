import Login from './Login';
import LOGIN_LINKS from './links';

export default [
  {
    path: LOGIN_LINKS.LOGIN_PAGE,
    exact: true,
    component: Login,
  },
  {
    path: LOGIN_LINKS.CHANGE_PASSWORD,
    exact: true,
    component: Login,
  },
];
