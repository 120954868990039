import Loader from 'components/Loader';
import { useFormik } from 'formik';
import isEmpty from 'lodash/isEmpty';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toastify';
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap';
import * as Yup from 'yup';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import useRequest from '../../../hooks/useRequest';
import { SMKLinks } from '../../../utils/links';
import { handleImageUploaded, handleUploadImage, showImage } from 'utils/helper';
import Dropzone from 'react-dropzone';
import { MODULE_TYPE } from 'utils/constants';
import ImageUploadPreview from 'components/ImageUploadPreview';
import TextEditor from 'components/TextEditor';
import AssetImageUploader from 'containers/blog/BlogList/components/AssetImageUploader';
import SlugInput from 'components/SlugInput';

const UpdateSchoolGroups = () => {
  const history = useHistory();
  const { id: schoolGroupID } = useParams();

  toast.configure();
  const [schoolGroupsDetails, setschoolGroupsDetails] = useState();

  const [uploadedImagePreview, setUploadImagePreview] = useState();
  const [imageUploading, setImageUploading] = useState(false);

  const [getSchoolGroupRequest, { loading: getSchoolGroupLoading }] = useRequest({
    url: `/school/admin/schoolGroups/${schoolGroupID}`,
    method: 'GET',
    onSuccess: (data) => {
      setschoolGroupsDetails(data);
    },
    onError: (err) => {
      toast.error(err?.message);
      console.log(err);
    },
  });

  const [addSchoolGroupRequest, { loading: addSchoolGroupLoading }] = useRequest({
    url: `/school/admin/schoolGroups`,
    method: 'POST',
    onSuccess: (data) => {
      const url = SMKLinks.SCHOOL_GROUPS;
      history.replace(url);
      toast.success('Success! School group has been added.');
    },
    onError: (err) => {
      toast.error(err?.message);
      console.log(err);
    },
  });

  const [updateSchoolGroupRequest, { loading: updateSchoolGroupLoading }] = useRequest({
    url: `/school/admin/schoolGroups/${schoolGroupID}`,
    method: 'PUT',
    onSuccess: (data) => {
      toast.success('Success! School group has been updated.');
      window.location.reload();
    },
    onError: (err) => {
      toast.error(err?.message);
      console.log(err);
    },
  });

  const editInitialValues = () => ({
    id: schoolGroupsDetails?.id,
    language: schoolGroupsDetails?.language == 1 ? { label: 'Hindi', value: 1 } : { label: 'English', value: 0 },
    isAssociation: schoolGroupsDetails?.isAssociation,
    active: schoolGroupsDetails?.active,
    deleted: schoolGroupsDetails?.deleted,
    name: schoolGroupsDetails?.name,
    shortName: schoolGroupsDetails?.shortName,
    slug: schoolGroupsDetails?.slug,
    website: schoolGroupsDetails?.website,
    summary: schoolGroupsDetails?.summary,
    description: schoolGroupsDetails?.description,
    content: schoolGroupsDetails?.content,
    logo: schoolGroupsDetails?.logo || {},
  });

  const getInitialValues = () => ({
    logo: {},
    id: '',
    language: { label: 'English', value: 0 },
    isAssociation: true,
    active: true,
    deleted: false,
    name: '',
    shortName: '',
    slug: '',
    website: '',
    summary: '',
    description: '',
    content: '',
  });

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: false,
    initialValues: isEmpty(schoolGroupID) ? { ...getInitialValues() } : { ...editInitialValues() },
    onSubmit: async (values) => {
      const data = {
        logo: values?.logo?.id ? { id: values?.logo?.id } : null,
        language: values?.language?.value,
        isAssociation: values?.isAssociation,
        active: values?.active,
        deleted: values?.deleted,
        name: values?.name,
        shortName: values?.shortName,
        slug: values?.slug,
        website: values?.website,
        summary: values?.summary,
        description: values?.description,
        content: values?.content,
      };

      isEmpty(schoolGroupID)
        ? addSchoolGroupRequest({
            body: {
              ...data,
            },
          })
        : updateSchoolGroupRequest({
            body: {
              ...data,
            },
          });
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required('Please provide name'),
      shortName: Yup.string().required('Please provide short name'),
      slug: Yup.string().required('Please provide slug'),
      language: Yup.object()
        .required('Please provide language')
        .nullable(true),
    }),
  });

  useEffect(() => {
    if (!isEmpty(schoolGroupID)) {
      getSchoolGroupRequest();
    }
  }, [schoolGroupID]);

  return (
    <>
      <div className="page-content">
        <Loader
          isActive={addSchoolGroupLoading || getSchoolGroupLoading || updateSchoolGroupLoading || imageUploading}
        />
        <Container fluid>
          <Breadcrumbs
            title="School Group"
            breadcrumbItem={`${isEmpty(schoolGroupID) ? 'Add' : 'Edit'}  School Group`}
          />

          <Form onSubmit={formik.handleSubmit}>
            <Row>
              <Col md={9}>
                <Card>
                  <CardBody>
                    <Row>
                      <Col md={12}>
                        <Row>
                          <Col md={12}>
                            <FormGroup className="mb-4">
                              <Label for="language" className="form-label">
                                Language
                              </Label>
                              <Select
                                id="language"
                                options={[
                                  {
                                    value: 0,
                                    label: 'English',
                                  },
                                  {
                                    value: 1,
                                    label: 'Hindi',
                                  },
                                ]}
                                value={formik.values.language}
                                onChange={(language) => formik.setFieldValue('language', language)}
                              />
                              {formik.touched.language && (
                                <div className="invalid-feedback d-block">{formik.errors.language}</div>
                              )}
                            </FormGroup>
                          </Col>
                        </Row>
                      </Col>

                      <Col md={6}>
                        <FormGroup className="mb-4">
                          <Label for="group_name" className="form-label  ">
                            Name
                          </Label>
                          <Input
                            id="group_name"
                            name="group_name"
                            type="text"
                            className="form-control"
                            placeholder="name..."
                            invalid={!!(formik.touched.name && formik.errors.name)}
                            {...formik.getFieldProps('name')}
                          />
                          <FormFeedback>{formik.errors.name}</FormFeedback>
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup className="mb-4">
                          <Label for="shortName" className="form-label  ">
                            Short Name
                          </Label>
                          <Input
                            id="shortName"
                            name="shortName"
                            type="text"
                            className="form-control"
                            placeholder="short name..."
                            invalid={!!(formik.touched.shortName && formik.errors.shortName)}
                            {...formik.getFieldProps('shortName')}
                          />
                          <FormFeedback>{formik.errors.shortName}</FormFeedback>
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <SlugInput formik={formik} nameValue={formik.values.name} formGroupClassName={'mb-0'} />
                      </Col>
                      <Col md={6}>
                        <FormGroup className="mb-4">
                          <Label for="website" className="form-label  ">
                            Website
                          </Label>
                          <Input
                            id="website"
                            name="website"
                            type="text"
                            className="form-control"
                            placeholder="URL..."
                            invalid={!!(formik.touched.website && formik.errors.website)}
                            {...formik.getFieldProps('website')}
                          />
                          <FormFeedback>{formik.errors.website}</FormFeedback>
                        </FormGroup>
                      </Col>
                      {/* <Col md={12}>
                        <FormGroup className="mb-4">
                          <Label for="summary" className="form-label  ">
                            Summary
                          </Label>
                          <Input
                            id="summary"
                            name="summary"
                            style={{ height: '100px' }}
                            type="textarea"
                            className="form-control"
                            placeholder="Summary..."
                            invalid={!!(formik.touched.summary && formik.errors.summary)}
                            {...formik.getFieldProps('summary')}
                          />
                          <FormFeedback>{formik.errors.summary}</FormFeedback>
                        </FormGroup>
                      </Col> */}

                      <Col md={12}>
                        <FormGroup className="mb-4">
                          <Label for="description" className="form-label">
                            Description
                          </Label>
                          <TextEditor
                            simple
                            height={200}
                            initialValue={formik.values?.description}
                            onChange={(val) => formik.setFieldValue('description', val)}
                          />

                          <FormFeedback>{formik.errors.description}</FormFeedback>
                        </FormGroup>
                      </Col>

                      {/* <Col md={12}>
                        <FormGroup className="mb-4 ">
                          <Label for="content" className="form-label  ">
                            Content
                          </Label>
                          <TextEditor
                            simple
                            height={200}
                            initialValue={formik.values?.content}
                            onChange={(val) => formik.setFieldValue('content', val)}
                          />

                          <FormFeedback>{formik.errors.content}</FormFeedback>
                        </FormGroup>
                      </Col> */}
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col md={3}>
                <Card>
                  <CardBody>
                    <Button type="submit" color="primary" className="w-100">
                      {isEmpty(schoolGroupID) ? 'Add' : 'Save'}
                    </Button>

                    <Button
                      className="w-100 mt-3"
                      color="light"
                      onClick={() => {
                        history.replace(SMKLinks.SCHOOL_GROUPS);
                      }}>
                      Cancel
                    </Button>
                  </CardBody>
                </Card>
                <Card>
                  <CardBody>
                    <div>
                      <CardTitle className="mb-3">Active </CardTitle>
                      {[true, false].map((option, i) => (
                        <div key={`active_${i}`} className="form-check mb-3 d-inline-block me-4">
                          <input
                            type="radio"
                            id={`active_${option}`}
                            name="babyname_active"
                            className="form-check-input"
                            checked={formik.values.active === option}
                            onChange={() => formik.setFieldValue('active', option)}
                          />
                          <label className="form-check-label" htmlFor={`active_${option}`}>
                            {option ? 'Yes' : 'No'}
                          </label>
                        </div>
                      ))}
                    </div>

                    <div>
                      <CardTitle className="mb-3">Delete </CardTitle>
                      {[true, false].map((option, i) => (
                        <div key={`deleted_${i}`} className="form-check mb-3 d-inline-block me-4">
                          <input
                            type="radio"
                            id={`deleted_${option}`}
                            name="babyname_deleted"
                            className="form-check-input"
                            checked={formik.values.deleted === option}
                            onChange={() => formik.setFieldValue('deleted', option)}
                          />
                          <label className="form-check-label" htmlFor={`deleted_${option}`}>
                            {option ? 'Yes' : 'No'}
                          </label>
                        </div>
                      ))}
                    </div>
                    <div>
                      <CardTitle className="mb-3">Is Association </CardTitle>
                      {[true, false].map((option, i) => (
                        <div key={`isAssociation_${i}`} className="form-check  d-inline-block me-4">
                          <input
                            type="radio"
                            id={`isAssociation_${option}`}
                            name="isAssociation"
                            className="form-check-input"
                            checked={formik.values.isAssociation === option}
                            onChange={() => formik.setFieldValue('isAssociation', option)}
                          />
                          <label className="form-check-label" htmlFor={`isAssociation_${option}`}>
                            {option ? 'Yes' : 'No'}
                          </label>
                        </div>
                      ))}
                    </div>
                  </CardBody>
                </Card>

                <AssetImageUploader
                  forType={MODULE_TYPE.SCHOOL}
                  formik={formik}
                  fieldName={'logo'}
                  label={'Select Logo'}
                />
              </Col>
            </Row>
          </Form>
        </Container>
      </div>
    </>
  );
};

export default UpdateSchoolGroups;
