import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card, CardBody, CardTitle } from 'reactstrap';
import { Link } from 'react-router-dom';

// import Charts
import { withTranslation } from 'react-i18next';
// import { RangeDatePicker } from 'react-google-flight-datepicker';
// import 'react-google-flight-datepicker/dist/main.css';
// Import Breadcrumb
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import ActivityHistory from './activityHistory';
import ActivityCount from './activityCount';
import TopUsers from './topUsers';
import UsersByLogin from './usersByLogin';
import UsersByCountry from './usersByCountry';
import UsersByApp from './usersByApp';
import ActivityTypeByLastUsage from './activityType';
import DateRangeSelector from '../../../components/dateRangeSelector';
import moment from 'moment';

const AppDashboard = (props) => {
  const [dateRange, setDateRange] = useState();
  const [startDate, setStartDate] = useState(moment(new Date())?.subtract(1, 'month'));
  const [endDate, setEndDate] = useState(new Date());

  useEffect(() => {
    if (dateRange) {
      setStartDate(dateRange.dateRange.startDate);
      setEndDate(dateRange.dateRange.endDate);
    }
  }, [dateRange]);

  return (
    <>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title={props.t('TPZ')} breadcrumbItem={props.t('APP Dashboard')} />

          {/* React Calendar  */}
          <DateRangeSelector dateRange={dateRange} setDateRange={setDateRange} />
          <Row>
            <Col xl="6">
              <Card>
                <CardBody>
                  <CardTitle className="float-sm-left">Activity Count</CardTitle>
                  <ActivityCount startDate={startDate} endDate={endDate} />
                </CardBody>
              </Card>
            </Col>
            <Col xl="6">
              <Card>
                <CardBody>
                  <CardTitle className="float-sm-left">Top Ten Users</CardTitle>
                  <TopUsers startDate={startDate} endDate={endDate} />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col xl="6">
              <UsersByApp startDate={startDate} endDate={endDate} />
            </Col>
            <Col xl="6">
              <UsersByLogin startDate={startDate} endDate={endDate} />
            </Col>
          </Row>
          <Row>
            <Col xl="6">
              <UsersByCountry startDate={startDate} endDate={endDate} />
            </Col>
            <Col xl="6">
              <ActivityTypeByLastUsage startDate={startDate} endDate={endDate} />
            </Col>
          </Row>

          <Row>
            <Col lg="12">
              <ActivityHistory startDate={startDate} endDate={endDate} />
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

AppDashboard.propTypes = {
  t: PropTypes.any,
};

export default withTranslation()(AppDashboard);
