import TableError from 'components/TableError';
import { invert, isEmpty } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { Badge, Card, CardBody, Col, Container, Row, Table } from 'reactstrap';
import { SORT_VALUES, SUBSCRIPTION_MODULE_TYPE, SUBSCRIPTION_STATUS_TYPES } from 'utils/constants';
import { SMKLinks } from 'utils/links';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import Loader from '../../../components/Loader';
import Paginate from '../../../components/Paginate';
import useRequest from '../../../hooks/useRequest';
import { useRouter } from '../../../hooks/useRouter';
import { buildURL, checkIfValueExist, printSerialNumbers, titleCaseIfExists } from '../../../utils/helper';

const UserSubscriptionsList = () => {
  const router = useRouter();
  const history = useHistory();
  toast.configure();

  const [userSubscriptionList, setUserSubscriptionList] = useState([]);
  const [userSubscriptionPagination, setUserSubscriptionPagination] = useState({});

  const subscriptionStatusTypes = Object.keys(SUBSCRIPTION_STATUS_TYPES)?.map((elem) => ({
    label: titleCaseIfExists(elem),
    value: SUBSCRIPTION_STATUS_TYPES[elem],
  }));

  const primaryModuleTypes = Object.keys(SUBSCRIPTION_MODULE_TYPE)?.map((elem) => ({
    label: titleCaseIfExists(elem),
    value: SUBSCRIPTION_MODULE_TYPE[elem],
  }));

  const defaultFilters = {
    status: null,
    primaryModule: '',
  };

  const [filters, setFilters] = useState(defaultFilters);

  const [getuserSubscriptionListRequest, { loading: getuserSubscriptionListLoading }] = useRequest({
    url: '/subscription/admin/user-subscription/search',
    method: 'POST',
    onSuccess: (data) => {
      setUserSubscriptionList(data?.results);
      setUserSubscriptionPagination(data?.paginator);
    },
  });

  const setPageInUrl = (page) => {
    history.push({
      pathname: router.pathname,
      search: `?${new URLSearchParams({ page }).toString()}`,
    });
  };

  const handleChangePage = (page) => {
    setPageInUrl(page);

    const payload = {
      page,
      size: 20,
      ...SORT_VALUES,
      ...filters,
      status: checkIfValueExist(filters?.status),
      primaryModule: checkIfValueExist(filters?.primaryModule),
    };
    getuserSubscriptionListRequest({
      body: {
        ...payload,
      },
    });
  };

  const applyFilters = () => {
    handleChangePage(1);
  };

  const clearFilters = () => {
    setPageInUrl(1);

    setFilters({ ...defaultFilters });
    const defaultPayload = {
      page: 1,
      size: 20,
      ...SORT_VALUES,
      ...defaultFilters,
      status: checkIfValueExist(defaultFilters?.status),
      primaryModule: checkIfValueExist(defaultFilters?.primaryModule),
    };
    getuserSubscriptionListRequest({
      body: {
        ...defaultPayload,
      },
    });
  };

  useEffect(() => {
    handleChangePage(parseInt(router?.query?.page, 10) || 1);
  }, []);

  return (
    <>
      <div className="page-content">
        <Loader isActive={getuserSubscriptionListLoading} />

        <Container fluid>
          <Breadcrumbs title="User Subscriptions" breadcrumbItem="User Subscriptions List" />

          <Card>
            <CardBody>
              <div className="mt-4">
                <Row>
                  <div className="col-lg-2 mb-4">
                    <label className="form-label">Status</label>
                    <Select
                      options={subscriptionStatusTypes}
                      value={subscriptionStatusTypes?.find((e) => e?.value === filters?.status) || null}
                      placeholder="Select Status..."
                      onChange={(e) => setFilters({ ...filters, status: e?.value })}
                    />
                  </div>

                  <div className="col-lg-3 mb-4">
                    <label className="form-label">Primary module</label>
                    <Select
                      options={primaryModuleTypes}
                      value={primaryModuleTypes?.find((e) => e?.value === filters?.primaryModule) || null}
                      placeholder="Select Primary Module..."
                      onChange={(e) => setFilters({ ...filters, primaryModule: e?.value })}
                    />
                  </div>
                </Row>
              </div>
              <div className="mt-4 pt-2">
                <a className="btn btn-primary me-4" onClick={applyFilters}>
                  Apply Filters
                </a>
                <a className="" onClick={clearFilters}>
                  Clear
                </a>
              </div>
            </CardBody>
          </Card>

          <Row className="mb-2 align-items-center">
            <Col sm="4">
              <div className="search-box me-2 mb-2 d-inline-block">
                <div className="position-relative font-bold">
                  {userSubscriptionPagination?.totalCount} User Subscriptions Found
                </div>
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg="12">
              <div className="">
                <div className="table-responsive" style={{ minHeight: 300 }}>
                  <Table className="project-list-table   table-centered table-borderless">
                    <thead>
                      <tr className="table-warning">
                        <th scope="col" style={{ width: '60px' }}>
                          S.No.
                        </th>
                        <th scope="col">Plan name</th>
                        <th scope="col">Payment Done By </th>
                        <th scope="col">Payment Currency</th>
                        <th scope="col">Plan Primary Module</th>
                        <th scope="col">Payment Amount</th>
                        <th scope="col">Status</th>
                        <th scope="col">Active</th>
                      </tr>
                    </thead>
                    <tbody>
                      {!isEmpty(userSubscriptionList) ? (
                        userSubscriptionList?.map((elem, index) => (
                          <tr>
                            <td>{printSerialNumbers(userSubscriptionPagination)[index]}</td>
                            <td>
                              <div className="d-flex align-items-center">
                                {elem?.active ? (
                                  <Link
                                    to={buildURL(SMKLinks.USER_SUBSCRIPTION_DETAILS, {
                                      id: elem?.id,
                                    })}>
                                    {elem?.subscriptionPlan?.name}
                                  </Link>
                                ) : (
                                  elem?.subscriptionPlan?.name
                                )}
                              </div>
                            </td>
                            <td>{elem?.payment?.paymentDetails?.payment?.email}</td>
                            <td>{elem?.payment?.paymentDetails?.payment?.currency}</td>
                            <td>
                              {titleCaseIfExists(
                                invert(SUBSCRIPTION_MODULE_TYPE)[elem?.subscriptionPlan?.primaryModule]
                              )}
                            </td>
                            <td>{elem?.payment?.amount}</td>
                            <td>
                              <Badge
                                className={
                                  elem?.status === 'active'
                                    ? 'bg-success'
                                    : elem?.status === 'payment_pending'
                                    ? 'bg-warning'
                                    : 'bg-danger'
                                }>
                                {titleCaseIfExists(elem?.status)}
                              </Badge>
                            </td>
                            <td>
                              <Badge className={elem?.active ? 'bg-success' : 'bg-danger'}>
                                {elem?.active ? 'Active' : 'Disabled'}
                              </Badge>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <TableError />
                      )}
                    </tbody>
                  </Table>
                </div>
              </div>
            </Col>
          </Row>

          {!isEmpty(userSubscriptionList) && (
            <Paginate pageInfo={userSubscriptionPagination} onChangePage={handleChangePage} />
          )}
        </Container>
      </div>
    </>
  );
};

export default UserSubscriptionsList;
