// Import Breadcrumb
import Breadcrumbs from 'components/Common/Breadcrumb';
import React, { useEffect, useState } from 'react';
import {
  Badge,
  Card,
  CardBody,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Row,
  Table,
  UncontrolledDropdown,
} from 'reactstrap';
// Import Image
import TableError from 'components/TableError';
import { isEmpty } from 'lodash';
import * as moment from 'moment';
import { Link, useHistory } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toastify';
import confirm from 'reactstrap-confirm';
import Loader from '../../../components/Loader';
import Paginate from '../../../components/Paginate';
import useRequest from '../../../hooks/useRequest';
import { useRouter } from '../../../hooks/useRouter';
import {
  ACTIVE_AND_DELETED_ARRAY,
  BabyNameMetaInfoType,
  BABY_NAMES_GENDER_ARRAY,
  GENDER_VALUES,
  LANGUAGE_ARRAY,
  LANGUAGE_VALUES,
  SORT_VALUES,
} from '../../../utils/constants';
import { buildURL, checkIfValueExist, convertHtmlToText, printDateTimeTwoLines } from '../../../utils/helper';
import { SMKLinks } from '../../../utils/links';

const BabynamesList = () => {
  const router = useRouter();
  const history = useHistory();
  toast.configure();

  const [babyNames, setBabyNames] = useState([]);
  const [babyNamesPagination, setBabyNamesPagination] = useState({});
  const [originList, setOriginList] = useState([]);
  const [religionList, setReligionList] = useState([]);

  const defaultFilters = {
    active: { value: null, label: 'All' },
    deleted: { value: null, label: 'All' },
    startDate: moment().subtract(1, 'years'),
    endDate: moment(),
    id: undefined,
    language: 0,
    name: undefined,
    meaning: undefined,
    gender: undefined,
    origin: undefined,
    religion: undefined,
    forTranslation: { value: null, label: 'All' },
  };

  const [filters, setFilters] = useState(defaultFilters);

  const [getBabyNamesRequest, { loading: getBabyNamesLoading }] = useRequest({
    url: `/baby-name/admin/search`,
    method: 'POST',
    onSuccess: (data) => {
      // console.log({ babyNameList: data });
      setBabyNamesPagination(data?.pagination);
      setBabyNames(data?.results);
    },
  });

  const [markPublishRequest, { loading: markPublishLoading }] = useRequest({
    method: 'POST',
    onSuccess: (data) => {
      // console.log(data);
      toast.success('Success! Baby Name has been Published');
    },
    onError: (err) => {
      toast.error(err?.message);
      console.log(`Error occurred: ${err}`);
    },
  });

  const [markUnpublishRequest, { loading: markUnpublishLoading }] = useRequest({
    method: 'POST',
    onSuccess: (data) => {
      // console.log(data);
      toast.success('Success! Baby Name has been Unpublished');
    },
    onError: (err) => {
      toast.error(err?.message);
      console.log(`Error occurred: ${err}`);
    },
  });

  const [undeleteReviewRequest, { loading: undeleteReviewLoading }] = useRequest({
    method: 'POST',
    onSuccess: (data) => {
      // console.log(data);
      toast.success('Success! Baby Name has been UnDelete');
    },
    onError: (err) => {
      toast.error(err?.message);
      console.log(`Error occurred: ${err}`);
    },
  });

  const setPageInUrl = (page) => {
    history.push({
      pathname: router.pathname,
      search: `?${new URLSearchParams({ page }).toString()}`,
    });
  };

  const handleChangePage = (page) => {
    setPageInUrl(page);

    const data = {
      page,
      size: 20,
      ...filters,
      active: checkIfValueExist(filters.active?.value),
      deleted: checkIfValueExist(filters.deleted?.value),
      forTranslation: checkIfValueExist(filters.forTranslation?.value),
      gender: filters?.gender?.value,
      ...SORT_VALUES,
    };
    if (filters.gender === '') {
      data.gender = undefined;
    }
    if (filters.religion === '') {
      data.religion = undefined;
    }
    if (filters.origin === '') {
      data.origin = undefined;
    }

    getBabyNamesRequest({
      body: {
        ...data,
      },
    });
  };

  const [deleteBabyNameRequest, { loading: deleteBabyNameLoading }] = useRequest({
    method: 'POST',
    onSuccess: (data) => {
      // console.log(data);
      handleChangePage(parseInt(router?.query?.page, 10) || 1);
      toast.success('Success! Baby Name has been Deleted');
    },
    onError: (err) => {
      toast.error(err?.message);
      console.log(`Error occurred: ${err}`);
    },
  });

  const applyFilters = () => {
    handleChangePage(1);
  };

  const clearFilters = () => {
    setPageInUrl(1);

    setFilters({
      ...defaultFilters,
      id: '',
      name: '',
      meaning: '',
      gender: '',
      origin: '',
      religion: '',
    });
    getBabyNamesRequest({
      body: {
        page: 1,
        size: 50,
        ...defaultFilters,
        active: checkIfValueExist(defaultFilters.active?.value),
        deleted: checkIfValueExist(defaultFilters.deleted?.value),
        forTranslation: checkIfValueExist(defaultFilters.forTranslation?.value),
        ...SORT_VALUES,
      },
    });
  };

  const [getOriginRequest, { loading: getOriginLoading }] = useRequest({
    url: `/baby-name/metaInfo/${BabyNameMetaInfoType.ORIGIN}`,
    method: 'GET',
    headers: {
      'accept-language': 'en',
    },
    onSuccess: (data) => {
      // console.log(data);
      setOriginList(data);
    },
  });

  const [getReligionRequest, { loading: getReligionLoading }] = useRequest({
    url: `/baby-name/metaInfo/${BabyNameMetaInfoType.RELIGION}`,
    method: 'GET',
    headers: {
      'accept-language': 'en',
    },
    onSuccess: (data) => {
      // console.log(data);
      setReligionList(data);
    },
  });

  useEffect(() => {
    getOriginRequest();
    getReligionRequest();
    handleChangePage(parseInt(router?.query?.page, 10) || 1);
  }, []);

  const handleToggleActive = async (data) => {
    const result = await confirm({
      title: `${data?.active ? 'Unpublish' : 'Publish'} Babyname`,
      message: `Are you sure, you want to ${data?.active ? 'Unpublish' : 'Publish'} this Babyname?`,
      confirmText: 'Confirm',
      confirmColor: 'primary',
      cancelColor: 'link text-danger',
    });

    if (result) {
      if (data?.active) {
        markUnpublishRequest({ url: `/baby-name/admin/unpublish/${data.id}` });
      } else {
        markPublishRequest({ url: `/baby-name/admin/publish/${data.id}` });
      }
    }
  };

  // const handleDeleteItem = async (data) => {
  //   const result = await confirm({
  //     title: `${data?.active ? 'Undelete' : 'Delete'} Babyname`,
  //     message: `Are you sure, you want to ${data?.deleted ? 'Undelete' : 'Delete'
  //       } this Babyname?`,
  //     confirmText: 'Confirm',
  //     confirmColor: 'primary',
  //     cancelColor: 'link text-danger',
  //   });

  //   if (result) {
  //     if (data?.active) {
  //       deleteReviewRequest({ url: `/baby-name/admin/delete/${data.id}` });
  //     } else {
  //       undeleteReviewRequest({ url: `/baby-name/admin/undelete/${data.id}` });
  //     }
  //   }
  // };

  const handleDeleteBabyName = async (data) => {
    const result = await confirm({
      title: 'Delete Baby Name',
      message: `Are you sure, you want to Delete this Babyname?`,
      confirmText: 'Confirm',
      confirmColor: 'primary',
      cancelColor: 'link text-danger',
    });

    if (result) {
      deleteBabyNameRequest({ url: `/baby-name/admin/delete/${data.id}` });
    }
  };

  return (
    <>
      <div className="page-content">
        <Loader
          isActive={
            getBabyNamesLoading ||
            undeleteReviewLoading ||
            deleteBabyNameLoading ||
            markUnpublishLoading ||
            markPublishLoading ||
            getReligionLoading ||
            getOriginLoading
          }
        />
        {/* {getBabyNamesLoading && <Loader isActive />} */}
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="BabyNames" breadcrumbItem="Baby Names List" />
          <Card>
            <CardBody>
              <div className="mt-4">
                <div className="row">
                  <div className="col-lg-2">
                    <label className="form-label">Published</label>
                    <Select
                      options={ACTIVE_AND_DELETED_ARRAY}
                      value={filters.active}
                      onChange={(value) => {
                        setFilters({ ...filters, active: value });
                      }}
                    />
                  </div>
                  <div className="col-lg-2">
                    <label className="form-label">Deleted</label>
                    <Select
                      options={ACTIVE_AND_DELETED_ARRAY}
                      value={filters.deleted}
                      onChange={(value) => setFilters({ ...filters, deleted: value })}
                    />
                  </div>
                  <div className="col-lg-2">
                    <label className="form-label">Created DateFrom</label>
                    <Input
                      type="date"
                      name="startDate"
                      placeholder="From Date"
                      value={filters?.startDate?.format('YYYY-MM-DD')}
                      max={moment().format('YYYY-MM-DD')}
                      onChange={(e) =>
                        setFilters({
                          ...filters,
                          startDate: moment(e.target.value),
                        })
                      }
                    />
                  </div>
                  <div className="col-lg-2">
                    <label className="form-label">Created DateTo</label>
                    <Input
                      type="date"
                      name="endDate"
                      placeholder="To Date"
                      value={filters?.endDate?.format('YYYY-MM-DD')}
                      max={moment().format('YYYY-MM-DD')}
                      onChange={(e) =>
                        setFilters({
                          ...filters,
                          endDate: moment(e.target.value),
                        })
                      }
                    />
                  </div>
                  <div className="col-lg-2">
                    <label className="form-label">Babyname Id</label>
                    <Input
                      type="number"
                      name="name"
                      placeholder="Babyname Id..."
                      value={filters.id}
                      onChange={(e) => setFilters({ ...filters, id: e.target.value })}
                    />
                  </div>
                  <div className="col-lg-2">
                    <label className="form-label">Babyname</label>
                    <Input
                      type="text"
                      name="name"
                      placeholder="name..."
                      value={filters.name}
                      onChange={(e) => setFilters({ ...filters, name: e.target.value })}
                    />
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-lg-2">
                    <label className="form-label">Gender</label>
                    <Select
                      options={BABY_NAMES_GENDER_ARRAY}
                      value={filters.gender}
                      onChange={(value) => setFilters({ ...filters, gender: value })}
                    />
                  </div>

                  <div className="col-lg-2">
                    <label className="form-label">Origin</label>
                    <Select
                      options={originList?.map((s) => ({
                        ...s,
                        value: s.id,
                        label: s.name,
                      }))}
                      value={filters.origin && originList.find((t) => t.label === filters.origin)}
                      onChange={(item) => setFilters({ ...filters, origin: item.label })}
                    />
                  </div>
                  <div className="col-lg-2">
                    <label className="form-label">Religion</label>
                    <Select
                      options={religionList?.map((s) => ({
                        ...s,
                        value: s.id,
                        label: s.name,
                      }))}
                      value={filters.religion && religionList.find((t) => t.label === filters.religion)}
                      onChange={(item) => setFilters({ ...filters, religion: item.label })}
                    />
                  </div>

                  <div className="col-lg-2">
                    <label className="form-label">Language</label>
                    <Select
                      options={LANGUAGE_ARRAY}
                      value={LANGUAGE_ARRAY.find((t) => t.value === filters.language)}
                      onChange={(item) =>
                        setFilters({
                          ...filters,
                          language: item.value,
                        })
                      }
                    />
                  </div>

                  <div className="col-lg-2">
                    <label className="form-label">Translated</label>
                    <Select
                      options={[
                        {
                          label: 'All',
                          value: null,
                        },
                        {
                          label: 'Yes',
                          value: 0,
                        },
                        {
                          label: 'No',
                          value: 1,
                        },
                      ]}
                      value={filters.forTranslation}
                      onChange={(value) => {
                        setFilters({ ...filters, forTranslation: value });
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="mt-4">
                <a className="btn btn-primary me-4" onClick={applyFilters}>
                  Apply Filters
                </a>
                <a className="" onClick={clearFilters}>
                  Clear
                </a>
              </div>
            </CardBody>
          </Card>
          <Row className="mb-2 align-items-center">
            <Col sm="4">
              <div className="search-box me-2 mb-2 d-inline-block">
                <div className="position-relative font-bold">{babyNamesPagination?.totalCount} Baby Names Found</div>
              </div>
            </Col>
            <Col sm="8">
              <div className="text-sm-end">
                <Link
                  replace
                  className="btn btn-success btn-rounded waves-effect waves-light mb-2 me-2"
                  to={SMKLinks.ADD_BABY_NAME}>
                  <i className="mdi mdi-plus me-1" />
                  Add Baby Name
                </Link>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg="12">
              <div className="">
                <div className="table-responsive" style={{ minHeight: 300 }}>
                  <Table className="project-list-table table-centered table-borderless">
                    <thead>
                      <tr className="table-warning">
                        <th scope="col">#ID</th>
                        <th scope="col" />
                        <th scope="col">Name</th>
                        <th scope="col">Translated</th>
                        <th scope="col">Meaning</th>
                        <th scope="col">Gender</th>
                        <th scope="col">Created Date</th>
                        <th scope="col">Language</th>
                        <th scope="col">Active</th>
                        <th scope="col">Deleted</th>
                        {/* <th scope="col">View Count</th> */}
                        {/* <th scope="col">Origin</th>
                        <th scope="col">Religion</th> */}
                        <th scope="col" />
                      </tr>
                    </thead>
                    <tbody>
                      {!isEmpty(babyNames) ? (
                        babyNames?.map((data, index) => (
                          <tr key={index}>
                            <td>
                              {' '}
                              <Link
                                to={buildURL(SMKLinks.BABY_NAME_DETAILS, {
                                  id: data.id,
                                })}>
                                {data.id}
                              </Link>
                            </td>
                            <td>
                              {data?.gender?.approved ? (
                                <i title="Active" className=" bx bx-check-shield font-size-24 text-success" />
                              ) : (
                                <i title="InActive" className=" bx bx-error-alt font-size-24 text-warning" />
                              )}
                            </td>
                            <td>
                              {convertHtmlToText(data.name)}
                              {!isEmpty(data.slug) && (
                                <span className="ms-2">
                                  <a
                                    target="_blank"
                                    rel="noreferrer"
                                    href={`https:www.theparentz.com/baby-names/${data?.slug}`}>
                                    <i className="bx bx-link-external fs-4" />
                                  </a>
                                </span>
                              )}
                            </td>
                            <td>
                              {data?.language !== 0 && data?.enId ? (
                                <Link
                                  to={buildURL(SMKLinks.EDIT_BABY_NAME, {
                                    id: data?.enId,
                                  })}>
                                  {data?.enId}
                                </Link>
                              ) : (
                                <Badge className={data?.translated ? 'bg-success' : 'bg-danger'}>
                                  {data?.translated ? 'Yes' : 'No'}
                                </Badge>
                              )}
                            </td>
                            <td style={{ width: '350px' }}>
                              {data.meaning}
                              {data.meanings?.length > 0 && (
                                <>
                                  <br />
                                  <b>Meanings: </b>
                                  {data.meanings?.map((o) => o.name).join(', ')}
                                </>
                              )}
                            </td>

                            <td>{GENDER_VALUES[data?.genderValue]}</td>
                            <td>{printDateTimeTwoLines(data?.createdDate)}</td>
                            <td>
                              <Badge
                                className={`${data?.language === 0 ? 'bg-info' : 'bg-warning'} p-1`}
                                style={{ fontSize: '10px' }}>
                                {LANGUAGE_VALUES[data?.language]}
                              </Badge>
                            </td>
                            <td>
                              <Badge className={data?.active ? 'bg-success' : 'bg-danger'}>
                                {data?.active ? 'Yes' : 'No'}
                              </Badge>
                            </td>
                            <td>
                              <Badge className={data?.deleted ? 'bg-success' : 'bg-danger'}>
                                {data?.deleted ? 'Yes' : 'No'}
                              </Badge>
                            </td>
                            {/* <td>{data?.viewCount}</td> */}
                            {/* <td>{data?.origins?.map((o) => o.name).join(', ')}</td>
                            <td>{data?.religions?.map((o) => o.name).join(', ')}</td> */}
                            <td>
                              <UncontrolledDropdown>
                                <DropdownToggle href="#" className="card-drop " tag="i">
                                  <i role="button" className="mdi mdi-dots-vertical font-size-18 " />
                                </DropdownToggle>
                                <DropdownMenu className="dropdown-menu-end">
                                  <Link
                                    to={buildURL(SMKLinks.EDIT_BABY_NAME, {
                                      id: data.id,
                                    })}>
                                    <DropdownItem>
                                      <i className="fas fa-pencil-alt text-success m-1 pe-3 " />
                                      Edit
                                    </DropdownItem>
                                  </Link>

                                  <Link
                                    to={buildURL(SMKLinks.TRANSLATE_BABY_NAME, {
                                      en: true,
                                      id: data.id,
                                    })}>
                                    <DropdownItem>
                                      <i className="fas fa-pencil-alt text-success m-1 pe-3 " />
                                      Translate
                                    </DropdownItem>
                                  </Link>
                                  <DropdownItem
                                    className="text-danger"
                                    onClick={() => {
                                      handleDeleteBabyName(data);
                                    }}>
                                    <i className="fas fa-trash m-1 pe-3 " />
                                    Delete
                                  </DropdownItem>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <TableError />
                      )}
                    </tbody>
                  </Table>
                </div>
              </div>
            </Col>
          </Row>
          <Paginate pageInfo={babyNamesPagination} onChangePage={handleChangePage} />
        </Container>
      </div>
    </>
  );
};

export default BabynamesList;
