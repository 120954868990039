import React, { useEffect, useState } from 'react';
import { Card, CardBody } from 'reactstrap';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import useRequest from '../../../hooks/useRequest';

const TopUsers = ({ startDate, endDate }) => {
  const size = 10;
  const [users, setUsers] = useState([]);
  const [getAllActivityTopUsersRequest, { loading: getAllActivityTopUsersLoading }] = useRequest({
    url: '/patient-tracking/admin-tools/app-top-users',
    method: 'POST',
    onSuccess: (data) => {
      setUsers(data.results);
    },
    onError: (err) => {
      toast.error(err?.message?.message || err?.message);
    },
  });

  useEffect(() => {
    getAllActivityTopUsersRequest({
      body: {
        startDate,
        endDate,
        size,
      },
    });
  }, [startDate, endDate]);

  return (
    <>
      <Card>
        <CardBody>
          <div className="table-responsive" style={{ minHeight: 300, maxHeight: 300 }}>
            <table className="table table-centered table-nowrap mb-0">
              <thead className="thead-light">
                <tr>
                  {/* <th>ID</th> */}
                  <th>User Name</th>
                  <th>Activity Name</th>
                  <th>Count</th>
                </tr>
              </thead>
              <tbody>
                {users.map((it, key) => (
                  <tr key={`_tr_${key}`}>
                    {/* <td>
                      <Link to="#" className="text-body font-weight-bold">
                        {it.userId}
                      </Link>
                    </td> */}
                    <td>{it.user_name}</td>
                    <td>{it.activity_type}</td>
                    <td>{it.count}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </CardBody>
      </Card>
    </>
  );
};

export default TopUsers;
