import AuthService from 'common/auth.service';
import edjsHTML from 'editorjs-html';
import { isEmpty } from 'lodash';
import { isDevMode } from 'utils/constants';

const getAlignmentClass = (alignment) => {
  switch (alignment) {
    case 'center':
      return 'text-center';
    case 'left':
      return 'text-start';
    case 'right':
      return 'text-end';
    default:
      return '';
  }
};

const handleColumns = (block, edjsParser) => {
  const colCount = block.data.cols.length;
  let colClass = 'col';

  switch (colCount) {
    case 2:
      colClass = 'col-md-6';
      break;
    case 3:
      colClass = 'col-md-4';
      break;
    case 4:
      colClass = 'col-md-3';
      break;
    default:
      colClass = 'col-md-12';
  }

  const alignmentClass = getAlignmentClass(block?.tunes?.anyTuneName?.alignment);

  const columnHtml = block.data.cols.map((col) => {
    const colBlocksHtml = col.blocks.map((colBlock) => handleBlock(colBlock, edjsParser));
    return `<div class="${colClass} ${alignmentClass}">${colBlocksHtml.join('')}</div>`;
  });

  return `<div class="row columns">${columnHtml.join('')}</div>`;
};

const handleTable = (block) => {
  const alignmentClass = getAlignmentClass(block?.tunes?.anyTuneName?.alignment);

  const rowsHtml = block.data.content
    .map((row) => {
      const cellsHtml = row.map((cell) => `<td>${cell}</td>`).join('');
      return `<tr>${cellsHtml}</tr>`;
    })
    .join('');

  return (
    '<div class="table-responsive"><table class="table table-bordered ' +
    alignmentClass +
    '">' +
    rowsHtml +
    '</table></div>'
  );
};

const handleCustomBlock = (block) => {
  if (block?.type === 'customBlock' && block?.data?.url) {
    const alignmentClass = getAlignmentClass(block?.tunes?.anyTuneName?.alignment);

    return (
      '<div class="custom-image-block mb-4 ' +
      alignmentClass +
      '">' +
      '<img src="' +
      block.data.url +
      '" alt="' +
      block.data.name +
      '" />' +
      '</div>'
    );
  }
  return '';
};
const handleRawHtml = (block) => {
  if (block?.type === 'raw' && block?.data?.html) {
    return `<div class="custom-raw-html">${block?.data?.html}</div>`;
  }
  return '';
};

const handleBlock = (block, edjsParser) => {
  switch (block.type) {
    case 'columns':
      return handleColumns(block, edjsParser);
    case 'table':
      return handleTable(block);
    case 'customBlock':
      return handleCustomBlock(block);
    case 'header':
      return handleHeader(block);
    case 'raw':
      return handleRawHtml(block);
    default:
      const alignmentClass = getAlignmentClass(block?.tunes?.anyTuneName?.alignment);
      return `<div class="${alignmentClass}">${edjsParser.parse({ blocks: [block] }).join('')}</div>`;
  }
};

const handleHeader = (block) => {
  const level = block.data?.level;
  const alignmentClass = getAlignmentClass(block?.tunes?.anyTuneName?.alignment);
  if (level === 2 || level === 3) {
    const text = block.data.text;
    const id = text?.replace(/\W/g, '');

    return `<h${level} id="${id}" class="${alignmentClass}">${text}</h${level}>`;
  }
  return `<h${level} class="${alignmentClass}">${block.data.text}</h${level}>`;
};

const processBlocksRecursively = (blocks, edjsParser) => {
  return blocks.map((block) => handleBlock(block, edjsParser)).join('');
};

export const convertEditorBlocksToHTML = (data) => {
  const edjsParser = edjsHTML();

  const combinedHtml = processBlocksRecursively(data?.blocks || [], edjsParser);

  return `<div class="content-wrapper">${combinedHtml}</div>`;
};

export const showImageError = (e) => {
  if (!isDevMode) {
    return;
  }

  const url = e.target.src;
  const authService = new AuthService();
  const source = authService.getAppSourceCookie();

  if (e.target.dataset.errorHandled) {
    e.target.onerror = null;
    return;
  }

  e.target.dataset.errorHandled = true;

  if (url?.includes('undefined')) {
    e.target.onerror = null;
    e.target.src = '';
    return null;
  } else {
    const stgURL =
      source === 'schoolmykids'
        ? process.env.REACT_APP_SMK_AWS_S3_CLOUDFRONT_URL
        : process.env.REACT_APP_TPZ_AWS_S3_CLOUDFRONT_URL;

    const prodURL =
      source === 'schoolmykids' ? process.env.REACT_APP_PRODUCTION_URL : process.env.REACT_APP_THEPARENTZ_WEB_URL;
    const img_url = e.target.src.replace(stgURL, prodURL?.replaceAll('.rhatech.in', '.com'));

    e.target.onerror = null;
    e.target.src = img_url;
  }
};

export const isObjectEmpty = (obj) => {
  if (!isEmpty(obj)) {
    const values = Object.values(obj);
    const filteredValues = values.filter((value) => {
      return typeof value !== 'boolean' && !isEmpty(value);
    });

    return filteredValues.length === 0;
  } else {
    return false;
  }
};

export const checkCustomListDataIsExist = (obj) => {
  if (!isEmpty(obj)) {
    const checkAllValuesEmpty =
      isEmpty(obj?.city?.name) && isEmpty(obj?.header) && isEmpty(obj?.footer) && isEmpty(obj?.sections);
    return !checkAllValuesEmpty;
  } else {
    return false;
  }
};

export const getSchoolCity = (school) => {
  const address = school?.address ? school?.address : school;
  return (address && address?.city && address?.city?.displayName) || address?.city?.displayName;
};

export const getSchoolAddress = (school) => {
  if (!school) return '';
  const address = school?.address ? school?.address : school;

  const parts = [
    address.subArea?.displayName,
    address.city?.displayName,
    address.region?.displayName,
    address.country?.displayName,
  ];

  return parts.filter(Boolean).join(', ');
};

export const createTreeStructure = (data, key) => {
  const dataMap = {};

  data.forEach((elem) => {
    dataMap[elem.id] = { ...elem, subcategories: [] };
  });

  const dataTree = [];
  data.forEach((elem) => {
    if (elem[key] && dataMap[elem[key]]) {
      dataMap[elem[key]].subcategories.push(dataMap[elem.id]);
    } else {
      dataTree.push(dataMap[elem.id]);
    }
  });

  const sortCategoriesByName = (elem) => {
    elem.sort((a, b) => a.name.localeCompare(b.name));
    elem.forEach((cat) => {
      if (cat.subcategories.length > 0) {
        sortCategoriesByName(cat.subcategories);
      }
    });
  };

  sortCategoriesByName(dataTree);

  return dataTree;
};

export const BLOG_STATUS_ENUM = {
  DRAFT: 'draft',
  READY_FOR_REVIEW: 'ready_for_review',
  ON_HOLD: 'on_hold',
  READY_FOR_PUBLISH: 'ready_for_publish',
  PUBLISHED: 'published',
  UNPUBLISHED: 'unpublished',
  TRASH: 'trash',
};

export const article_contributor_status = [
  {
    label: 'Draft',
    value: BLOG_STATUS_ENUM.DRAFT,
    show: true,
    keys: {
      active: false,
      deleted: false,
    },
  },
  {
    label: 'Ready for Review',
    value: BLOG_STATUS_ENUM.READY_FOR_REVIEW,
    show: true,
    nextStep: true,
    keys: {
      active: false,
      deleted: false,
    },
  },

  {
    label: 'On Hold',
    value: BLOG_STATUS_ENUM.ON_HOLD,
    show: false,
    keys: {
      active: false,
      deleted: false,
    },
  },
  {
    label: 'Ready for Publish',
    value: BLOG_STATUS_ENUM.READY_FOR_PUBLISH,
    show: false,
    keys: {
      active: false,
      deleted: false,
    },
  },
];

export const article_reviewer_status = [
  {
    label: 'Draft',
    value: BLOG_STATUS_ENUM.DRAFT,
    show: true,
    keys: {
      active: false,
      deleted: false,
    },
  },
  {
    label: 'On Hold',
    value: BLOG_STATUS_ENUM.ON_HOLD,
    show: true,
    keys: {
      active: false,
      deleted: false,
    },
  },
  {
    label: 'Ready for Publish',
    value: BLOG_STATUS_ENUM.READY_FOR_PUBLISH,
    show: true,
    keys: {
      active: false,
      deleted: false,
    },
  },
];

export const article_editor_status = [
  {
    label: 'Draft',
    value: BLOG_STATUS_ENUM.DRAFT,
    show: true,
    keys: {
      active: false,
      deleted: false,
    },
  },
  {
    label: 'Ready for Review',
    value: BLOG_STATUS_ENUM.READY_FOR_REVIEW,
    show: true,
    keys: {
      active: false,
      deleted: false,
    },
  },
  {
    label: 'On Hold',
    value: BLOG_STATUS_ENUM.ON_HOLD,
    show: true,
    keys: {
      active: false,
      deleted: false,
    },
  },
  {
    label: 'Ready for Publish',
    value: BLOG_STATUS_ENUM.READY_FOR_PUBLISH,
    show: true,
    keys: {
      active: false,
      deleted: false,
    },
  },
];

export const article_admin_status = [...article_editor_status];

export const checkUserPermission = (loggedInUser, module) => {
  if (isEmpty(loggedInUser)) {
    return;
  }

  const getRoleWithStatus = (role, status) => ({
    role,
    ...(module === 'article' ? { status } : {}),
  });

  const { create, read, update, delete: del, publish, admin } = loggedInUser?.entityPermissions?.[module] || {};

  if (create && read && update && del && publish && admin) {
    return getRoleWithStatus('role_admin', article_admin_status);
  }

  if (create && read && update && del && publish) {
    return getRoleWithStatus('role_editor', article_editor_status);
  }

  if (create && read && update && del) {
    return getRoleWithStatus('role_reviewer', article_reviewer_status);
  }

  if (create && read && update) {
    return getRoleWithStatus('role_contributor', article_contributor_status);
  }
};

export const hasPublishPermission = (loggedInUser, module) => {
  if (isEmpty(loggedInUser)) {
    return;
  } else {
    return loggedInUser?.entityPermissions?.[module]?.publish;
  }
};

export const hasDeletePermission = (loggedInUser, module) => {
  if (isEmpty(loggedInUser)) {
    return;
  } else {
    return loggedInUser?.entityPermissions?.[module]?.delete;
  }
};
export const hasAdminPermission = (loggedInUser, module) => {
  if (isEmpty(loggedInUser)) {
    return;
  } else {
    return loggedInUser?.entityPermissions?.[module]?.admin;
  }
};
