import Loader from 'components/Loader';
import TableError from 'components/TableError';
import useRequest from 'hooks/useRequest';
import { useRouter } from 'hooks/useRouter';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Link, Router, useHistory } from 'react-router-dom';
import Select from 'react-select';
import {
  Badge,
  Card,
  CardBody,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Row,
  Table,
  UncontrolledDropdown,
} from 'reactstrap';
import { LANGUAGE_ARRAY_WITH_STRING, LANGUAGE_VALUES } from 'utils/constants';
import { buildURL, printDateTimeTwoLines } from 'utils/helper';
import { SMKLinks } from 'utils/links';
import Paginate from '../../../components/Paginate';
import Breadcrumb from 'components/Common/Breadcrumb';

const Subjects = () => {
  const router = useRouter();
  const history = useHistory();
  const [subjects, setSubjects] = useState([]);
  const [subjectPagination, setSubjectPagination] = useState({});
  const [sort, setSort] = useState({
    sortBy: 'id',
    sortOrder: 0,
  });

  const defaultFilters = {
    active: { value: null, label: 'All' },
    deleted: { value: null, label: 'All' },
    name: '',
    language: LANGUAGE_ARRAY_WITH_STRING[0]?.value,
    id: '',
  };
  const [filters, setFilters] = useState(defaultFilters);

  const setPageInUrl = (page) => {
    history.push({
      pathname: router.pathname,
      search: `?${new URLSearchParams({ page }).toString()}`,
    });
  };
  const [getSubjectRequest, { loading: getSubjectListLoading }] = useRequest({
    url: `/curriculum/admin/subject/search`,
    method: 'POST',
    onSuccess: (data) => {
      setSubjectPagination(data?.paginator);
      setSubjects(data.results);
      console.log(data);
    },
  });
  const handleChangePage = (page) => {
    setPageInUrl(page);

    getSubjectRequest({
      body: {
        page,
        size: 50,
        sortBy: 'id',
        sortOrder: 0,
        ...filters,
        active: filters.active?.value,
        deleted: filters.deleted?.value,
        ...sort,
      },
    });
  };

  const clearFilters = () => {
    setPageInUrl(1);
    setFilters(defaultFilters);

    getSubjectRequest({
      body: {
        page: 1,
        size: 50,
        sortBy: 'id',
        sortOrder: 0,
        ...defaultFilters,
        active: defaultFilters.active?.value,
        deleted: defaultFilters.deleted?.value,
        ...sort,
      },
    });
  };

  const applyFilters = () => {
    handleChangePage(1);
  };

  useEffect(() => {
    handleChangePage(parseInt(Router?.query?.page, 10) || 1);
  }, []);

  const handleActive = () => {};
  const handlehandleDeleteClickActive = () => {};
  const handleEditClick = () => {};

  return (
    <div>
      <Loader isActive={getSubjectListLoading} />

      <div className="page-content">
        <Breadcrumb title="Worksheets" breadcrumbItem="Worksheets List" />
        <Card>
          <CardBody>
            <div className="mt-4">
              <div className="row">
                <div className="col-lg-2 mb-4">
                  <label className="form-label">Active</label>
                  <Select
                    options={[
                      { value: null, label: 'All' },
                      { value: true, label: 'Yes' },
                      { value: false, label: 'No' },
                    ]}
                    value={filters?.active}
                    onChange={(value) => {
                      setFilters({ ...filters, active: value });
                      if (value === null) {
                        const newFilters = { ...filters };
                        delete newFilters.active;
                        setFilters(newFilters);
                      }
                    }}
                  />
                </div>
                <div className="col-lg-2 mb-4">
                  <label className="form-label">Deleted</label>
                  <Select
                    options={[
                      { value: null, label: 'All' },
                      { value: true, label: 'Yes' },
                      { value: false, label: 'No' },
                    ]}
                    value={filters?.deleted}
                    onChange={(value) => setFilters({ ...filters, deleted: value })}
                  />
                </div>
                <div className="col-lg-2 mb-4">
                  <label className="form-label">Language</label>
                  <Select
                    options={LANGUAGE_ARRAY_WITH_STRING?.map((s) => ({
                      ...s,
                      value: s.value,
                      label: s.label,
                    }))}
                    value={LANGUAGE_ARRAY_WITH_STRING?.find((t) => t.value == filters?.language)}
                    onChange={(item) =>
                      setFilters({
                        ...filters,
                        language: item.value,
                      })
                    }
                  />
                </div>
                <div className="col-lg-2 mb-4">
                  <label className="form-label">Name</label>
                  <Input
                    type="text"
                    name="name"
                    placeholder="name..."
                    value={filters?.name}
                    onChange={(e) => setFilters({ ...filters, name: e.target.value })}
                  />
                </div>
              </div>
            </div>

            <div className="mt-4">
              <a className="btn btn-primary me-4" onClick={applyFilters}>
                Apply Filters
              </a>
              <a className="" onClick={clearFilters}>
                Clear
              </a>
            </div>
          </CardBody>
        </Card>
        {/* <Row className="mb-2 align-items-center">
        <Col sm="4">
          <div className="search-box me-2 mb-2 d-inline-block">
            <div className="position-relative font-bold">
              {subjectPagination?.totalCount} Subject Found
            </div>
          </div>
          <div className="search-box me-2 mb-2 d-inline-block">
            <div className="position-relative">
              <i className="bx bx-search-alt search-icon" />
            </div>
          </div>
        </Col>
        <Col sm="8">
          <div className="text-sm-end">
            <Link
              replace
              className="btn btn-success btn-rounded waves-effect waves-light mb-2 me-2"
              to={SMKLinks.WORKSHEET_ADD}>
              <i className="mdi mdi-plus me-1" />
              Add Worksheet
            </Link>
          </div>
        </Col>
      </Row> */}

        <Container fluid>
          <Row>
            <Col lg="12">
              <div className="">
                <div className="table-responsive" style={{ minHeight: 300 }}>
                  <Table className="project-list-table table-nowrap table-centered table-borderless">
                    <thead>
                      <tr className="table-warning">
                        <th scope="col">ID</th>
                        <th scope="col">
                          Name <br /> Slug
                        </th>
                        {/* <th scope="col">Created By</th> */}
                        <th scope="col">Created Date</th>
                        <th scope="col">Language</th>
                        <th scope="col">Active</th>
                        <th scope="col">Deleted</th>
                        <th scope="col" />
                        <th scope="col" />
                      </tr>
                    </thead>
                    <tbody>
                      {!isEmpty(subjects) ? (
                        subjects?.map((data, index) => (
                          <tr key={index}>
                            <td>
                              {/*  <img src={companies[data.img]} alt="" className="avatar-sm" /> */}
                              <div className="text-truncate font-size-14">
                                <Link
                                  to={buildURL(SMKLinks.WORKSHEET_DETAIL, {
                                    id: data.id,
                                  })}>
                                  {data.id}
                                </Link>
                              </div>
                            </td>

                            <td>
                              <Link className="text-primary">
                                {data?.name} <br />
                              </Link>
                              {data?.slug}
                            </td>
                            <td>{printDateTimeTwoLines(data.createdDate)}</td>

                            <td>
                              <Badge className={`p-1 ${data?.language ? 'bg-info' : 'bg-primary'}`}>
                                {LANGUAGE_VALUES[data?.language]}
                              </Badge>
                            </td>

                            <td>
                              <Badge className={data.active ? 'bg-success' : 'bg-danger'}>
                                {data?.active ? 'Yes' : 'No'}
                              </Badge>
                            </td>
                            <td>
                              <Badge className={data.deleted ? 'bg-success' : 'bg-danger'}>
                                {data?.deleted ? 'Yes' : 'No'}
                              </Badge>
                            </td>
                            <td>
                              <a href={`/worksheet/subjecttopic?subjectId=${data.id}`}>
                                Show Topics
                                <i className={'fa fa-chevron-right m-1 pe-3'} />
                              </a>
                            </td>
                            <td>
                              <UncontrolledDropdown>
                                <DropdownToggle href="#" className="card-drop " tag="i">
                                  <i role="button" className="mdi mdi-dots-vertical font-size-18 " />
                                </DropdownToggle>
                                <DropdownMenu className="dropdown-menu-end">
                                  <DropdownItem onClick={() => handleActive(data)}>
                                    <i
                                      className={`fas  ${
                                        data?.active ? 'fa-ban text-danger' : 'fa-power-off text-success'
                                      }   m-1 pe-3`}
                                    />
                                    {data?.active ? 'Disable' : 'Enable'}
                                  </DropdownItem>
                                  {/* <DropdownItem
                                    href="#"
                                    className="text-danger"
                                    onClick={() => handleDeleteClick(data)}>
                                    <i className="fas fa-trash m-1 pe-3 " />
                                    {data?.deleted ? 'Undelete' : 'Delete'}
                                  </DropdownItem> */}
                                  {/* <Divider className="my-2" /> */}

                                  {/* <DropdownItem
                                    href={buildURL(SMKLinks.WORKSHEET_DETAIL, {
                                      id: data.id,
                                    })}>
                                    <i className="fas fa-eye text-primary m-1 pe-3 " />
                                    Details
                                  </DropdownItem> */}
                                  {/* 
                                  <DropdownItem href="#" onClick={() => handleEditClick(data)}>
                                    <i className="fas fa-pencil-alt text-success m-1 pe-3 " />
                                    Edit
                                  </DropdownItem> */}
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <TableError />
                      )}
                    </tbody>
                  </Table>
                </div>
              </div>
            </Col>
          </Row>
          <Paginate pageInfo={subjectPagination} onChangePage={handleChangePage} />
        </Container>
      </div>
    </div>
  );
};

export default Subjects;
