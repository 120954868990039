import Loader from 'components/Loader';
import { getCountries } from 'containers/school/actions';
import { useFormik } from 'formik';
import isEmpty from 'lodash/isEmpty';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toastify';
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap';
import { LANGUAGE_ARRAY } from 'utils/constants';
import * as Yup from 'yup';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import useRequest from '../../../hooks/useRequest';
import { SMKLinks } from '../../../utils/links';

const UpdateBabynameSeoMetaData = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id: babyNameId } = useParams();

  toast.configure();
  const [babyNameDetails, setBabyNameDetails] = useState();
  const [nakshatraList, setNakshatraList] = useState([]);
  const [meaningWordsList, setMeaningWordsList] = useState([]);

  const [addBabyNameRequest, { loading: addBabyNameLoading }] = useRequest({
    url: `/baby-name/admin/seoMetaInfo`,
    method: 'POST',
    onSuccess: (data) => {
      const url = SMKLinks.BABY_NAME_MEANING_ORIGIN;
      history.replace(url);
      toast.success('Success! Baby Name Meaning origin has been added.');
    },
    onError: (err) => {
      toast.error(err?.message);
      console.log(err);
    },
  });
  const [getBabyNameRequest, { loading: getBabyNameLoading }] = useRequest({
    url: `/baby-name/admin/seoMetaInfo/${babyNameId}`,
    method: 'GET',
    onSuccess: (data) => {
      setBabyNameDetails(data);
    },
    onError: (err) => {
      toast.error(err?.message);
      console.log(err);
    },
  });

  const [updateBabyNameRequest, { loading: updateBabyNameLoading }] = useRequest({
    url: `/baby-name/admin/seoMetaInfo/${babyNameId}`,
    method: 'PUT',
    onSuccess: () => {
      const url = SMKLinks.BABY_NAME_SEO_META_DATA;
      history.replace(url);
      toast.success('Success! Baby Name SEO Meta data has been updated.');
    },
    onError: (err) => {
      toast.error(err?.message);
      console.log(err);
    },
  });

  const [getbabyNameMetaRequest, { loading: getbabyNameMetaLoading }] = useRequest({
    url: `/baby-name/admin/metaInfo/search`,
    method: 'POST',
    onSuccess: (data) => {
      const reply = data?.results;
      setNakshatraList(reply.filter((t) => t.type === 1).map((r) => ({ ...r, label: r.name, value: r.id })));
      setMeaningWordsList(reply.filter((t) => t.type === 5).map((r) => ({ ...r, label: r.name, value: r.id })));
    },
  });

  const editInitialValues = () => {
    const filteredLanguageObj = LANGUAGE_ARRAY?.find((e) => e?.value == babyNameDetails?.language);

    return {
      active: babyNameDetails?.active,
      deleted: babyNameDetails?.deleted,
      name: babyNameDetails?.name,
      url: babyNameDetails?.url,
      baby_name: babyNameDetails?.baby_name,
      alpha: babyNameDetails?.alpha,
      god_name: babyNameDetails?.god_name,
      indian_numerology: babyNameDetails?.indian_numerology,
      priority: babyNameDetails?.priority,
      title: babyNameDetails?.title,
      comments: babyNameDetails?.comments,

      page_title: babyNameDetails?.page_title,
      page_schema: babyNameDetails?.page_schema,
      page_introduction: babyNameDetails?.page_introduction,

      meta_title: babyNameDetails?.meta_title,
      meta_description: babyNameDetails?.meta_description,
      meta_type: babyNameDetails?.meta_type,
      meta_keywords: babyNameDetails?.meta_keywords,
      meta_image: babyNameDetails?.meta_image,

      origin_religion: babyNameDetails?.origin_religion,

      gender: babyNameDetails?.gender,
      meaning: babyNameDetails?.meaning,
      nakshatra: babyNameDetails?.nakshatra,
      rashi: babyNameDetails?.rashi,

      language: !isEmpty(filteredLanguageObj) && {
        label: filteredLanguageObj?.label,
        value: filteredLanguageObj?.value,
      },
    };
  };

  const getInitialValues = () => ({
    active: true,
    deleted: false,
    name: '',
    url: '',
    baby_name: '',
    alpha: '',
    god_name: '',
    indian_numerology: '',
    priority: '',
    title: '',
    comments: '',

    page_title: '',
    page_schema: '',
    page_introduction: '',

    meta_title: '',
    meta_description: '',
    meta_type: '',
    meta_keywords: '',
    meta_image: '',

    rashi: '',
    meaning: '',
    origin_religion: '',
    gender: '',
    nakshatra: '',
    language: '',
  });

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: false,
    initialValues: isEmpty(babyNameId) ? { ...getInitialValues() } : { ...editInitialValues() },
    onSubmit: (values) => {
      const data = {
        active: values?.active,
        deleted: values?.deleted,
        name: values?.name,
        url: values?.url,
        baby_name: values?.baby_name,
        alpha: String(values?.alpha),
        god_name: String(values?.god_name),
        indian_numerology: String(values?.indian_numerology),
        priority: String(values?.priority),
        title: values?.title,
        comments: values?.comments,

        page_title: values?.page_title,
        page_schema: values?.page_schema,
        page_introduction: values?.page_introduction,

        meta_title: values?.meta_title,
        meta_description: values?.meta_description,
        meta_type: values?.meta_type,
        meta_keywords: values?.meta_keywords,

        origin_religion: values?.origin_religion,
        meta_image: values?.meta_image,

        rashi: String(values?.rashi),
        meaning: String(values?.meaning),
        nakshatra: String(values?.nakshatra),
        gender: String(values?.gender),
        language: values?.language?.value,
      };

      isEmpty(babyNameId)
        ? addBabyNameRequest({
            body: {
              ...data,
            },
          })
        : updateBabyNameRequest({
            body: {
              ...data,
            },
          });
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required('Please provide name'),
      url: Yup.string().required('Please provide URL'),
      baby_name: Yup.string().required('Please provide baby name'),
      alpha: Yup.string().required('Please provide alpha'),
      rashi: Yup.string().required('Please provide rashi'),
      god_name: Yup.string().required('Please provide god name'),
      indian_numerology: Yup.string().required('Please provide indian numerology'),
      priority: Yup.string().required('Please provide priority'),
      title: Yup.string().required('Please provide title'),
      // comments: Yup.string().required('Please provide comments'),

      page_title: Yup.string().required('Please provide Page title'),
      // page_schema: Yup.string().required('Please provide Page schema'),
      page_introduction: Yup.string().required('Please provide page introduction'),

      meta_title: Yup.string().required('Please provide meta title'),
      meta_description: Yup.string().required('Please provide meta description'),
      // meta_type: Yup.string().required('Please provide meta type'),
      // meta_keywords: Yup.string().required('Please provide meta keywords'),
      meta_image: Yup.string().required('Please provide meta image'),

      origin_religion: Yup.string().required('Please provide origin or religion'),

      language: Yup.object()
        .required('Please provide language')
        .nullable(true),
      meaning: Yup.string().required('Please provide meaning'),
      gender: Yup.string().required('Please provide gender'),
      nakshatra: Yup.string().required('Please provide nakshatra'),
    }),
  });

  useEffect(() => {
    getbabyNameMetaRequest({
      body: {
        page: 1,
        size: 1000,
        sortBy: 'id',
        sortOrder: -1,
      },
    });
    dispatch(getCountries.trigger({ page: 1, size: 500 }));
  }, []);

  useEffect(() => {
    if (!isEmpty(babyNameId)) {
      getBabyNameRequest();
    }
  }, [babyNameId]);

  return (
    <>
      <div className="page-content">
        <Loader
          isActive={addBabyNameLoading || getbabyNameMetaLoading || getBabyNameLoading || updateBabyNameLoading}
        />
        <Container fluid>
          <Breadcrumbs
            title="Babynames"
            breadcrumbItem={`${isEmpty(babyNameId) ? 'Add' : 'Edit'}  Babyname SEO meta data`}
          />

          <Form onSubmit={formik.handleSubmit}>
            <Row>
              <Col md={9}>
                <Card>
                  <CardBody>
                    <h5 className="border-bottom pb-3">Babyname Config</h5>
                    <Row className="mt-4">
                      <Col md={3}>
                        <FormGroup className="mb-4">
                          <Label for="baby_name" className="form-label  ">
                            baby_name
                          </Label>
                          <Input
                            id="baby_name"
                            type="number"
                            className="form-control"
                            placeholder="Baby Name..."
                            label="Baby Name"
                            invalid={!!(formik.touched.baby_name && formik.errors.baby_name)}
                            {...formik.getFieldProps('baby_name')}
                            error={!isEmpty(formik.errors.baby_name)}
                          />
                          <FormFeedback>{formik.errors.baby_name}</FormFeedback>
                        </FormGroup>
                      </Col>
                      <Col md={3}>
                        <FormGroup className="mb-4">
                          <Label for="alpha" className="form-label  ">
                            Alpha
                          </Label>
                          <Input
                            id="alpha"
                            name="alpha"
                            type="number"
                            className="form-control"
                            placeholder="Alphabets..."
                            invalid={!!(formik.touched.alpha && formik.errors.alpha)}
                            {...formik.getFieldProps('alpha')}
                          />
                          <FormFeedback>{formik.errors.alpha}</FormFeedback>
                        </FormGroup>
                      </Col>

                      <Col md={3}>
                        <FormGroup className="mb-4">
                          <Label for="rashi" className="form-label  ">
                            rashi
                          </Label>
                          <Input
                            id="rashi"
                            name="rashi"
                            type="number"
                            className="form-control"
                            placeholder="origin or rashi..."
                            value={formik.values.rashi}
                            invalid={!!(formik.touched.rashi && formik.errors.rashi)}
                            onChange={(e) => formik.setFieldValue('rashi', e.target.value)}
                            // {...formik.getFieldProps('rashi')}
                          />
                          <FormFeedback>{formik.errors.rashi}</FormFeedback>
                        </FormGroup>
                      </Col>
                      <Col md={3}>
                        <FormGroup className="mb-4">
                          <Label for="nakshatra" className="form-label  ">
                            nakshatra
                          </Label>
                          <Input
                            id="nakshatra"
                            name="nakshatra"
                            type="number"
                            className="form-control"
                            placeholder="origin or nakshatra..."
                            invalid={!!(formik.touched.nakshatra && formik.errors.nakshatra)}
                            {...formik.getFieldProps('nakshatra')}
                          />
                          <FormFeedback>{formik.errors.nakshatra}</FormFeedback>
                        </FormGroup>
                      </Col>
                      <Col md={3}>
                        <FormGroup className="mb-4">
                          <Label for="gender" className="form-label  ">
                            gender
                          </Label>
                          <Input
                            id="gender"
                            name="gender"
                            type="text"
                            className="form-control"
                            placeholder="gender..."
                            value={formik?.values?.gender}
                            invalid={!!(formik.touched.gender && formik.errors.gender)}
                            onChange={(e) => formik.setFieldValue('gender', e.target.value)}
                            // {...formik.getFieldProps('gender')}
                          />
                          <FormFeedback>{formik.errors.gender}</FormFeedback>
                        </FormGroup>
                      </Col>
                      <Col md={3}>
                        <FormGroup className="mb-4">
                          <Label for="god_name" className="form-label  ">
                            god_name
                          </Label>
                          <Input
                            id="god_name"
                            name="god_name"
                            type="number"
                            className="form-control"
                            placeholder="god_name..."
                            invalid={!!(formik.touched.god_name && formik.errors.god_name)}
                            {...formik.getFieldProps('god_name')}
                          />
                          <FormFeedback>{formik.errors.god_name}</FormFeedback>
                        </FormGroup>
                      </Col>
                      <Col md={3}>
                        <FormGroup className="mb-4">
                          <Label for="origin_religion" className="form-label  ">
                            Origin / Religion
                          </Label>
                          <Input
                            id="origin_religion"
                            name="origin_religion"
                            type="text"
                            className="form-control"
                            placeholder="origin or religion..."
                            invalid={!!(formik.touched.origin_religion && formik.errors.origin_religion)}
                            {...formik.getFieldProps('origin_religion')}
                          />
                          <FormFeedback>{formik.errors.origin_religion}</FormFeedback>
                        </FormGroup>
                      </Col>
                      <Col md={3}>
                        <FormGroup className="mb-4">
                          <Label for="meaning" className="form-label  ">
                            meaning
                          </Label>
                          <Input
                            id="meaning"
                            name="meaning"
                            type="text"
                            className="form-control"
                            placeholder="origin or meaning..."
                            value={formik.values.meaning}
                            invalid={!!(formik.touched.meaning && formik.errors.meaning)}
                            onChange={(e) => formik.setFieldValue('meaning', e.target.value)}
                          />
                          <FormFeedback>{formik.errors.meaning}</FormFeedback>
                        </FormGroup>
                      </Col>

                      <Col md={3}>
                        <FormGroup className="mb-4">
                          <Label for="indian_numerology" className="form-label  ">
                            indian_numerology
                          </Label>
                          <Input
                            id="indian_numerology"
                            name="indian_numerology"
                            type="number"
                            className="form-control"
                            placeholder="Indian Numerology..."
                            invalid={!!(formik.touched.indian_numerology && formik.errors.indian_numerology)}
                            {...formik.getFieldProps('indian_numerology')}
                          />
                          <FormFeedback>{formik.errors.indian_numerology}</FormFeedback>
                        </FormGroup>
                      </Col>

                      <Col md={3}>
                        <FormGroup className="mb-4">
                          <Label for="priority" className="form-label  ">
                            priority
                          </Label>
                          <Input
                            id="priority"
                            name="priority"
                            type="number"
                            className="form-control"
                            placeholder="Priority..."
                            invalid={!!(formik.touched.priority && formik.errors.priority)}
                            {...formik.getFieldProps('priority')}
                          />
                          <FormFeedback>{formik.errors.priority}</FormFeedback>
                        </FormGroup>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
                <Card>
                  <CardBody>
                    <h5 className="border-bottom pb-3">SEO Meta data Details</h5>
                    <Row className="mt-4">
                      <Col md={6}>
                        <FormGroup className="mb-4">
                          <Label for="name" className="form-label  ">
                            Name
                          </Label>
                          <Input
                            id="name"
                            name="name"
                            type="text"
                            className="form-control"
                            placeholder="Name..."
                            invalid={!!(formik.touched.name && formik.errors.name)}
                            {...formik.getFieldProps('name')}
                          />
                          <FormFeedback>{formik.errors.name}</FormFeedback>
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup className="mb-4">
                          <Label for="title" className="form-label  ">
                            Title
                          </Label>
                          <Input
                            id="title"
                            name="title"
                            type="text"
                            className="form-control"
                            placeholder="Title..."
                            invalid={!!(formik.touched.title && formik.errors.title)}
                            {...formik.getFieldProps('title')}
                          />
                          <FormFeedback>{formik.errors.title}</FormFeedback>
                        </FormGroup>
                      </Col>

                      <Col md={12}>
                        <FormGroup className="mb-4">
                          <Label for="url" className="form-label  ">
                            URL
                          </Label>
                          <Input
                            id="url"
                            name="url"
                            type="text"
                            className="form-control"
                            placeholder="Page Introduction..."
                            invalid={!!(formik.touched.url && formik.errors.url)}
                            {...formik.getFieldProps('url')}
                          />
                          <FormFeedback>{formik.errors.url}</FormFeedback>
                        </FormGroup>
                      </Col>

                      <Col md={12}>
                        <FormGroup className="mb-4">
                          <Label for="comments" className="form-label  ">
                            Comments
                          </Label>
                          <Input
                            id="comments"
                            name="comments"
                            type="textarea"
                            className="form-control"
                            placeholder="Comments..."
                            invalid={!!(formik.touched.comments && formik.errors.comments)}
                            {...formik.getFieldProps('comments')}
                          />
                          <FormFeedback>{formik.errors.comments}</FormFeedback>
                        </FormGroup>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>

                <Card>
                  <CardBody>
                    <h5 className="border-bottom pb-3">Meta data Details</h5>
                    <Row className="mt-4">
                      <Col md={6}>
                        <FormGroup className="mb-4">
                          <Label for="meta_title" className="form-label  ">
                            Meta Title
                          </Label>
                          <Input
                            id="meta_title"
                            name="meta_title"
                            type="text"
                            className="form-control"
                            placeholder="Meta Title..."
                            invalid={!!(formik.touched.meta_title && formik.errors.meta_title)}
                            {...formik.getFieldProps('meta_title')}
                          />
                          <FormFeedback>{formik.errors.meta_title}</FormFeedback>
                        </FormGroup>
                      </Col>

                      <Col md={6}>
                        <FormGroup className="mb-4">
                          <Label for="meta_type" className="form-label  ">
                            Meta Type
                          </Label>
                          <Input
                            id="meta_type"
                            name="meta_type"
                            type="text"
                            className="form-control"
                            placeholder="Meta Type..."
                            invalid={!!(formik.touched.meta_type && formik.errors.meta_type)}
                            {...formik.getFieldProps('meta_type')}
                          />
                          <FormFeedback>{formik.errors.meta_type}</FormFeedback>
                        </FormGroup>
                      </Col>
                      <Col md={12}>
                        <FormGroup className="mb-4">
                          <Label for="meta_image" className="form-label  ">
                            Meta Image URL
                          </Label>
                          <Input
                            id="meta_image"
                            name="meta_image"
                            type="text"
                            className="form-control"
                            placeholder="Meta Image..."
                            invalid={!!(formik.touched.meta_image && formik.errors.meta_image)}
                            {...formik.getFieldProps('meta_image')}
                          />
                          <FormFeedback>{formik.errors.meta_image}</FormFeedback>
                        </FormGroup>
                      </Col>
                      <Col md={12}>
                        <FormGroup className="mb-4">
                          <Label for="meta_keywords" className="form-label  ">
                            Meta Keywords
                          </Label>
                          <Input
                            id="meta_keywords"
                            name="meta_keywords"
                            type="text"
                            className="form-control"
                            placeholder="Meta Keywords..."
                            invalid={!!(formik.touched.meta_keywords && formik.errors.meta_keywords)}
                            {...formik.getFieldProps('meta_keywords')}
                          />
                          <FormFeedback>{formik.errors.meta_keywords}</FormFeedback>
                        </FormGroup>
                      </Col>
                      <Col md={12}>
                        <FormGroup className="mb-4">
                          <Label for="meta_description" className="form-label  ">
                            Meta Description
                          </Label>
                          <Input
                            rows={6}
                            id="meta_description"
                            name="meta_description"
                            type="textarea"
                            className="form-control"
                            placeholder="Meta Description..."
                            invalid={!!(formik.touched.meta_description && formik.errors.meta_description)}
                            {...formik.getFieldProps('meta_description')}
                          />
                          <FormFeedback>{formik.errors.meta_description}</FormFeedback>
                        </FormGroup>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>

                <Card>
                  <CardBody>
                    <h5 className="border-bottom pb-3">Page Details</h5>
                    <Row className="mt-4">
                      <Col md={6}>
                        <FormGroup className="mb-4">
                          <Label for="page_title" className="form-label  ">
                            Page Title
                          </Label>
                          <Input
                            id="page_title"
                            name="page_title"
                            type="text"
                            className="form-control"
                            placeholder="Page Title..."
                            invalid={!!(formik.touched.page_title && formik.errors.page_title)}
                            {...formik.getFieldProps('page_title')}
                          />
                          <FormFeedback>{formik.errors.page_title}</FormFeedback>
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup className="mb-4">
                          <Label for="page_schema" className="form-label  ">
                            Page Schema
                          </Label>
                          <Input
                            id="page_schema"
                            name="page_schema"
                            type="text"
                            className="form-control"
                            placeholder="Page Schema..."
                            invalid={!!(formik.touched.page_schema && formik.errors.page_schema)}
                            {...formik.getFieldProps('page_schema')}
                          />
                          <FormFeedback>{formik.errors.page_schema}</FormFeedback>
                        </FormGroup>
                      </Col>

                      <Col md={12}>
                        <FormGroup className="mb-4">
                          <Label for="page_introduction" className="form-label  ">
                            Page Introduction
                          </Label>
                          <Input
                            rows={6}
                            id="page_introduction"
                            name="page_introduction"
                            type="textarea"
                            className="form-control"
                            placeholder="Page Introduction..."
                            invalid={!!(formik.touched.page_introduction && formik.errors.page_introduction)}
                            {...formik.getFieldProps('page_introduction')}
                          />
                          <FormFeedback>{formik.errors.page_introduction}</FormFeedback>
                        </FormGroup>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col md={3}>
                <Card>
                  <CardBody>
                    <Button type="submit" color="primary" className="w-100 mb-3">
                      Save Name
                    </Button>

                    <Button
                      className="w-100"
                      color="light"
                      onClick={() => {
                        history.replace(SMKLinks.BABY_NAME_SEO_META_DATA);
                      }}>
                      Cancel
                    </Button>
                  </CardBody>
                </Card>
                <Card>
                  <CardBody>
                    <div>
                      <CardTitle className="mb-3">Active Babyname</CardTitle>
                      {[true, false].map((option, i) => (
                        <div key={`active_${i}`} className="form-check mb-3 d-inline-block me-4">
                          <input
                            type="radio"
                            id={`active_${option}`}
                            name="babyname_active"
                            className="form-check-input"
                            checked={formik.values.active === option}
                            onChange={() => formik.setFieldValue('active', option)}
                          />
                          <label className="form-check-label" htmlFor={`active_${option}`}>
                            {option ? 'Yes' : 'No'}
                          </label>
                        </div>
                      ))}
                    </div>
                    <div>
                      <CardTitle className="mb-3">Delete Babyname</CardTitle>
                      {[true, false].map((option, i) => (
                        <div key={`deleted_${i}`} className="form-check mb-3 d-inline-block me-4">
                          <input
                            type="radio"
                            id={`deleted_${option}`}
                            name="babyname_deleted"
                            className="form-check-input"
                            checked={formik.values.deleted === option}
                            onChange={() => formik.setFieldValue('deleted', option)}
                          />
                          <label className="form-check-label" htmlFor={`deleted_${option}`}>
                            {option ? 'Yes' : 'No'}
                          </label>
                        </div>
                      ))}
                    </div>

                    <div>
                      <FormGroup c>
                        <Label for="language" className="form-label">
                          Language
                        </Label>
                        <Select
                          id="language"
                          options={LANGUAGE_ARRAY}
                          value={formik.values.language}
                          onChange={(language) => formik.setFieldValue('language', language)}
                        />
                        {formik.touched.language && (
                          <div className="invalid-feedback d-block">{formik.errors.language}</div>
                        )}
                      </FormGroup>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Form>
        </Container>
      </div>
    </>
  );
};

export default UpdateBabynameSeoMetaData;
