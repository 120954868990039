import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Button,
  Label,
  Input,
  Container,
  Form,
  FormFeedback,
  CardTitle,
} from 'reactstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import isEmpty from 'lodash/isEmpty';
import Select from 'react-select';
import { Link, useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Loader from 'components/Loader';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import useRequest from '../../../hooks/useRequest';
import { SMKLinks } from '../../../utils/links';
import { createStructuredSelector } from 'reselect';
import { useDispatch, useSelector } from 'react-redux';
import { countriesSelector, regionsSelector, citiesSelector, subAreasSelector } from '../selectors';
import { getCities, getCountries, getRegions, getSubAreas } from '../actions';
import { BANNER_TYPE, FEATURED_TYPE } from 'utils/constants';
import moment from 'moment';
import SchoolAutoComplete from 'components/SchoolAutoComplete';
import { titleCaseIfExists } from 'utils/helper';

const stateSelector = createStructuredSelector({
  countries: countriesSelector,
  regions: regionsSelector,
  cities: citiesSelector,
  subAreas: subAreasSelector,
});

const UpdateSchoolFeatured = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id: schoolFeaturedID } = useParams();

  toast.configure();

  const [schoolFeaturedDetails, setschoolFeaturedDetails] = useState();
  const { countries, regions, cities, subAreas } = useSelector(stateSelector);

  const selectTypeOptions = [];

  for (const [key, value] of Object.entries(FEATURED_TYPE)) {
    selectTypeOptions?.push({
      label: titleCaseIfExists(key),
      value: value,
    });
  }

  const [getSchoolFeaturedRequest, { loading: getSchoolFeaturedLoading }] = useRequest({
    url: `/school/admin/featured/${schoolFeaturedID}`,
    method: 'GET',
    onSuccess: (data) => {
      setschoolFeaturedDetails(data);
    },
    onError: (err) => {
      toast.error(err?.message);
      console.log(err);
    },
  });

  const [addSchoolFeaturedRequest, { loading: addSchoolFeaturedLoading }] = useRequest({
    url: `/school/admin/featured`,
    method: 'POST',
    onSuccess: (data) => {
      const url = SMKLinks.SCHOOL_FEATURED;
      history.replace(url);
      toast.success('Success! School Featured has been added.');
    },
    onError: (err) => {
      toast.error(err?.message);
      console.log(err);
    },
  });

  const [updateSchoolFeaturedRequest, { loading: updateSchoolFeaturedLoading }] = useRequest({
    url: `/school/admin/featured/${schoolFeaturedID}`,
    method: 'PUT',
    onSuccess: (data) => {
      const url = SMKLinks.SCHOOL_FEATURED;
      history.replace(url);
      toast.success('Success!School Featured has been updated.');
    },
    onError: (err) => {
      toast.error(err?.message);
      console.log(err);
    },
  });

  const isJSON = (val) => {
    try {
      const parseObj = JSON.parse(val);
      return typeof parseObj === 'object' || typeof parseObj === 'Array' ? true : false;
    } catch (err) {
      toast.error(err);
      return false;
    }
  };

  const convertJSON = (val) => {
    try {
      const parseObj = JSON.parse(val);
      return parseObj;
    } catch (err) {
      toast.error(err);
    }
  };

  const editInitialValues = () => {
    const featuredtypeObj = selectTypeOptions?.filter((e) => e?.value === schoolFeaturedDetails?.featuredType);
    const countryObj = countries?.filter((e) => e?.value === schoolFeaturedDetails?.country?.id);
    const regionObj = regions?.filter((e) => e?.value === schoolFeaturedDetails?.region?.id);
    const cityObj = cities?.filter((e) => e?.value === schoolFeaturedDetails?.city?.id);

    const metaDataJSON = !isEmpty(schoolFeaturedDetails?.metaData)
      ? JSON.stringify(schoolFeaturedDetails?.metaData, null, 2)
      : '';

    return {
      id: schoolFeaturedDetails?.id,
      active: schoolFeaturedDetails?.active,
      deleted: schoolFeaturedDetails?.deleted,
      position: schoolFeaturedDetails?.position,
      expiryDate: moment(schoolFeaturedDetails?.expiryDate),
      school: schoolFeaturedDetails?.school,

      metaData: metaDataJSON,

      featuredType: !isEmpty(featuredtypeObj)
        ? {
            label: featuredtypeObj[0]?.label,
            value: featuredtypeObj[0]?.value,
          }
        : undefined,
      country: !isEmpty(countryObj)
        ? {
            label: countryObj[0]?.label,
            value: countryObj[0]?.value,
          }
        : undefined,
      region: !isEmpty(regionObj)
        ? {
            label: regionObj[0]?.label,
            value: regionObj[0]?.value,
          }
        : undefined,
      city: !isEmpty(cityObj)
        ? {
            label: cityObj[0]?.label,
            value: cityObj[0]?.value,
          }
        : undefined,
    };
  };

  const getInitialValues = () => ({
    active: true,
    deleted: false,
    position: '',
    featuredType: '',
    school: '',
    expiryDate: null,

    country: '',
    region: '',
    city: '',
    metaData: '',
    // subArea: '',
  });

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: false,
    initialValues: isEmpty(schoolFeaturedID) ? getInitialValues() : editInitialValues(),
    onSubmit: (values) => {
      const data = {
        active: values?.active,
        deleted: values?.deleted,
        type: values?.type?.value,
        expiryDate: values?.expiryDate,
        featuredType: values?.featuredType?.value,

        metaData: convertJSON(values?.metaData),

        position: values?.position,
        school: !isEmpty(values?.school)
          ? {
              id: values?.school?.id,
            }
          : undefined,

        country: values?.country?.id
          ? {
              id: values?.country?.id,
            }
          : undefined,
        region: values?.region?.id
          ? {
              id: values?.region?.id,
            }
          : undefined,
        city: values?.city?.id
          ? {
              id: values?.city?.id,
            }
          : undefined,
        subArea: values?.subArea?.id
          ? {
              id: values?.subArea?.id,
            }
          : undefined,
      };

      isEmpty(schoolFeaturedID)
        ? addSchoolFeaturedRequest({
            body: {
              ...data,
            },
          })
        : updateSchoolFeaturedRequest({
            body: {
              ...data,
            },
          });
    },
    validationSchema: Yup.object().shape({
      position: Yup.string().required('Please provide position'),
      featuredType: Yup.object()
        .required('Please provide Featured type')
        .nullable(true),
      metaData: Yup.string().test(
        'metaDataTest',
        'Please enter valid JSON in meta data',
        (val) => isEmpty(val) || isJSON(val)
      ),
      expiryDate: Yup.string()
        .test('expiryDateTest', 'Please provide a expiry date', (val) => !isEmpty(val))
        .nullable(true),
      school: Yup.object()
        .test('schoolTest', 'Please select a school', (val) => !isEmpty(val))
        .nullable(true),
    }),
  });

  useEffect(() => {
    dispatch(getCountries.trigger({ level: 0, page: 1, size: 500 }));
  }, []);

  useEffect(() => {
    if (!isEmpty(schoolFeaturedID)) {
      getSchoolFeaturedRequest();
      dispatch(
        getCities.trigger({
          level: 3,
          page: 1,
          size: 500,
        })
      );
      dispatch(
        getRegions.trigger({
          level: 1,
          page: 1,
          size: 500,
        })
      );
    }
  }, [schoolFeaturedID]);

  const onCountrySelect = (country) => {
    if (!isEmpty(country)) {
      dispatch(
        getRegions.trigger({
          level: 1,
          country: country.name,
          page: 1,
          size: 500,
        })
      );
      formik.setFieldValue('country', country);
      formik.setFieldValue('region', {});
      formik.setFieldValue('city', {});
      // formik.setFieldValue('subArea', {});
    } else {
      formik.setFieldValue('country', '');
    }
  };
  const onRegionSelect = (region) => {
    if (!isEmpty(region)) {
      dispatch(
        getCities.trigger({
          level: 3,
          cityOnly: true,
          region: region.name,
          page: 1,
          size: 500,
        })
      );

      formik.setFieldValue('region', region);
      formik.setFieldValue('city', {});
      // formik.setFieldValue('subArea', {});
    } else {
      formik.setFieldValue('region', '');
    }
  };
  const onCitySelect = (city) => {
    if (!isEmpty(city)) {
      formik.setFieldValue('city', city);
    } else {
      formik.setFieldValue('city', '');
    }
  };

  return (
    <>
      <div className="page-content">
        <Loader isActive={addSchoolFeaturedLoading || getSchoolFeaturedLoading || updateSchoolFeaturedLoading} />
        <Container fluid>
          <Breadcrumbs
            title="Location"
            breadcrumbItem={`${isEmpty(schoolFeaturedID) ? 'Add' : 'Edit'} School Featured`}
          />

          <Form onSubmit={formik.handleSubmit}>
            <Row>
              <Col md={9}>
                <Card>
                  <CardBody>
                    <Row className="align-items-center">
                      {schoolFeaturedID && (
                        <Col md={6}>
                          <FormGroup className="mb-4">
                            <Label className="form-label  ">School Featured ID</Label>
                            <Input
                              id="id"
                              style={{ background: 'whitesmoke' }}
                              disabled
                              className="form-control"
                              {...formik.getFieldProps('id')}
                            />
                          </FormGroup>
                        </Col>
                      )}
                      <Col md={schoolFeaturedID ? 6 : 4}>
                        <FormGroup className="mb-4">
                          <Label for="position" className="form-label  ">
                            Position
                          </Label>
                          <Input
                            id="position"
                            name="position"
                            type="number"
                            className="form-control"
                            placeholder="Position..."
                            invalid={!!(formik.touched.position && formik.errors.position)}
                            {...formik.getFieldProps('position')}
                          />
                          <FormFeedback>{formik.errors.position}</FormFeedback>
                        </FormGroup>
                      </Col>

                      <Col md={schoolFeaturedID ? 6 : 4}>
                        <FormGroup className="mb-4">
                          <Label for="featuredType" className="form-label  ">
                            Featured Type
                          </Label>
                          <Select
                            id="featuredType"
                            isClearable
                            options={selectTypeOptions}
                            value={formik.values.featuredType}
                            onChange={(featuredType) => formik.setFieldValue('featuredType', featuredType)}
                          />
                          {formik.touched.featuredType && (
                            <div className="invalid-feedback d-block">{formik.errors.featuredType}</div>
                          )}
                        </FormGroup>
                      </Col>

                      <Col md={schoolFeaturedID ? 6 : 4}>
                        <FormGroup className="mb-4">
                          <Label for="expiryDate" className="form-label  ">
                            Expiry Date
                          </Label>
                          <Input
                            type="date"
                            id="expiryDate"
                            placeholder="Expiry Date"
                            value={formik?.values?.expiryDate?.format('YYYY-MM-DD')}
                            onChange={(e) => formik.setFieldValue('expiryDate', moment(e.target.value))}
                          />
                          {formik.touched.expiryDate && (
                            <div className="invalid-feedback d-block">{formik.errors.expiryDate}</div>
                          )}
                        </FormGroup>
                      </Col>

                      <Col md={12}>
                        <FormGroup className="mb-4">
                          <Label for="school" className="form-label  ">
                            School
                          </Label>
                          <SchoolAutoComplete
                            isNotClearable={true}
                            selectedSchool={formik.values.school}
                            onSelectSchool={(school) => {
                              formik.setFieldValue('school', school);
                            }}
                          />
                          {formik.touched.school && (
                            <div className="invalid-feedback d-block">{formik.errors.school}</div>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={4}>
                        <FormGroup className="mb-4">
                          <Label for="school_country" className="form-label">
                            Country
                          </Label>
                          <Select
                            isClearable
                            id="school_country"
                            options={countries}
                            value={formik?.values?.country}
                            onChange={onCountrySelect}
                          />
                        </FormGroup>
                      </Col>

                      <Col md={4}>
                        <FormGroup className="mb-4">
                          <Label for="school_state" className="form-label  ">
                            Region/State
                          </Label>

                          <Select
                            isClearable
                            id="school_state"
                            placeholder="Select..."
                            value={formik?.values?.region}
                            onChange={onRegionSelect}
                            options={regions}
                          />
                        </FormGroup>
                      </Col>

                      <Col md={4}>
                        <FormGroup className="mb-4">
                          <Label for="school_city" className="form-label  ">
                            City
                          </Label>
                          <Select
                            isClearable
                            id="school_city"
                            placeholder="Select..."
                            value={formik?.values?.city}
                            onChange={onCitySelect}
                            options={cities}
                          />
                        </FormGroup>
                      </Col>
                      {/* <Col md={3}>
                                        <FormGroup className="mb-4">
                                            <Label for="school_area" className="form-label  ">
                                                SubArea
                                            </Label>
                                            <Select
                                                id="school_area"
                                                placeholder="Select..."
                                                value={formik.values.subArea}
                                                onChange={onSubAreaSelect}
                                                options={subAreas}
                                            />
                                            {formik.touched.subArea && (
                                                <div className="invalid-feedback d-block">{formik.errors.subArea}</div>
                                            )}
                                        </FormGroup>
                                    </Col>
                                    */}

                      <Col md={12}>
                        <FormGroup className="mb-4">
                          <Label for="metaData" className="form-label  ">
                            Meta Data
                          </Label>
                          <Input
                            id="metaData"
                            name="metaData"
                            style={{ height: '150px' }}
                            type="textarea"
                            cols={50}
                            rows={5}
                            className="form-control"
                            placeholder="Enter Meta Data JSON..."
                            invalid={!!(formik.touched.metaData && formik.errors.metaData)}
                            {...formik.getFieldProps('metaData')}
                          />
                          <FormFeedback className="d-block">{formik.errors.metaData} </FormFeedback>
                        </FormGroup>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col md={3}>
                <Card>
                  <CardBody>
                    <div>
                      <Button type="submit" color="primary" className="mb-3 w-100">
                        {schoolFeaturedID ? 'Save' : 'Add'}
                      </Button>

                      <Button
                        className="w-100"
                        color="light"
                        onClick={() => {
                          history.replace(SMKLinks.SCHOOL_FEATURED);
                        }}>
                        Cancel
                      </Button>
                    </div>
                  </CardBody>
                </Card>

                <Card>
                  <CardBody>
                    <Row>
                      <Col md={12}>
                        <CardTitle className="mb-3">Active </CardTitle>
                        {[true, false].map((option, i) => (
                          <div key={`active_${i}`} className="form-check mb-3 d-inline-block me-4">
                            <input
                              type="radio"
                              id={`active_${option}`}
                              name="active"
                              className="form-check-input"
                              checked={formik.values.active === option}
                              onChange={() => formik.setFieldValue('active', option)}
                            />
                            <label className="form-check-label" htmlFor={`active_${option}`}>
                              {option ? 'Yes' : 'No'}
                            </label>
                          </div>
                        ))}
                      </Col>

                      <Col md={12}>
                        <CardTitle className="mb-3">Delete </CardTitle>
                        {[true, false].map((option, i) => (
                          <div key={`deleted_${i}`} className="form-check mb-3 d-inline-block me-4">
                            <input
                              type="radio"
                              id={`deleted_${option}`}
                              name="deleted"
                              className="form-check-input"
                              checked={formik.values.deleted === option}
                              onChange={() => formik.setFieldValue('deleted', option)}
                            />
                            <label className="form-check-label" htmlFor={`deleted_${option}`}>
                              {option ? 'Yes' : 'No'}
                            </label>
                          </div>
                        ))}
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Form>
        </Container>
      </div>
    </>
  );
};

export default UpdateSchoolFeatured;
