import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

import { Badge, Card, CardBody, Col, Container, FormGroup, Input, Label, Row, Table } from 'reactstrap';
// actions
import * as moment from 'moment';
import Select from 'react-select';
// helpers
import Loader from 'components/Loader';
import TableError from 'components/TableError';
import { toast } from 'react-toastify';
import { ACTIVE_AND_DELETED_ARRAY, SORT_VALUES, WORKSHEET_TYPE } from 'utils/constants';
import { buildURL, checkIfValueExist } from '../../../utils/helper';
// links
import { SMKLinks } from '../../../utils/links';
// components
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import Paginate from '../../../components/Paginate';
import useRequest from '../../../hooks/useRequest';
import { useRouter } from '../../../hooks/useRouter';
import { clearCDNCacheApiCall } from '../helper';

const WorksheetsListBasic = () => {
  const router = useRouter();
  const history = useHistory();
  toast.configure();

  const [worksheets, setWorksheets] = useState([]);
  const [worksheetPagination, setWorksheetPagination] = useState({});
  // const [selectedWorksheet, setSelectedWorksheet] = useState(null);

  const defaultFilters = {
    active: { value: null, label: 'All' },
    deleted: { value: null, label: 'All' },
    type: { value: null, label: 'All' },
    startDate: moment().subtract(5, 'years'),
    endDate: moment(),
    id: '',
  };

  const [filters, setFilters] = useState(defaultFilters);

  const setPageInUrl = (page) => {
    history.push({
      pathname: router.pathname,
      search: `?${new URLSearchParams({ page }).toString()}`,
    });
  };

  const [getWorksheetListRequest, { loading: getWorksheetListLoading }] = useRequest({
    url: `/worksheet/admin/search`,
    method: 'POST',
    onSuccess: (data) => {
      setWorksheetPagination(data?.pagination);
      setWorksheets(data?.results);
    },
  });

  const handleChangePage = (page) => {
    setPageInUrl(page);

    getWorksheetListRequest({
      body: {
        page,
        size: 50,
        ...filters,
        type: checkIfValueExist(filters.type?.value),
        active: checkIfValueExist(filters.active?.value),
        deleted: checkIfValueExist(filters.deleted?.value),
        ...SORT_VALUES,
      },
    });
  };

  const [markActiveInActiveRequest, { loading: markActiveInActiveLoading }] = useRequest({
    method: 'PUT',
    onSuccess: (data) => {
      toast.success(`Success! Worksheet has been ${data?.active ? 'active' : 'Inactive'}.`);
      handleChangePage(parseInt(router?.query?.page, 10) || 1);
    },
  });
  const [deleteWorksheetRequest, { loading: deleteWorksheetLoading }] = useRequest({
    url: `/worksheet/admin/delete`,
    method: 'PUT',
    onSuccess: (data) => {
      toast.success('Success! Worksheet has been deleted.');
      handleChangePage(parseInt(router?.query?.page, 10) || 1);
    },
    onError: (err) => {
      toast.error(err?.message);
    },
  });

  const applyFilters = () => {
    handleChangePage(1);
  };

  const clearFilters = () => {
    setPageInUrl(1);

    setFilters(defaultFilters);
    getWorksheetListRequest({
      body: {
        page: 1,
        size: 50,
        ...defaultFilters,
        type: checkIfValueExist(defaultFilters.type?.value),
        active: checkIfValueExist(defaultFilters.active?.value),
        deleted: checkIfValueExist(defaultFilters.deleted?.value),
        worksheetId: defaultFilters.worksheetId,
        ...SORT_VALUES,
      },
    });
  };

  useEffect(() => {
    handleChangePage(parseInt(router?.query?.page, 10) || 1);
  }, []);

  const handleEditClick = (worksheet) => {
    const url = buildURL(SMKLinks.WORKSHEET_EDIT, { id: worksheet.id });
    history.replace(url);
  };

  const handleActive = (worksheetObj) => {
    clearCDNCacheApiCall(worksheetObj?.slug);
    markActiveInActiveRequest({
      url: `/worksheet/admin/approve`,
      body: {
        active: !worksheetObj?.active,
        id: worksheetObj.id,
      },
    });
  };
  const handleDeleteClick = (worksheetObj) => {
    clearCDNCacheApiCall(worksheetObj?.slug);
    deleteWorksheetRequest({
      body: {
        deleted: !worksheetObj?.deleted,
        id: worksheetObj.id,
      },
    });
  };

  const [duplicateWorksheetRequest, { loading: duplicateWorksheetLoading }] = useRequest({
    method: 'POST',
    onSuccess: (data) => {
      toast.success('Success! Worksheet has been duplicated.');
      handleChangePage(parseInt(router?.query?.page, 10) || 1);
    },
    onError: (err) => {
      toast.error(err?.message);
    },
  });

  const handleDuplicateClick = (worksheetObj) => {
    duplicateWorksheetRequest({
      url: `/worksheet/admin/${worksheetObj.id}/duplicate`,
    });
  };

  return (
    <>
      <div className="page-content">
        <Loader
          isActive={
            getWorksheetListLoading || deleteWorksheetLoading || markActiveInActiveLoading || duplicateWorksheetLoading
          }
        />

        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Worksheets" breadcrumbItem="Worksheets Table List" />
          <Card>
            <CardBody>
              <div className="mt-4">
                <div className="row">
                  <div className="col-lg-2">
                    <label className="form-label">Active</label>
                    <Select
                      options={ACTIVE_AND_DELETED_ARRAY}
                      value={filters.active}
                      onChange={(value) => {
                        setFilters({ ...filters, active: value });
                      }}
                    />
                  </div>
                  <div className="col-lg-2">
                    <label className="form-label">Deleted</label>
                    <Select
                      options={ACTIVE_AND_DELETED_ARRAY}
                      value={filters.deleted}
                      onChange={(value) => setFilters({ ...filters, deleted: value })}
                    />
                  </div>
                  <div className="col-lg-2">
                    <FormGroup>
                      <Label htmlFor="type">Select Type</Label>
                      <Select
                        className="select2-dropdown"
                        value={filters.type}
                        onChange={(value) => setFilters({ ...filters, type: value })}
                        options={[
                          { value: null, label: 'All' },
                          ...Object.keys(WORKSHEET_TYPE).map((e) => ({
                            value: WORKSHEET_TYPE[e],
                            label: e,
                          })),
                        ]}
                      />
                    </FormGroup>
                  </div>
                  <div className="col-lg-2">
                    <label className="form-label">Created Date - From</label>
                    <Input
                      type="date"
                      name="startDate"
                      placeholder="From Date"
                      value={filters?.startDate?.format('YYYY-MM-DD')}
                      max={moment().format('YYYY-MM-DD')}
                      onChange={(e) =>
                        setFilters({
                          ...filters,
                          startDate: moment(e.target.value),
                        })
                      }
                    />
                  </div>
                  <div className="col-lg-2">
                    <label className="form-label">Created Date - To</label>
                    <Input
                      type="date"
                      name="endDate"
                      placeholder="To Date"
                      value={filters?.endDate?.format('YYYY-MM-DD')}
                      max={moment().format('YYYY-MM-DD')}
                      onChange={(e) =>
                        setFilters({
                          ...filters,
                          endDate: moment(e.target.value),
                        })
                      }
                    />
                  </div>

                  <div className="col-lg-2">
                    <label className="form-label">Worksheet Id</label>
                    <Input
                      type="number"
                      name="worksheetId"
                      placeholder="Worksheet Id"
                      value={filters?.id}
                      onChange={(e) => setFilters({ ...filters, id: Number(e.target.value) })}
                    />
                  </div>
                </div>
              </div>

              <div className="mt-4">
                <a className="btn btn-primary me-4" onClick={applyFilters}>
                  Apply Filters
                </a>
                <a className="" onClick={clearFilters}>
                  Clear
                </a>
              </div>
            </CardBody>
          </Card>
          <Row className="mb-2 align-items-center">
            <Col sm="4">
              <div className="search-box me-2 mb-2 d-inline-block">
                <div className="position-relative font-bold">{worksheetPagination?.totalCount} Worksheets Found</div>
              </div>
              {/* <div className="search-box me-2 mb-2 d-inline-block">
                <div className="position-relative">
                  <i className="bx bx-search-alt search-icon" />
                </div>
              </div> */}
            </Col>
            <Col sm="8">
              <div className="text-sm-end">
                <Link
                  replace
                  className="btn btn-success btn-rounded waves-effect waves-light mb-2 me-2"
                  to={SMKLinks.WORKSHEET_ADD}>
                  <i className="mdi mdi-plus me-1" />
                  Add Worksheet
                </Link>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg="12">
              <div className="">
                <div className="table-responsive" style={{ minHeight: 300 }}>
                  <Table className="project-list-table table-nowrap table-centered table-borderless">
                    <thead>
                      <tr className="table-warning">
                        <th scope="col">ID</th>
                        <th scope="col">Title</th>
                        <th scope="col">Slug</th>
                        <th scope="col">Type</th>
                        <th scope="col">generatorID</th>
                        {/* <th scope="col">PrimarySubject</th> */}
                        <th scope="col">Subject - ID</th>
                        {/* <th scope="col">Subject - Name</th> */}
                        <th scope="col">Subject - Slug</th>
                        {/* <th scope="col">PrimaryTopic</th> */}
                        <th scope="col">Topic - ID</th>
                        {/* <th scope="col">Topic - Name</th> */}
                        <th scope="col">Topic - Slug</th>
                        <th scope="col">Active</th>
                        <th scope="col">Deleted</th>
                        <th scope="col" />
                      </tr>
                    </thead>
                    <tbody>
                      {!isEmpty(worksheets) ? (
                        worksheets?.map((data, index) => (
                          <tr key={index}>
                            <td>
                              {/*  <img src={companies[data.img]} alt="" className="avatar-sm" /> */}
                              <div className="text-truncate font-size-14">
                                <Link
                                  to={buildURL(SMKLinks.WORKSHEET_DETAIL, {
                                    id: data.id,
                                  })}>
                                  {data.id}
                                </Link>
                              </div>
                            </td>

                            <td>
                              <h5 className="text-truncate font-size-14">
                                <Link
                                  to={buildURL(SMKLinks.WORKSHEET_EDIT, {
                                    id: data.id,
                                  })}
                                  className="text-primary">
                                  {data.title}
                                </Link>
                              </h5>
                              {/* <div
                                dangerouslySetInnerHTML={{
                                  __html: data.description,
                                }}
                              /> */}
                            </td>
                            <td>{data.slug}</td>
                            <td>{data.type}</td>
                            <td>{data?.generatorId}</td>

                            {data.subjects
                              ?.filter((e) => e?.id === data?.primarySubjectId)
                              ?.map((fs, i) => {
                                return (
                                  <>
                                    <td>
                                      {data?.primarySubjectId} = {fs.id}
                                    </td>
                                    {/* <td>{fs.name}</td> */}
                                    <td>{fs.slug}</td>
                                  </>
                                );
                              })}

                            {data.topics
                              ?.filter((e) => e?.id === data?.primaryTopicId)
                              ?.map((fs, i) => {
                                return (
                                  <>
                                    <td>
                                      {data?.primaryTopicId} = {fs.id}
                                    </td>
                                    {/* <td>{fs.name}</td> */}
                                    <td>{fs.slug}</td>
                                  </>
                                );
                              })}

                            <td>
                              <Badge className={data.active ? 'bg-success' : 'bg-danger'}>
                                {data?.active ? 'Yes' : 'No'}
                              </Badge>
                            </td>
                            <td>
                              <Badge className={data.deleted ? 'bg-success' : 'bg-danger'}>
                                {data?.deleted ? 'Yes' : 'No'}
                              </Badge>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <TableError />
                      )}
                    </tbody>
                  </Table>
                </div>
              </div>
            </Col>
          </Row>
          <Paginate pageInfo={worksheetPagination} onChangePage={handleChangePage} />
        </Container>
      </div>
    </>
  );
};

export default WorksheetsListBasic;
