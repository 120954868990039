import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import {
  Badge,
  Card,
  CardBody,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Row,
  Table,
  UncontrolledDropdown,
} from 'reactstrap';
import {
  ACTIVE_AND_DELETED_ARRAY,
  BABY_NAMES_GENDER_ARRAY,
  LANGUAGE_VALUES,
  SORT_VALUES,
} from '../../../utils/constants';

// Import Breadcrumb
import Breadcrumbs from 'components/Common/Breadcrumb';
import Select from 'react-select';

import { Divider } from '@material-ui/core';
import TableError from 'components/TableError';
import { isEmpty } from 'lodash';
import { toast } from 'react-toastify';
import { buildURL, checkIfValueExist } from 'utils/helper';
import { SMKLinks } from 'utils/links';
import Loader from '../../../components/Loader';
import Paginate from '../../../components/Paginate';
import useRequest from '../../../hooks/useRequest';
import { useRouter } from '../../../hooks/useRouter';

const BabynameSeoMetaDataList = (props) => {
  const router = useRouter();
  const history = useHistory();
  toast.configure();

  const [babyNameSeoMetaDataList, setbabyNameSeoMetaDataList] = useState([]);
  const [babyNameSeoMetaDataPagination, setbabyNameSeoMetaDataPagination] = useState({});

  const [nakshatraList, setNakshatraList] = useState([]);

  const defaultFilters = {
    active: { value: null, label: 'All' },
    deleted: { value: null, label: 'All' },
    baby_name: undefined,
    gender: undefined,
    nakshatra: undefined,
    indian_numerology: undefined,
    alpha: undefined,
    meaning: undefined,
  };

  const [filters, setFilters] = useState(defaultFilters);

  const [getbabyNameSeoMetaRequest, { loading: getbabyNameSeoMetaLoading }] = useRequest({
    url: `/baby-name/admin/seoMetaInfo/search`,
    method: 'POST',
    onSuccess: (data) => {
      setbabyNameSeoMetaDataList(data?.results);
      setbabyNameSeoMetaDataPagination(data?.paginator);
    },
  });
  const [updateBabyNameRequest, { loading: updateBabyNameLoading }] = useRequest({
    method: 'PUT',
    onSuccess: (data) => {
      handleChangePage(1);
      toast.success('Success! Baby Name Pronunciation has been updated.');
    },
    onError: (err) => {
      toast.error(err?.message);
      console.log(err);
    },
  });

  const setPageInUrl = (page) => {
    history.push({
      pathname: router.pathname,
      search: `?${new URLSearchParams({ page }).toString()}`,
    });
  };

  const handleChangePage = (page) => {
    setPageInUrl(page);
    const data = {
      page,
      size: 20,
      ...filters,
      active: checkIfValueExist(filters.active?.value),
      deleted: checkIfValueExist(filters.deleted?.value),
      gender: filters?.gender?.value,
      nakshatra: filters?.nakshatra?.value,
      ...SORT_VALUES,
    };
    if (filters.baby_name == '') {
      data.baby_name = undefined;
    }
    if (filters.meaning == '') {
      data.meaning = undefined;
    }
    if (filters.alpha == '') {
      data.alpha = undefined;
    }
    if (filters.indian_numerology == '') {
      data.indian_numerology = undefined;
    }
    if (filters.gender == '') {
      data.gender = undefined;
    }
    if (filters.nakshatra == '') {
      data.religion = undefined;
    }

    getbabyNameSeoMetaRequest({
      body: {
        ...data,
      },
    });
  };

  const applyFilters = () => {
    handleChangePage(1);
  };

  const clearFilters = () => {
    setPageInUrl(1);

    setFilters({ ...defaultFilters, baby_name: '', meaning: '', alpha: '', indian_numerology: '' });
    getbabyNameSeoMetaRequest({
      body: {
        page: 1,
        size: 50,
        ...defaultFilters,
        active: checkIfValueExist(defaultFilters.active?.value),
        deleted: checkIfValueExist(defaultFilters.deleted?.value),
        gender: undefined,
        nakshatra: undefined,
        ...SORT_VALUES,
      },
    });
  };

  const [getbabyNameMetaRequest, { loading: getbabyNameMetaLoading }] = useRequest({
    url: `/baby-name/admin/metaInfo/search`,
    method: 'POST',
    onSuccess: (data) => {
      const reply = data?.results;
      setNakshatraList(reply.filter((t) => t.type === 1).map((r) => ({ ...r, label: r.name, value: r.id })));
    },
  });

  const handleActive = (data) => {
    updateBabyNameRequest({
      url: `/baby-name/admin/seoMetaInfo/${data?.id}`,
      body: {
        active: data?.active ? false : true,
      },
    });
  };
  const handleDelete = (data) => {
    updateBabyNameRequest({
      url: `/baby-name/admin/seoMetaInfo/${data?.id}`,
      body: {
        deleted: data?.deleted ? false : true,
      },
    });
  };
  useEffect(() => {
    getbabyNameMetaRequest({
      body: {
        page: 1,
        size: 1000,
        sortBy: 'id',
        sortOrder: -1,
      },
    });
    handleChangePage(parseInt(router?.query?.page, 10) || 1);
  }, []);

  return (
    <>
      <div className="page-content">
        <Loader isActive={getbabyNameSeoMetaLoading || getbabyNameMetaLoading || updateBabyNameLoading} />
        <Container fluid>
          <Breadcrumbs title="Babynames" breadcrumbItem="Baby Names SEO Meta Data List" />

          <Card>
            <CardBody>
              <div className="mt-4">
                <div className="row">
                  <div className="col-lg-2">
                    <label className="form-label">Active</label>
                    <Select
                      options={ACTIVE_AND_DELETED_ARRAY}
                      value={filters.active}
                      onChange={(value) => {
                        setFilters({ ...filters, active: value });
                      }}
                    />
                  </div>
                  <div className="col-lg-2">
                    <label className="form-label">Deleted</label>
                    <Select
                      options={ACTIVE_AND_DELETED_ARRAY}
                      value={filters.deleted}
                      onChange={(value) => setFilters({ ...filters, deleted: value })}
                    />
                  </div>
                  <div className="col-lg-2">
                    <label className="form-label">Gender</label>
                    <Select
                      options={BABY_NAMES_GENDER_ARRAY}
                      value={filters.gender || ''}
                      onChange={(value) => setFilters({ ...filters, gender: value })}
                    />
                  </div>
                  <div className="col-lg-3">
                    <label className="form-label">Babyname</label>
                    <Input
                      type="text"
                      name="name"
                      placeholder="name..."
                      value={filters.baby_name}
                      onChange={(e) => setFilters({ ...filters, baby_name: e.target.value })}
                    />
                  </div>
                  <div className="col-lg-3">
                    <label className="form-label">Meaning</label>
                    <Input
                      type="text"
                      name="meaning"
                      placeholder="meaning..."
                      value={filters.meaning}
                      onChange={(e) => setFilters({ ...filters, meaning: e.target.value })}
                    />
                  </div>
                  <div className="col-lg-3 mt-4 pt-2">
                    <label className="form-label">Alphabets</label>
                    <Input
                      type="number"
                      name="alphabets"
                      placeholder="alphabets..."
                      value={filters.alpha}
                      onChange={(e) => setFilters({ ...filters, alpha: e.target.value })}
                    />
                  </div>
                  <div className="col-lg-3 mt-4 pt-2">
                    <label className="form-label">Indian Numerology</label>
                    <Input
                      type="number"
                      name="indian_numerology"
                      placeholder="Indian Numerology..."
                      value={filters.indian_numerology}
                      onChange={(e) => setFilters({ ...filters, indian_numerology: e.target.value })}
                    />
                  </div>
                  <div className="col-lg-3 mt-4 pt-2">
                    <label className="form-label">Nakshatra</label>
                    <Select
                      placeholder="Select..."
                      options={nakshatraList}
                      value={filters.nakshatra || ''}
                      onChange={(value) => setFilters({ ...filters, nakshatra: value })}
                      isClearable
                    />
                  </div>
                </div>
              </div>

              <div className="mt-4">
                <a className="btn btn-primary me-4" onClick={applyFilters}>
                  Apply Filters
                </a>
                <a onClick={clearFilters}>Clear</a>
              </div>
            </CardBody>
          </Card>

          <Row className="mb-2 align-items-center">
            <Col sm="4">
              <div className="search-box me-2 mb-2">
                <div className="position-relative font-bold">
                  {babyNameSeoMetaDataPagination?.totalCount} SEO Meta Data Found
                </div>
              </div>
            </Col>
            <Col sm="8">
              <div className="text-sm-end">
                <Link
                  replace
                  className="btn btn-success btn-rounded waves-effect waves-light mb-2 me-2"
                  to={SMKLinks.ADD_BABY_NAME_SEO_META_DATA}>
                  <i className="mdi mdi-plus me-1" />
                  Add SEO Meta Data
                </Link>
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg="12">
              <div className="">
                <div className="table-responsive" style={{ minHeight: 300 }}>
                  <Table className="project-list-table table-centered table-borderless ">
                    <thead>
                      <tr className="table-warning">
                        <th scope="col">#ID</th>
                        <th scope="col">Name</th>
                        <th scope="col">Priority</th> <th scope="col">URL</th>
                        {/* <th scope="col">Meta Title</th> */}
                        {/* <th scope="col">Meta Description</th> */}
                        <th scope="col">Language</th>
                        <th scope="col">Active</th>
                        <th scope="col">Deleted</th>
                        <th scope="col" />
                      </tr>
                    </thead>
                    <tbody>
                      {!isEmpty(babyNameSeoMetaDataList) ? (
                        babyNameSeoMetaDataList?.map((data, index) => (
                          <tr key={index}>
                            <td>{data?.id}</td>
                            <td>
                              <Link
                                to={buildURL(SMKLinks.EDIT_BABY_NAME_SEO_META_DATA, {
                                  id: data?.id,
                                })}>
                                {data?.name}
                              </Link>
                            </td>
                            <td>{data?.priority}</td> <td>{data?.url}</td>
                            {/* <td>{data?.meta_title}</td> */}
                            {/* <td>{data?.meta_description}</td> */}
                            <td>{LANGUAGE_VALUES[data?.language]}</td>
                            <td>
                              <Badge className={data?.active ? 'bg-success' : 'bg-danger'}>
                                {data?.active ? 'Yes' : 'No'}
                              </Badge>
                            </td>
                            <td>
                              <Badge className={data?.deleted ? 'bg-success' : 'bg-danger'}>
                                {data?.deleted ? 'Yes' : 'No'}
                              </Badge>
                            </td>
                            <td>
                              <UncontrolledDropdown>
                                <DropdownToggle href="#" className="card-drop " tag="i">
                                  <i role="button" className="mdi mdi-dots-vertical font-size-18 " />
                                </DropdownToggle>
                                <DropdownMenu className="dropdown-menu-end">
                                  <DropdownItem onClick={() => handleActive(data)}>
                                    <i
                                      className={`fas  ${
                                        data?.active ? 'fa-ban text-danger' : 'fa-power-off text-success'
                                      }   m-1 pe-3`}
                                    />
                                    {data?.active ? 'Disable' : 'Enable'}
                                  </DropdownItem>
                                  <DropdownItem onClick={() => handleDelete(data)}>
                                    <i className={`fas fa-trash-alt text-danger m-1 pe-3`} />
                                    {data?.deleted ? 'Undelete' : 'Delete'}
                                  </DropdownItem>
                                  <Divider className="my-2" />

                                  <Link
                                    to={buildURL(SMKLinks.EDIT_BABY_NAME_SEO_META_DATA, {
                                      id: data?.id,
                                    })}>
                                    <DropdownItem>
                                      <i className="fas fa-pencil-alt text-success m-1 pe-3 " />
                                      Edit
                                    </DropdownItem>
                                  </Link>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <TableError />
                      )}
                    </tbody>
                  </Table>
                </div>
              </div>
            </Col>
          </Row>
          <Paginate pageInfo={babyNameSeoMetaDataPagination} onChangePage={handleChangePage} />
        </Container>
      </div>
    </>
  );
};

export default BabynameSeoMetaDataList;
