import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';

// //Import Scrollbar
import SimpleBar from 'simplebar-react';

// MetisMenu
import MetisMenu from 'metismenujs';
import { withTranslation } from 'react-i18next';
import { Link, withRouter } from 'react-router-dom';

// i18n

// links
import { isLoggedInSelector, userSelector } from 'common/globalComponents/selectors';
import { useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { taxonomyType } from 'utils/constants';
import { SMKLinks } from '../../utils/links';

const stateSelector = createStructuredSelector({
  isLoggedIn: isLoggedInSelector,
  user: userSelector,
});

const SchoolMyKidsMenuBar = (props) => {
  const { location, t } = props;
  const ref = useRef();
  const { user, isLoggedIn } = useSelector(stateSelector);

  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    const pathName = location.pathname;

    const initMenu = () => {
      new MetisMenu('#side-menu');
      let matchingMenuItem = null;
      const ul = document.getElementById('side-menu');
      const items = ul.getElementsByTagName('a');
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i];
          break;
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem);
      }
    };
    initMenu();
  }, [location.pathname]);

  useEffect(() => {
    ref.current.recalculate();
  });

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300;
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add('active');
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];
    if (parent2El && parent2El.id !== 'side-menu') {
      parent2El.classList.add('mm-show');
    }

    if (parent) {
      parent.classList.add('mm-active');
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add('mm-show'); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add('mm-active'); // li
          parent3.childNodes[0].classList.add('mm-active'); // a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add('mm-show'); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add('mm-show'); // li
              parent5.childNodes[0].classList.add('mm-active'); // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  }

  return (
    <>
      <SimpleBar style={{ maxHeight: '100%' }} ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            {/* <li className="menu-title">{t('SchoolMyKids')}</li> */}

            <li>
              <Link replace to="/dashboard" className="waves-effect">
                <i className="bx bx-home-circle" />
                <span>{t('Dashboard')}</span>
              </Link>
            </li>

            {user?.entityPermissions?.masterdata?.read && (
              <li>
                <Link replace to={SMKLinks?.SEND_NOTIFICATINS} className="waves-effect">
                  <i className="bx bx-bell" />
                  <span>{t('Send Notifications')}</span>
                </Link>
              </li>
            )}

            {user?.entityPermissions?.contact_inquiry?.read && (
              <li>
                <Link replace to={SMKLinks.CONTACT_INQUIRY_LISTING}>
                  <i className="bx bxs-contact" />
                  <span>{t('Contact Inquiry')}</span>
                </Link>
                {/* <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link replace to={SMKLinks.CONTACT_INQUIRY_LISTING}>
                      {t('Contact Inquiry')}
                    </Link>
                  </li>
                </ul> */}
              </li>
            )}

            {user.entityPermissions?.user?.read && (
              <li>
                <Link replace to="/#" className="has-arrow waves-effect">
                  <i className="bx bxs-user-detail" />
                  <span>{t('Users')}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link replace to={SMKLinks.SMK_USERS_LISTING}>
                      {t('Users List')}
                    </Link>
                  </li>
                  <li>
                    <Link replace to={SMKLinks.SMK_ADD_USER}>
                      {t('Add User')}
                    </Link>
                  </li>

                  <li>
                    <Link replace to={SMKLinks.USER_SUBSCRIPTIONS_LISTING}>
                      {t('User Subscriptions List')}
                    </Link>
                  </li>
                  <li>
                    <Link replace to="/roles/list">
                      {t('Roles List')}
                    </Link>
                  </li>
                  <li>
                    <Link replace to="/roles/add">
                      {t('Add Role')}
                    </Link>
                  </li>
                </ul>
              </li>
            )}

            {user?.entityPermissions?.masterdata?.read && (
              <li>
                <Link replace to="/#" className="has-arrow waves-effect">
                  <i className="bx bx-dollar-circle" />
                  <span>{t('Subscription Plans')}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link replace to={SMKLinks.SUBSCRIPTION_PLANS}>
                      {t('Subscription List')}
                    </Link>
                  </li>
                  <li>
                    <Link replace to={SMKLinks.SUBSCRIPTION_OFFERS}>
                      {t('Subscription Offers')}
                    </Link>
                  </li>
                  <li>
                    <Link replace to={SMKLinks.ADD_SUBSCRIPTION_PLAN}>
                      {t('Add Subscription')}
                    </Link>
                  </li>
                </ul>
              </li>
            )}

            {user.entityPermissions?.masterdata?.read && (
              <li>
                <Link replace to="/#" className="has-arrow waves-effect">
                  <i className="bx bxs-book-content" />
                  <span>{t('CMS')}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link replace to={SMKLinks.FAQ_LIST}>
                      {t('FAQ List')}
                    </Link>
                  </li>

                  <li>
                    <Link replace to={SMKLinks.STATIC_PAGES_LIST}>
                      {t('Pages List')}
                    </Link>
                  </li>
                  <li>
                    <Link replace to={SMKLinks.STATIC_CONTENT_LIST}>
                      {t('Static Content list')}
                    </Link>
                  </li>
                </ul>
              </li>
            )}

            <hr className="border border-dark" />
            {user.entityPermissions?.school?.read && (
              <li>
                <Link replace to="/#" className="has-arrow waves-effect">
                  <i className="bx bxs-building-house" />
                  <span>{t('Schools')}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link replace to={SMKLinks.SCHOOL_LISTING}>
                      {t('Schools List')}
                    </Link>
                  </li>
                  <li>
                    <Link replace to={SMKLinks.ADMISSION_INQUIRY}>
                      {t('Admission Inquiries')}
                    </Link>
                  </li>
                  <li>
                    <Link replace to={SMKLinks.SCHOOL_REVIEWS}>
                      {t('Schools Reviews')}
                    </Link>
                  </li>
                  <li>
                    <Link replace to={SMKLinks.SCHOOL_BOARDS}>
                      {t('Schools Boards')}
                    </Link>
                  </li>
                  <li>
                    <Link replace to={SMKLinks.SCHOOL_GROUPS}>
                      {t('Schools Groups')}
                    </Link>
                  </li>
                  <li>
                    <Link replace to={SMKLinks.SCHOOL_META_CONTENT}>
                      {t('Schools Meta Data')}
                    </Link>
                  </li>
                  <li>
                    <Link replace to={SMKLinks.SCHOOL_FEATURED}>
                      {t('Schools Featured')}
                    </Link>
                  </li>
                  <li>
                    <Link replace to={SMKLinks.ADD_SCHOOL}>
                      {t('Add School')}
                    </Link>
                  </li>
                </ul>
              </li>
            )}
            {user.entityPermissions?.college?.read && (
              <li>
                <Link replace to="/#" className="has-arrow waves-effect">
                  <i className="bx bxs-buildings" />
                  <span>{t('Colleges')}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link replace to={SMKLinks.COLLEGE_LISTING}>
                      {t('Colleges List')}
                    </Link>
                  </li>
                  <li>
                    <Link replace to={SMKLinks.COLLEGE_ADMISSION_INQUIRY}>
                      {t('Admission Inquiries')}
                    </Link>
                  </li>
                  <li>
                    <Link replace to={SMKLinks.COLLEGE_FEATURED}>
                      {t('Colleges Featured')}
                    </Link>
                  </li>
                  <li>
                    <Link replace to={SMKLinks.COLLEGE_REVIEWS}>
                      {t('College Reviews')}
                    </Link>
                  </li>
                  {/* <li>
                        <Link replace to={SMKLinks.COLLEGE_META_CONTENT}>
                          {t('Colleges Meta Data')}
                        </Link>
                      </li> */}
                  <li>
                    <Link replace to={SMKLinks.COLLEGE_GROUPS}>
                      {t('Colleges Groups')}
                    </Link>
                  </li>
                  <li>
                    <Link replace to={SMKLinks.COLLEGE_COURSES}>
                      {t('Courses List')}
                    </Link>
                  </li>
                  <li>
                    <Link replace to={SMKLinks.ADD_COLLEGE}>
                      {t('Add College')}
                    </Link>
                  </li>
                </ul>
              </li>
            )}
            {user.entityPermissions?.worksheet?.read && (
              <li>
                <Link replace to="/#" className="has-arrow waves-effect">
                  <i className="bx bx-receipt" />
                  <span>{t('Worksheets')}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link replace to={SMKLinks.WORKSHEET_LISTING}>
                      {t('Worksheets List')}
                    </Link>
                  </li>
                  <li>
                    <Link replace to={SMKLinks.WORKSHEET_ADD}>
                      {t('Add Worksheet')}
                    </Link>
                  </li>
                  <li>
                    <Link replace to={SMKLinks.WORKSHEET_SUBJECT_TOPIC}>
                      {t('Subject Topic')}
                    </Link>
                  </li>
                  <li>
                    <Link replace to={SMKLinks.WORKSHEET_SUBJECT}>
                      {t('Subjects')}
                    </Link>
                  </li>
                  <li>
                    <Link replace to={SMKLinks.WORKSHEET_LISTING_BASIC}>
                      {t('Worksheets Table')}
                    </Link>
                  </li>
                  {/* <li>
                    <Link replace to="/worksheet/categories">
                      {t('Worksheet Categories')}
                    </Link>
                  </li> */}
                </ul>
              </li>
            )}
            {user.entityPermissions?.user?.admin && (
              <li>
                <Link replace to="/#" className="has-arrow waves-effect">
                  <i className="bx bx-calculator" />
                  <span>{t('Calculators')}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link replace to={SMKLinks.CALCULATOR_LISTING}>
                      {t('Calculators List')}
                    </Link>
                  </li>
                  <li>
                    <Link replace to={SMKLinks.SMK_TAXONOMY_LISTING + `?type=${taxonomyType?.CALCULATOR_CATEGORY}`}>
                      {t('Calculator Categories')}
                    </Link>
                  </li>
                  <li>
                    <Link replace to={SMKLinks.ADD_CALCULATOR}>
                      {t('Add calculator')}
                    </Link>
                  </li>
                </ul>
              </li>
            )}
            {user.entityPermissions?.ptable?.read && (
              <li>
                <Link replace to="/#" className="has-arrow waves-effect">
                  <i className="bx bx-table" />
                  <span>{t('PTable')}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link replace to="/ptable/elements/list">
                      {t('Elements List')}
                    </Link>
                  </li>
                  <li>
                    <Link replace to="/ptable/group/list">
                      {t('Group Masters List')}
                    </Link>
                  </li>
                  <li>
                    <Link replace to="/ptable/property/list">
                      {t('Property Master List')}
                    </Link>
                  </li>
                </ul>
              </li>
            )}

            {user?.entityPermissions?.article?.read && (
              <li>
                <Link replace to="/#" className="has-arrow waves-effect">
                  <i className="bx bxl-blogger" />
                  <span>{t('Blog')}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link replace to={SMKLinks.BLOG_LIST}>
                      {t('Blog List')}
                    </Link>
                  </li>
                  <li>
                    <Link replace to={SMKLinks.BLOG_CATEGORIES_LIST}>
                      {t('Categories List')}
                    </Link>
                  </li>
                  <li>
                    <Link replace to={SMKLinks.BLOG_TAGS_LIST}>
                      {t('Tags List')}
                    </Link>
                  </li>
                  {user?.entityPermissions?.article?.create && (
                    <li>
                      <Link replace to={SMKLinks.ADD_BLOG}>
                        {t('Add Blog')}
                      </Link>
                    </li>
                  )}
                </ul>
              </li>
            )}
            {user.entityPermissions?.user?.admin && (
              <li>
                <Link replace to="/#" className="has-arrow waves-effect">
                  <i className="bx bxs-file" />
                  <span>{t('Forums')}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link replace to={SMKLinks.TOPIC_LIST}>
                      {t('Topic List')}
                    </Link>
                  </li>
                  <li>
                    <Link replace to={SMKLinks.POST_LIST}>
                      {t('Posts List')}
                    </Link>
                  </li>
                  <li>
                    <Link replace to={SMKLinks.CATEGORY_LIST}>
                      {t('Categories List')}
                    </Link>
                  </li>
                </ul>
              </li>
            )}

            <hr className="border border-dark" />

            {user?.entityPermissions?.masterdata?.read && (
              <li>
                <Link replace to="/#" className="has-arrow waves-effect">
                  <i className="bx bxs-map" />
                  <span>{t('Locations')}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link replace to={SMKLinks?.LOCATION_LISTING}>
                      {t('Locations List')}
                    </Link>
                  </li>
                  <li>
                    <Link replace to={SMKLinks?.ADD_LOCATION}>
                      {t('Add Location')}
                    </Link>
                  </li>
                </ul>
              </li>
            )}

            {user?.entityPermissions?.masterdata?.read && (
              <li>
                <Link replace to="/#" className="has-arrow waves-effect">
                  <i className="bx bx-image-alt" />
                  <span>{t('SMK Media')}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link replace to={SMKLinks.SMK_MEDIA_LISTING}>
                      {t('SMK Media List')}
                    </Link>
                  </li>
                  <li>
                    <Link replace to={SMKLinks.ADD_SMK_MEDIA}>
                      {t('Add SMK Media')}
                    </Link>
                  </li>
                </ul>
              </li>
            )}

            {user.entityPermissions?.user?.admin && (
              <li>
                <Link replace to="/#" className="has-arrow waves-effect">
                  <i className="bx bx-message-alt-dots" />
                  <span>{t('Others')}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link replace to={SMKLinks?.CATEGORIES_LIST}>
                      {t('Categories List')}
                    </Link>
                  </li>
                  <li>
                    <Link replace to={SMKLinks?.TAGS_LIST}>
                      {t('Tags List')}
                    </Link>
                  </li>
                  <li>
                    <Link replace to={SMKLinks?.FEATURE_BANNER_LIST}>
                      {t('Feature Banner List')}
                    </Link>
                  </li>
                  <li>
                    <Link replace to={SMKLinks?.API_CACHE_LIST}>
                      {t('API Cache')}
                    </Link>
                  </li>
                  <li>
                    <Link replace to={SMKLinks?.CDN_CACHE_LIST}>
                      {t('CDN Cache')}
                    </Link>
                  </li>
                  <li>
                    <Link replace to={SMKLinks?.SEO_CHECKER}>
                      {t('SEO Checker')}
                    </Link>
                  </li>
                </ul>
              </li>
            )}

            {user?.entityPermissions?.masterdata?.read && (
              <li className="sidebar-menu-item">
                <Link replace to="/#" className="has-arrow waves-effect">
                  <i className="bx bxs-analyse" />
                  <span>{t('Analytics')}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <>
                    <li>
                      <Link replace to={SMKLinks?.PAGEVIEW} className="waves-effect">
                        <i className="bx bx-calendar" />
                        <span>{t('Page views')}</span>
                      </Link>
                    </li>
                    <li>
                      <Link replace to={SMKLinks?.WORKSHEET_DOWNLOAD} className="waves-effect">
                        <i className="bx bx-calendar" />
                        <span>{t('Worksheet download')}</span>
                      </Link>
                    </li>
                  </>
                </ul>
              </li>
            )}

            {user?.entityPermissions?.user?.admin && (
              <li>
                <Link replace to={SMKLinks.GENERATE_SITEMAP}>
                  <i className="bx bx-sitemap" />
                  <span>{t('Sitemap')}</span>
                </Link>
              </li>
            )}
          </ul>
        </div>
      </SimpleBar>
    </>
  );
};

SchoolMyKidsMenuBar.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(SchoolMyKidsMenuBar));
