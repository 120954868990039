import DOCTORS_LINKS from './links';
import { USER_ROLE } from '../../utils/constants';
import DoctorsListing from './DoctorsListing';
import UpdateDoctor from './UpdateDoctor';
import HealthCareFacilityList from './HealthCareFacilityList';
import UpdateHealthCareFacility from './HealthCareFacilityList/updateHealthCareFacility';
import DoctorDetail from './doctorDetails';
import HealthCareFacilityDetails from './HealthCareFacilityDetails';

export default [
  {
    path: DOCTORS_LINKS.DOCTORS_LISTING,
    exact: true,
    component: DoctorsListing,
    role: [USER_ROLE.ADMIN],
  },
  {
    path: DOCTORS_LINKS.ADD_DOCTOR,
    exact: true,
    component: UpdateDoctor,
    role: [USER_ROLE.ADMIN],
  },
  {
    path: DOCTORS_LINKS.EDIT_DOCTOR,
    exact: true,
    component: UpdateDoctor,
    role: [USER_ROLE.ADMIN],
  },
  {
    path: DOCTORS_LINKS.DOCTOR_DETAILS,
    exact: true,
    component: DoctorDetail,
    role: [USER_ROLE.ADMIN],
  },
  {
    path: DOCTORS_LINKS.HEALTH_CARE_LISTING,
    exact: true,
    component: HealthCareFacilityList,
    role: [USER_ROLE.ADMIN],
  },
  {
    path: DOCTORS_LINKS.ADD_HEALTH_CARE,
    exact: true,
    component: UpdateHealthCareFacility,
    role: [USER_ROLE.ADMIN],
  },
  {
    path: DOCTORS_LINKS.EDIT_HEALTH_CARE,
    exact: true,
    component: UpdateHealthCareFacility,
    role: [USER_ROLE.ADMIN],
  },
  {
    path: DOCTORS_LINKS.HEALTH_CARE_DETAILS,
    exact: true,
    component: HealthCareFacilityDetails,
    role: [USER_ROLE.ADMIN],
  },
];
