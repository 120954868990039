import React, { useEffect, useState } from 'react';
import { Card, CardBody, CardTitle } from 'reactstrap';
import ReactApexChart from 'react-apexcharts';
import { printCurrencyTooltip, printNumber } from '../../../utils/helper';
import { parse } from 'query-string';
import { toast } from 'react-toastify';
import useRequest from '../../../hooks/useRequest';

const UsersByApp = ({ startDate, endDate }) => {
  const [users, setUsers] = useState([]);
  const [chartLabels, setChartLabels] = useState([]);
  const [donutChartSeries, setDonutChartSeries] = useState([]);
  const [getUsersPercentageByDeviceRequest, { loading: getUsersPercentageByDeviceLoading }] = useRequest({
    url: '/patient-tracking/admin-tools/app-device-percentage',
    method: 'POST',
    onSuccess: (data) => {
      setUsers(data?.results);
      setChartLabels(data?.results.map((e) => e.system));
      setDonutChartSeries(data?.results.map((e) => e.percentage));
    },
    onError: (err) => {
      toast.error(err?.message?.message || err?.message);
    },
  });

  useEffect(() => {
    getUsersPercentageByDeviceRequest({
      body: {
        startDate,
        endDate,
      },
    });
  }, [startDate, endDate]);

  useEffect(() => {
    setChartLabels(users.map((e) => e.system));
    setDonutChartSeries(users.map((e) => parseFloat(e.percentage)));
  }, [users]);

  let donutChartOptions = {
    chart: {
      type: 'pie',
    },
    legend: {
      position: 'bottom',
    },
    colors: ['#278EFD', '#A5D0FF', '#4673A4'],
    labels: chartLabels,
    dataLabels: {
      style: {
        fontSize: '5px',
      },
      dropShadow: {
        enabled: false,
      },
    },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            value: {
              fontSize: '12px',
              fontWeight: 600,
              offsetY: 0,
            },
          },
        },
      },
    },
    tooltip: {
      y: printCurrencyTooltip(),
    },
    responsive: [
      {
        breakpoint: 1200,
        options: {
          legend: {
            position: 'bottom',
            fontSize: 14,
            horizontalAlign: 'center',
          },
          chart: {
            height: 280,
          },
        },
      },
      {
        breakpoint: 1400,
        options: {
          legend: {
            position: 'top',
            fontSize: 10,
            offsetX: -70,
          },
          chart: {
            height: 200,
          },
        },
      },
      {
        breakpoint: 1600,
        options: {
          legend: {
            position: 'bottom',
            fontSize: 11,
            horizontalAlign: 'center',
            offsetX: -30,
          },
          chart: {
            height: 240,
          },
        },
      },
    ],
  };

  return (
    <>
      <Card>
        <CardBody>
          <CardTitle className="mb-4">% Users By ios/android</CardTitle>
          <div className="d-flex flex-wrap justify-content-between mt-4 total-by-gender">
            {users?.map((e, i) => {
              return (
                <div className="pe-md-1 mb-md-2 mb-3" key={i}>
                  <div className="widget-metric">
                    <h6 className="widget-metric-title">{e.system}</h6>
                    <h3 className="widget-metric-value">{e?.percentage}%</h3>
                  </div>
                </div>
              );
            })}
          </div>
          <ReactApexChart options={donutChartOptions} series={donutChartSeries} type="pie" width={400} />
        </CardBody>
      </Card>
    </>
  );
};

export default UsersByApp;
