import { ConnectedRouter } from 'connected-react-router';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from './App';
import GlobalComponents from './common/globalComponents';
import './i18n';
import * as serviceWorker from './serviceWorker';
import { GoogleOAuthProvider } from '@react-oauth/google';
import store, { history } from './store';

const app = (
  <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <GlobalComponents />
        <App />
      </ConnectedRouter>
    </Provider>
  </GoogleOAuthProvider>
);

ReactDOM.render(app, document.getElementById('root'));
serviceWorker.unregister();
