import TableError from 'components/TableError';
import { isEmpty } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toastify';
import {
  Badge,
  Card,
  CardBody,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Row,
  Table,
  UncontrolledDropdown,
} from 'reactstrap';
import { ACTIVE_AND_DELETED_ARRAY, SORT_VALUES } from 'utils/constants';
import { SMKLinks } from 'utils/links';
import Breadcrumbs from '../../../../components/Common/Breadcrumb';
import Loader from '../../../../components/Loader';
import Paginate from '../../../../components/Paginate';
import useRequest from '../../../../hooks/useRequest';
import { useRouter } from '../../../../hooks/useRouter';
import { buildURL, checkIfValueExist, printSerialNumbers, showImage } from '../../../../utils/helper';

const SubscriptionOffers = () => {
  const router = useRouter();
  const history = useHistory();
  toast.configure();

  const [subscriptionOffersList, setSubscriptionOffersList] = useState([]);
  const [subscriptionOffersPagination, setSubscriptionOffersPagination] = useState({});

  const defaultFilters = {
    active: null,
    deleted: null,
    name: '',
  };

  const [filters, setFilters] = useState(defaultFilters);

  const [getsubscriptionOffersListRequest, { loading: getsubscriptionOffersListLoading }] = useRequest({
    url: '/subscription/offers/admin/subscription-offer/search',
    method: 'POST',
    onSuccess: (data) => {
      setSubscriptionOffersList(data?.results);
      setSubscriptionOffersPagination(data?.paginator);
    },
  });

  const [deleteUnDeleteRequest, { loading: deleteUnDeleteLoading }] = useRequest({
    method: 'DELETE',
    onSuccess: (data) => {
      handleChangePage(parseInt(router?.query?.page, 10) || 1);
      toast.success(`Success! Subscription Offer has been ${data?.deleted ? 'deleted' : 'Undeleted'}.`);
    },
  });

  const setPageInUrl = (page) => {
    history.push({
      pathname: router.pathname,
      search: `?${new URLSearchParams({ page }).toString()}`,
    });
  };

  const handleChangePage = (page) => {
    setPageInUrl(page);

    const payload = {
      page,
      size: 20,
      ...SORT_VALUES,
      ...filters,
      active: checkIfValueExist(filters?.active),
      deleted: checkIfValueExist(filters?.deleted),
      name: checkIfValueExist(filters?.name?.trim()),
    };
    getsubscriptionOffersListRequest({
      body: {
        ...payload,
      },
    });
  };

  const applyFilters = () => {
    handleChangePage(1);
  };

  const clearFilters = () => {
    setPageInUrl(1);

    setFilters({ ...defaultFilters });
    const defaultPayload = {
      page: 1,
      size: 20,
      ...SORT_VALUES,
      ...defaultFilters,
      active: checkIfValueExist(defaultFilters?.active),
      deleted: checkIfValueExist(defaultFilters?.deleted),
      name: checkIfValueExist(defaultFilters?.name?.trim()),
    };
    getsubscriptionOffersListRequest({
      body: {
        ...defaultPayload,
      },
    });
  };

  const handleDeletePlan = (data) => {
    deleteUnDeleteRequest({
      url: `/subscription/offers/admin/subscription-offer/${data?.id}`,
    });
  };

  useEffect(() => {
    handleChangePage(parseInt(router?.query?.page, 10) || 1);
  }, []);

  return (
    <>
      <div className="page-content">
        <Loader isActive={getsubscriptionOffersListLoading || deleteUnDeleteLoading} />

        <Container fluid>
          <Breadcrumbs title="Subscription Offers" breadcrumbItem="Subscription Offers List" />

          <Card>
            <CardBody>
              <div className="mt-4">
                <Row>
                  <div className="col-lg-2 mb-4">
                    <label className="form-label">Active</label>
                    <Select
                      options={ACTIVE_AND_DELETED_ARRAY}
                      value={ACTIVE_AND_DELETED_ARRAY?.find((e) => e?.value === filters?.active) || null}
                      placeholder="Select Status..."
                      onChange={(e) => setFilters({ ...filters, active: e?.value })}
                    />
                  </div>
                  <div className="col-lg-2 mb-4">
                    <label className="form-label">Deleted</label>
                    <Select
                      options={ACTIVE_AND_DELETED_ARRAY}
                      value={ACTIVE_AND_DELETED_ARRAY?.find((e) => e?.value === filters?.deleted) || null}
                      placeholder="Select Status..."
                      onChange={(e) => setFilters({ ...filters, deleted: e?.value })}
                    />
                  </div>
                  <div className="col-lg-3 mb-4">
                    <label className="form-label">Name</label>
                    <Input
                      type="text"
                      placeholder="Subscription Offer Name..."
                      value={filters.name}
                      onChange={(e) => setFilters({ ...filters, name: e?.target?.value })}
                    />
                  </div>
                </Row>
              </div>
              <div className="mt-4 pt-2">
                <a className="btn btn-primary me-4" onClick={applyFilters}>
                  Apply Filters
                </a>
                <a className="" onClick={clearFilters}>
                  Clear
                </a>
              </div>
            </CardBody>
          </Card>

          <Row className="mb-2 align-items-center">
            <Col sm="4">
              <div className="search-box me-2 mb-2 d-inline-block">
                <div className="position-relative font-bold">
                  {subscriptionOffersPagination?.totalCount} Subscription Offers Found
                </div>
              </div>
            </Col>
            <Col sm="8">
              <div className="text-sm-end">
                <Link
                  replace
                  className="btn btn-success btn-rounded waves-effect waves-light mb-2 me-2"
                  to={SMKLinks.ADD_SUBSCRIPTION_OFFER}>
                  <i className="mdi mdi-plus me-1" />
                  Add Subscription Offer
                </Link>
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg="12">
              <div className="">
                <div className="table-responsive" style={{ minHeight: 300 }}>
                  <Table className="project-list-table   table-centered table-borderless">
                    <thead>
                      <tr className="table-warning">
                        <th scope="col" style={{ width: '60px' }}>
                          S.No.
                        </th>
                        <th scope="col"></th>
                        <th scope="col">Name</th>
                        <th scope="col">Code</th>
                        <th scope="col">Discount</th>
                        <th scope="col">Discount Type</th>
                        <th scope="col">Start Date</th>
                        <th scope="col">End Date</th>
                        <th scope="col">Deleted</th>
                        <th scope="col">Active</th>
                        <th scope="col"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {!isEmpty(subscriptionOffersList) ? (
                        subscriptionOffersList?.map((elem, index) => (
                          <tr>
                            <td>{printSerialNumbers(subscriptionOffersPagination)[index]}</td>
                            <td>
                              {!isEmpty(elem?.image) && (
                                <img src={showImage(elem?.image?.original)} width={40} height={40} />
                              )}
                            </td>
                            <td>{elem?.code}</td>
                            <td>
                              <div className="d-flex align-items-center">
                                {!elem?.deleted ? (
                                  <Link
                                    to={buildURL(SMKLinks.EDIT_SUBSCRIPTION_OFFER, {
                                      id: elem?.id,
                                    })}>
                                    {elem?.name}
                                  </Link>
                                ) : (
                                  elem?.name
                                )}
                              </div>
                            </td>
                            <td>{elem?.discount}</td>
                            <td>{elem?.discountType}</td>
                            <td>{moment(elem?.startDate)?.format('DD-MM-YYYY')}</td>
                            <td>{moment(elem?.endDate)?.format('DD-MM-YYYY')}</td>
                            <td>
                              <Badge className={elem?.deleted ? 'bg-success' : 'bg-danger'}>
                                {elem?.deleted ? 'Yes' : 'No'}
                              </Badge>
                            </td>
                            <td>
                              <Badge className={elem?.active ? 'bg-success' : 'bg-danger'}>
                                {elem?.active ? 'Active' : 'Disabled'}
                              </Badge>
                            </td>

                            <td className="text-center">
                              {!elem?.deleted && (
                                <UncontrolledDropdown>
                                  <DropdownToggle className="card-drop" tag="a">
                                    <i className="mdi mdi-dots-vertical font-size-18" />
                                  </DropdownToggle>
                                  <DropdownMenu className="dropdown-menu-end">
                                    {elem?.active && (
                                      <DropdownItem className="border-bottom">
                                        <Link
                                          to={buildURL(SMKLinks.EDIT_SUBSCRIPTION_OFFER, {
                                            id: elem?.id,
                                          })}>
                                          <div>
                                            <i className="mdi mdi-pencil font-size-16 text-success me-1" />{' '}
                                            <span style={{ color: '#212529' }}>Edit</span>
                                          </div>
                                        </Link>
                                      </DropdownItem>
                                    )}

                                    <DropdownItem onClick={() => handleDeletePlan(elem)}>
                                      <div>
                                        <i className="mdi mdi-trash-can-outline font-size-16 text-danger me-1" />{' '}
                                        <span style={{ color: '#212529' }}>Delete</span>
                                      </div>
                                    </DropdownItem>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              )}
                            </td>
                          </tr>
                        ))
                      ) : (
                        <TableError />
                      )}
                    </tbody>
                  </Table>
                </div>
              </div>
            </Col>
          </Row>

          {!isEmpty(subscriptionOffersList) && (
            <Paginate pageInfo={subscriptionOffersPagination} onChangePage={handleChangePage} />
          )}
        </Container>
      </div>
    </>
  );
};

export default SubscriptionOffers;
