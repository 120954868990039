import Loader from 'components/Loader';
import useRequest from 'hooks/useRequest';
import React, { useEffect, useState } from 'react';
import { Button, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import Select from 'react-select';
import isEmpty from 'lodash/isEmpty';
import { toast } from 'react-toastify/dist';
import { SOURCE } from 'utils/constants';

const UpdateCreatedbyModal = ({ showCreatedByModal, setShowCreatedByModal, apiUrl, isTpzRoute }) => {
  const [staffUsers, setStaffUsers] = useState([]);
  const [createdByType, setCreatedByType] = useState();
  const [error, setError] = useState('');

  const [getStaffUsersRequest, { loading: getStaffUsersLoading }] = useRequest({
    url: `/user/admin/search`,
    method: 'POST',
    onSuccess: (data) => {
      setStaffUsers(
        data?.results?.map((d) => {
          return {
            ...d,
            label: `${d.firstName} ${d.lastName}\n${d.email}`,
            value: d.id,
          };
        })
      );
    },
  });

  const [updateCreatedByRequest, { loading: updateCreatedByLoading }] = useRequest({
    url: apiUrl,
    method: 'PUT',
    onSuccess: (data) => {
      toast.sucess('Sucesss! Updated createdBy');
      setShowCreatedByModal(false);
    },
    onError: (err) => {
      toast.error(err);
    },
  });

  useEffect(() => {
    getStaffUsersRequest({
      body: {
        active: true,
        deleted: false,
        staffOnly: true,
        page: 1,
        size: 500,
        appSource: isTpzRoute ? SOURCE[1]?.value : SOURCE[0]?.value,
      },
    });
  }, []);

  const handleCreatedBy = () => {
    if (!isEmpty(createdByType)) {
      updateCreatedByRequest({
        body: {
          createdBy: {
            id: parseInt(createdByType?.id),
          },
        },
      });
    } else {
      setError('Please select at least one user');
    }
  };

  return (
    <>
      <Loader isActive={getStaffUsersLoading || updateCreatedByLoading} />
      <Modal
        isOpen={showCreatedByModal}
        role="dialog"
        autoFocus
        centered
        className=""
        tabIndex="-1"
        toggle={() => {
          setShowCreatedByModal(false);
        }}>
        <div className="modal-content">
          <ModalHeader
            className="modalHeader"
            toggle={() => {
              setShowCreatedByModal(false);
            }}>
            Update CreatedBy
          </ModalHeader>
          <ModalBody>
            <FormGroup className="mb-3 mt-4">
              <Label for="school_createdBy" className="form-label">
                Select Created By
              </Label>
              <Select
                styles={{
                  menu: (provided) => ({ ...provided, zIndex: 9999 }),
                }}
                id="school_createdBy"
                options={staffUsers}
                value={createdByType}
                onChange={(e) => {
                  setCreatedByType(e);
                  setError('');
                }}
              />
            </FormGroup>

            <p className="text-danger mb-0">{error}</p>
          </ModalBody>
          <ModalFooter>
            <Button type="button" color="success" onClick={() => handleCreatedBy()}>
              Save
            </Button>
          </ModalFooter>
        </div>
      </Modal>
    </>
  );
};

export default UpdateCreatedbyModal;
