import AdmissionInquiryList from 'containers/school/AdmissionInquiry';
import React from 'react';
import { Redirect } from 'react-router-dom';
// import WorksheetsList from '../containers/worksheets/WorksheetsList';
// import WorksheetsCreate from '../containers/worksheets/CreateWorksheet/index.js';
// import AdminProfile from '../containers/authentication/admin-profile';
import ForgetPwd from '../containers/authentication/forgetPassword';
// authentication related pages
import Logout from '../containers/authentication/Logout';
// import Register from '../containers/authentication/Register';
import LOGIN_ROUTES from '../containers/authentication/routes';
import BABY_NAME_ROUTES from '../containers/babyName/routes';
import DASHBOARD_ROUTES from '../containers/dashboard/routes';
import GENERATE_SITEMAP_ROUTES from '../containers/generateSitemap/routes';
import CONTACT_INQUIRY_ROUTES from '../containers/inquiry/routes';
import AddRole from '../containers/role/addRole';
import RolesList from '../containers/role/roleList';
// import ContactInquiry from '../containers/inquiry/InquiryList';
// import SchoolsList from '../containers/school/SchoolList/school-list';
// import AdmissionInquiry from '../containers/school/AdmissionInquiry';
import BLOG_ROUTES from '../containers/blog/routes';
import CALCULATORS_ROUTES from '../containers/calculators/routes';
import COLLEGE_ROUTES from '../containers/college/routes';
import DOCTORS_ROUTES from '../containers/doctors/routes';
import LocationMasterList from '../containers/location/LocationMasterList';
import LOCATION_ROUTES from '../containers/location/routes';
import OTHERS_ROUTES from '../containers/others/routes';
import EditElement from '../containers/ptable/EditElement';
import EditProperty from '../containers/ptable/EditProperty';
import ElementsList from '../containers/ptable/ElementsList';
import GroupMasterList from '../containers/ptable/GroupMasterList';
import PropertyMasterList from '../containers/ptable/PropertyMasterList';
import EditSchool from '../containers/school/EditSchool';
import SchoolReviews from '../containers/school/SchoolReviews';
import SCHOOL_ROUTES from '../containers/school/routes';
import AddUser from '../containers/user/AddUser/addUser';
import UsersProfile from '../containers/user/UserList/UsersProfile/usersProfile';
import WORKSHEET_ROUTES from '../containers/worksheets/routes';

import CategoryList from 'containers/forum/Category/categoryList';
import PostDetail from 'containers/forum/Post/postDetail';
import PostsList from 'containers/forum/Post/postsList';
import TopicDetail from 'containers/forum/Topic/topicDetail';
import TopicsList from 'containers/forum/Topic/topicsList';
import { SMKLinks } from '../utils/links';

import { CMS_ROUTES } from 'containers/cms/routes';
import { FORUM_ROUTES } from 'containers/forum/routes';
import { TAXONOMY_ROUTES } from 'containers/taxonomy/routes';
import { TPZ_ROUTES } from 'containers/tpz/routes';
import { USER_ROUTES } from 'containers/user/routes';
import UpdateLocation from '../containers/location/UpdateLocation';

const userRoutes = [
  // smk routes
  // { path: "/dashboard", component: Dashboard },topicDetail
  // { path: "/forum", component: Chat },
  { path: '/forum/topics-list', component: TopicsList },
  { path: '/forum/posts-list', component: PostsList },
  { path: '/forum/category-list', component: CategoryList },
  { path: '/forum/post/detail/:id', component: PostDetail },
  { path: '/forum/topic/detail/:id', component: TopicDetail },

  // { path: '/worksheets/list', component: WorksheetsList },
  // { path: '/worksheets/add', component: WorksheetsCreate },
  ...WORKSHEET_ROUTES,
  ...FORUM_ROUTES,
  ...TPZ_ROUTES,
  ...TAXONOMY_ROUTES,
  // { path: '/babynames/list', component: BabynamesList },
  // { path: '/babynames/add', component: AddBabynames },
  // { path: '/babynames/suggested-names', component: SuggestedBabynamesList },
  ...BABY_NAME_ROUTES,

  { path: '/roles/list', component: RolesList },
  { path: '/roles/add', component: AddRole },
  { path: '/roles/:id', component: AddRole },

  { path: '/ptable/elements/list', component: ElementsList },
  { path: '/ptable/property/list', component: PropertyMasterList },
  { path: '/ptable/property/:id', component: EditProperty },
  { path: '/ptable/group/list', component: GroupMasterList },
  { path: '/ptable/element/:id', component: EditElement },

  { path: '/location/list', component: LocationMasterList },
  { path: '/location/add', component: UpdateLocation },
  { path: '/location/edit/:id', component: UpdateLocation },

  // { path: '/users/list', component: UsersList },
  { path: '/users/profile', component: UsersProfile },
  { path: '/users/profile/:id', component: UsersProfile },
  // { path: '/users/add', component: AddUser },
  { path: '/users/:id', component: AddUser },

  // { path: '/blog/list', component: BlogList },

  // { path: '/users/contact-inquiry', component: ContactInquiry },
  ...CONTACT_INQUIRY_ROUTES,
  // //profile
  // { path: '/profile', component: AdminProfile },

  // { path: '/projects-overview', component: ProjectsOverview },
  // { path: '/projects-overview/:id', component: ProjectsOverview },

  { path: '/school-reviews-list', component: SchoolReviews },
  // { path: '/school/list', component: SchoolsList },
  { path: '/school/admission-inquiry', component: AdmissionInquiryList },
  // { path: '/school/add', component: AddSchool },
  { path: '/school/:id/edit', component: EditSchool },
  ...SCHOOL_ROUTES,
  ...CALCULATORS_ROUTES,
  ...DOCTORS_ROUTES,

  ...DASHBOARD_ROUTES,
  ...COLLEGE_ROUTES,
  ...BLOG_ROUTES,
  ...CMS_ROUTES,
  // this route should be at the end of all other routes
  {
    path: '/',
    exact: true,
    component: () => <Redirect to={SMKLinks.DASHBOARD} />,
  },

  ...GENERATE_SITEMAP_ROUTES,
  ...LOCATION_ROUTES,
  ...OTHERS_ROUTES,
  ...USER_ROUTES,
];

const authRoutes = [
  { path: '/logout', component: Logout },
  // {path: "/login", component: Login},
  { path: '/forgot-password', component: ForgetPwd },
  // { path: '/register', component: Register },

  ...LOGIN_ROUTES,
];

export { authRoutes, userRoutes };
