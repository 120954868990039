import Loader from 'components/Loader';
import { useFormik } from 'formik';
import isEmpty from 'lodash/isEmpty';
import React from 'react';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { Button, Card, CardBody, Col, Container, Form, FormFeedback, FormGroup, Input, Label, Row } from 'reactstrap';
import { NotificationActionEnum } from 'utils/constants';
import { titleCaseIfExists } from 'utils/helper';
import * as Yup from 'yup';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import useRequest from '../../../hooks/useRequest';

const SendNotifications = () => {
  toast.configure();

  const [sendNotificationRequest, { loading: sendNotificationLoading }] = useRequest({
    url: '/notification/admin/send',
    method: 'POST',
    onSuccess: (data) => {
      toast.success('Success! Notification sended.');
    },
    onError: (err) => {
      toast.error(err?.message);
    },
  });

  const isJSON = (val) => {
    try {
      const parseObj = JSON.parse(val);
      return typeof parseObj === 'object' || typeof parseObj === 'Array' ? true : false;
    } catch (err) {
      toast.error(err);
      return false;
    }
  };

  const actionValues = Object.keys(NotificationActionEnum)?.map((name) => ({
    label: titleCaseIfExists(name),
    value: NotificationActionEnum[name],
  }));

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: false,
    initialValues: {
      topic: '',
      deviceIds: [],
      userIds: [],
      notification_title: '',
      notification_body: '',
      action: '',
      payload: '',
    },
    onSubmit: (values, { resetForm }) => {
      if (isJSON(values?.payload)) {
        const api_payload = {
          topic: values?.topic,
          deviceIds: values?.deviceIds?.map((e) => e?.id)?.filter((elem) => elem !== ''),
          userIds: values?.userIds?.map((e) => e?.id)?.filter((elem) => elem !== ''),
          payload: {
            notification: {
              title: values?.notification_title,
              body: values?.notification_body,
            },
            data: {
              action: values?.action,
              payload: JSON.stringify(JSON.parse(values?.payload)),
            },
          },
        };

        resetForm();
        sendNotificationRequest({
          body: api_payload,
        });
      } else {
        toast.error('Please provide valid JSON payload');
      }
    },
    validationSchema: Yup.object().shape({
      // topic: Yup.string().required('Please provide topic'),
      // deviceIds: Yup.string().required('Please provide deviceIds'),
      // userIds: Yup.string().required('Please provide userIds'),
      notification_title: Yup.string().required('Please provide notification title'),
      // notification_body: Yup.string().required('Please provide notification body'),
      action: Yup.string().required('Please provide action'),
      payload: Yup.string().required('Please provide payload'),
    }),
  });

  const renderMultipleId = (name, formikvalue) => {
    const handleAddId = () => {
      formik.setFieldValue(formikvalue, [...formik.values[formikvalue], '']);
    };

    const handleRemoveId = (index) => {
      const array = formik.values[formikvalue].slice();
      array.splice(index, 1);
      formik.setFieldValue(formikvalue, array);
    };

    const handleUpdatelId = (index, value) => {
      const array = formik.values[formikvalue].slice();
      array[index] = { ...array[index], id: value };
      formik.setFieldValue(formikvalue, array);
    };

    return (
      <>
        <Label className="form-label mb-4 border-bottom pb-2 w-100">{name}</Label>
        {formik.values[formikvalue]?.map((singleId, i) => (
          <Row>
            <Col md={10}>
              <FormGroup className="mb-4">
                <Label htmlFor="Device ID" className="form-label  ">
                  {formikvalue === 'userIds' ? 'User Ids' : name}
                </Label>
                <Input
                  id="device_id"
                  type={'text'}
                  // type={formikvalue === 'userIds' ? 'number' : 'text'}
                  className="form-control"
                  placeholder="ID..."
                  value={singleId.id}
                  onChange={(e) => handleUpdatelId(i, e.target.value)}
                />
                {!isEmpty(formik.errors[formikvalue]) && (
                  <div className="invalid-feedback d-block">{formik.errors[formikvalue][i]}</div>
                )}
              </FormGroup>
            </Col>
            <Col sm={2}>
              <div className="mt-4">
                <Button color="danger" className=" w-100" onClick={() => handleRemoveId(i)}>
                  <i className="bx bx-trash" />
                </Button>
              </div>
            </Col>
          </Row>
        ))}
        <Col md={12}>
          <Button className="w-100 mt-3" color="light" onClick={handleAddId}>
            <i className="bx bx-plus me-2" />
            Add {name}
          </Button>
        </Col>
      </>
    );
  };

  return (
    <div className="page-content">
      <Loader isActive={sendNotificationLoading} />

      <Container fluid>
        <Breadcrumbs title="Others" breadcrumbItem="Send Notifications" />

        <Form onSubmit={formik.handleSubmit}>
          <Row>
            <Col md={9}>
              <Card>
                <CardBody>
                  <Row className="align-items-center">
                    <Col md={6}>
                      <FormGroup className="mb-4">
                        <Label for="topic" className="form-label  ">
                          Topic
                        </Label>
                        <Input
                          id="topic"
                          name="topic"
                          type="text"
                          className="form-control"
                          placeholder="Topic.."
                          invalid={!!(formik.touched.topic && formik.errors.topic)}
                          {...formik.getFieldProps('topic')}
                        />
                        <FormFeedback>{formik.errors.topic}</FormFeedback>
                      </FormGroup>
                    </Col>

                    <Col md={6}>
                      <FormGroup className="mb-4">
                        <Label for="action" className="form-label  ">
                          Action
                        </Label>

                        <Select
                          id="action"
                          options={actionValues}
                          value={
                            !isEmpty(formik.values.action)
                              ? actionValues?.find((e) => e?.value === formik.values.action)
                              : null
                          }
                          onChange={(obj) => formik.setFieldValue('action', obj?.value)}
                        />
                        <FormFeedback className="d-block">{formik.errors.action}</FormFeedback>
                      </FormGroup>
                    </Col>

                    <Col md={12}>
                      <FormGroup className="mb-4">
                        <Label for="notification_title" className="form-label  ">
                          Title
                        </Label>
                        <Input
                          id="notifica  tion_title"
                          name="notification_title"
                          type="text"
                          className="form-control"
                          placeholder="Notification Title.."
                          invalid={!!(formik.touched.notification_title && formik.errors.notification_title)}
                          {...formik.getFieldProps('notification_title')}
                        />
                        <FormFeedback>{formik.errors.notification_title}</FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col md={12}>
                      <FormGroup className="mb-4">
                        <Label for="notification_body" className="form-label  ">
                          Body
                        </Label>
                        <Input
                          id="notification_body"
                          name="notification_body"
                          rows={5}
                          type="textarea"
                          className="form-control"
                          placeholder="Notification Body.."
                          invalid={!!(formik.touched.notification_body && formik.errors.notification_body)}
                          {...formik.getFieldProps('notification_body')}
                        />
                        <FormFeedback>{formik.errors.notification_body}</FormFeedback>
                      </FormGroup>
                    </Col>

                    <Col md={12}>
                      <FormGroup className="mb-4">
                        <Label for="payload" className="form-label  ">
                          Payload
                        </Label>
                        <Input
                          id="payload"
                          name="payload"
                          rows={5}
                          type="textarea"
                          className="form-control"
                          placeholder="Payload JSON.."
                          invalid={!!(formik.touched.payload && formik.errors.payload)}
                          {...formik.getFieldProps('payload')}
                        />
                        <FormFeedback>{formik.errors.payload}</FormFeedback>
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
              </Card>

              <Row>
                <Col lg={6}>
                  <Card>
                    <CardBody>{renderMultipleId('Device Ids', 'deviceIds')}</CardBody>
                  </Card>
                </Col>
                <Col lg={6}>
                  <Card>
                    <CardBody>{renderMultipleId('User Ids', 'userIds')}</CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
            <Col md={3}>
              <Card>
                <CardBody>
                  <Button type="submit" color="primary" className="w-100">
                    Send Now
                  </Button>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Form>
      </Container>
    </div>
  );
};

export default SendNotifications;
