import { useFormik } from 'formik';
import invert from 'lodash/invert';
import React, { useEffect, useState } from 'react';
import 'react-phone-input-2/lib/style.css';
import Select from 'react-select';
import { Button, Card, CardBody, Col, Container, Form, FormFeedback, FormGroup, Input, Label, Row } from 'reactstrap';
import * as Yup from 'yup';
import Loader from '../../../components/Loader';
import useRequest from '../../../hooks/useRequest';
import { useRouter } from '../../../hooks/useRouter';
import { GENDER, SOURCE, USER_ROLE, USER_SOURCE, USER_STATUS } from '../../../utils/constants';
import { isValidPassword, titleCaseIfExists } from '../../../utils/helper';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { SMKLinks } from 'utils/links';
import Breadcrumbs from '../../../components/Common/Breadcrumb';

const AddUser = () => {
  const router = useRouter();
  const [roles, setRoles] = useState([]);
  const [user, setUser] = useState(null);
  toast.configure();

  const isTpzRoute = router?.location?.pathname?.includes('tpz');
  const appSource = isTpzRoute ? 'TPZ' : 'SMK';

  const history = useHistory();

  const [getUserRolesRequest, { loading: getUserRolesLoading }] = useRequest({
    url: `/user/admin/roles/search`,
    method: 'POST',
    onSuccess: (data) => {
      setRoles(data?.results);
    },
    onError: (err) => {
      toast.error(err?.message);
    },
  });

  const [addUserRequest, { loading: addUserLoading }] = useRequest({
    url: '/user/admin',
    method: 'POST',
    onSuccess: (data) => {
      window.location = isTpzRoute ? SMKLinks.TPZ_USERS_LISTING : SMKLinks.SMK_USERS_LISTING;
      toast.success('Success! User has been Added.');
    },
    onError: (err) => {
      toast.error(err?.message);
    },
  });

  const [updateUserRequest, { loading: updateUserLoading }] = useRequest({
    method: 'PUT',
    onSuccess: (data) => {
      window.location = isTpzRoute ? SMKLinks.TPZ_USERS_LISTING : SMKLinks.SMK_USERS_LISTING;
      toast.success('Success! User has been Updated.');
    },
    onError: (err) => {
      toast.error(err?.message);
    },
  });

  const [getUserRequest, { loading: getUserLoading }] = useRequest({
    method: 'GET',
    onSuccess: (data) => {
      setUser(data);
    },
    onError: (err) => {
      toast.error(err?.message);
    },
  });

  useEffect(() => {
    if (router?.query?.id) {
      getUserRequest({ url: `/user/admin/${router?.query?.id}` });
    }

    getUserRolesRequest({ page: 1, size: 500 });
  }, []);

  const isEmptyValue = (val) => {
    return val ? val : undefined;
  };
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: user
      ? {
          ...user,
          roles: isEmptyValue(user.roles),
        }
      : {
          email: '',
          bio: '',
          password: '',
          firstName: '',
          lastName: '',
          gender: '',
          role: USER_ROLE.USER,
          source: USER_SOURCE.ADMIN,
          status: USER_STATUS.ACTIVE,
          roles: [],
        },

    onSubmit(values) {
      let newValues = { ...values };
      const appSourceValue = isTpzRoute ? SOURCE[1]?.value : SOURCE[0]?.value;

      if (user) {
        newValues = { ...user, ...newValues };
        updateUserRequest({ url: `/user/admin/${user?.id}`, data: { ...newValues, appSource: appSourceValue } });
      } else {
        addUserRequest({ data: { ...newValues, appSource: appSourceValue } });
      }
    },
    validationSchema: Yup.object().shape({
      firstName: Yup.string().required('First Name is requried'),
      lastName: Yup.string().required('Last Name is requried'),
      email: Yup.string().required('Email is requried'),
      gender: Yup.string()
        .required('Please Select a Gender')
        .nullable(true),
      role: Yup.number().required('Please Select a Type'),
      source: Yup.number().required('Please Select a Source'),
      status: Yup.number().required('Please Select a Status'),

      password: Yup.string()
        .optional()
        .test('new-password-test', 'Please provide valid password', function(val) {
          if (user) {
            return true;
          }
          return isValidPassword(val);
        }),
      confirmPassword: Yup.string()
        .optional()
        .test('confirm-new-password-test', 'Please provide valid password', function(val) {
          if (user) {
            return true;
          }
          const { password } = this.parent;
          let message = '';
          if (!isValidPassword(val)) {
            message = 'Please provide valid password';
          } else if (val !== password) {
            message = 'Passwords do not match';
          } else {
            return true;
          }
          return this.createError({ message });
        }),
    }),
  });

  return (
    <div className="page-content">
      <Loader isActive={getUserLoading || addUserLoading || updateUserLoading || getUserRolesLoading} />
      <Container fluid>
        {/* Render Breadcrumbs */}
        <Breadcrumbs title={`${appSource} Users`} breadcrumbItem={`${user ? 'Edit' : 'Add'} ${appSource} User`} />

        <Form onSubmit={formik.handleSubmit}>
          <Row>
            <Col md={9}>
              <Card>
                <CardBody>
                  <Row>
                    <Col md={6}>
                      <FormGroup className="mb-4">
                        <Label htmlFor="firstName" className="form-label  ">
                          First Name
                        </Label>
                        <Input
                          id="firstName"
                          type="text"
                          className="form-control"
                          placeholder="First Name"
                          invalid={!!(formik.touched.firstName && formik.errors.firstName)}
                          {...formik.getFieldProps('firstName')}
                        />
                        <FormFeedback>{formik.errors.firstName}</FormFeedback>
                      </FormGroup>
                    </Col>

                    <Col md={6}>
                      <FormGroup className="mb-4">
                        <Label htmlFor="lastName" className="form-label  ">
                          Last Name
                        </Label>
                        <Input
                          id="lastName"
                          type="text"
                          className="form-control"
                          placeholder="Last Name"
                          invalid={!!(formik.touched.lastName && formik.errors.lastName)}
                          {...formik.getFieldProps('lastName')}
                        />
                        <FormFeedback>{formik.errors.lastName}</FormFeedback>
                      </FormGroup>
                    </Col>

                    <Col md={12}>
                      <FormGroup className="mb-4">
                        <Label htmlFor="email" className="form-label  ">
                          Email
                        </Label>
                        <Input
                          id="email"
                          type="text"
                          className="form-control"
                          placeholder="Email"
                          invalid={!!(formik.touched.email && formik.errors.email)}
                          {...formik.getFieldProps('email')}
                        />
                        <FormFeedback>{formik.errors.email}</FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col md={12}>
                      <FormGroup className="mb-4">
                        <Label htmlFor="bio" className="form-label  ">
                          Bio
                        </Label>
                        <Input
                          id="bio"
                          type="text"
                          className="form-control"
                          placeholder="Bio"
                          invalid={!!(formik.touched.bio && formik.errors.bio)}
                          {...formik.getFieldProps('bio')}
                        />
                        <FormFeedback>{formik.errors.bio}</FormFeedback>
                      </FormGroup>
                    </Col>

                    {!user?.id && (
                      <>
                        <Col md={6}>
                          <FormGroup className="mb-4">
                            <Label htmlFor="password" className="form-label  ">
                              Password
                            </Label>
                            <Input
                              id="password"
                              type="text"
                              className="form-control"
                              placeholder="Password"
                              invalid={!!(formik.touched.password && formik.errors.password)}
                              {...formik.getFieldProps('password')}
                            />
                            <FormFeedback>{formik.errors.password}</FormFeedback>
                          </FormGroup>
                        </Col>
                        <Col md={6}>
                          <FormGroup className="mb-4">
                            <Label htmlFor="confirmPassword" className="form-label  ">
                              Confirm Password
                            </Label>
                            <Input
                              id="confirmPassword"
                              type="text"
                              className="form-control"
                              placeholder="Confirm Password"
                              invalid={!!(formik.touched.confirmPassword && formik.errors.confirmPassword)}
                              {...formik.getFieldProps('confirmPassword')}
                            />
                            <FormFeedback>{formik.errors.confirmPassword}</FormFeedback>
                          </FormGroup>
                        </Col>
                      </>
                    )}

                    <Col md={6}>
                      <FormGroup className="mb-4">
                        <Label htmlFor="gender" className="form-label  ">
                          Gender
                        </Label>
                        <Select
                          name="gender"
                          id="gender"
                          placeholder="Please select an option"
                          options={Object.entries(GENDER).map(([key, value]) => ({
                            value,
                            label: titleCaseIfExists(key),
                          }))}
                          onChange={(val) => formik.setFieldValue('gender', val?.value)}
                          value={
                            formik.values.gender && {
                              label: titleCaseIfExists(invert(GENDER)[formik.values.gender]),
                              value: formik.values.gender,
                            }
                          }
                        />
                        {formik.touched.gender && (
                          <div className="invalid-feedback d-block">{formik.errors.gender}</div>
                        )}
                      </FormGroup>
                    </Col>

                    <Col md={6}>
                      <FormGroup className="mb-4">
                        <Label htmlFor="role" className="form-label  ">
                          Type
                        </Label>
                        <Select
                          menuPlacement="top"
                          id="role"
                          placeholder="Please select an option"
                          options={Object.entries(USER_ROLE).map(([key, value]) => ({
                            value,
                            label: titleCaseIfExists(key),
                          }))}
                          value={{
                            label: titleCaseIfExists(invert(USER_ROLE)[formik.values.role]),
                            value: formik.values.role,
                          }}
                          onChange={(val) => formik.setFieldValue('role', val?.value)}
                        />

                        {formik.touched.role && <div className="invalid-feedback d-block">{formik.errors.role}</div>}
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup className="mb-4">
                        <Label htmlFor="source" className="form-label  ">
                          Source
                        </Label>

                        <Select
                          menuPlacement="top"
                          id="source"
                          placeholder="Please select an option"
                          options={Object.entries(USER_SOURCE).map(([key, value]) => ({
                            value,
                            label: titleCaseIfExists(key),
                          }))}
                          value={{
                            label: titleCaseIfExists(invert(USER_SOURCE)[formik.values.source]),
                            value: formik.values.source,
                          }}
                          onChange={(val) => formik.setFieldValue('source', val?.value)}
                        />
                        {formik.touched.source && (
                          <div className="invalid-feedback d-block">{formik.errors.source}</div>
                        )}
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup className="mb-4">
                        <Label htmlFor="userStatus" className="form-label  ">
                          Status
                        </Label>

                        <Select
                          menuPlacement="top"
                          id="userStatus"
                          placeholder="Please select an option"
                          options={Object.entries(USER_STATUS).map(([key, value]) => ({
                            value,
                            label: titleCaseIfExists(key),
                          }))}
                          value={{
                            label: titleCaseIfExists(invert(USER_STATUS)[formik.values.status]),
                            value: formik.values.status,
                          }}
                          onChange={(val) => formik.setFieldValue('status', val?.value)}
                        />
                        {formik.touched.status && (
                          <div className="invalid-feedback d-block">{formik.errors.status}</div>
                        )}
                      </FormGroup>
                    </Col>

                    <Col md={12}>
                      <FormGroup className="mb-4">
                        <Label htmlFor="name" className="form-label  ">
                          Roles
                        </Label>
                        <Select
                          menuPlacement="top"
                          isClearable
                          isMulti
                          id="user_roles"
                          placeholder="Please select user roles"
                          options={roles.map((s) => ({
                            ...s,
                            value: s.id,
                            label: s.name,
                          }))}
                          value={formik.values.roles?.map((s) => ({
                            ...s,
                            value: s.id,
                            label: s.name,
                          }))}
                          onChange={(val) => formik.setFieldValue('roles', val)}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col md={3}>
              <Card>
                <CardBody>
                  <Button type="submit" color="primary" className="mb-3 w-100">
                    Save User
                  </Button>
                  <Button
                    className="w-100"
                    color="light"
                    onClick={() => {
                      history.replace(isTpzRoute ? SMKLinks.TPZ_USERS_LISTING : SMKLinks.SMK_USERS_LISTING);
                    }}>
                    Cancel
                  </Button>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Form>
      </Container>
    </div>
  );
};

AddUser.propTypes = {};

export default AddUser;
