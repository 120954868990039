import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Button,
  Label,
  Input,
  Container,
  Form,
  FormFeedback,
  CardTitle,
} from 'reactstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import isEmpty from 'lodash/isEmpty';
import Select from 'react-select';
import { Link, useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Loader from 'components/Loader';
import { createStructuredSelector } from 'reselect';
import { useDispatch, useSelector } from 'react-redux';
import { LOCATION_LEVEL_ARRAY } from 'utils/constants';
import { useRouter } from 'hooks/useRouter';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import useRequest from '../../../hooks/useRequest';
import { SMKLinks } from '../../../utils/links';
import { countriesSelector, regionsSelector, citiesSelector, subAreasSelector, districtsSelector } from '../selectors';
import { getCities, getCountries, getDistricts, getRegions, getSubAreas } from '../actions';
import InputSlug from 'components/EditSlug/InputSlug';

const stateSelector = createStructuredSelector({
  countries: countriesSelector,
  regions: regionsSelector,
  districtsList: districtsSelector,
  citiesList: citiesSelector,
  subAreas: subAreasSelector,
});

const UpdateLocation = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id: locationID } = useParams();
  const router = useRouter();

  const { query } = router;

  toast.configure();

  const [locationDetails, setlocationDetails] = useState();
  let { countries, regions, citiesList, subAreas, districtsList } = useSelector(stateSelector);

  const [districts, setdistricts] = useState([]);
  const [cities, setCities] = useState([]);

  useEffect(() => {
    if (!isEmpty(districtsList)) {
      setdistricts(
        districtsList?.map((e) => ({
          ...e,
          label: `${e?.name} - ( ${e?.level} )`,
        }))
      );
    }
  }, [districtsList]);

  useEffect(() => {
    if (!isEmpty(citiesList)) {
      setCities(
        citiesList?.map((e) => ({
          ...e,
          label: `${e?.name} - ( ${e?.level} )`,
        }))
      );
    }
  }, [citiesList]);

  const [getLocationRequest, { loading: getLocationLoading }] = useRequest({
    url: `/place/admin/${locationID}`,
    method: 'GET',
    onSuccess: (data) => {
      setlocationDetails(data);
    },
    onError: (err) => {
      toast.error(err?.message);
      console.log(err);
    },
  });

  const [addLocationRequest, { loading: addLocationLoading }] = useRequest({
    url: `/place/admin`,
    method: 'POST',
    onSuccess: (data) => {
      const url = SMKLinks.LOCATION_LISTING;
      history.replace(url);
      toast.success('Success! Location has been added.');
    },
    onError: (err) => {
      toast.error(err?.message);
      console.log(err);
    },
  });

  const [updateLocationRequest, { loading: updateLocationLoading }] = useRequest({
    url: `/place/admin/${locationID}`,
    method: 'PUT',
    onSuccess: (data) => {
      const url = SMKLinks.LOCATION_LISTING;
      history.replace(url);
      toast.success('Success! Location has been updated.');
    },
    onError: (err) => {
      toast.error(err?.message);
      console.log(err);
    },
  });

  const editInitialValues = () => {
    const levelObj = LOCATION_LEVEL_ARRAY?.find((e) => e?.value === locationDetails?.level);
    const countryObj = countries?.find((e) => e?.value === locationDetails?.country?.id);
    const districtObj = districts?.find((e) => e?.value === locationDetails?.district?.id);
    const regionObj = regions?.find((e) => e?.value === locationDetails?.region?.id);
    const cityObj = cities?.find((e) => e?.value === locationDetails?.city?.id);

    return {
      id: locationDetails?.id,
      active: locationDetails?.active,
      deleted: locationDetails?.deleted,
      name: locationDetails?.name,
      slug: locationDetails?.slug,
      metro: locationDetails?.metro,
      displayName: locationDetails?.displayName,
      alternateName: locationDetails?.alternateName,
      shortCode: locationDetails?.shortCode,
      level: levelObj,
      country: countryObj,
      region: regionObj,
      city: cityObj,
      district: districtObj,
    };
  };

  const getInitialValues = () => ({
    active: true,
    deleted: false,
    name: '',
    slug: '',
    displayName: '',
    alternateName: '',
    shortCode: '',
    level: '',

    metro: '',

    country: '',
    region: '',
    district: '',
    city: '',
  });

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: false,
    initialValues: isEmpty(locationID) ? { ...getInitialValues() } : { ...editInitialValues() },
    onSubmit: (values) => {
      const data = {
        active: values?.active,
        deleted: values?.deleted,
        name: values?.name,
        metro: values?.metro,
        displayName: values?.displayName,
        alternateName: values?.alternateName,
        shortCode: values?.shortCode,
        level: values?.level?.value,
        ...(!isEmpty(values?.slug) && locationID
          ? {
              slug: values?.slug,
            }
          : {}),
        ...(!isEmpty(values?.country)
          ? {
              country: {
                id: values?.country?.id,
              },
            }
          : {}),
        ...(!isEmpty(values?.region)
          ? {
              region: {
                id: values?.region?.id,
              },
            }
          : {}),
        ...(!isEmpty(values?.district)
          ? {
              district: {
                id: values?.district?.id,
              },
            }
          : {}),
        ...(!isEmpty(values?.city)
          ? {
              city: {
                id: values?.city?.id,
              },
            }
          : {}),
      };
      isEmpty(locationID)
        ? addLocationRequest({
            body: {
              ...data,
            },
          })
        : updateLocationRequest({
            body: {
              ...data,
            },
          });
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required('Please provide name'),
      displayName: Yup.string().required('Please provide display name'),
      level: Yup.object()
        .required('Please provide level')
        .nullable(true),
    }),
  });

  useEffect(() => {
    if (!isEmpty(locationID)) {
      getLocationRequest();

      dispatch(
        getCities.trigger({
          level: 3,
          page: 1,
          size: 500,
        })
      );
      dispatch(
        getRegions.trigger({
          level: 1,
          page: 1,
          size: 500,
        })
      );
    }
  }, [locationID]);

  const onCountrySelect = (country) => {
    if (!isEmpty(country)) {
      dispatch(
        getRegions.trigger({
          level: 1,
          country: country.name,
          page: 1,
          size: 500,
        })
      );
      formik.setFieldValue('country', country);
      formik.setFieldValue('region', {});
      formik.setFieldValue('city', {});
    } else {
      formik.setFieldValue('country', '');
    }
  };

  const onRegionSelect = (region) => {
    if (!isEmpty(region)) {
      dispatch(
        getCities.trigger({
          level: 3,
          region: region.name,
          page: 1,
          size: 500,
        })
      );
      formik.setFieldValue('region', region);
      formik.setFieldValue('city', {});
    } else {
      formik.setFieldValue('region', '');
    }

    if (!isEmpty(region)) {
      dispatch(
        getDistricts.trigger({
          level: 2,
          region: region.name,
          page: 1,
          size: 500,
        })
      );
    }
  };

  const onDistrictSelect = (district) => {
    if (!isEmpty(district)) {
      formik.setFieldValue('district', district);
      formik.setFieldValue('city', {});
    } else {
      formik.setFieldValue('district', '');
    }
  };

  const onCitySelect = (city) => {
    if (!isEmpty(city)) {
      formik.setFieldValue('city', city);
    } else {
      formik.setFieldValue('city', '');
    }
  };

  useEffect(() => {
    dispatch(getCountries.trigger({ level: 0, page: 1, size: 500 }));
  }, []);

  // change query params
  useEffect(() => {
    if (!isEmpty(query?.country) && !isEmpty(countries)) {
      onCountrySelect(countries?.find((e) => e?.value === parseInt(query?.country)));
    }
  }, [query?.country, countries]);

  useEffect(() => {
    if (!isEmpty(query?.region) && !isEmpty(regions)) {
      onRegionSelect(regions?.find((e) => e?.value === parseInt(query?.region)));
    }
  }, [query?.region, regions]);

  useEffect(() => {
    if (!isEmpty(query?.district) && !isEmpty(districtsList)) {
      const districtObj = districtsList
        ?.map((e) => ({
          ...e,
          label: `${e?.name} - ( ${e?.level} )`,
        }))
        ?.find((e) => e?.value === parseInt(query?.district));

      formik.setFieldValue('district', districtObj);
    }
  }, [query?.district, districtsList]);

  useEffect(() => {
    if (!isEmpty(query?.district) && !isEmpty(citiesList)) {
      const cityObj = citiesList
        ?.map((e) => ({
          ...e,
          label: `${e?.name} - ( ${e?.level} )`,
        }))
        ?.find((e) => e?.value === parseInt(query?.city));

      formik.setFieldValue('city', cityObj);
    }
  }, [query?.district, citiesList]);

  useEffect(() => {
    if (!isEmpty(query?.level)) {
      formik.setFieldValue(
        'level',
        LOCATION_LEVEL_ARRAY?.find((e) => e?.value == query?.level)
      );
    }
  }, [query?.level]);

  return (
    <>
      <div className="page-content">
        <Loader isActive={addLocationLoading || getLocationLoading || updateLocationLoading} />
        <Container fluid>
          <Breadcrumbs title="Location" breadcrumbItem={`${isEmpty(locationID) ? 'Add' : 'Edit'}  Location`} />

          <Form onSubmit={formik.handleSubmit}>
            <Row>
              <Col md={9}>
                <Card>
                  <CardBody>
                    <Row className="align-items-center">
                      {locationID && (
                        <Col md={12}>
                          <FormGroup className="mb-4">
                            <Label className="form-label  ">Location ID</Label>
                            <Input
                              id="id"
                              style={{ background: 'whitesmoke' }}
                              disabled
                              className="form-control"
                              {...formik.getFieldProps('id')}
                            />
                          </FormGroup>
                        </Col>
                      )}

                      <Col md={locationID ? 6 : 12}>
                        <FormGroup className="mb-4">
                          <Label for="group_name" className="form-label  ">
                            Name
                          </Label>
                          <Input
                            id="group_name"
                            name="group_name"
                            type="text"
                            className="form-control"
                            placeholder="name..."
                            invalid={!!(formik.touched.name && formik.errors.name)}
                            {...formik.getFieldProps('name')}
                          />
                          <FormFeedback>{formik.errors.name}</FormFeedback>
                        </FormGroup>
                      </Col>

                      {locationID && (
                        <Col md={6}>
                          <FormGroup className="mb-4">
                            <Label for="slug" className="form-label  ">
                              Slug
                            </Label>
                            <InputSlug formik={formik} />
                            <FormFeedback>{formik.errors.slug}</FormFeedback>
                          </FormGroup>
                        </Col>
                      )}

                      <Col md={12}>
                        <FormGroup className="mb-4">
                          <Label for="displayName" className="form-label  ">
                            Display Name
                          </Label>
                          <Input
                            id="displayName"
                            name="displayName"
                            type="text"
                            className="form-control"
                            placeholder="display name..."
                            invalid={!!(formik.touched.displayName && formik.errors.displayName)}
                            {...formik.getFieldProps('displayName')}
                          />
                          <FormFeedback>{formik.errors.displayName}</FormFeedback>
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup className="mb-4">
                          <Label for="alternateName" className="form-label  ">
                            Alternate Name
                          </Label>
                          <Input
                            id="alternateName"
                            name="alternateName"
                            type="text"
                            className="form-control"
                            placeholder="alternate name..."
                            invalid={!!(formik.touched.alternateName && formik.errors.alternateName)}
                            {...formik.getFieldProps('alternateName')}
                          />
                          <FormFeedback>{formik.errors.alternateName}</FormFeedback>
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup className="mb-4">
                          <Label for="shortCode" className="form-label  ">
                            Short Code
                          </Label>
                          <Input
                            id="shortCode"
                            name="shortCode"
                            type="text"
                            className="form-control"
                            placeholder="short code..."
                            invalid={!!(formik.touched.shortCode && formik.errors.shortCode)}
                            {...formik.getFieldProps('shortCode')}
                          />
                          <FormFeedback>{formik.errors.shortCode}</FormFeedback>
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup className="mb-4">
                          <Label for="level" className="form-label  ">
                            Level
                          </Label>
                          <Select
                            id="level"
                            isClearable
                            options={LOCATION_LEVEL_ARRAY}
                            value={formik.values.level}
                            onChange={(level) => formik.setFieldValue('level', level)}
                          />
                          {formik.touched.level && (
                            <div className="invalid-feedback d-block">{formik.errors.level}</div>
                          )}
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup className="mb-4">
                          <Label for="metro" className="form-label  ">
                            Metro
                          </Label>
                          <Input
                            id="metro"
                            name="metro"
                            type="text"
                            className="form-control"
                            placeholder="metro station.."
                            invalid={!!(formik.touched.metro && formik.errors.metro)}
                            {...formik.getFieldProps('metro')}
                          />
                          <FormFeedback>{formik.errors.metro}</FormFeedback>
                        </FormGroup>
                      </Col>

                      <Col md={6}>
                        <FormGroup className="mb-4">
                          <Label for="school_country" className="form-label">
                            Country
                          </Label>
                          <Select
                            menuPlacement="top"
                            isClearable
                            id="school_country"
                            options={countries}
                            value={formik?.values?.country}
                            onChange={onCountrySelect}
                          />
                        </FormGroup>
                      </Col>

                      <Col md={6}>
                        <FormGroup className="mb-4">
                          <Label for="school_state" className="form-label  ">
                            Region/State
                          </Label>

                          <Select
                            menuPlacement="top"
                            isClearable
                            id="school_state"
                            placeholder="Select..."
                            value={formik?.values?.region}
                            onChange={onRegionSelect}
                            options={regions}
                          />
                        </FormGroup>
                      </Col>

                      <Col md={6}>
                        <FormGroup className="mb-4">
                          <Label for="district" className="form-label  ">
                            District
                          </Label>
                          <Select
                            menuPlacement="top"
                            isClearable
                            id="district"
                            placeholder="Select..."
                            value={formik?.values?.district}
                            onChange={onDistrictSelect}
                            options={districts}
                          />
                        </FormGroup>
                      </Col>

                      <Col md={6}>
                        <FormGroup className="mb-4">
                          <Label for="school_city" className="form-label  ">
                            City
                          </Label>
                          <Select
                            menuPlacement="top"
                            isClearable
                            id="school_city"
                            placeholder="Select..."
                            value={formik?.values?.city}
                            onChange={onCitySelect}
                            options={cities}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col md={3}>
                <Card>
                  <CardBody>
                    <Button type="submit" color="primary" className="mb-3 w-100">
                      {locationID ? 'Save Location' : 'Add Location'}
                    </Button>

                    <Button
                      className="w-100"
                      color="light"
                      onClick={() => {
                        history.replace(SMKLinks.LOCATION_LISTING);
                      }}>
                      Cancel
                    </Button>
                  </CardBody>
                </Card>
                <Card>
                  <CardBody>
                    <div>
                      <CardTitle className="mb-3">Active </CardTitle>
                      {[true, false].map((option, i) => (
                        <div key={`active_${i}`} className="form-check mb-3 d-inline-block me-4">
                          <input
                            type="radio"
                            id={`active_${option}`}
                            name="active"
                            className="form-check-input"
                            checked={formik.values.active === option}
                            onChange={() => formik.setFieldValue('active', option)}
                          />
                          <label className="form-check-label" htmlFor={`active_${option}`}>
                            {option ? 'Yes' : 'No'}
                          </label>
                        </div>
                      ))}
                    </div>

                    <div>
                      <CardTitle className="mb-3">Delete </CardTitle>
                      {[true, false].map((option, i) => (
                        <div key={`deleted_${i}`} className="form-check mb-3 d-inline-block me-4">
                          <input
                            type="radio"
                            id={`deleted_${option}`}
                            name="deleted"
                            className="form-check-input"
                            checked={formik.values.deleted === option}
                            onChange={() => formik.setFieldValue('deleted', option)}
                          />
                          <label className="form-check-label" htmlFor={`deleted_${option}`}>
                            {option ? 'Yes' : 'No'}
                          </label>
                        </div>
                      ))}
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Form>
        </Container>
      </div>
    </>
  );
};

export default UpdateLocation;
